import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxDeeplinkerService } from 'ngx-deeplinker';
import { GlobalAlertService } from '../shared/services/global-alert.service';
import { TranslateService } from '@ngx-translate/core';
import { OperatorsService } from '../shared/services/operators.service';
import { ProgramsService } from '../shared/services/programs.service';
import { ProductsService } from '../shared/services/products.service';
import { OperatorDetailsComponent } from '../details/operator/operator-details.component';
import { ProgramDetailsComponent } from '../details/program/program-details.component';
import { ProductDetailsComponent } from '../details/product/product-details.component';
import { MarketsService } from '../shared/services/markets.service';
import { Product } from '../shared/model/product.model';
import { Operator } from '../shared/model/operator.model';
import { Program } from '../shared/model/program.model';
import { ErrorHandlingService } from '../shared/services/error-handling.service';

@Component({
  selector: 'app-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss', '../shared/shared.styles.scss'],
})
export class CreateComponent implements OnInit, OnDestroy {
  appPrefix = 'market';
  SUCCESS = 'Created Successfully';
  BAD_REQUEST = 'Oops, There was a problem with your request';
  NOT_CREATED = 'Oops, There was a problem creating your product';
  REQUIRED = 'required';
  marketTypes: any[];
  marketType = 'Product';
  marketId = '';
  marketEntityType = 'Product';
  loading = false;
  saving = false;
  marketEntity: any;
  subs = [];
  loadingClone = false;

  readonly mode = 'create';
  readonly APPPREFIX = 'mrk';
  private productComponentView: ProductDetailsComponent;
  private programComponentView: ProgramDetailsComponent;
  private operatorComponentView: OperatorDetailsComponent;

  @ViewChild('create')
  private theForm: NgForm;

  @ViewChild(ProductDetailsComponent)
  set productComponent(productDetailsComponent: ProductDetailsComponent) {
    this.productComponentView = productDetailsComponent;
  }

  @ViewChild(ProgramDetailsComponent)
  set programComponent(programDetailsComponent: ProgramDetailsComponent) {
    this.programComponentView = programDetailsComponent;
  }

  @ViewChild(OperatorDetailsComponent)
  set operatorComponent(operatorDetailsComponent: OperatorDetailsComponent) {
    this.operatorComponentView = operatorDetailsComponent;
  }

  @ViewChild('create', { static: true }) createForm: NgForm;

  constructor(
    private ngxDeeplinkerService: NgxDeeplinkerService,
    private router: Router,
    private route: ActivatedRoute,
    private messageService: GlobalAlertService,
    private marketsService: MarketsService,
    private operatorsService: OperatorsService,
    private programsService: ProgramsService,
    private productsService: ProductsService,
    private translateService: TranslateService,
    private errorHandlingService: ErrorHandlingService,
  ) {
    this.marketTypes = marketsService.getMarketTypes();

    this.resetAll();
  }

  ngOnInit() {
    const operSub = this.operatorsService.operator$.subscribe((operator) => {
      if (operator?.displayLabel) {
        this.marketEntity = operator;
        this.marketId = operator.id;
        this.marketEntityType = this.marketEntity.drType;
        this.marketEntity.displayLabel = this.marketEntity.displayLabel + ' Clone';
        Object.keys(this.marketEntity.displayLabels).forEach((locale) => {
          this.marketEntity.displayLabels[locale] = this.marketEntity.displayLabels[locale] + ' Clone';
        });
        delete this.marketEntity.children;
        this.loadingClone = false;
      }
    });
    this.subs.push(operSub);

    const progSub = this.programsService.program$.subscribe((program) => {
      if (program?.displayLabel) {
        this.marketEntity = program;
        this.marketEntity.programExternalReferenceId = '';
        this.marketEntity.classicProgramName = '';
        this.marketId = program.id;
        this.marketEntityType = this.marketEntity.drType;
        delete this.marketEntity.children;
        this.marketEntity.displayLabel = this.marketEntity.displayLabel + ' Clone';
        Object.keys(this.marketEntity.displayLabels).forEach((locale) => {
          this.marketEntity.displayLabels[locale] = this.marketEntity.displayLabels[locale] + ' Clone';
        });
        this.loadingClone = false;
      }
    });
    this.subs.push(progSub);

    const prodSub = this.productsService.product$.subscribe((product) => {
      if (product?.displayLabel) {
        this.marketEntity = product;
        this.marketEntity.ecrmId = '';
        this.marketEntity.productExternalReferenceId = '';
        this.marketId = product.id;
        this.marketEntityType = this.marketEntity.drType;
        this.marketEntity.displayLabel = this.marketEntity.displayLabel + ' Clone';
        Object.keys(this.marketEntity.displayLabels).forEach((locale) => {
          this.marketEntity.displayLabels[locale] = this.marketEntity.displayLabels[locale] + ' Clone';
        });
        this.loadingClone = false;
      }
    });
    this.subs.push(prodSub);

    this.route.params.subscribe(this.getNext());
  }

  ngOnDestroy() {
    this.subs.forEach((sub) => {
      sub.unsubscribe();
    });
  }

  private getNext() {
    return async () => {
      /* tslint:disable:no-string-literal */
      if (this.route.queryParams['value'] && this.route.queryParams['value']['drType']) {
        this.marketEntityType = this.route.queryParams['value']['drType'];
        this.marketId = this.route.queryParams['value']['clone'];
      }

      if (!this.marketId || !this.marketEntityType || !this.router.isActive('/create', false)) {
        this.loadingClone = false;
        return;
      }

      this.loadingClone = true;

      switch (this.marketEntityType) {
        case 'Operator':
          this.operatorsService.fetchOperator(this.marketId);
          break;
        case 'Program':
          this.programsService.fetchProgram(this.marketId);
          break;
        default:
          this.productsService.fetchProduct(this.marketId);
      }
    };
  }

  handleCancel() {
    this.ngxDeeplinkerService.returnHandler({ appPrefix: this.appPrefix });
    switch (this.marketEntityType) {
      case 'Operator':
        break;
      case 'Program':
        this.programComponentView.resetForm();
        break;
      default:
        this.productComponentView.resetForm();
    }
    this.router.navigate(['list'], {});
  }

  getTitle() {
    switch (this.marketEntityType) {
      case 'Operator':
        return 'operator.create.page_title';
      case 'Program':
        return 'program.create.page_title';
      default:
        return 'product.create.page_title';
    }
  }

  getSubtitle() {
    switch (this.marketEntityType) {
      case 'Operator':
        return 'operator.create.subtitle';
      case 'Program':
        return 'program.create.subtitle';
      default:
        return 'product.create.subtitle';
    }
  }

  async handleSubmit() {
    try {
      this.saving = true;
      let marketEntityModel = this.getModelForMarketEntityType();

      marketEntityModel = { ...marketEntityModel };
      delete marketEntityModel.id; // shouldn't be necessary but remove the ID to create a new one.

      switch (this.marketEntityType) {
        case 'Operator':
          this.handleOperatorResponse(await this.operatorsService.createOperator(marketEntityModel));
          break;
        case 'Program':
          this.handleProgramResponse(await this.programsService.createProgram(marketEntityModel));
          break;
        default:
          this.handleProductResponse(await this.productsService.createProduct(marketEntityModel));
      }
      this.operatorsService.refetchOperators();
    } catch (e) {
      this.saving = false;
      this.errorHandlingService.setErrorMessage(e);
    }
  }

  private getModelForMarketEntityType() {
    let entity: any;
    switch (this.marketEntityType) {
      case 'Operator':
        entity = {
          ...this.operatorComponentView._operator,
        };
        break;
      case 'Program':
        const program = this.programComponentView.program;
        const dispatchProgram = this.programComponentView.dispatchableForm;
        program.isDispatchableProgram = false;

        if (!dispatchProgram.disabled) {
          program.isDispatchableProgram = true;
        }
        const newProgram = JSON.parse(JSON.stringify(program));
        /*for all required field:*/
        if (
          !newProgram.gateRules ||
          (newProgram.gateRules &&
            (!newProgram.gateRules.gateClosureDescription || !newProgram.gateRules.gateClosureTolerance))
        ) {
          delete newProgram.gateRules;
        }

        if (newProgram.schedule && !newProgram.schedule.offerFrequency) {
          delete newProgram.schedule;
        }

        if (newProgram.notificationConf && !newProgram.notificationConf.template) {
          delete newProgram.notificationConf;
        } else {
          newProgram.notificationConf.notifyInGate = !!newProgram.notificationConf.notifyInGate;
          newProgram.notificationConf.notifyBeforeGate = !!newProgram.notificationConf.notifyBeforeGate;
        }

        entity = {
          ...newProgram,
        };

        break;
      default:
        // Product
        const product = this.productComponentView.product;
        product.isDispatchableProduct = false;
        const dispatchForm = this.productComponentView.dispatchableForm;

        if (!dispatchForm.disabled) {
          product.isDispatchableProduct = true;
        }

        const newProduct = JSON.parse(JSON.stringify(product));

        if (newProduct.isDispatchableProduct &&
          (!newProduct.ebGroupSyncSchedule || newProduct.ebGroupSyncSchedule === 'do_not_sync')
        ) {
          newProduct.ebGroupSyncSchedule = null;
        }

        if (newProduct.notificationConf && !newProduct.notificationConf.template) {
          delete newProduct.notificationConf;
        } else {
          newProduct.notificationConf.notifyInGate = !!newProduct.notificationConf.notifyInGate;
          newProduct.notificationConf.notifyBeforeGate = !!newProduct.notificationConf.notifyBeforeGate;
        }

        entity = {
          ...newProduct,
        };

    }
    if (entity.operator === '-1') {
      delete entity.operator;
    }

    if (entity.marketMeterId === '') {
      delete entity.marketMeterId;
    }
    entity.drType = this.marketEntityType;
    this.translateService.get('product.notification.created_successfully').subscribe((result: string) => {
      switch (this.marketEntityType) {
        case 'Operator':
          this.SUCCESS = this.translateService.instant('operator.notification.created_successfully');
          this.BAD_REQUEST = this.translateService.instant('operator.notification.bad_request');
          this.NOT_CREATED = this.translateService.instant('operator.notification.operator.not_created');
          this.REQUIRED = this.translateService.instant('common.validation.required');
          break;
        case 'Program':
          this.SUCCESS = this.translateService.instant('program.notification.created_successfully');
          this.BAD_REQUEST = this.translateService.instant('program.notification.bad_request');
          this.NOT_CREATED = this.translateService.instant('program.notification.program.not_created');
          this.REQUIRED = this.translateService.instant('common.validation.required');
          break;
        default:
          this.SUCCESS = result;
          this.BAD_REQUEST = this.translateService.instant('product.notification.bad_request');
          this.NOT_CREATED = this.translateService.instant('product.notification.product.not_created');
          this.REQUIRED = this.translateService.instant('common.validation.required');
      }
    });

    return entity;
  }

  private handleProductResponse(response: any) {
    const marketEntityId = response.id;
    const marketEntityType = response.drType;
    this.ngxDeeplinkerService.returnHandler({ appPrefix: this.APPPREFIX, callbackValue: marketEntityId });
    this.messageService.setSuccess(this.SUCCESS);
    this.router.navigate([`details/${marketEntityId}/view`], { queryParams: { drType: marketEntityType } });
  }

  private handleProgramResponse(response: any) {
    const marketEntityId = response.id;
    const marketEntityType = response.drType;
    this.ngxDeeplinkerService.returnHandler({ appPrefix: this.APPPREFIX, callbackValue: marketEntityId });
    this.messageService.setSuccess(this.SUCCESS);
    setTimeout(() => {
      this.router.navigate([`details/${marketEntityId}/view`], {
        queryParams: {
          drType: marketEntityType,
        },
      });
    }, 2000);
  }

  private handleOperatorResponse(response: any) {
    const marketEntityId = response.id;
    const marketEntityType = response.drType;
    this.ngxDeeplinkerService.returnHandler({ appPrefix: this.APPPREFIX, callbackValue: marketEntityId });
    this.messageService.setSuccess(this.SUCCESS);
    setTimeout(() => {
      this.loading = true;
      this.router.navigate([`details/${marketEntityId}/view`], {
        queryParams: {
          drType: marketEntityType,
        },
      });
    }, 2000);
  }

  private resetAll() {
    const product = Product.create({});
    const program = Program.create({});
    const operator = Operator.create({});

    this.productsService.product$.next(product);
    this.programsService.program$.next(program);
    this.operatorsService.operator$.next(operator);
  }
}
