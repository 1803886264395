import { Product } from './product.model';
import { MarketEntity } from './market-entity.model';
import { statuses } from './program.model';

export class ProgramStub implements MarketEntity {
  static create(program: any) {
    return new ProgramStub(
      program.id,
      program.children,
      program.classicProgramName,
      program.locale || program.defaultLocale,
      program.description,
      program.descriptions,
      program.discontinueDttm,
        program.displayLabel || program.name,
      program.displayLabels,
      program.drType,
      program.operatorDisplayLabel,
      program.operatorId,
      program.programExternalReferenceId,
      program.programName,
      program.shortDisplayLabel,
      program.shortDisplayLabels,
      program.status,
      program.timezone,
      program.incomplete,
      program.isDispatchableProgram,
    );
  }

  // tslint:disable-next-line:variable-name
  display_label: string;

  constructor(
    public id = '',
    public children: Product[] = [],
    public classicProgramName = '',
    public locale = 'en_US',
    public description = '',
    public descriptions = {},
    public discontinueDttm: Date,
    public displayLabel = '',
    public displayLabels = {},
    public drType = '',
    public operatorDisplayLabel = '',
    public operatorId = '',
    public programExternalReferenceId = '',
    public programName = '',
    public shortDisplayLabel = '',
    public shortDisplayLabels = {},
    public status = statuses[0].id,
    public timezone = 'America/New_York',
    public incomplete = false,
    public isDispatchableProgram?,
  ) {
    this.display_label = this.displayLabel;
  }
}
