<div id="measuredPointsForm" [ngClass]="'viewing'" ngModelGroup="measuredPointsForm">
    <mat-expansion-panel
        (opened)="panelOpenedState = true"
        (closed)="panelOpenedState = false"
        togglePosition="before"
        class="expansion-panel panel_{{ index }}"
    >
        <mat-expansion-panel-header class="panel-override" collapsedHeight="74px" expandedHeight="74px">
            <div data-automation="nestedProgramDisplayLabel">
                <mat-panel-title class="point-title">
                    <div (click)="handleViewDetails($event)" data-automation="displayLabel">
                        <ng-container>
                            {{ getDisplayLabels() }}
                        </ng-container>
                    </div>
                    <div>
                        <i *ngIf="isCreateMode || isEditMode" (click)="addProgram()" id="add_new_panel_{{ index }}"
                            ><img class="edit-icon" src="../../../assets/favicons/enelx/plusButton.svg" alt=""
                        /></i>
                        <i *ngIf="isViewMode" (click)="handleEditDetails()"
                            ><img
                                class="edit-icon"
                                id="edit_panel_{{ index }}"
                                src="../../../assets/favicons/enelx/edit-new.svg"
                                alt=""
                        /></i>
                        <i *ngIf="isCreateMode || isEditMode" (click)="deleteProgram()"
                            ><img
                                class="edit-icon"
                                data-index="{{ index }}"
                                id="delete_panel_{{ index }}"
                                src="../../../assets/favicons/enelx/delete.svg"
                                alt=""
                        /></i>
                    </div>
                </mat-panel-title>

                <mat-panel-description class="nested-program-description">
                    <ng-container>
                        {{ getDescriptions() }}
                    </ng-container>
                </mat-panel-description>
            </div>
        </mat-expansion-panel-header>

        <mat-panel-description class="nested-program-timezone">
            <ng-container>
                <mat-label class="info-title">{{ 'common.timezone' | translate }}:</mat-label>
                <mat-label class="info-value" data-automation="timezone">
                    {{ timezone }}
                </mat-label>
            </ng-container>
        </mat-panel-description>

        <mat-panel-description class="nested-program-locale">
            <ng-container>
                <mat-label class="info-title">{{ 'common.locale' | translate }}:</mat-label>
                <mat-label class="info-value" data-automation="locale">
                    {{ locale }}
                </mat-label>
            </ng-container>
        </mat-panel-description>

        <mat-panel-description class="nested-program-status">
            <ng-container>
                <mat-label class="info-title">{{ 'common.status' | translate }}:</mat-label>
                <mat-label class="info-value" data-automation="status">
                    {{ status }}
                </mat-label>
            </ng-container>
        </mat-panel-description>
    </mat-expansion-panel>
</div>
