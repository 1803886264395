import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Product, RefData } from '../../../shared/model/product.model';
import { RefService } from '../../../shared/services/ref.service';
import { NgxMatColorPickerInput } from '@angular-material-components/color-picker';
import { ControlContainer, UntypedFormGroup, NgForm } from '@angular/forms';

@Component({
  selector: 'app-product-dispatchable-fields',
  templateUrl: './product-dispatchable-fields.component.html',
  styleUrls: ['./product-dispatchable-fields.component.scss', '../../../shared/shared.styles.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class ProductDispatchableFieldsComponent implements OnInit {
  offerFrequencyUnits: RefData[] = [];
  offerGroupFrequencyUnits: RefData[] = [];
  preferedPrezDemandUoms: RefData[] = [];
  expectedCapacitySources: RefData[] = [];
  keyPerformanceMetrics: RefData[] = [];
  selectionOfferTimes: RefData[] = [];
  selectionTypes: RefData[] = [];
  targetTypes: RefData[] = [];
  performanceAggregateTypes: RefData[] = [];
  portfolioObligationSources: RefData[] = [];
  reportingIntervals: RefData[] = [];
  adjustmentOffsetTimes: RefData[] = [];
  lookbackTypes: RefData[] = [];
  dispatchSystems: RefData[] = [];
  cronOptions: RefData[] = [];
  isInit = true;
  panelOpenedState = false;
  notificationConfigSection = false; //notificationConfigurationPanel
  _product: Product;

  @ViewChild(NgxMatColorPickerInput) colorPickerInput: NgxMatColorPickerInput;

  @Input()
  set product(product: Product) {
    this._product = product;
    if (product.isDispatchableProduct) {
      this.panelOpenedState = true;
      if (!product.ebGroupSyncSchedule && this.isEditMode) {
        product.ebGroupSyncSchedule = 'do_not_sync';
      }
    }
  }

  get product() {
    return this._product;
  }
  @Input() mode: string;
  @Input() locale: string;
  @Input() frm;

  constructor(private refService: RefService) {
    this._product = Product.create({});
  }

  ngOnInit(): void {
    this.refService.expectedCapacitySources$.subscribe(
      (expectedCapacitySources) => (this.expectedCapacitySources = expectedCapacitySources),
    );
    this.refService.selectionTypes$.subscribe((selectionTypes) => (this.selectionTypes = selectionTypes));
    this.refService.selectionOfferTimes$.subscribe(
      (selectionOfferTimes) => (this.selectionOfferTimes = selectionOfferTimes),
    );
    this.refService.portfolioObligationSources$.subscribe(
      (portfolioObligationSources) => (this.portfolioObligationSources = portfolioObligationSources),
    );
    this.refService.dispatchSystems$.subscribe((dispatchSystems) => (this.dispatchSystems = dispatchSystems));
    this.refService.cronOptions$.subscribe((cronOptions) => (this.cronOptions = cronOptions));
    this.refService.targetTypes$.subscribe((targetTypes) => (this.targetTypes = targetTypes));
    this.refService.preferedPrezDemandUoms$.subscribe((uoms) => (this.preferedPrezDemandUoms = uoms));
    this.refService.performanceAggregateTypes$.subscribe(
      (performanceAggregateTypes) => (this.performanceAggregateTypes = performanceAggregateTypes),
    );
    this.refService.offerFrequencyUnits$.subscribe((offerFrequencyUnits) => {
      this.offerFrequencyUnits = offerFrequencyUnits;
      this.updateGroupUnit({ value: this.product.schedule.offerFrequencyUnit });
    });
    this.refService.keyPerformanceMetrics$.subscribe(
      (keyPerformanceMetrics) => (this.keyPerformanceMetrics = keyPerformanceMetrics),
    );
    this.refService.reportingIntervals$.subscribe(
      (reportingIntervals) => (this.reportingIntervals = reportingIntervals),
    );
    this.refService.baselineOffsetTimes$.subscribe(
      (baselineOffsetTimes) => (this.adjustmentOffsetTimes = baselineOffsetTimes),
    );

    this.refService.baselineLookbackTypes$.subscribe((types) => (this.lookbackTypes = types));
    this.refService.getCronOptions();

    setTimeout(() => {
      if (
        this.frm &&
        this.dispatchableForm &&
        !this.dispatchableForm.disabled &&
        !this.product?.isDispatchableProduct &&
        this.isInit
      ) {
        this.dispatchableForm.disable();
        this.isInit = false;
      }
    }, 0);
  }

  get dispatchableForm() {
    return this.frm.form.get('dispatchableFields') as UntypedFormGroup;
  }

  get isEditMode() {
    return this.mode === 'edit';
  }

  get isCreateMode() {
    return this.mode === 'create';
  }

  get isViewMode() {
    return this.mode === 'view';
  }

  getSelectionTypeForProduct() {
    if (this.product.selectionType && this.selectionTypes.length > 0) {
      const selectionType = this.selectionTypes.find((type) => type.code === this.product.selectionType);
      if (selectionType) {
        return selectionType.displayLabel;
      }
    }

    return '';
  }

  getPerformanceAggregateTypeForProduct() {
    if (this.product.performanceAggregateType && this.performanceAggregateTypes.length > 0) {
      const aggregateType = this.performanceAggregateTypes.find(
        (performanceType) => performanceType.code === this.product.performanceAggregateType,
      );
      if (aggregateType) {
        return aggregateType.displayLabel;
      }
    }

    return '';
  }

  getTargetTypeForProduct() {
    if (this.product.targetType && this.targetTypes.length > 0) {
      const targetType = this.targetTypes.find((type) => type.code === this.product.targetType);
      if (targetType) {
        return targetType.displayLabel;
      }
    }

    return '';
  }

  getKeyPerformanceMetricForProduct() {
    if (this.product.keyPerformanceMetric && this.keyPerformanceMetrics.length > 0) {
      const metric = this.keyPerformanceMetrics.find(
        (keyMetric) => keyMetric.code === this.product.keyPerformanceMetric,
      );
      if (metric) {
        return metric.displayLabel;
      }
    }
    return '';
  }

  getSelectionOfferTimeForProduct() {
    if (this.product.selectionOfferTime && this.selectionOfferTimes.length > 0) {
      const selectionOfferTime = this.selectionOfferTimes.find(
        (offerTime) => offerTime.code === this.product.selectionOfferTime,
      );
      if (selectionOfferTime) {
        return selectionOfferTime.displayLabel;
      }
    }

    return '';
  }

  getExpectedCapacitySourceForProduct() {
    if (this.product.expectedCapacitySource && this.expectedCapacitySources.length > 0) {
      const capacitySource = this.expectedCapacitySources.find(
        (source) => source.code === this.product.expectedCapacitySource,
      );
      if (capacitySource) {
        return capacitySource.displayLabel;
      }
    }

    return '';
  }

  getCronOption(ebGroupValue) {
    const option = this.cronOptions.find((option: any) => {
      if (option.value == ebGroupValue) {
        return option;
      }
    });
    if (option) {
      return option.displayLabel;
    } else {
      return null;
    }
  }

  getOfferFrequencyUnitForProduct() {
    if (this.product.schedule && this.product.schedule.offerFrequencyUnit && this.offerFrequencyUnits.length > 0) {
      const offerFrequencyUnit = this.offerFrequencyUnits.find(
        (frequencyUnit) => frequencyUnit.code === this.product.schedule.offerFrequencyUnit,
      );
      if (offerFrequencyUnit) {
        return offerFrequencyUnit.displayLabel;
      }
    }

    return '';
  }

  getOfferGroupUnitForProduct() {
    if (this.product.schedule && this.product.schedule.offerGroupUnit && this.offerFrequencyUnits.length > 0) {
      const offerGroupUnit = this.offerFrequencyUnits.find(
        (frequencyUnit) => frequencyUnit.code === this.product.schedule.offerGroupUnit,
      );
      if (offerGroupUnit) {
        return offerGroupUnit.displayLabel;
      }
    }

    return '';
  }

  getPreferredPrezDemandUomForProduct() {
    if (
      this.product.prezConf &&
      this.product.prezConf.preferedPrezDemandUom &&
      this.preferedPrezDemandUoms.length > 0
    ) {
      const uom = this.preferedPrezDemandUoms.find(
        (preferredUom) => preferredUom.code === this.product.prezConf.preferedPrezDemandUom,
      );
      if (uom) {
        return uom.displayLabel;
      }
    }
    return '';
  }

  getPortfolioObligationSourceForProduct() {
    if (this.product.portfolioObligationSource && this.portfolioObligationSources.length > 0) {
      const portfolioObligationSource = this.portfolioObligationSources.find(
        (obligationSource) => obligationSource.code === this.product.portfolioObligationSource,
      );
      if (portfolioObligationSource) {
        return portfolioObligationSource.displayLabel;
      }
    }

    return '';
  }

  setLabelForAdjustmentOffsetTimes() {
    const displayLabel = '';

    if (this.product.baselineConfiguration && this.product.baselineConfiguration.baselineAdjustmentOffsetTime) {
      const foundOffsetTime = this.adjustmentOffsetTimes.find(
        (offsetTime) => offsetTime.code === this.product.baselineConfiguration.baselineAdjustmentOffsetTime,
      );
      if (foundOffsetTime) {
        return foundOffsetTime.displayLabel;
      }
    }

    return displayLabel;
  }

  setLabelForLookbackTypes() {
    const displayLabel = '';

    if (this.product.baselineConfiguration && this.product.baselineConfiguration.baselineLookbackType) {
      const foundLookbackType = this.lookbackTypes.find(
        (lookbackType) => lookbackType.code === this.product.baselineConfiguration.baselineLookbackType,
      );
      if (foundLookbackType) {
        return foundLookbackType.displayLabel;
      }
    }

    return displayLabel;
  }

  getReportingIntervalForProduct() {
    if (this.product.reportingIntervalMs && this.reportingIntervals.length > 0) {
      const reportingInterval = this.reportingIntervals.find(
        (reportingInterval) =>
          parseInt(reportingInterval.durationInMilliseconds) === parseInt(this.product.reportingIntervalMs),
      );
      if (reportingInterval) {
        return reportingInterval.displayLabel;
      }
    }

    return '';
  }

  compareReportingInterval(item1, item2) {
    return parseInt(item1) === parseInt(item2);
  }

  updateGroupUnit($event) {
    const index = this.offerFrequencyUnits.findIndex((unit) => unit.code === $event.value);
    this.offerGroupFrequencyUnits = this.offerFrequencyUnits.slice(index + 1);
  }
}
