<div
    ngModelGroup="app-operator"
    id="operatorForm"
    [ngClass]="isEditMode ? 'editing' : isCreateMode ? 'creating' : 'viewing'"
>
    <div class="form-wrapper">
        <ng-container *ngIf="isCreateMode || isEditMode">
            <!--    Supported Locales   -->
            <div class="form-entry">
                <mat-form-field appearance="outline" floatLabel="always">
                    <mat-label>{{ 'common.supported_locales' | translate }}</mat-label>
                    <mat-select
                            id="supportedLocale"
                            name="supportedLocale"
                            #supportedLocale="ngModel"
                            [ngClass]="{
                            failure: supportedLocale.invalid && (supportedLocale.dirty || supportedLocale.touched),
                            'create-select': true,
                            'input-entry': true
                        }"
                            [(ngModel)]="multiLocaleConfig.supportedLocales"
                            (selectionChange)="handleSelectionChange()"
                            data-automation="supportedLocale"
                            required
                            placeholder="{{ 'common.supported_locales.placeholder' | translate }}"
                            multiple
                    >
                        <mat-option
                                [disabled]="limitLocaleSelectList(5, locale)"
                                *ngFor="let locale of locales"
                                [value]="locale"
                        >{{ locale.displayLabel }}</mat-option
                        >
                    </mat-select>
                    <mat-error *ngIf="supportedLocale.invalid">
                        <mat-error *ngIf="supportedLocale.errors.pattern">
                            {{ 'common.validation.required' | translate }}
                        </mat-error>
                    </mat-error>
                </mat-form-field>
            </div>

            <!--- operator name --->
            <div class="form-entry">
                <multi-locale-input
                        data-automation="operatorName"
                        name="displayLabels"
                        [inputLabel]="operatorLabel"
                        [locales]="multiLocaleConfig.supportedLocales"
                        [(ngModel)]="operator.displayLabels"
                        [displayLabelKey]="multiLocaleConfig.displayLabelKey"
                        [localeKey]="multiLocaleConfig.localeKey"
                        [defaultLocale]="multiLocaleConfig.defaultLocale"
                        [placeholderText]="operatorPlaceholder"
                        [required]="true"
                        data-automation="operator_display_labels"
                        [id]="'operator_display_labels'"
                >
                </multi-locale-input>
            </div>

            <!--- Short Labels --->

            <div class="form-entry">
                <multi-locale-input
                        name="shortDisplayLabels"
                        [inputLabel]="shortDisplayLabel"
                        [locales]="multiLocaleConfig.supportedLocales"
                        [(ngModel)]="operator.shortDisplayLabels"
                        [displayLabelKey]="multiLocaleConfig.displayLabelKey"
                        [localeKey]="multiLocaleConfig.localeKey"
                        [defaultLocale]="multiLocaleConfig.defaultLocale"
                        [placeholderText]="shortDisplayLabelPlaceholder"
                        [required]="false"
                        [id]="'short_display_labels'"
                >
                </multi-locale-input>
            </div>

            <div class="form-entry">
                <mat-form-field appearance="outline" floatLabel="always">
                    <mat-label>{{ 'common.timezone' | translate }}</mat-label>
                    <mat-select
                        id="timezone"
                        name="timezone"
                        #timezone="ngModel"
                        [ngClass]="{
                            failure: timezone.invalid && (timezone.dirty || timezone.touched),
                            'create-select': true,
                            'input-entry': true
                        }"
                        [(ngModel)]="operator.timezone"
                        data-automation="timezone"
                        required
                    >
                        <mat-option *ngFor="let timezone of timezones" [value]="timezone.timezoneName">{{
                            timezone.displayLabel
                        }}</mat-option>
                    </mat-select>
                    <div *ngIf="timezone.invalid && (timezone.dirty || timezone.touched)" class="alert alert-danger">
                        <div *ngIf="timezone.errors.pattern">
                            {{ 'validation.required' | translate }}
                        </div>
                    </div>
                </mat-form-field>
            </div>

            <div class="form-entry">
                <mat-form-field appearance="outline" floatLabel="always">
                    <mat-label>{{ 'common.locale' | translate }}</mat-label>
                    <mat-select
                        id="locale"
                        name="locale"
                        #locale="ngModel"
                        [ngClass]="{
                            failure: locale.invalid && (locale.dirty || locale.touched),
                            'create-select': true
                        }"
                        [(ngModel)]="operator.locale"
                        data-automation="locale"
                        required
                    >
                        <mat-option *ngFor="let locale of multiLocaleConfig.supportedLocales" [value]="locale.localeName">{{
                            locale.displayLabel
                        }}</mat-option>
                    </mat-select>
                    <mat-error *ngIf="locale.invalid && (locale.dirty || locale.touched)" class="alert alert-danger">
                        <mat-error *ngIf="locale.errors.pattern">
                            {{ 'common.validation.required' | translate }}
                        </mat-error>
                    </mat-error>
                </mat-form-field>
            </div>

            <div class="form-entry">
                <mat-form-field appearance="outline" floatLabel="always">
                    <mat-label>{{ 'common.dr_group' | translate }}</mat-label>
                    <mat-select
                        id="drGroupId"
                        name="drGroupId"
                        #drGroupId="ngModel"
                        [ngClass]="{
                            failure: drGroupId.invalid && (drGroupId.dirty || drGroupId.touched),
                            'create-select': true
                        }"
                        [(ngModel)]="operator.drGroupId"
                        placeholder="{{ 'operator.create.placeholder.operator_dr_group' | translate }}"
                        data-automation="drGroupId"
                        required
                    >
                        <mat-option
                            *ngFor="let DRGroup of DRGroups"
                            [value]="DRGroup.id"
                            id="drGroup_{{ DRGroup.id }}"
                            >{{ DRGroup.displayLabel }}</mat-option
                        >
                    </mat-select>
                    <mat-error
                        *ngIf="drGroupId.invalid && (drGroupId.dirty || drGroupId.touched)"
                        class="alert alert-danger"
                    >
                        <mat-error *ngIf="drGroupId.errors.required">
                            {{ 'common.validation.required' | translate }}
                        </mat-error>
                    </mat-error>
                </mat-form-field>
            </div>

            <div class="form-entry">
                <mat-form-field appearance="outline" floatLabel="always">
                    <mat-label>{{ 'common.status' | translate }}</mat-label>
                    <mat-select
                        id="status"
                        name="status"
                        #status="ngModel"
                        [ngClass]="{
                            failure: status.invalid && (status.dirty || status.touched),
                            'create-select': true
                        }"
                        [(ngModel)]="operator.status"
                        data-automation="status"
                        required
                    >
                        <mat-option *ngFor="let status of statuses" [value]="status.code">
                            {{ status.displayLabel }}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="status.invalid && (status.dirty || status.touched)" class="alert alert-danger">
                        <mat-error *ngIf="status.errors.pattern">
                            {{ 'common.validation.required' | translate }}
                        </mat-error>
                    </mat-error>
                </mat-form-field>
            </div>

            <!--- description --->

            <div class="form-entry">
                <multi-locale-input
                        name="description"
                        data-automation="description"
                        [inputLabel]="descriptionLabel"
                        [locales]="multiLocaleConfig.supportedLocales"
                        [(ngModel)]="operator.descriptions"
                        [displayLabelKey]="multiLocaleConfig.displayLabelKey"
                        [localeKey]="multiLocaleConfig.localeKey"
                        [defaultLocale]="multiLocaleConfig.defaultLocale"
                        [placeholderText]="descriptionPlaceholder"
                        [required]="false"
                        [id]="'operator_descriptions'"
                >
                </multi-locale-input>
            </div>

            <div class="form-entry">
                <mat-form-field appearance="outline" floatLabel="always">
                    <mat-label for="operatorExternalReferenceId">{{
                        'operator.create.operator_external_reference_id' | translate
                    }}</mat-label>
                    <input
                        class="x-ellipsis"
                        matInput
                        id="operatorExternalReferenceId"
                        #operatorExternalReferenceId="ngModel"
                        type="text"
                        [ngClass]="{
                            failure:
                                operatorExternalReferenceId.invalid &&
                                (operatorExternalReferenceId.dirty || operatorExternalReferenceId.touched)
                        }"
                        name="operatorExternalReferenceId"
                        placeholder="{{ 'operator.create.placeholder.operator_external_reference_id' | translate }}"
                        maxlength="18"
                        [(ngModel)]="operator.operatorExternalReferenceId"
                        data-automation="operatorExternalReferenceId"
                        data-url-persist
                    />
                    <mat-error
                        *ngIf="
                            operatorExternalReferenceId.invalid &&
                            (operatorExternalReferenceId.dirty || operatorExternalReferenceId.touched)
                        "
                        class="alert alert-danger"
                    >
                    </mat-error>
                </mat-form-field>
            </div>
        </ng-container>

        <ng-container *ngIf="isViewMode">
            <div class="form-entry">
                <mat-label for="displayLabel" class="input-title"
                    >{{ 'operator.create.operator_display_label' | translate }}:</mat-label
                >
                <mat-label class="input-entry" data-automation="displayLabel">{{ getDisplayLabel() }}</mat-label>
            </div>
            <div class="form-entry">
                <mat-label class="input-title">{{ 'common.timezone' | translate }}:</mat-label>
                <mat-label class="input-entry" data-automation="timezone">
                    {{ getTimezoneForOperator() }}
                </mat-label>
            </div>

            <div class="form-entry">
                <mat-label class="input-title">{{ 'operator.create.short_display_label' | translate }}:</mat-label>
                <mat-label class="input-entry" data-automation="shortDisplayLabel">
                    {{ getShortDisplayLabel() }}
                </mat-label>
            </div>

            <div class="form-entry">
                <mat-label class="input-title">{{ 'common.locale' | translate }}:</mat-label>
                <mat-label class="input-entry" data-automation="locale">
                    {{ getLocaleForOperator() }}
                </mat-label>
            </div>

            <div class="form-entry">
                <mat-label class="input-title">{{ 'common.dr_group' | translate }}:</mat-label>
                <mat-label class="input-entry" data-automation="drGroup">
                    {{ getDRGroupForOperator() }}
                </mat-label>
            </div>

            <div class="form-entry">
                <mat-label class="input-title">{{ 'common.status' | translate }}</mat-label>
                <mat-label class="input-entry" data-automation="status">
                    {{ getStatusForOperator() }}
                </mat-label>
            </div>

            <div class="form-entry">
                <mat-label for="description" class="input-title">{{ 'common.description' | translate }}</mat-label>
                <mat-label class="input-entry" data-automation="description">
                    {{ getDescription() }}
                </mat-label>
            </div>

            <div class="form-entry">
                <mat-label for="operatorExternalReferenceId" class="input-title"
                    >{{ 'operator.create.operator_external_reference_id' | translate }}:</mat-label
                >
                <mat-label class="input-entry" data-automation="externalReferenceId">
                    {{
                        operator.operatorExternalReferenceId
                            ? operator.operatorExternalReferenceId
                            : ('program.view.no.program_external_reference_id' | translate)
                    }}
                </mat-label>
            </div>
        </ng-container>
    </div>

    <ng-container *ngIf="isViewMode">
        <div class="form-header with-button">
            <h4 id="programs-title" data-automation="programsListTitle">
                {{ 'common.programs' | translate }}
            </h4>
            <button (click)="routeToCreatePage('Program')" class="icon-btn x-btn-primary">
                <mat-icon>add</mat-icon>
                <span class="btn-text">{{ 'list.button.create' | translate }}</span>
            </button>
        </div>

        <ng-container *ngIf="hasPrograms()">
            <ng-container *ngFor="let program of programs; let programIndex = index">
                <app-nested-program [program]="program" [index]="programIndex"> </app-nested-program>
            </ng-container>
        </ng-container>
        <ng-container *ngIf="!hasPrograms()">
            <div>{{ 'operator.no_programs' | translate }}</div>
        </ng-container>
    </ng-container>

    <ng-container *ngIf="isViewMode">
        <div class="form-header with-button">
            <h4 id="products-title" data-automation="productsListTitle">
                {{ 'common.products' | translate }}
            </h4>
            <button (click)="routeToCreatePage('Product')" class="icon-btn x-btn-primary">
                <mat-icon>add</mat-icon>
                <span class="btn-text">{{ 'list.button.create' | translate }}</span>
            </button>
        </div>
        <ng-container *ngIf="hasProducts()">
            <ng-container *ngFor="let product of products; let productIndex = index">
                <app-nested-product [product]="product" [index]="productIndex"> </app-nested-product>
            </ng-container>
        </ng-container>
        <ng-container *ngIf="!hasProducts()">
            <div>{{ 'operator.no_products' | translate }}</div>
        </ng-container>
    </ng-container>
</div>
