import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup, NgForm } from '@angular/forms';
import { ProductDetailsComponent } from '../details/product/product-details.component';
import { NgxDeeplinkerService } from 'ngx-deeplinker';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalAlertService } from '../shared/services/global-alert.service';
import { ProductsService } from '../shared/services/products.service';
import { MarketsService } from '../shared/services/markets.service';
import { CookieService as NgxCookieService } from 'ngx-shared-services';
import { ProgramDetailsComponent } from '../details/program/program-details.component';
import { OperatorDetailsComponent } from '../details/operator/operator-details.component';
import { TimezonesService } from '../shared/services/timezones.service';
import { LocalesService } from '../shared/services/locales.service';
import { ContextSelectorService } from 'ngx-global-nav';
import { TranslateService } from '@ngx-translate/core';
import { ProgramsService } from '../shared/services/programs.service';
import { OperatorsService } from '../shared/services/operators.service';
import { Program } from '../shared/model/program.model';
import { Operator } from '../shared/model/operator.model';
import { DeleteDialogComponent } from '../dialogs/delete/delete-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { ErrorHandlingService } from '../shared/services/error-handling.service';
import { TreeService } from '../shared/services/tree.service';

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss', '../shared/shared.styles.scss'],
})
export class EditComponent implements OnInit, OnDestroy {
  SUCCESS = 'Created Successfully';
  BAD_REQUEST = 'Oops, There was a problem with your request';
  NOT_CREATED = 'Oops, There was a problem creating your equipment';
  CANNOT_DELETE = '';
  CONFLICT = 'There is a data conflict with this product';
  PARTIAL_SUCCESS = 'Not all data may have returned successfully';
  REQUIRED = 'Required';
  org = '';
  programs = [];
  operators = [];
  marketId: string;
  marketEntity: any;
  marketEntityType: string;
  loadingPrograms: true;
  loadingOperators: true;
  selectedMarketEntityType = 'Product';
  appData: any = {};
  postingMarketEntity = false;
  appPrefix = 'mrk';
  componentPermissions = 'UPDATE';
  form: UntypedFormGroup;
  marketTypes: any[];
  productId = '';
  subs = [];
  saving = false;

  readonly mode = 'edit';
  private productComponentView: ProductDetailsComponent;
  private programComponentView: ProgramDetailsComponent;
  private operatorComponentView: OperatorDetailsComponent;

  @ViewChild(ProductDetailsComponent)
  set productComponent(productDetailsComponent: ProductDetailsComponent) {
    this.productComponentView = productDetailsComponent;
  }

  @ViewChild(ProgramDetailsComponent)
  set programComponent(programDetailsComponent: ProgramDetailsComponent) {
    this.programComponentView = programDetailsComponent;
  }

  @ViewChild(OperatorDetailsComponent)
  set operatorComponent(operatorDetailsComponent: OperatorDetailsComponent) {
    this.operatorComponentView = operatorDetailsComponent;
  }

  @ViewChild('edit', { static: true }) editForm: NgForm;

  constructor(
    private timezonesService: TimezonesService,
    private localesService: LocalesService,
    private ngxCookieService: NgxCookieService,
    private ngxDeeplinkerService: NgxDeeplinkerService,
    private router: Router,
    private route: ActivatedRoute,
    private messageService: GlobalAlertService,
    private marketsService: MarketsService,
    private productsService: ProductsService,
    private programsService: ProgramsService,
    private operatorsService: OperatorsService,
    private orgSelectorService: ContextSelectorService,
    private translateService: TranslateService,
    private messagingService: GlobalAlertService,
    private productService: ProductsService,
    public dialog: MatDialog,
    private errorHandlingService: ErrorHandlingService,
    private treeService: TreeService,
  ) {
    this.marketTypes = marketsService.getMarketTypes();

    /* tslint:disable:no-string-literal */

    this.selectedMarketEntityType = 'Product';
    this.org = this.ngxCookieService.getCookie('preferred-org-id') || '';

    this.translateService.get('product.notification.updated_successfully').subscribe((result: string) => {
      switch (this.marketEntityType) {
        case 'Operator':
          this.SUCCESS = this.translateService.instant('operator.notification.updated_successfully');
          this.BAD_REQUEST = this.translateService.instant('operator.notification.bad_request');
          this.NOT_CREATED = this.translateService.instant('operator.notification.not_created');
          this.CANNOT_DELETE = this.translateService.instant('operator.tooltip.must_delete_children');
          this.REQUIRED = this.translateService.instant('common.validation.required');
          break;
        case 'Program':
          this.SUCCESS = this.translateService.instant('program.notification.updated_successfully');
          this.BAD_REQUEST = this.translateService.instant('program.notification.bad_request');
          this.NOT_CREATED = this.translateService.instant('program.notification.not_created');
          this.CANNOT_DELETE = this.translateService.instant('program.tooltip.must_delete_children');
          this.REQUIRED = this.translateService.instant('common.validation.required');
          break;
        default:
          this.SUCCESS = result;
          this.BAD_REQUEST = this.translateService.instant('product.notification.bad_request');
          this.NOT_CREATED = this.translateService.instant('product.notification.product.not_created');
          this.REQUIRED = this.translateService.instant('common.validation.required');
          this.CONFLICT = this.translateService.instant('common.errors.conflict_error');
          this.PARTIAL_SUCCESS = this.translateService.instant('common.errors.partial_success');
      }
    });

    this.marketTypes = this.marketsService.getMarketTypes();
    this.appData.marketTypes = this.marketTypes;

    this.programsService.programs$.subscribe((programs: Program[]) => {
      this.appData.programs = programs;
      this.appData.loadingPrograms = false;
    });

    this.operatorsService.operators$.subscribe((operators: Operator[]) => {
      this.appData.operators = operators;
      this.appData.loadingOperators = false;
    });

    this.productService.conflictError$.subscribe((conflictError) => {
      this.errorHandlingService.setErrorMessage(conflictError);
    });

    this.productService.partialSuccessWarning$.subscribe(() => {
      this.messagingService.setError(this.PARTIAL_SUCCESS, null, 7000);
    });

    this.programsService.conflictError$.subscribe((conflictError) => {
      this.errorHandlingService.setErrorMessage(conflictError);
    });

    this.programsService.partialSuccessWarning$.subscribe(() => {
      this.messagingService.setError(this.PARTIAL_SUCCESS, null, 7000);
    });
  }

  ngOnInit() {
    const operSub = this.operatorsService.operator$.subscribe((operator) => {
      if (operator?.displayLabel) {
        this.marketEntity = operator;
        this.marketId = operator.id;
        this.marketEntityType = this.marketEntity.drType;
      }
    });
    this.subs.push(operSub);

    const progSub = this.programsService.program$.subscribe((program) => {
      if (program?.displayLabel) {
        this.marketEntity = program;
        this.marketId = program.id;
        this.marketEntityType = this.marketEntity.drType;
      }
    });
    this.subs.push(progSub);

    const prodSub = this.productsService.product$.subscribe((product) => {
      if (product?.displayLabel) {
        this.marketEntity = product;
        this.marketId = product.id;
        this.marketEntityType = this.marketEntity.drType;
      }
    });
    this.subs.push(prodSub);

    this.route.params.subscribe(this.getNext());
    this.form = new UntypedFormGroup({});
  }

  ngOnDestroy() {
    this.subs.forEach((sub) => {
      sub.unsubscribe();
    });
  }

  getNext() {
    return async () => {
      if (
        this.route.params['value']['id'] &&
        this.route.queryParams['value'] &&
        this.route.queryParams['value']['drType']
      ) {
        this.marketEntityType = this.route.queryParams['value']['drType'];
        this.marketId = this.route.params['value']['id'];
        this.productId = this.route.params['value']['id'];
        this.marketEntity = null;
      }

      if (!this.marketId || !this.marketEntityType) {
        return;
      }

      switch (this.marketEntityType) {
        case 'Operator':
          this.operatorsService.fetchOperator(this.marketId);
          break;

        case 'Program':
          this.programsService.fetchProgram(this.marketId);
          break;

        default:
          this.productsService.fetchProduct(this.marketId);
      }
      this.treeService.setSelectedTreeNode(this.marketId);
    };
  }

  getTitle() {
    return this.marketEntity.displayLabel;
  }

  getSubtitle() {
    switch (this.marketEntityType) {
      case 'Operator':
        return 'operator.edit.subtitle';
      case 'Program':
        return 'program.edit.subtitle';
      default:
        return 'product.edit.subtitle';
    }
  }

  async handleSubmit() {
    if (!this.form.valid) {
      this.messageService.setError(this.REQUIRED);
    } else {
      try {
        this.saving = true;
        let marketEntityModel = this.getModelForMarketEntityType();

        marketEntityModel = { ...marketEntityModel };

        if (marketEntityModel.drType === 'Operator') {
          this.handleResponse(await this.operatorsService.updateOperator(marketEntityModel));
        } else if (marketEntityModel.drType === 'Program') {
          this.handleResponse(await this.programsService.updateProgram(marketEntityModel));
        } else {
          this.handleResponse(await this.productsService.updateProduct(marketEntityModel));
        }
        this.operatorsService.refetchOperators();
      } catch (e) {
        this.saving = false;
        this.errorHandlingService.setErrorMessage(e);
      }
    }
  }

  handleCancel() {
    this.router.navigate([`details/${this.marketId}/view`], { queryParams: { drType: this.marketEntityType } });
  }

  private handleResponse(response: any) {
    this.marketId = response.id;
    this.messageService.setSuccess(this.SUCCESS);
    setTimeout(() => {
      this.router.navigate([`details/${this.marketId}/view`], { queryParams: { drType: this.marketEntityType } });
    }, 2000);
  }

  private getModelForMarketEntityType() {
    let entity: any = {};
    switch (this.marketEntityType) {
      case 'Operator':
        entity = {
          ...this.operatorComponentView._operator,
        };
        break;
      case 'Program':
        const program = this.programComponentView.program;
        const dispatchProgram = this.programComponentView.dispatchableForm;

        program.isDispatchableProgram = !dispatchProgram.disabled;

        const newProgram = JSON.parse(JSON.stringify(program));
        /*for all required field:*/
        if (
          !newProgram.gateRules ||
          (newProgram.gateRules &&
            (!newProgram.gateRules.gateClosureDescription || newProgram.gateRules.gateClosureTolerance == null))
        ) {
          delete newProgram.gateRules;
        }

        if (newProgram.schedule && newProgram.schedule.offerFrequency == null) {
          delete newProgram.schedule;
        }

        if (newProgram.notificationConf && !newProgram.notificationConf.template) {
          delete newProgram.notificationConf;
        } else {
          newProgram.notificationConf.notifyInGate = !!newProgram.notificationConf.notifyInGate;
          newProgram.notificationConf.notifyBeforeGate = !!newProgram.notificationConf.notifyBeforeGate;
        }

        entity = {
          ...newProgram,
        };

        break;
      default:
        // Product
        const product = this.productComponentView.product;
        product.declaredAvailability = product.declaredAvailability ? product.declaredAvailability : false;
        product.isDispatchableProduct = false;
        const dispatchForm = this.productComponentView.dispatchableForm;

        if (!dispatchForm.disabled) {
          product.isDispatchableProduct = true;
        }

        const newProduct = JSON.parse(JSON.stringify(product));

        if (
          newProduct.isDispatchableProduct &&
          (!newProduct.ebGroupSyncSchedule || newProduct.ebGroupSyncSchedule === 'do_not_sync')
        ) {
          newProduct.ebGroupSyncSchedule = null;
        }

        if (newProduct.notificationConf && !newProduct.notificationConf.template) {
          delete newProduct.notificationConf;
        } else {
          newProduct.notificationConf.notifyInGate = !!newProduct.notificationConf.notifyInGate;
          newProduct.notificationConf.notifyBeforeGate = !!newProduct.notificationConf.notifyBeforeGate;
        }

        entity = {
          ...newProduct,
        };
    }
    entity.drType = this.marketEntityType;
    return entity;
  }

  openDeleteDialog() {
    this.dialog.open(DeleteDialogComponent, {
      width: '400px',
      data: {
        marketId: this.marketId,
        marketEntityType: this.marketEntityType.toLowerCase(),
        marketEntity: this.marketEntity,
      },
    });
  }

  canDelete() {
    return !(this.marketEntity && this.marketEntity.children && this.marketEntity.children.length > 0);
  }
}
