import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Locale } from '../../../shared/model/locale.model';
import { Product, RefData } from '../../../shared/model/product.model';
import { FormGroup } from '@angular/forms';
import { RefService } from '../../../shared/services/ref.service';
import { ControlContainer, NgForm } from '@angular/forms';

@Component({
  selector: 'app-product-customer-offer-management',
  templateUrl: './product-customer-offer-management.component.html',
  styleUrls: ['./product-customer-offer-management.component.scss', '../../../shared/shared.styles.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class ProductCustomerOfferManagementComponent implements OnInit {
  @Input() mode: string;
  @Input() locale: Locale;
  @Input() product: Product;
  @Input() frm: FormGroup;
  @Output("toggleDispatchableProduct") toggleDispatchableProduct: EventEmitter<any> = new EventEmitter();
  panelOpenState = false;
  offerFrequencyUnits: RefData[] = [];
  offerGroupFrequencyUnits: RefData[] = [];

  constructor(private refService: RefService) {}

  ngOnInit(): void {
    this.refService.offerFrequencyUnits$.subscribe((offerFrequencyUnits) => {
      this.offerFrequencyUnits = offerFrequencyUnits;
      this.updateGroupUnit({ value: this.product.schedule.offerFrequencyUnit });
    });
  }

  get isEditMode() {
    return this.mode === 'edit';
  }

  get isCreateMode() {
    return this.mode === 'create';
  }

  get isViewMode() {
    return this.mode === 'view';
  }

  get offerFrequencyUnitForProduct() {
    if (this.product.schedule && this.product.schedule.offerFrequencyUnit && this.offerFrequencyUnits.length > 0) {
      const offerFrequencyUnit = this.offerFrequencyUnits.find(
        (frequencyUnit) => frequencyUnit.code === this.product.schedule.offerFrequencyUnit,
      );
      if (offerFrequencyUnit) {
        return offerFrequencyUnit.displayLabel;
      }
    }

    return '';
  }

  get offerGroupUnitForProduct() {
    if (this.product.schedule && this.product.schedule.offerGroupUnit && this.offerFrequencyUnits.length > 0) {
      const offerGroupUnit = this.offerFrequencyUnits.find(
        (frequencyUnit) => frequencyUnit.code === this.product.schedule.offerGroupUnit,
      );
      if (offerGroupUnit) {
        return offerGroupUnit.displayLabel;
      }
    }

    return '';
  }

  updateGroupUnit($event) {
    const index = this.offerFrequencyUnits.findIndex((unit) => unit.code === $event.value);
    this.offerGroupFrequencyUnits = this.offerFrequencyUnits.slice(index + 1);
  }

  onPanelExpand() {
    this.toggleDispatchableProduct.emit();
  }
}
