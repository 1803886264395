<div ngModelGroup="dispatchableFields">
    <ng-container *ngIf="isCreateMode || isEditMode">
        <!--- Dates Wrapper --->
        <ng-container
            ngModelGroup="startEndDates"
            #startEndDates="ngModelGroup"
            startEndDateValidator
            [startControl]="startDttm"
            [endControl]="endDttm"
        >
            <!--- start date --->
            <div class="form-entry">
                <mat-form-field appearance="outline" floatLabel="always">
                    <mat-label>{{ 'common.start_date' | translate }}</mat-label>
                    <input
                        matInput
                        [matDatepicker]="startDate"
                        #startDttm="ngModel"
                        [(ngModel)]="program.startDttm"
                        required
                        name="startDttm"
                        id="startDttm"
                        placeholder="{{ 'product.create.placeholder.select_date' | translate }}"
                        data-automation="startDttm"
                        data-url-persist
                    />
                    <mat-datepicker-toggle matSuffix [for]="startDate">
                        <mat-icon matDatepickerToggleIcon svgIcon="enelx:calendar" class="mat-form-icon" data-automation="startDttm_icon">
                        </mat-icon>
                    </mat-datepicker-toggle>
                    <mat-datepicker #startDate startView="month"></mat-datepicker>
                    <mat-error
                        *ngIf="startDttm.invalid && (startDttm.dirty || startDttm.touched)"
                        class="alert alert-danger"
                    >
                        <mat-error *ngIf="startDttm.errors.required">
                            {{ 'common.validation.required' | translate }}
                        </mat-error>
                    </mat-error>
                </mat-form-field>
            </div>

            <!--- end date --->
            <div class="form-entry">
                <mat-form-field appearance="outline" floatLabel="always">
                    <mat-label>{{ 'common.end_date' | translate }}</mat-label>
                    <input
                        matInput
                        [matDatepicker]="endDate"
                        [(ngModel)]="program.endDttm"
                        #endDttm="ngModel"
                        name="endDttm"
                        id="endDttm"
                        data-url-persist
                        placeholder="{{ 'product.create.placeholder.select_date' | translate }}"
                        data-automation="endDttm"
                    />
                    <mat-datepicker-toggle matSuffix [for]="endDate">
                        <mat-icon matDatepickerToggleIcon svgIcon="enelx:calendar" class="mat-form-icon" data-automation="endDttm_icon">
                        </mat-icon>
                    </mat-datepicker-toggle>
                    <mat-datepicker #endDate startView="month"></mat-datepicker>
                    <mat-error *ngIf="endDttm.invalid && (endDttm.dirty || endDttm.touched)"
                        class="alert alert-danger">
                    </mat-error>
                </mat-form-field>
            </div>

            <mat-error class="group-error" *ngIf="startEndDates.invalid">
                <mat-error *ngIf="startEndDates.errors && startEndDates.errors.startEndDateValid">
                    <span class="input-title"></span>
                    <span class="input-entry" data-automation="validationMessage">{{
                        'common.errors.start_end_date' | translate
                    }}</span>
                </mat-error>
            </mat-error>
        </ng-container>

        <!--- Account Number Display Label --->
        <div class="form-entry">
            <mat-form-field appearance="outline" floatLabel="always">
                <mat-label for="accountNumberDisplayLabel">{{
                    'program.create.account_number_display_label' | translate
                }}</mat-label>
                <input
                    matInput
                    id="accountNumberDisplayLabel"
                    #accountNumberDisplayLabel="ngModel"
                    type="text"
                    [ngClass]="{
                        failure:
                            accountNumberDisplayLabel.invalid &&
                            (accountNumberDisplayLabel.dirty || accountNumberDisplayLabel.touched)
                    }"
                    name="accountNumberDisplayLabel"
                    placeholder="{{ 'program.create.placeholder.account_number_display_label' | translate }}"
                    maxlength="80"
                    [(ngModel)]="program.accountNumberDisplayLabel"
                    data-automation="accountNumberDisplayLabel"
                    data-url-persist
                    required
                />
                <mat-error
                    *ngIf="
                        accountNumberDisplayLabel.invalid &&
                        (accountNumberDisplayLabel.dirty || accountNumberDisplayLabel.touched)
                    "
                    class="alert alert-danger"
                >
                    <mat-error *ngIf="accountNumberDisplayLabel.errors.required">
                        <span class="input-title"></span
                        ><span class="input-entry">{{ 'common.validation.required' | translate }}</span>
                    </mat-error>
                </mat-error>
            </mat-form-field>
        </div>

        <!--- Currency Code --->
        <div class="form-entry">
            <mat-form-field appearance="outline" floatLabel="always">
                <mat-label>{{ 'program.create.currency_code' | translate }}</mat-label>
                <mat-select
                    id="currencyCode"
                    name="currencyCode"
                    #currencyCode="ngModel"
                    [ngClass]="{
                        failure: currencyCode.invalid && (currencyCode.dirty || currencyCode.touched),
                        'create-select': true
                    }"
                    [(ngModel)]="program.currencyCode"
                    data-automation="currencyCode"
                    required
                    placeholder="{{ 'program.create.placeholder.currency_code' | translate }}"
                >
                    <mat-option *ngFor="let code of currencies" [value]="code.code" id="{{ code.code }}">
                        {{ code.displayLabel }}
                    </mat-option>
                </mat-select>
                <mat-error
                    *ngIf="currencyCode.invalid && (currencyCode.dirty || currencyCode.touched)"
                    class="alert alert-danger"
                >
                    <mat-error *ngIf="currencyCode.errors.required">
                        {{ 'common.validation.required' | translate }}
                    </mat-error>
                </mat-error>
            </mat-form-field>
        </div>

        <!--- Enrollment Offset --->
        <div class="form-entry">
            <mat-form-field appearance="outline" floatLabel="always">
                <mat-label for="enrollmentOffset">{{ 'program.create.enrollment_offset' | translate }}</mat-label>
                <input
                    matInput
                    id="enrollmentOffset"
                    #enrollmentOffset="ngModel"
                    type="text"
                    [ngClass]="{
                        failure: enrollmentOffset.invalid && (enrollmentOffset.dirty || enrollmentOffset.touched)
                    }"
                    name="enrollmentOffset"
                    placeholder="{{ 'program.create.placeholder.enrollment_offset' | translate }}"
                    maxlength="80"
                    [(ngModel)]="program.enrollmentOffset"
                    data-automation="enrollmentOffset"
                    data-url-persist
                    required
                />
                <mat-error
                    *ngIf="enrollmentOffset.invalid && (enrollmentOffset.dirty || enrollmentOffset.touched)"
                    class="alert alert-danger"
                >
                    <mat-error *ngIf="enrollmentOffset.errors.required">
                        {{ 'common.validation.required' | translate }}
                    </mat-error>
                </mat-error>
            </mat-form-field>
        </div>

        <!--- Holiday Calendar Name --->
        <div class="form-entry">
            <mat-form-field appearance="outline" floatLabel="always">
                <mat-label for="holidayCalendarName">{{
                    'program.create.holiday_calendar_name' | translate
                }}</mat-label>
                <input
                    matInput
                    id="holidayCalendarName"
                    #holidayCalendarName="ngModel"
                    type="text"
                    [ngClass]="{
                        failure:
                            holidayCalendarName.invalid && (holidayCalendarName.dirty || holidayCalendarName.touched)
                    }"
                    name="holidayCalendarName"
                    placeholder="{{ 'program.create.placeholder.holiday_calendar_name' | translate }}"
                    maxlength="80"
                    [(ngModel)]="program.holidayCalendarName"
                    data-automation="holidayCalendarName"
                    data-url-persist
                    required
                />
                <mat-error
                    *ngIf="holidayCalendarName.invalid && (holidayCalendarName.dirty || holidayCalendarName.touched)"
                    class="alert alert-danger"
                >
                    <mat-error *ngIf="holidayCalendarName.errors.required">
                        {{ 'common.validation.required' | translate }}
                    </mat-error>
                </mat-error>
            </mat-form-field>
        </div>

        <!--- Minimum Economic Requirement --->
        <div class="form-entry">
            <mat-form-field appearance="outline" floatLabel="always">
                <mat-label for="minimumEconomicRequirement">{{
                    'program.create.minimum_economic_requirement' | translate
                }}</mat-label>
                <input
                    matInput
                    id="minimumEconomicRequirement"
                    #minimumEconomicRequirement="ngModel"
                    type="number"
                    [ngClass]="{
                        failure:
                            minimumEconomicRequirement.invalid &&
                            (minimumEconomicRequirement.dirty || minimumEconomicRequirement.touched)
                    }"
                    name="minimumEconomicRequirement"
                    [(ngModel)]="program.minimumEconomicRequirement"
                    data-automation="minimumEconomicRequirement"
                    data-url-persist
                />
                <mat-error
                    *ngIf="
                        minimumEconomicRequirement.invalid &&
                        (minimumEconomicRequirement.dirty || minimumEconomicRequirement.touched)
                    "
                    class="alert alert-danger"
                >
                </mat-error>
            </mat-form-field>
        </div>

        <!--- Minimum Starts Per Duration --->
        <div class="form-entry">
            <mat-form-field appearance="outline" floatLabel="always">
                <mat-label for="minimumStartsPerDuration">{{
                    'program.create.minimum_starts_per_duration' | translate
                }}</mat-label>
                <input
                    matInput
                    id="minimumStartsPerDuration"
                    #minimumStartsPerDuration="ngModel"
                    type="number"
                    [ngClass]="{
                        failure:
                            minimumStartsPerDuration.invalid &&
                            (minimumStartsPerDuration.dirty || minimumStartsPerDuration.touched)
                    }"
                    name="minimumStartsPerDuration"
                    [(ngModel)]="program.minimumStartsPerDuration"
                    data-automation="minimumStartsPerDuration"
                    data-url-persist
                />
                <mat-error
                    *ngIf="
                        minimumStartsPerDuration.invalid &&
                        (minimumStartsPerDuration.dirty || minimumStartsPerDuration.touched)
                    "
                    class="alert alert-danger"
                >
                </mat-error>
            </mat-form-field>
        </div>

        <!--- Mandatory Hours --->
        <div class="form-entry">
            <mat-form-field appearance="outline" floatLabel="always">
                <mat-label for="mandatoryHours">{{ 'program.create.mandatory_hours' | translate }}</mat-label>
                <input
                    matInput
                    id="mandatoryHours"
                    #mandatoryHours="ngModel"
                    type="number"
                    [ngClass]="{
                        failure: mandatoryHours.invalid && (mandatoryHours.dirty || mandatoryHours.touched)
                    }"
                    name="mandatoryHours"
                    [(ngModel)]="program.mandatoryHours"
                    data-automation="mandatoryHours"
                    data-url-persist
                />
                <mat-error
                    *ngIf="mandatoryHours.invalid && (mandatoryHours.dirty || mandatoryHours.touched)"
                    class="alert alert-danger"
                >
                </mat-error>
            </mat-form-field>
        </div>

        <!--- Voluntary Hours --->
        <div class="form-entry">
            <mat-form-field appearance="outline" floatLabel="always">
                <mat-label for="voluntaryHours">{{ 'program.create.voluntary_hours' | translate }}</mat-label>
                <input
                    matInput
                    id="voluntaryHours"
                    #voluntaryHours="ngModel"
                    type="number"
                    [ngClass]="{
                        failure: voluntaryHours.invalid && (voluntaryHours.dirty || voluntaryHours.touched)
                    }"
                    name="voluntaryHours"
                    [(ngModel)]="program.voluntaryHours"
                    data-automation="voluntaryHours"
                    data-url-persist
                />
                <mat-error
                    *ngIf="voluntaryHours.invalid && (voluntaryHours.dirty || voluntaryHours.touched)"
                    class="alert alert-danger"
                >
                </mat-error>
            </mat-form-field>
        </div>

        <!--- Participant Identifier --->
        <div class="form-entry">
            <mat-form-field appearance="outline" floatLabel="always">
                <mat-label for="participantIdentifier">{{
                    'program.create.participant_identifier' | translate
                }}</mat-label>
                <input
                    matInput
                    id="participantIdentifier"
                    #participantIdentifier="ngModel"
                    type="text"
                    [ngClass]="{
                        failure:
                            participantIdentifier.invalid &&
                            (participantIdentifier.dirty || participantIdentifier.touched)
                    }"
                    name="participantIdentifier"
                    placeholder="{{ 'program.create.placeholder.participant_identifier' | translate }}"
                    maxlength="80"
                    [(ngModel)]="program.participantIdentifier"
                    data-automation="participantIdentifier"
                    data-url-persist
                />
                <mat-error
                    *ngIf="
                        participantIdentifier.invalid && (participantIdentifier.dirty || participantIdentifier.touched)
                    "
                    class="alert alert-danger"
                >
                </mat-error>
            </mat-form-field>
        </div>

        <!--- Requires Market Offers--->
        <div class="form-entry">
            <div class="form-entry-column">
                <div class="checkbox-wrapper">
                    <mat-checkbox
                        id="requiresMarketOffers"
                        #requiresMarketOffers="ngModel"
                        class="input-entry"
                        [ngClass]="{
                            failure:
                                requiresMarketOffers.invalid &&
                                (requiresMarketOffers.dirty || requiresMarketOffers.touched)
                        }"
                        name="requiresMarketOffers"
                        [(ngModel)]="program.requiresMarketOffers"
                        data-automation="requiresMarketOffers"
                        data-url-persist
                    >
                        {{ 'program.create.requires_market_offers' | translate }}
                    </mat-checkbox>
                    <mat-error
                        *ngIf="
                            requiresMarketOffers.invalid && (requiresMarketOffers.dirty || requiresMarketOffers.touched)
                        "
                        class="alert alert-danger"
                    >
                        <mat-error *ngIf="requiresMarketOffers.errors.required">
                            <span class="input-title"></span>
                            <span class="input-entry">{{ 'common.validation.required' | translate }}</span>
                        </mat-error>
                    </mat-error>
                </div>
                <div class="checkbox-wrapper">
                    <mat-checkbox
                        id="defaultNominationSchedule"
                        #defaultNominationSchedule="ngModel"
                        class="input-entry"
                        [ngClass]="{
                            failure:
                                defaultNominationSchedule.invalid &&
                                (defaultNominationSchedule.dirty || defaultNominationSchedule.touched)
                        }"
                        name="defaultNominationSchedule"
                        [(ngModel)]="program.defaultNominationSchedule"
                        data-automation="defaultNominationSchedule"
                        data-url-persist
                    >
                        {{ 'common.default_nomination_schedule' | translate }}
                    </mat-checkbox>
                </div>
            </div>
        </div>

        <!--- schedule --->
        <mat-expansion-panel
            (opened)="toggleSchedule()"
            (closed)="toggleSchedule()"
            togglePosition="before"
            class="expansion-panel"
            [ngClass]="{ failure: showScheduleError }"
            *ngIf="isCreateMode || isEditMode"
            id="schedule_panel"
            name="schedulePanel"
            data-automation="schedulePanel"
            ngModelGroup="schedulePanel"
        >
            <mat-expansion-panel-header collapsedHeight="48px" expandedHeight="48px">
                <mat-panel-title class="panel-title">
                    <div data-automation="panelTitle" data-automation="schedule_panel">
                        {{ 'common.schedule_title' | translate }}
                    </div>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="panel-content-wrapper">
                <!--- offer frequency --->
                <div class="form-entry">
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>{{ 'common.schedule.offer_frequency' | translate }}</mat-label>
                        <input
                            matInput
                            id="offerFrequency"
                            type="number"
                            step="1"
                            #offerFrequency="ngModel"
                            [ngClass]="{
                                failure: offerFrequency.invalid && (offerFrequency.dirty || offerFrequency.touched)
                            }"
                            name="offerFrequency"
                            placeholder="0"
                            maxlength="16"
                            [(ngModel)]="program.schedule.offerFrequency"
                            data-automation="offerFrequency"
                            data-url-persist
                            [required]="scheduleSection"
                        />
                        <mat-error
                            *ngIf="offerFrequency.invalid && (offerFrequency.dirty || offerFrequency.touched)"
                            class="alert alert-danger"
                        >
                            <mat-error *ngIf="offerFrequency.errors.required">
                                <span class="input-title"></span>
                                <span class="input-entry">{{ 'common.validation.required' | translate }}</span>
                            </mat-error>
                        </mat-error>
                    </mat-form-field>
                </div>

                <!--- offer frequency unit--->
                <div class="form-entry">
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>{{ 'common.schedule.offer_frequency_unit' | translate }}</mat-label>
                        <mat-select
                            id="offerFrequencyUnit"
                            name="offerFrequencyUnit"
                            data-automation="offerFrequencyUnit"
                            #offerFrequencyUnit="ngModel"
                            [ngClass]="{
                                failure:
                                    offerFrequencyUnit.invalid &&
                                    (offerFrequencyUnit.dirty || offerFrequencyUnit.touched),
                                'create-select': true
                            }"
                            [(ngModel)]="program.schedule.offerFrequencyUnit"
                            (selectionChange)="updateGroupUnit($event)"
                            data-automation="offerFrequencyUnitSelector"
                            data-url-persist
                            [required]="scheduleSection"
                            appSelectValid
                            placeholder="{{ 'product.create.placeholder.frequency_unit' | translate }}"
                        >
                            <mat-option
                                *ngFor="let offerFrequencyUnit of offerFrequencyUnits"
                                [value]="offerFrequencyUnit.code"
                                id="offerFrequencyUnit_{{ offerFrequencyUnit.code }}"
                            >
                                {{ offerFrequencyUnit.displayLabel }}
                            </mat-option>
                        </mat-select>
                        <mat-error
                            *ngIf="
                                offerFrequencyUnit.invalid && (offerFrequencyUnit.dirty || offerFrequencyUnit.touched)
                            "
                            class="alert alert-danger"
                        >
                            <mat-error *ngIf="offerFrequencyUnit.errors.selectValid">
                                {{ 'common.validation.required' | translate }}
                            </mat-error>
                        </mat-error>
                    </mat-form-field>
                </div>

                <!--  offer_group_unit //should be greater unit than frequency unit?-->
                <div class="form-entry">
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>{{ 'common.schedule.offer_group_unit' | translate }}</mat-label>
                        <mat-select
                            id="offerGroupUnit"
                            name="offerGroupUnit"
                            #offerGroupUnit="ngModel"
                            [ngClass]="{
                                failure: offerGroupUnit.invalid && (offerGroupUnit.dirty || offerGroupUnit.touched),
                                'create-select': true
                            }"
                            [(ngModel)]="program.schedule.offerGroupUnit"
                            [disabled]="program.schedule.offerFrequencyUnit === ''"
                            data-automation="offerGroupUnitSelector"
                            data-url-persist
                            [required]="scheduleSection"
                            appSelectValid
                            placeholder="{{ 'product.create.placeholder.frequency_unit' | translate }}"
                        >
                            <mat-option
                                *ngFor="let offerFrequencyUnit of offerGroupFrequencyUnits"
                                [value]="offerFrequencyUnit.code"
                                id="offerGroupUnit_{{ offerFrequencyUnit.code }}"
                            >
                                {{ offerFrequencyUnit.displayLabel }}
                            </mat-option>
                        </mat-select>
                        <mat-error
                            *ngIf="offerGroupUnit.invalid && (offerGroupUnit.dirty || offerGroupUnit.touched)"
                            class="alert alert-danger"
                        >
                            <mat-error *ngIf="offerGroupUnit.errors.selectValid">
                                {{ 'common.validation.required' | translate }}
                            </mat-error>
                        </mat-error>
                    </mat-form-field>
                </div>

                <!--- rule --->
                <div class="form-entry">
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>{{ 'common.schedule.rule' | translate }}:</mat-label>
                        <input
                            matInput
                            id="scheduleRule"
                            #scheduleRule="ngModel"
                            type="text"
                            [ngClass]="{
                                failure: scheduleRule.invalid && (scheduleRule.dirty || scheduleRule.touched)
                            }"
                            name="scheduleRule"
                            placeholder="{{ 'product.create.placeholder.cron_expression' | translate }}"
                            maxlength="80"
                            [(ngModel)]="program.schedule.rule"
                            data-automation="scheduleRule"
                            data-url-persist
                            [required]="scheduleSection"
                            cronValidator
                        />
                        <mat-error
                            *ngIf="scheduleRule.invalid && (scheduleRule.dirty || scheduleRule.touched)"
                            class="alert alert-danger"
                        >
                            <mat-error *ngIf="scheduleRule.errors.required">
                                <span class="input-title"></span>
                                <span>{{ 'common.validation.required' | translate }}</span>
                            </mat-error>
                            <mat-error *ngIf="scheduleRule.errors.cronValid">
                                <span class="input-title"></span>
                                <span>{{ 'common.errors.non_cron_expression' | translate }}</span>
                            </mat-error>
                        </mat-error>
                    </mat-form-field>
                </div>

                <ng-container
                    ngModelGroup="scheduleStartEndDates"
                    #scheduleStartEndDates="ngModelGroup"
                    startEndDateValidator
                    [startControl]="scheduleStartDttm"
                    [endControl]="scheduleEndDttm"
                >
                    <!--- start date --->
                    <div class="form-entry">
                        <mat-form-field appearance="outline" floatLabel="always">
                            <mat-label>{{ 'common.schedule.start_date' | translate }}</mat-label>
                            <input
                                matInput
                                [matDatepicker]="scheduleStartDate"
                                #scheduleStartDttm="ngModel"
                                [(ngModel)]="program.schedule.startDttm"
                                [required]="scheduleSection"
                                name="scheduleStartDttm"
                                id="scheduleStartDttm"
                                placeholder="{{ 'product.create.placeholder.select_date' | translate }}"
                                data-automation="scheduleStartDttm"
                                data-url-persist
                            />
                            <mat-datepicker-toggle matSuffix [for]="scheduleStartDate">
                                <mat-icon matDatepickerToggleIcon svgIcon="enelx:calendar" class="mat-form-icon" data-automation="scheduleStartDttm_icon">
                                </mat-icon>
                            </mat-datepicker-toggle>
                            <mat-datepicker #scheduleStartDate startView="month"></mat-datepicker>
                            <mat-error
                                *ngIf="
                                    scheduleStartDttm.invalid && (scheduleStartDttm.dirty || scheduleStartDttm.touched)
                                "
                                class="alert alert-danger"
                            >
                                <mat-error *ngIf="scheduleStartDttm.errors.required">
                                    {{ 'common.validation.required' | translate }}
                                </mat-error>
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <!--- end date --->
                    <div class="form-entry">
                        <mat-form-field appearance="outline" floatLabel="always">
                            <mat-label>{{ 'common.schedule.end_date' | translate }}</mat-label>
                            <input
                                matInput
                                [matDatepicker]="scheduleEndDate"
                                #scheduleEndDttm="ngModel"
                                [(ngModel)]="program.schedule.endDttm"
                                [required]="scheduleSection"
                                name="scheduleEndDttm"
                                id="scheduleEndDttm"
                                placeholder="{{ 'product.create.placeholder.select_date' | translate }}"
                                data-automation="scheduleEndDttm"
                                data-url-persist
                            />
                            <mat-datepicker-toggle matSuffix [for]="scheduleEndDate">
                                <mat-icon matDatepickerToggleIcon svgIcon="enelx:calendar" class="mat-form-icon" data-automation="scheduleEndDttm_icon">
                                </mat-icon>
                            </mat-datepicker-toggle>
                            <mat-datepicker #scheduleEndDate startView="month"></mat-datepicker>
                            <mat-error
                                *ngIf="scheduleEndDttm.invalid && (scheduleEndDttm.dirty || scheduleEndDttm.touched)"
                                class="alert alert-danger"
                            >
                                <mat-error *ngIf="scheduleEndDttm.errors.required">
                                    {{ 'common.validation.required' | translate }}
                                </mat-error>
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <mat-error
                        class="group-error"
                        *ngIf="
                            scheduleStartEndDates.invalid &&
                            (scheduleStartEndDates.dirty || scheduleStartEndDates.touched)
                        "
                    >
                        <mat-error
                            *ngIf="scheduleStartEndDates.errors && scheduleStartEndDates.errors.startEndDateValid"
                        >
                            <span class="input-title"></span>
                            <span class="input-entry">{{ 'common.errors.start_end_date' | translate }}</span>
                        </mat-error>
                    </mat-error>
                </ng-container>
            </div>
        </mat-expansion-panel>

        <!--- Gate Rules --->
        <mat-expansion-panel
            (opened)="toggleGateRules()"
            (closed)="toggleGateRules()"
            togglePosition="before"
            class="expansion-panel"
            [ngClass]="{ failure: showGateRulesError }"
            *ngIf="isCreateMode || isEditMode"
            id="gateRulesPanel"
            data-automation="gateRulesPanel"
            name="gateRulesPanel"
            ngModelGroup="gateRules"
        >
            <mat-expansion-panel-header collapsedHeight="48px" expandedHeight="48px">
                <mat-panel-title class="panel-title">
                    <div data-automation="panelTitle">
                        {{ 'common.gate_rules_title' | translate }}
                    </div>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="panel-content-wrapper">
                <!--- gate closure description --->
                <div class="form-entry">
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>{{ 'common.gate_rules.gate_closure_description' | translate }}:</mat-label>
                        <input
                            matInput
                            id="gateClosureDescription"
                            #gateClosureDescription="ngModel"
                            type="text"
                            [ngClass]="{
                                failure:
                                    gateClosureDescription?.invalid &&
                                    (gateClosureDescription?.dirty || gateClosureDescription?.touched)
                            }"
                            name="gateClosureDescription"
                            placeholder="{{ 'product.create.placeholder.gate_rules.gate_description' | translate }}"
                            maxlength="80"
                            [(ngModel)]="program.gateRules.gateClosureDescription"
                            data-automation="gateClosureDescription"
                            data-url-persist
                            [required]="gateRulesSection || showGateRulesError"
                        />
                        <mat-error
                            *ngIf="
                                gateClosureDescription?.invalid &&
                                (gateClosureDescription?.dirty || gateClosureDescription?.touched)
                            "
                            class="alert alert-danger"
                        >
                        </mat-error>
                    </mat-form-field>
                </div>

                <!--- gate closure tolerance --->
                <div class="form-entry">
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>{{ 'common.gate_rules.gate_closure_tolerance' | translate }}</mat-label>
                        <input
                            matInput
                            id="gateClosureTolerance"
                            type="number"
                            step="1"
                            #gateClosureTolerance="ngModel"
                            [ngClass]="{
                                failure:
                                    gateClosureTolerance?.invalid &&
                                    (gateClosureTolerance?.dirty || gateClosureTolerance?.touched)
                            }"
                            name="gateClosureTolerance"
                            placeholder="0"
                            maxlength="16"
                            [(ngModel)]="program.gateRules.gateClosureTolerance"
                            data-automation="gateClosureTolerance"
                            data-url-persist
                            [required]="gateRulesSection || showGateRulesError"
                        />
                        <mat-error
                            *ngIf="
                                gateClosureTolerance?.invalid &&
                                (gateClosureTolerance?.dirty || gateClosureTolerance?.touched)
                            "
                            class="alert alert-danger"
                        >
                            <mat-error *ngIf="gateClosureTolerance.errors.required">
                                <span class="input-title"></span>
                                <span class="input-entry">{{ 'common.validation.required' | translate }}</span>
                            </mat-error>
                        </mat-error>
                    </mat-form-field>
                </div>

                <!--- gate closes every --->
                <div class="form-entry">
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>{{ 'common.gate_rules.gate_closes_every' | translate }}:</mat-label>
                        <input
                            matInput
                            id="gateClosesEvery"
                            #gateClosesEvery="ngModel"
                            type="text"
                            [ngClass]="{
                                failure: gateClosesEvery.invalid && (gateClosesEvery.dirty || gateClosesEvery.touched)
                            }"
                            name="gateClosesEvery"
                            placeholder="{{ 'product.create.placeholder.cron_expression' | translate }}"
                            maxlength="80"
                            [(ngModel)]="program.gateRules.gateClosesEvery"
                            data-automation="gateClosesEvery"
                            cronValidator
                        />
                        <mat-error
                            *ngIf="gateClosesEvery.invalid && (gateClosesEvery.dirty || gateClosesEvery.touched)"
                            class="alert alert-danger"
                        >
                            <mat-error *ngIf="gateClosesEvery.errors.required">
                                <span class="input-title"></span>
                                <span>{{ 'common.validation.required' | translate }}</span>
                            </mat-error>
                            <mat-error *ngIf="gateClosesEvery.errors.cronValid">
                                <span class="input-title"></span>
                                <span>{{ 'common.errors.non_cron_expression' | translate }}</span>
                            </mat-error>
                        </mat-error>
                    </mat-form-field>
                </div>

                <!--- gate closes on  --->
                <div class="form-entry">
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>{{ 'common.gate_rules.gate_closes_on' | translate }}</mat-label>
                        <input
                            matInput
                            [matDatepicker]="gateClosesOn"
                            [(ngModel)]="program.gateRules.gateClosesOn"
                            name="gateClosesOn"
                            id="gateClosesOn"
                            placeholder="{{ 'product.create.placeholder.select_date' | translate }}"
                            data-automation="gateClosesOn"
                        />
                        <mat-datepicker-toggle matSuffix [for]="gateClosesOn">
                            <mat-icon matDatepickerToggleIcon svgIcon="enelx:calendar" class="mat-form-icon" data-automation="gateClosesOn_icon">
                            </mat-icon>
                        </mat-datepicker-toggle>
                        <mat-datepicker #gateClosesOn startView="month"></mat-datepicker>
                    </mat-form-field>
                </div>

                <!--- gate closes to specific  --->
                <div class="form-entry">
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>{{ 'common.gate_rules.gate_closes_to_specific' | translate }}</mat-label>
                        <input
                            matInput
                            [matDatepicker]="gateClosesToSpecific"
                            [(ngModel)]="program.gateRules.gateClosesToSpecific"
                            name="gateClosesToSpecific"
                            id="gateClosesToSpecific"
                            placeholder="{{ 'product.create.placeholder.select_date' | translate }}"
                            data-automation="gateClosesToSpecific"
                        />
                        <mat-datepicker-toggle matSuffix [for]="gateClosesToSpecific">
                            <mat-icon matDatepickerToggleIcon svgIcon="enelx:calendar" class="mat-form-icon" data-automation="gateClosesToSpecific_icon">
                            </mat-icon>
                        </mat-datepicker-toggle>
                        <mat-datepicker #gateClosesToSpecific startView="month"></mat-datepicker>
                    </mat-form-field>
                </div>

                <!--- gate closes from specific  --->
                <div class="form-entry">
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>{{ 'common.gate_rules.gate_closes_from_specific' | translate }}</mat-label>
                        <input
                            matInput
                            [matDatepicker]="gateClosesFromSpecific"
                            [(ngModel)]="program.gateRules.gateClosesFromSpecific"
                            name="gateClosesFromSpecific"
                            id="gateClosesFromSpecific"
                            placeholder="{{ 'product.create.placeholder.select_date' | translate }}"
                            data-automation="gateClosesFromSpecific"
                        />
                        <mat-datepicker-toggle matSuffix [for]="gateClosesFromSpecific">
                            <mat-icon matDatepickerToggleIcon svgIcon="enelx:calendar" class="mat-form-icon" data-automation="gateClosesFromSpecific_icon">
                            </mat-icon>
                        </mat-datepicker-toggle>
                        <mat-datepicker #gateClosesFromSpecific startView="month"></mat-datepicker>
                    </mat-form-field>
                </div>

                <!--- gate closes to relative --->
                <div class="form-entry">
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>{{ 'common.gate_rules.gate_closes_to_relative' | translate }}</mat-label>
                        <input
                            matInput
                            id="gateClosesToRelative"
                            type="number"
                            step="1"
                            #gateClosesToRelative="ngModel"
                            [ngClass]="{
                                failure:
                                    gateClosesToRelative.invalid &&
                                    (gateClosesToRelative.dirty || gateClosesToRelative.touched)
                            }"
                            name="gateClosesToRelative"
                            placeholder="0"
                            maxlength="16"
                            [(ngModel)]="program.gateRules.gateClosesToRelative"
                            data-automation="gateClosesToRelative"
                        />
                        <mat-error
                            *ngIf="
                                gateClosesToRelative.invalid &&
                                (gateClosesToRelative.dirty || gateClosesToRelative.touched)
                            "
                            class="alert alert-danger"
                        >
                            <mat-error *ngIf="gateClosesToRelative.errors.required">
                                <span class="input-title"></span>
                                <span class="input-entry">{{ 'common.validation.required' | translate }}</span>
                            </mat-error>
                        </mat-error>
                    </mat-form-field>
                </div>

                <!--- gate closes from relative --->
                <div class="form-entry">
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>{{ 'common.gate_rules.gate_closes_from_relative' | translate }}</mat-label>
                        <input
                            matInput
                            id="gateClosesFromRelative"
                            type="number"
                            step="1"
                            #gateClosesFromRelative="ngModel"
                            [ngClass]="{
                                failure:
                                    gateClosesFromRelative.invalid &&
                                    (gateClosesFromRelative.dirty || gateClosesFromRelative.touched)
                            }"
                            name="gateClosesFromRelative"
                            placeholder="0"
                            maxlength="16"
                            [(ngModel)]="program.gateRules.gateClosesFromRelative"
                            data-automation="gateClosesFromRelative"
                        />
                        <mat-error
                            *ngIf="
                                gateClosesFromRelative.invalid &&
                                (gateClosesFromRelative.dirty || gateClosesFromRelative.touched)
                            "
                            class="alert alert-danger"
                        >
                            <mat-error *ngIf="gateClosesFromRelative.errors.required">
                                <span class="input-title"></span>
                                <span class="input-entry">{{ 'common.validation.required' | translate }}</span>
                            </mat-error>
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="form-entry-column">
                    <!-- allow offer increase --->
                    <div class="checkbox-wrapper">
                        <mat-checkbox
                            id="allowOfferIncrease"
                            #allowOfferIncrease="ngModel"
                            class="input-entry"
                            [ngClass]="{
                                failure:
                                    allowOfferIncrease.invalid &&
                                    (allowOfferIncrease.dirty || allowOfferIncrease.touched)
                            }"
                            name="allowOfferIncrease"
                            [(ngModel)]="program.gateRules.postGateEdits.allowOfferIncrease"
                            data-automation="allowOfferIncrease"
                            data-url-persist
                        >
                            {{ 'common.gate_rules.allow_offer_increase' | translate }}
                        </mat-checkbox>
                    </div>

                    <!-- allow offer decrease --->
                    <div class="checkbox-wrapper">
                        <mat-checkbox
                            id="allowOfferDecrease"
                            #allowOfferDecrease="ngModel"
                            class="input-entry"
                            [ngClass]="{
                                failure:
                                    allowOfferDecrease.invalid &&
                                    (allowOfferDecrease.dirty || allowOfferDecrease.touched)
                            }"
                            name="allowOfferDecrease"
                            [(ngModel)]="program.gateRules.postGateEdits.allowOfferDecrease"
                            data-automation="allowOfferDecrease"
                            data-url-persist
                        >
                            {{ 'common.gate_rules.allow_offer_decrease' | translate }}
                        </mat-checkbox>
                    </div>

                    <!-- allow increase after decrease --->
                    <div class="checkbox-wrapper">
                        <mat-checkbox
                            id="allowIncreaseAfterDecrease"
                            #allowIncreaseAfterDecrease="ngModel"
                            class="input-entry"
                            [ngClass]="{
                                failure:
                                    allowIncreaseAfterDecrease.invalid &&
                                    (allowIncreaseAfterDecrease.dirty || allowIncreaseAfterDecrease.touched)
                            }"
                            name="allowIncreaseAfterDecrease"
                            [(ngModel)]="program.gateRules.postGateEdits.allowIncreaseAfterDecrease"
                            data-automation="allowIncreaseAfterDecrease"
                            data-url-persist
                        >
                            {{ 'common.gate_rules.allow_increase_after_decrease' | translate }}
                        </mat-checkbox>
                    </div>

                    <!-- - provide change reason - -->

                    <div class="checkbox-wrapper">
                        <mat-checkbox
                            id="provideChangeReason"
                            #provideChangeReason="ngModel"
                            class="input-entry"
                            [ngClass]="{
                                failure:
                                    provideChangeReason.invalid &&
                                    (provideChangeReason.dirty || provideChangeReason.touched)
                            }"
                            name="provideChangeReason"
                            [(ngModel)]="program.gateRules.postGateEdits.provideChangeReason"
                            data-automation="provideChangeReason"
                            data-url-persist
                        >
                            {{ 'common.gate_rules.provide_change_reason' | translate }}
                        </mat-checkbox>
                    </div>
                </div>
                <div class="form-entry-column">
                    <!--- allow post gate closure edits--->
                    <div class="checkbox-wrapper">
                        <mat-checkbox
                            id="allowPostGateClosureEdits"
                            #allowPostGateClosureEdits="ngModel"
                            class="input-entry"
                            [ngClass]="{
                                failure:
                                    allowPostGateClosureEdits.invalid &&
                                    (allowPostGateClosureEdits.dirty || allowPostGateClosureEdits.touched)
                            }"
                            name="allowPostGateClosureEdits"
                            [(ngModel)]="program.gateRules.postGateEdits.allowPostGateClosureEdits"
                            data-automation="allowPostGateClosureEdits"
                            data-url-persist
                        >
                            {{ 'common.gate_rules.allow_post_gate_closure_edits' | translate }}
                        </mat-checkbox>
                    </div>

                    <!-- allow current interval edits --->
                    <div class="checkbox-wrapper">
                        <mat-checkbox
                            id="allowCurrentIntervalEdit"
                            #allowCurrentIntervalEdit="ngModel"
                            class="input-entry"
                            [ngClass]="{
                                failure:
                                    allowCurrentIntervalEdit.invalid &&
                                    (allowCurrentIntervalEdit.dirty || allowCurrentIntervalEdit.touched)
                            }"
                            name="allowCurrentIntervalEdit"
                            [(ngModel)]="program.gateRules.postGateEdits.allowCurrentIntervalEdit"
                            data-automation="allowCurrentIntervalEdit"
                            data-url-persist
                        >
                            {{ 'common.gate_rules.allow_current_interval_edit' | translate }}
                        </mat-checkbox>
                    </div>
                    <!-- full day offer value --->
                    <div class="checkbox-wrapper">
                        <mat-checkbox
                            id="fullDayOfferValue"
                            #fullDayOfferValue="ngModel"
                            class="input-entry"
                            [ngClass]="{
                                failure:
                                    fullDayOfferValue.invalid && (fullDayOfferValue.dirty || fullDayOfferValue.touched)
                            }"
                            name="fullDayOfferValue"
                            [(ngModel)]="program.gateRules.fullDayOfferValue"
                            data-automation="fullDayOfferValue"
                            data-url-persist
                        >
                            {{ 'common.gate_rules.full_day_offer_value' | translate }}
                        </mat-checkbox>
                    </div>
                </div>
            </div>
        </mat-expansion-panel>

        <!--- Notification Configuration --->
        <mat-expansion-panel
            (opened)="notificationConfigSection = true"
            (closed)="notificationConfigSection = false"
            togglePosition="before"
            class="expansion-panel"
            id="notification_config_panel"
            name="notificationConfigurationPanel"
            data-automation="notificationConfigurationPanel"
        >
            <mat-expansion-panel-header collapsedHeight="48px" expandedHeight="48px">
                <mat-panel-title class="panel-title">
                    <div data-automation="panelTitle">
                        {{ 'common.notification_config_title' | translate }}
                    </div>
                </mat-panel-title>
            </mat-expansion-panel-header>

            <div class="panel-content-wrapper">
                <!--- template [required]="notificationConfigSection &&
                                (program.notificationConf.notifyBeforeGate || program.notificationConf.notifyInGate)" --->
                <div class="form-entry template-top-space">
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>{{ 'common.notification_conf.template' | translate }} </mat-label>
                        <input
                            matInput
                            id="templateNotify"
                            type="text"
                            step="1"
                            #templateNotify="ngModel"
                            [required]="notificationConfigSection"
                            [ngClass]="{
                                failure: templateNotify?.invalid && (templateNotify?.dirty || templateNotify?.touched)
                            }"
                            name="templateNotify"
                            placeholder="Enter a template"
                            [(ngModel)]="program.notificationConf.template"
                            data-automation="templateNotify"
                        />
                        <mat-error
                            *ngIf="templateNotify?.invalid && (templateNotify?.dirty || templateNotify?.touched)"
                            class="alert alert-danger"
                        >
                            <mat-error *ngIf="templateNotify.errors.required">
                                {{ 'common.validation.required' | translate }}
                            </mat-error>
                        </mat-error>
                    </mat-form-field>
                </div>
                <!--- notify before gate --->
                <div class="form-entry">
                    <div class="form-entry-column">
                        <div class="checkbox-wrapper">
                            <mat-checkbox
                                id="notifyBeforeGate"
                                #notifyBeforeGate="ngModel"
                                class="input-entry"
                                [ngClass]="{
                                    failure:
                                        notifyBeforeGate?.invalid &&
                                        (notifyBeforeGate?.dirty || notifyBeforeGate?.touched)
                                }"
                                name="notifyBeforeGate"
                                [(ngModel)]="program.notificationConf.notifyBeforeGate"
                                data-automation="notifyBeforeGate"
                                data-url-persist
                            >
                                {{ 'common.notification_conf.notify_before_gate' | translate }}
                            </mat-checkbox>
                        </div>

                        <!--- notify in gate --->

                        <div class="checkbox-wrapper">
                            <mat-checkbox
                                id="notifyInGate"
                                #notifyInGate="ngModel"
                                class="input-entry"
                                [ngClass]="{
                                    failure: notifyInGate?.invalid && (notifyInGate?.dirty || notifyInGate?.touched)
                                }"
                                name="notifyInGate"
                                [(ngModel)]="program.notificationConf.notifyInGate"
                                data-automation="notifyInGate"
                                data-url-persist
                            >
                                {{ 'common.notification_conf.notify_in_gate' | translate }}
                            </mat-checkbox>
                        </div>
                    </div>
                </div>
            </div>
        </mat-expansion-panel>
    </ng-container>
</div>

<ng-container *ngIf="isViewMode">

    <div class="form-entry">
        <mat-label for="accountNumberDisplayLabel" class="info-title"
            >{{ 'program.create.account_number_display_label' | translate }}:</mat-label
        >
        <mat-label class="input-entry" data-automation="accountNumberDispalyLabel">
            {{ program.accountNumberDisplayLabel }}
        </mat-label>
    </div>

    <div class="form-entry">
        <mat-label for="currencyCode" class="info-title">{{ 'program.create.currency_code' | translate }}:</mat-label>
        <mat-label class="input-entry" data-automation="currencyCode">
            {{ getCurrencyCodeDisplayLabel() }}
        </mat-label>
    </div>

    <div class="form-entry">
        <mat-label for="minimumEconomicRequirement" class="info-title"
            >{{ 'program.create.minimum_economic_requirement' | translate }}:</mat-label
        >
        <mat-label class="input-entry" data-automation="minimumEconomicRequirement">
            {{
                program.minimumEconomicRequirement
                    ? program.minimumEconomicRequirement
                    : ('program.view.no.minimum_economic_requirement' | translate)
            }}
        </mat-label>
    </div>

    <div class="form-entry">
        <mat-label for="enrollmentOffset" class="info-title"
            >{{ 'program.create.enrollment_offset' | translate }}:</mat-label
        >
        <mat-label class="input-entry" data-automation="enrollmentOffset">
            {{ program.enrollmentOffset }}
        </mat-label>
    </div>

    <div class="form-entry">
        <mat-label for="holidayCalendarName" class="info-title"
            >{{ 'program.create.holiday_calendar_name' | translate }}:</mat-label
        >
        <mat-label class="input-entry" data-automation="calendarName">
            {{ program.holidayCalendarName }}
        </mat-label>
    </div>

    <div class="form-entry">
        <mat-label for="mandatoryHours" class="info-title"
            >{{ 'program.create.mandatory_hours' | translate }}:</mat-label
        >
        <mat-label class="input-entry" data-automation="mandatoryHours">
            {{ program.mandatoryHours ? program.mandatoryHours : ('program.view.no.mandatory_hours' | translate) }}
        </mat-label>
    </div>

    <div class="form-entry">
        <mat-label for="voluntaryHours" class="info-title"
            >{{ 'program.create.voluntary_hours' | translate }}:</mat-label
        >
        <mat-label class="input-entry" data-automation="voluntaryHours">
            {{ program.voluntaryHours ? program.voluntaryHours : ('program.view.no.voluntary_hours' | translate) }}
        </mat-label>
    </div>

    <div class="form-entry">
        <mat-label for="minimumStartsPerDuration" class="info-title"
            >{{ 'program.create.minimum_starts_per_duration' | translate }}:</mat-label
        >
        <mat-label class="input-entry" data-automation="minimumStartsPerDuration">
            {{
                program.minimumStartsPerDuration
                    ? program.minimumStartsPerDuration
                    : ('program.view.no.minimum_starts_per_duration' | translate)
            }}
        </mat-label>
    </div>

    <div class="form-entry">
        <mat-label for="participantIdentifier" class="info-title"
            >{{ 'program.create.participant_identifier' | translate }}:</mat-label
        >
        <mat-label class="input-entry" data-automation="participantIdentifier">
            {{
                program.participantIdentifier
                    ? program.participantIdentifier
                    : ('program.view.no.participant_identifier' | translate)
            }}
        </mat-label>
    </div>

    <div class="form-entry">
        <mat-label for="startDttm" class="info-title">{{ 'common.start_date' | translate }}:</mat-label>
        <mat-label class="input-entry" data-automation="startDttm">
            {{program.startDttm | date: 'shortDate':'':locale }}
        </mat-label>
    </div>

    <div class="form-entry">
        <mat-label for="endDttm" class="info-title">{{ 'common.end_date' | translate }}:</mat-label>
        <mat-label class="input-entry" data-automation="endDttm">
            {{ program.endDttm | date: 'shortDate':'':locale }}
        </mat-label>
    </div>

    <div class="form-entry">
        <mat-label for="requiresMarketOffers" class="info-title"
            >{{ 'program.create.requires_market_offers' | translate }}:</mat-label
        >
        <mat-label class="input-entry" data-automation="requiresMarketOffers">
            {{ program.requiresMarketOffers }}
        </mat-label>
    </div>

    <h4 class="form-header">{{ 'common.gate_rules_title' | translate }}</h4>

    <!--- gate closure description --->
    <div class="form-entry">
        <mat-label class="info-title">{{ 'common.gate_rules.gate_closure_description' | translate }}:</mat-label>
        <mat-label class="input-entry" data-automation="gateClosureDescription">
            {{ program.gateRules.gateClosureDescription }}
        </mat-label>
    </div>

    <!--- gate closure tolerance --->
    <div class="form-entry">
        <mat-label class="info-title">{{ 'common.gate_rules.gate_closure_tolerance' | translate }}:</mat-label>
        <mat-label class="input-entry" data-automation="gateClosureTolerance">
            {{ program.gateRules.gateClosureTolerance }}
        </mat-label>
    </div>

    <!--- gate closes every --->
    <div class="form-entry">
        <mat-label class="info-title">{{ 'common.gate_rules.gate_closes_every' | translate }}:</mat-label>
        <mat-label class="input-entry" data-automation="gateClosesEvery">
            {{ program.gateRules.gateClosesEvery }}
        </mat-label>
    </div>

    <!--- gate closes on --->
    <div class="form-entry">
        <mat-label class="info-title">{{ 'common.gate_rules.gate_closes_on' | translate }}:</mat-label>
        <mat-label class="input-entry" data-automation="gateClosesOn">
            {{ program.gateRules.gateClosesOn | date: 'shortDate' }}
        </mat-label>
    </div>

    <!--- gate closes to specific --->
    <div class="form-entry">
        <mat-label class="info-title">{{ 'common.gate_rules.gate_closes_to_specific' | translate }}:</mat-label>
        <mat-label class="input-entry" data-automation="gateClosesToSpecific">
            {{ program.gateRules.gateClosesToSpecific | date: 'shortDate' }}
        </mat-label>
    </div>

    <!--- gate closes from specific --->
    <div class="form-entry">
        <mat-label class="info-title">{{ 'common.gate_rules.gate_closes_from_specific' | translate }}:</mat-label>
        <mat-label class="input-entry" data-automation="gateClosesFromSpecific">
            {{ program.gateRules.gateClosesFromSpecific | date: 'shortDate' }}
        </mat-label>
    </div>

    <!--- gate closes to relative --->
    <div class="form-entry">
        <mat-label class="info-title">{{ 'common.gate_rules.gate_closes_to_relative' | translate }}:</mat-label>
        <mat-label class="input-entry" data-automation="gateClosesToRelative">
            {{ program.gateRules.gateClosesToRelative }}
        </mat-label>
    </div>

    <!--- gate closes from relative --->
    <div class="form-entry">
        <mat-label class="info-title">{{ 'common.gate_rules.gate_closes_from_relative' | translate }}:</mat-label>
        <mat-label class="input-entry" data-automation="gateClosesFromRelative">
            {{ program.gateRules.gateClosesFromRelative }}
        </mat-label>
    </div>

    <!--- allow post gate closure edits --->
    <ng-container *ngIf="!!program.gateRules.postGateEdits">
        <div class="form-entry">
            <mat-label class="info-title"
                >{{ 'common.gate_rules.allow_post_gate_closure_edits' | translate }}:
            </mat-label>
            <mat-label class="input-entry" data-automation="allowPostGateClosureEdits">
                {{ !!program.gateRules.postGateEdits.allowPostGateClosureEdits }}
            </mat-label>
        </div>

        <!--- allow current interval edit --->
        <div class="form-entry">
            <mat-label class="info-title">{{ 'common.gate_rules.allow_current_interval_edit' | translate }}:</mat-label>
            <mat-label class="input-entry" data-automation="allowCurrentIntervalEdit">
                {{ !!program.gateRules.postGateEdits.allowCurrentIntervalEdit }}
            </mat-label>
        </div>

        <!--- allow offer increase --->
        <div class="form-entry">
            <mat-label class="info-title">{{ 'common.gate_rules.allow_offer_increase' | translate }}:</mat-label>
            <mat-label class="input-entry" data-automation="allowOfferIncrease">
                {{ !!program.gateRules.postGateEdits.allowOfferIncrease }}
            </mat-label>
        </div>

        <!--- allow offer decrease --->
        <div class="form-entry">
            <mat-label class="info-title">{{ 'common.gate_rules.allow_offer_decrease' | translate }}:</mat-label>
            <mat-label class="input-entry" data-automation="allowOfferDecrease">
                {{ !!program.gateRules.postGateEdits.allowOfferDecrease }}
            </mat-label>
        </div>
    </ng-container>

    <h4 class="form-header">{{ 'common.schedule_title' | translate }}</h4>

    <!--- offer frequency --->
    <div class="form-entry">
        <mat-label class="info-title">{{ 'common.schedule.offer_frequency' | translate }}:</mat-label>
        <mat-label class="input-entry" data-automation="offerFrequency">
            {{ program.schedule.offerFrequency }}
        </mat-label>
    </div>

    <!--- offer frequency unit --->
    <div class="form-entry">
        <mat-label class="info-title">{{ 'common.schedule.offer_frequency_unit' | translate }}:</mat-label>
        <mat-label class="input-entry" data-automation="offerFrequencyUnit">
            {{ program.schedule.offerFrequencyUnit }}
        </mat-label>
    </div>

    <!-- offer group unit
  <div class="form-entry">
    <mat-label class="info-title">{{ 'common.schedule.offer_group_unit' | translate }}:</mat-label>
    <mat-label class="input-entry" data-automation="offerGroupUnit">

    </mat-label>
  </div> -->

    <!--- schedule start date --->
    <div class="form-entry">
        <mat-label class="info-title">{{ 'common.schedule.start_date' | translate }}:</mat-label>
        <mat-label class="input-entry" data-automation="scheduleStartDttm">
            {{ program.schedule.startDttm | date: 'shortDate':'':locale }}
        </mat-label>
    </div>

    <!--- schedule end date --->
    <div class="form-entry">
        <mat-label class="info-title">{{ 'common.schedule.end_date' | translate }}:</mat-label>
        <mat-label class="input-entry" data-automation="scheduleEndDttm">
            {{ program.schedule.endDttm | date: 'shortDate':'':locale }}
        </mat-label>
    </div>

    <!--- schedule rule --->
    <div class="form-entry">
        <mat-label class="info-title">{{ 'common.schedule.rule' | translate }}:</mat-label>
        <mat-label class="input-entry" data-automation="scheduleRule">
            {{ program.schedule.rule }}
        </mat-label>
    </div>

    <h4 class="form-header">{{ 'common.default_nomination_schedule' | translate }}</h4>

    <!--- default nomination schedule --->
    <div class="form-entry">
        <mat-label class="info-title">{{ 'common.default_nomination_schedule' | translate }}:</mat-label>
        <mat-label class="input-entry" data-automation="defaultNominationSchedule">
            {{ program.defaultNominationSchedule }}
        </mat-label>
    </div>

    <h4 class="form-header">{{ 'common.notification_config_title' | translate }}</h4>

    <!--- notify before gate --->
    <div class="form-entry">
        <mat-label class="info-title">{{ 'common.notification_conf.notify_before_gate' | translate }}:</mat-label>
        <mat-label class="input-entry" data-automation="notifyBeforeGate">
            {{ program.notificationConf.notifyBeforeGate }}
        </mat-label>
    </div>

    <!--- notify in gate --->
    <div class="form-entry">
        <mat-label class="info-title">{{ 'common.notification_conf.notify_in_gate' | translate }}:</mat-label>
        <mat-label class="input-entry" data-automation="notifyInGate">
            {{ program.notificationConf.notifyInGate }}
        </mat-label>
    </div>

    <!--- template --->
    <div class="form-entry">
        <mat-label class="info-title">{{ 'common.notification_conf.template' | translate }}:</mat-label>
        <mat-label class="input-entry" data-automation="template">
            {{ program.notificationConf?.template }}
        </mat-label>
    </div>
</ng-container>
