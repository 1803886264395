<div class="app-product-view content">
    <header class="sticky-header">
        <div class="left-column">
            <div class="icon-header">
                <div class="icon-container">
                    <mat-icon *ngIf="marketEntity">emoji_objects</mat-icon>
                    <mat-spinner *ngIf="!marketEntity" matSuffix diameter="30" color="warn"></mat-spinner>
                </div>
                <h1 id="view-title" data-automation="viewTitle">
                    <ng-container *ngIf="marketEntity">{{ this.getTitle() | translate }}</ng-container>
                    <ng-container *ngIf="!marketEntity">{{ 'common.loading' | translate }}</ng-container>
                <div class="label-container">
                  <mat-icon
                    svgIcon="enelx:general-info"
                    data-automation="viewTitleTooltip"
                    class="tooltip-icon tooltip-icon-container"
                    [matTooltipPosition]="'below'"
                    matTooltip="{{ 'common.tooltip.product.title' | translate }}"
                  ></mat-icon>
                </div>
                </h1>
                <div [ngSwitch]="marketEntityType">
                    <p *ngSwitchCase="'Product'" class="subtitle" data-automation="viewSubtitle">
                        {{ 'product.view.subtitle' | translate }}
                    </p>
                    <p *ngSwitchCase="'Program'" class="subtitle" data-automation="viewSubtitle">
                        {{ 'program.view.subtitle' | translate }}
                    </p>
                    <p *ngSwitchCase="'Operator'" class="subtitle" data-automation="viewSubtitle">
                        {{ 'operator.view.subtitle' | translate }}
                    </p>
                </div>
            </div>
        </div>
        <div class="right-column" data-automation="right_buttons">
            <div class="tooltip-btn-wrapper" matTooltip="{{ !canDelete() ? CANNOT_DELETE : null }}">
                <button
                    class="icon-btn x-btn-primary"
                    color="accent"
                    aria-label="delete"
                    (click)="openDeleteDialog()"
                    [disabled]="!marketEntity || !canDelete() || disableActions"
                    data-automation="productDelete"
                >
                    <mat-icon>delete</mat-icon>
                    <span class="btn-text"> {{ 'common.button.delete' | translate }}</span>
                </button>
            </div>

            <button
                class="icon-btn x-btn-primary"
                color="accent"
                aria-label="Edit"
                (click)="handleEdit()"
                [disabled]="this.marketId === '' || disableActions"
                data-automation="productEdit"
            >
                <mat-icon>edit</mat-icon>
                <span class="btn-text"> {{ 'common.button.edit' | translate }}</span>
            </button>
        </div>
    </header>
    <div class="page-content">
        <form #view="ngForm" class="product-form" *ngIf="marketEntity && !incomplete">
            <div [ngSwitch]="marketEntityType">
                <div *ngSwitchCase="'Product'">
                    <app-product [mode]="mode"></app-product>
                </div>
                <div *ngSwitchCase="'Program'">
                    <app-program [mode]="mode"></app-program>
                </div>
                <div *ngSwitchCase="'Operator'">
                    <app-operator [mode]="mode"></app-operator>
                </div>
            </div>
        </form>
        <h4 *ngIf="incomplete" class="pad-5-t">{{ 'common.notifications.incomplete' | translate }}</h4>
    </div>
</div>
