import { Directive } from '@angular/core';
import { NG_VALIDATORS, Validator } from '@angular/forms';
import { isValidCron } from 'cron-validator/lib';

@Directive({
  selector: '[cronValidator]',
  providers: [{ provide: NG_VALIDATORS, useExisting: CronValidator, multi: true }],
})
export class CronValidator implements Validator {
  validate(control) {
    let isValid = true;

    if (control && control.value) {
      isValid = isValidCron(control.value, { allowBlankDay: true, seconds: true, alias: true });
    }

    if (isValid) {
      return null;
    } else {
      return {
        cronValid: {
          valid: false,
        },
      };
    }
  }
}
