import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ControlContainer, UntypedFormGroup, NgForm } from '@angular/forms';
import { Program } from '../../shared/model/program.model';
import { CookieService as NgxCookieService } from 'ngx-shared-services';
import { NgxDeeplinkerService } from 'ngx-deeplinker';
import { ActivatedRoute, Router } from '@angular/router';
import { ProgramsService } from '../../shared/services/programs.service';
import { LocalesService } from '../../shared/services/locales.service';
import { TimezonesService } from '../../shared/services/timezones.service';
import { Context, ContextSelectorService } from 'ngx-global-nav';
import { DeeplinksService } from '../../shared/services/deeplinks.service';
import { TranslateService } from '@ngx-translate/core';
import { OperatorsService } from '../../shared/services/operators.service';
import { RefService } from '../../shared/services/ref.service';
import { FormValidatorService } from '../../shared/services/form-validator.service';
import { SidenavService } from '../../shared/services/sidenav.service';

@Component({
  selector: 'app-program',
  templateUrl: './program-details.component.html',
  styleUrls: ['../../shared/shared.styles.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class ProgramDetailsComponent implements OnInit {
  @ViewChild(`displayLabel`, { read: ElementRef }) displayLabel: ElementRef;

  readonly EDIT = 'edit';
  readonly VIEW = 'view';
  readonly CREATE = 'create';

  CREATE_NEW_PRODUCT = 'Create New Program';
  REQUIRED = 'required';
  idToPopulate = 'Operator';
  appPrefix = 'mkt';
  deeplinkUrl: string;
  deeplinkerData: any;
  products: any[];
  program: Program;
  panelOpenedState = false;
  showDispatchableFieldsError = false;

  constructor(
    private ngxCookieService: NgxCookieService,
    private ngxDeeplinkerService: NgxDeeplinkerService,
    private route: ActivatedRoute,
    private operatorsService: OperatorsService,
    private programsService: ProgramsService,
    private localesService: LocalesService,
    private timezonesService: TimezonesService,
    private orgSelectorService: ContextSelectorService,
    private deeplinksInternalService: DeeplinksService,
    private translateService: TranslateService,
    private refService: RefService,
    private formValidatorService: FormValidatorService,
    private router: Router,
    private sidenavService: SidenavService,
  ) {
    this.program = Program.create({});

    this.deeplinksInternalService.deeplink$.subscribe(async deeplinkUrl => {
      this.deeplinkUrl = deeplinkUrl;
    });

    this.translateService.get('program.create.button.create_new_program').subscribe((result: string) => {
      this.CREATE_NEW_PRODUCT = result;
      this.REQUIRED = this.translateService.instant('common.validation.required');
    });

    this.localesService.fetchLocales();
    this.timezonesService.fetchTimezones();
    this.deeplinksInternalService.setMarketAdminUrl();
    this.programsService.fetchPrograms();
    this.refService.getCurrencies();
    this.refService.getRefMetadata();
  }

  @Input() mode;
  @Input() frm;
  get theForm() {
    return this.frm.form as UntypedFormGroup;
  }

  get blueButtonForm() {
    return this.frm.form.get('blueButtonFields') as UntypedFormGroup;
  }

  get dispatchableForm() {
    return this.frm.form.get('dispatchableFields') as UntypedFormGroup;
  }

  get isEditMode() {
    return this.mode === this.EDIT;
  }

  get isCreateMode() {
    return this.mode === this.CREATE;
  }

  get isViewMode() {
    return this.mode === this.VIEW;
  }

  ngOnInit() {
    if (this.isCreateMode) {
      const deeplinkerData = this.ngxDeeplinkerService.getReturnData(this.appPrefix);
      if (deeplinkerData) {
        if (Object.keys(deeplinkerData).length) {
          const { displayLabel, operator, startDttm } = deeplinkerData;
          this.program.displayLabels[this.program.locale] = displayLabel || '';
          this.program.operatorId = operator || -1;
          this.program.startDttm = startDttm || '';
        }
      }
    }
    this.programsService.program$.subscribe(this.getNext());
  }

  private getNext() {
    return (program: Program) => {
      if (program && program.id) {
        this.populateProgram(program);
      }
    };
  }

  populateProgram(programData) {
    if (programData) {
      this.program = programData;
      this.products = this.program.children || [];
      if(this.isEditMode) {
        setTimeout(() => {
          this.formValidatorService.triggerFormValidation(this.frm.form);
        }, 1000)
      }
    }
  }

  hasProducts() {
    return this.products && this.products.length > 0;
  }

  resetPrograms() {
    this.program.operatorId = '-1';
  }

  resetDeeplinkerData() {
    this.deeplinkerData = {};
  }

  resetForm() {
    this.resetPrograms();
    this.resetDeeplinkerData();
  }

  handleClearClick($event) {
    $event.stopImmediatePropagation();
    const newProgram = Program.create({});
    const blueButtonFields = this.blueButtonForm.getRawValue();
    this.program = { ...newProgram, ...blueButtonFields };
    this.formValidatorService.clearFormControlErrors(this.dispatchableForm);
    this.dispatchableForm.markAsPristine({ onlySelf: false });
  }

  toggleDispatchableProgram() {
    this.panelOpenedState = !this.panelOpenedState;

    if (this.dispatchableForm?.valid) {
      return;
    }

    if (this.panelOpenedState) {
      if (this.dispatchableForm?.disabled) {
        this.dispatchableForm.enable();
      }
      if (this.showDispatchableFieldsError) {
        this.formValidatorService.triggerValidation(this.dispatchableForm);
      }
      this.showDispatchableFieldsError = false;
    } else {
      if (!this.formValidatorService.hasRequiredDirtyFields(this.dispatchableForm)) {
        this.dispatchableForm.disable();
      } else {
        this.showDispatchableFieldsError = true;
      }
    }
  }

  routeToCreatePage(drType: string) {
    this.sidenavService.closeIfMobile();
    this.router.navigate([`create`], { queryParams: { drType } });
  }
}
