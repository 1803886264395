import { Injectable } from '@angular/core';
import {AbstractControl, FormGroup, UntypedFormGroup} from '@angular/forms';

@Injectable()
export class FormValidatorService {

  hasRequiredDirtyFields(frmGrp: UntypedFormGroup) {
    let formIsDirty = false;

    if (!frmGrp || (frmGrp.pristine && frmGrp.invalid)) {
      return false;
    }

    for (const controlKey in frmGrp.controls) {
      const control: any = frmGrp.get(controlKey);
      try {
        if (typeof control.controls === 'undefined') {
          if (this.isRequiredField(control)) {
            if (this.isFieldDirty(control)) {
              formIsDirty = true;
              break;
            }
          }
        } else {
          // has controls need to recursively look at controls
          if (this.hasRequiredDirtyFields(control as UntypedFormGroup)) {
            formIsDirty = true;
            break;
          }
        }
      } catch (e) {
        console.log(e);
      }
    }

    return formIsDirty;
  }

  hasRequiredTouchedFields(frmGrp: UntypedFormGroup) {
    let formIsDirty = false;

    if (!frmGrp || (frmGrp.pristine && frmGrp.invalid)) {
      return false;
    }

    for (const controlKey in frmGrp.controls) {
      const control: any = frmGrp.get(controlKey);
      try {
        if (typeof control.controls === 'undefined') {
          if (this.isRequiredField(control)) {
            if (this.isTouched(control)) {
              formIsDirty = true;
              break;
            }
          }
        } else {
          // has controls need to recursively look at controls
          if (this.hasRequiredDirtyFields(control as UntypedFormGroup)) {
            formIsDirty = true;
            break;
          }
        }
      } catch (e) {
        console.log(e);
      }
    }

    return formIsDirty;
  }

  isRequiredField(control: AbstractControl) {
    if (control.validator) {
      // eslint-disable-next-line
      const validator = control.validator({} as AbstractControl);
      return validator && validator.required;
    }
    return false;
  }

  isTouched(control: AbstractControl) {
    if (control.validator) {
      // eslint-disable-next-line
      const validator = control.touched;
      return validator;
    }
    return false;
  }

  isFieldDirty(control: AbstractControl) {
    if (
      (control.dirty && control.value !== '' && control.value !== null && control.value !== '-1') ||
      control.invalid
    ) {
      return true;
    }
    return false;
  }

  triggerValidation(frmGroup: UntypedFormGroup) {
    frmGroup.markAllAsTouched();
    frmGroup.markAsDirty();
  }

  clearFormControlErrors(frmGroup: UntypedFormGroup) {
    Object.keys(frmGroup.controls).forEach((key) => {
      const control: any = frmGroup.get(key);
      if (typeof control.controls === 'undefined') {
        control.setErrors(null);
        control.markAsUntouched({ onlySelf: true });
      } else {
        this.clearFormControlErrors(control);
      }
    });
  }

  resetFieldDefaultLocaleField(control) {
    control?.reset();
  }

  triggerFormValidation(frmGroup: FormGroup) {
    if (Object.keys(frmGroup.controls).length > 0) {
      for (const formName in frmGroup?.controls) {
        for (const ctrlValue in frmGroup.controls[formName]['controls']) {
          frmGroup.controls[formName]['controls'][ctrlValue].updateValueAndValidity()
        }
      }
    }
  }
}
