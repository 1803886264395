import { Component, OnInit, Input } from '@angular/core';
import { Locale, LocaleBaseConfig } from '../../../shared/model/locale.model';
import { Product, RefData } from '../../../shared/model/product.model';
import { FormGroup } from '@angular/forms';
import { RefService } from '../../../shared/services/ref.service';
import { Program } from '../../../shared/model/program.model';
import { LocalesService } from 'src/app/shared/services/locales.service';
import { OperatorsService } from 'src/app/shared/services/operators.service';
import { Timezone } from '../../../shared/model/timezone.model';
import { Status } from '../../../shared/model/status.model';
import { TranslateService } from '@ngx-translate/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { CookieService as NgxCookieService } from 'ngx-shared-services';

@Component({
  selector: 'app-product-general-information',
  templateUrl: './product-general-information.component.html',
  styleUrls: ['./product-general-information.component.scss', '../../../shared/shared.styles.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class ProductGeneralInformationComponent implements OnInit {
  @Input() mode: string;
  @Input() locale: Locale;
  @Input() product: Product;
  @Input() frm: FormGroup;
  panelOpenState = false;

  selectionTypes: RefData[] = [];
  locales: Locale[];
  timezones: Timezone[];
  statuses: Status[];
  operators = [];
  userLocale = 'en_US';

  multiLocaleConfig: LocaleBaseConfig = {
    supportedLocales: [new Locale()],
    locales: [new Locale()],
    defaultLocale: new Locale(),
    id: '',
    displayLabelKey: 'displayLabel',
    localeKey: 'localeName',
  };

  productLabel: '';
  productPlaceholder: '';

  shortDisplayLabel: '';
  shortDisplayLabelPlaceholder: '';

  descriptionLabel: '';
  descriptionPlaceholder: '';

  ecrmPattern = /^[A-Za-z0-9]{18}$/;

  constructor(private refService: RefService, private localesService: LocalesService,private operatorsService: OperatorsService,private translateService: TranslateService,private ngxCookieService: NgxCookieService) {
    this.localesService.fetchLocales();
    this.productLabel = this.translateService.instant('common.product_display_label');
    this.productPlaceholder = this.translateService.instant('product.create.placeholder.product_name');

    this.descriptionLabel = this.translateService.instant('common.description');
    this.descriptionPlaceholder = this.translateService.instant('product.create.placeholder.product_description');

    this.shortDisplayLabel = this.translateService.instant('product.create.short_display_label');
    this.shortDisplayLabelPlaceholder = this.translateService.instant('product.create.placeholder.short_display_label');
    this.userLocale = this.ngxCookieService.getCookie('locale') || 'en_US';
  }

  ngOnInit(): void {
    this.refService.selectionTypes$.subscribe((selectionTypes) => (this.selectionTypes = selectionTypes));
    this.localesService.locales$.subscribe((locales) => (this.locales = locales));
    this.operatorsService.operators$.subscribe((operators) => {
      if (operators.length > 0 && this.operators.length !== operators.length) {
        this.operators = operators;
      }
    });
    this.refService.timezones$.subscribe((timezones) => (this.timezones = timezones));
    this.refService.statuses$.subscribe((statuses) => {
      if (statuses && statuses.length > 0) {
        this.statuses = statuses;
      }
    });
    this.refService.getStatuses();

    this.localesService.fetchLocales();

    this.localesService.locales$.subscribe((locales) => {
      if (locales?.length) {
        this.locales = locales;
        const locale = this.localesService.checkForLocale(this.userLocale, locales) || locales[0];
        this.multiLocaleConfig.defaultLocale = locale;

        if (this.isCreateMode) {
          this.multiLocaleConfig.supportedLocales = [locale];
          this.product.defaultLocale = this.userLocale;
        } else if (this.isEditMode) {
          this.buildSupportedLocales();
        }
      }
    });
  }

  get isEditMode() {
    return this.mode === 'edit';
  }

  get isCreateMode() {
    return this.mode === 'create';
  }

  get isViewMode() {
    return this.mode === 'view';
  }

  get localeForProduct() {
    if (this.locales?.length > 0) {
      const locale = this.locales.find((locale) => this.product.defaultLocale === locale.localeName);
      if (locale) {
        return locale.displayLabel;
      }
    }
    return '-';
  }

  get programDisplayLabel() {
    let foundProgram: { displayLabel: string; id: string };
    if (this.product.programId && this.product.programId !== '-1' && this.operators.length > 0) {
      this.operators.find((operator) => {
        if (operator.children && operator.children.length > 0) {
          return operator.children.find((program) => {
            if (program.id === this.product.programId) {
              foundProgram = program;
              return true;
            }
          });
        }
      });

      if (foundProgram) {
        return foundProgram.displayLabel;
      }
    }
    return '';
  }

  getShortDisplayLabel(): string {
    return this.localesService.getValueFromMultilocaleObject(this.product.shortDisplayLabels, this.userLocale, this.product.defaultLocale);
  }

  get displayLabel(): string {
    return this.localesService.getValueFromMultilocaleObject(this.product.displayLabels, this.userLocale, this.product.defaultLocale);
  }

  get descriptions(): string {
    return this.localesService.getValueFromMultilocaleObject(this.product.descriptions, this.userLocale, this.product.defaultLocale);
  }

  limitLocaleSelectList(num: number, locale: Locale) {
    return (
      this.multiLocaleConfig.supportedLocales.length > num - 1 &&
      !this.multiLocaleConfig.supportedLocales.includes(locale)
    );
  }

  async handleSelectionChange() {
    if (this.multiLocaleConfig.supportedLocales.length === 0) {
      this.multiLocaleConfig.supportedLocales = [
        ...this.multiLocaleConfig.supportedLocales,
        this.multiLocaleConfig.defaultLocale,
      ];
    }
    await this.localesService.getLocaleSelectorChanged();
    if (this.isEditMode) {
      this.frm.controls['localeInputsForm_product_display_labels']?.markAsDirty();
      this.frm.controls['localeInputsForm_product_descriptions']?.markAsDirty();
      this.frm.controls['localeInputsForm_short_display_labels']?.markAsDirty();
    }
  }

  buildSupportedLocales() {
    if (this.multiLocaleConfig.supportedLocales && this.product && this.locales) {
      this.multiLocaleConfig.supportedLocales = [];
      const keys = Object.keys(this.product.displayLabels);
      for (const key of keys) {
        const localeFound = this.locales.find((locale) => locale.localeName === key);
        if (localeFound) {
          this.multiLocaleConfig.supportedLocales.push(localeFound);
        }
      }
    }
  }
}
