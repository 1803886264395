<div class="list-container">
  <form class="context-search-row">
    <div class="tree-search">
      <mat-icon svgIcon="SEARCH" class="input-icon" color="primary"></mat-icon>
      <input
        autofocus
        data-automation-name="search-text-field"
        [(ngModel)]="searchText"
        type="text"
        name="searchText"
        placeholder="{{ 'list.search' | translate }}"
      />
    </div>
  </form>
  <div class="spinner-container" *ngIf="loading">
    <mat-spinner mode="indeterminate" diameter="60"></mat-spinner>
  </div>
  <div class="context-list" *ngIf="!loading && operators && operators.length > 0">
    <ng-container>
      <enel-tree-display
        [treeData]="operators"
        [checkbox]="true"
        [multiSelect]="false"
        [showSearch]="false"
        [hideIrrelevant]="true"
        [searchString]="searchText"
        [selectedNodes]="selectedTreeNodes"
        [select]="selectedNode"
        (selection$)="handleSelection($event)"
        (deselection$)="handleDeselection($event)"
        [iconIdentifier]="iconIdentifier"
        [iconDefinitions]="iconDefinitions"
      >
      </enel-tree-display>
      <br />
      <div *ngIf="operators.length <= 0" class="alert-padding alert alert-warning">
        {{ 'list.none_configured' | translate }}
      </div>
    </ng-container>
  </div>

  <div class="list-button-container">
    <button
      *ngIf="showCloneButton"
      (click)="routeToCreatePageClone()"
      [disabled]="disableCloneButton"
      id="clone-button"
      class="icon-btn x-btn-primary"
      color="accent"
      data-automation="cloneButton"
    >
      <mat-icon>content_copy</mat-icon>
      <span class="btn-text" data-automation="cloneButtonLabel">{{ 'common.clone' | translate }}</span>
    </button>
    <button (click)="routeToCreatePage()" id="create-button" data-automation="create-button" class="icon-btn x-btn-primary">
      <mat-icon>add</mat-icon>
      <span class="btn-text">{{ 'list.button.create' | translate }}</span>
    </button>
  </div>

</div>
