<!--- CREATE --->
<div ngModelGroup="dispatchableFields" data-automation="dispatchableFieldsPanel">
    <ng-container *ngIf="(isCreateMode || isEditMode) && product">
        <!--- selection type --->
        <div class="form-entry">
            <mat-form-field appearance="outline" floatLabel="always">
                <mat-label>{{ 'common.selection_type' | translate }}</mat-label>
                <mat-select
                    id="selectionType"
                    name="selectionType"
                    #selectionType="ngModel"
                    [(ngModel)]="product.selectionType"
                    data-automation="selectionTypeSelector"
                    data-url-persist
                    required
                    placeholder="{{ 'product.create.placeholder.selection_type' | translate }}"
                >
                    <mat-option>{{ 'product.create.placeholder.selection_type' | translate }}</mat-option>
                    <mat-option
                        *ngFor="let selectionType of selectionTypes"
                        [value]="selectionType.code"
                        id="{{ selectionType.code }}"
                    >
                        {{ selectionType.displayLabel }}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="selectionType.invalid" class="alert alert-danger">
                    <mat-error *ngIf="selectionType.errors.required">
                        {{ 'common.validation.required' | translate }}
                    </mat-error>
                </mat-error>
            </mat-form-field>
        </div>

        <!--- performance aggregate type --->
        <div class="form-entry">
            <mat-form-field appearance="outline" floatLabel="always">
                <mat-label>{{ 'common.performance_aggregate_type' | translate }}</mat-label>
                <mat-select
                    id="performanceAggregateType"
                    name="performanceAggregateType"
                    #performanceAggregateType="ngModel"
                    [(ngModel)]="product.performanceAggregateType"
                    data-automation="performanceAggregateTypeSelector"
                    data-url-persist
                    required
                    placeholder="{{ 'product.create.placeholder.performance_aggregate_type' | translate }}"
                >
                    <mat-option>{{ 'product.create.placeholder.performance_aggregate_type' | translate }}</mat-option>
                    <mat-option
                        *ngFor="let performanceAggregateType of performanceAggregateTypes"
                        [value]="performanceAggregateType.code"
                        id="{{ performanceAggregateType.code }}"
                    >
                        {{ performanceAggregateType.displayLabel }}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="performanceAggregateType.invalid" class="alert alert-danger">
                    <mat-error *ngIf="performanceAggregateType.errors.required">
                        {{ 'common.validation.required' | translate }}
                    </mat-error>
                </mat-error>
            </mat-form-field>
        </div>

        <!--- target type --->
        <div class="form-entry">
            <mat-form-field appearance="outline" floatLabel="always">
                <mat-label>{{ 'common.target_type' | translate }}</mat-label>
                <mat-select
                    id="targetType"
                    name="targetType"
                    #targetType="ngModel"
                    [(ngModel)]="product.targetType"
                    data-automation="targetTypeSelector"
                    data-url-persist
                    required
                    placeholder="{{ 'product.create.placeholder.target_type' | translate }}"
                >
                    <mat-option>{{ 'product.create.placeholder.target_type' | translate }}</mat-option>
                    <mat-option
                        *ngFor="let targetType of targetTypes"
                        [value]="targetType.code"
                        id="{{ targetType.code }}"
                    >
                        {{ targetType.displayLabel }}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="targetType.invalid" class="alert alert-danger">
                    <mat-error *ngIf="targetType.errors.required">
                        {{ 'common.validation.required' | translate }}
                    </mat-error>
                </mat-error>
            </mat-form-field>
        </div>

        <!--- key performance metric --->
        <div class="form-entry">
            <mat-form-field appearance="outline" floatLabel="always">
                <mat-label>{{ 'common.key_performance_metric' | translate }}</mat-label>
                <mat-select
                    id="keyPerformanceMetric"
                    name="keyPerformanceMetric"
                    #keyPerformanceMetric="ngModel"
                    [(ngModel)]="product.keyPerformanceMetric"
                    data-automation="keyPerformanceMetricSelector"
                    data-url-persist
                    required
                    placeholder="{{ 'product.create.placeholder.key_performance_metric' | translate }}"
                >
                    <mat-option>{{ 'product.create.placeholder.key_performance_metric' | translate }}</mat-option>
                    <mat-option
                        *ngFor="let keyPerformanceMetric of keyPerformanceMetrics"
                        [value]="keyPerformanceMetric.code"
                        id="{{ keyPerformanceMetric.code }}"
                    >
                        {{ keyPerformanceMetric.displayLabel }}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="keyPerformanceMetric.invalid" class="alert alert-danger">
                    <mat-error *ngIf="keyPerformanceMetric.errors.required">
                        {{ 'common.validation.required' | translate }}
                    </mat-error>
                </mat-error>
            </mat-form-field>
        </div>

        <!--- selection offer time --->
        <div class="form-entry">
            <mat-form-field appearance="outline" floatLabel="always">
                <mat-label>{{ 'common.selection_offer_time' | translate }}</mat-label>
                <mat-select
                    id="selectionOfferTime"
                    name="selectionOfferTime"
                    #selectionOfferTime="ngModel"
                    [(ngModel)]="product.selectionOfferTime"
                    data-automation="selectionOfferTimeSelector"
                    data-url-persist
                    required
                    placeholder="{{ 'product.create.placeholder.selection_offer_time' | translate }}"
                >
                    <mat-option>{{ 'product.create.placeholder.selection_offer_time' | translate }}</mat-option>
                    <mat-option
                        *ngFor="let selectionOfferTime of selectionOfferTimes"
                        [value]="selectionOfferTime.code"
                        id="{{ selectionOfferTime.code }}"
                    >
                        {{ selectionOfferTime.displayLabel }}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="selectionOfferTime.invalid" class="alert alert-danger">
                    <mat-error *ngIf="selectionOfferTime.errors.required">
                        {{ 'common.validation.required' | translate }}
                    </mat-error>
                </mat-error>
            </mat-form-field>
        </div>

        <!--- expected capacity source --->
        <div class="form-entry">
            <mat-form-field appearance="outline" floatLabel="always">
                <mat-label>{{ 'common.expected_capacity_source' | translate }}</mat-label>
                <mat-select
                    id="expectedCapacitySource"
                    name="expectedCapacitySource"
                    #expectedCapacitySource="ngModel"
                    [(ngModel)]="product.expectedCapacitySource"
                    data-automation="expectedCapacitySourceSelector"
                    data-url-persist
                    required
                    placeholder="{{ 'product.create.placeholder.expected_capacity_source' | translate }}"
                >
                    <mat-option>{{ 'product.create.placeholder.expected_capacity_source' | translate }}</mat-option>
                    <mat-option
                        *ngFor="let expectedCapacitySource of expectedCapacitySources"
                        [value]="expectedCapacitySource.code"
                        id="{{ expectedCapacitySource.code }}"
                    >
                        {{ expectedCapacitySource.displayLabel }}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="expectedCapacitySource.invalid" class="alert alert-danger">
                    <mat-error *ngIf="expectedCapacitySource.errors.required">
                        {{ 'common.validation.required' | translate }}
                    </mat-error>
                </mat-error>
            </mat-form-field>
        </div>

        <!--- Reporting Interval --->
        <div class="form-entry">
            <mat-form-field appearance="outline" floatLabel="always">
                <mat-label>{{ 'common.reporting_interval' | translate }}</mat-label>
                <mat-select
                    id="reportingIntervalMs"
                    name="reportingIntervalMs"
                    #reportingInterval="ngModel"
                    [(ngModel)]="product.reportingIntervalMs"
                    data-automation="reportingIntervalSelector"
                    data-url-persist
                    required
                    placeholder="{{ 'product.create.placeholder.reporting_interval' | translate }}"
                    [compareWith]="compareReportingInterval"
                >
                    <mat-option>{{ 'product.create.placeholder.reporting_interval' | translate }}</mat-option>
                    <mat-option
                        *ngFor="let reportingInterval of reportingIntervals"
                        [value]="reportingInterval.durationInMilliseconds"
                        id="{{ reportingInterval.granularityName }}"
                    >
                        {{ reportingInterval.displayLabel }}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="reportingInterval.invalid" class="alert alert-danger">
                    <mat-error *ngIf="reportingInterval.errors.required">
                        {{ 'common.validation.required' | translate }}
                    </mat-error>
                </mat-error>
            </mat-form-field>
        </div>

        <!--- portfolio obligation source --->
        <div class="form-entry">
            <mat-form-field appearance="outline" floatLabel="always">
                <mat-label>{{ 'common.portfolio_obligation_source' | translate }}</mat-label>
                <mat-select
                    id="portfolioObligationSource"
                    name="portfolioObligationSource"
                    #portfolioObligationSource="ngModel"
                    [(ngModel)]="product.portfolioObligationSource"
                    data-automation="portfolioObligationSourceSelector"
                    data-url-persist
                    required
                    placeholder="{{ 'product.create.placeholder.portfolio_obligation_source' | translate }}"
                >
                    <mat-option>{{ 'product.create.placeholder.portfolio_obligation_source' | translate }}</mat-option>
                    <mat-option
                        *ngFor="let portfolioObligationSource of portfolioObligationSources"
                        [value]="portfolioObligationSource.code"
                        id="{{ portfolioObligationSource.code }}"
                    >
                        {{ portfolioObligationSource.displayLabel }}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="portfolioObligationSource.invalid" class="alert alert-danger">
                    <mat-error *ngIf="portfolioObligationSource.errors.required">
                        {{ 'common.validation.required' | translate }}
                    </mat-error>
                </mat-error>
            </mat-form-field>
        </div>

        <!-- Start / End Wrapper --->
        <ng-container
            ngModelGroup="startEndDates"
            #startEndDates="ngModelGroup"
            startEndDateValidator
            [startControl]="startDttm"
            [endControl]="endDttm"
        >
            <!--- start date --->
            <div class="form-entry">
                <mat-form-field appearance="outline" floatLabel="always">
                    <mat-label>{{ 'common.start_date' | translate }}</mat-label>
                    <input
                        matInput
                        [matDatepicker]="startDate"
                        #startDttm="ngModel"
                        [(ngModel)]="product.startDttm"
                        required
                        name="startDttm"
                        id="startDttm"
                        placeholder="{{ 'product.create.placeholder.select_date' | translate }}"
                        data-automation="startDttm"
                        data-url-persist
                    />
                    <mat-datepicker-toggle matSuffix [for]="startDate">
                        <mat-icon
                            matDatepickerToggleIcon
                            svgIcon="enelx:calendar"
                            class="mat-form-icon"
                            data-automation="startDttm_icon"
                        >
                        </mat-icon>
                    </mat-datepicker-toggle>
                    <mat-datepicker #startDate startView="month"></mat-datepicker>
                    <mat-error *ngIf="startDttm.invalid" class="alert alert-danger">
                        <mat-error *ngIf="startDttm.errors.required">
                            {{ 'common.validation.required' | translate }}
                        </mat-error>
                    </mat-error>
                </mat-form-field>
            </div>

            <!--- end date --->
            <div class="form-entry">
                <mat-form-field appearance="outline" floatLabel="always">
                    <mat-label>{{ 'common.end_date' | translate }}</mat-label>
                    <input
                        matInput
                        [matDatepicker]="endDate"
                        [(ngModel)]="product.endDttm"
                        #endDttm="ngModel"
                        name="endDttm"
                        id="endDttm"
                        data-url-persist
                        placeholder="{{ 'product.create.placeholder.select_date' | translate }}"
                        data-automation="endDttm"
                    />
                    <mat-datepicker-toggle matSuffix [for]="endDate">
                        <mat-icon
                            matDatepickerToggleIcon
                            svgIcon="enelx:calendar"
                            class="mat-form-icon"
                            data-automation="endDttm_icon"
                        >
                        </mat-icon>
                    </mat-datepicker-toggle>
                    <mat-datepicker #endDate startView="month"></mat-datepicker>
                    <mat-error *ngIf="endDttm.invalid" class="alert alert-danger"> </mat-error>
                </mat-form-field>
            </div>

            <mat-error class="group-error" *ngIf="startEndDates.invalid">
                <mat-error *ngIf="startEndDates.errors && startEndDates.errors.startEndDateValid">
                    <span class="input-title"></span>
                    <span class="input-entry" data-automation="validationMessage">{{
                        'common.errors.start_end_date' | translate
                    }}</span>
                </mat-error>
            </mat-error>
        </ng-container>

        <!--- control timeout --->
        <div class="form-entry">
            <mat-form-field appearance="outline" floatLabel="always">
                <mat-label>{{ 'common.control_timeout' | translate }}:</mat-label>
                <input
                    matInput
                    id="controlTimeout"
                    #controlTimeout="ngModel"
                    type="number"
                    step="1"
                    name="controlTimeout"
                    placeholder="0"
                    maxlength="16"
                    [(ngModel)]="product.controlTimeout"
                    data-automation="controlTimeout"
                    data-url-persist
                    required
                />
                <mat-error *ngIf="controlTimeout.invalid" class="alert alert-danger">
                    <mat-error *ngIf="controlTimeout.errors.required">
                        <span class="input-title"></span>
                        <span>{{ 'common.validation.required' | translate }}</span>
                    </mat-error>
                </mat-error>
            </mat-form-field>
        </div>

        <!--- season reset date --->
        <div class="form-entry">
            <mat-form-field appearance="outline" floatLabel="always">
                <mat-label>{{ 'common.season_reset_date' | translate }}:</mat-label>
                <input
                    matInput
                    id="seasonResetDate"
                    #seasonResetDate="ngModel"
                    type="text"
                    name="seasonResetDate"
                    placeholder="{{ 'product.create.placeholder.cron_expression' | translate }}"
                    maxlength="80"
                    [(ngModel)]="product.seasonResetDate"
                    data-automation="seasonResetDate"
                    data-url-persist
                    required
                    cronValidator
                />
                <mat-error *ngIf="seasonResetDate.invalid" class="alert alert-danger">
                    <mat-error *ngIf="seasonResetDate.errors.required">
                        <span class="input-title"></span>
                        <span>{{ 'common.validation.required' | translate }}</span>
                    </mat-error>
                    <mat-error *ngIf="seasonResetDate.errors.cronValid">
                        <span class="input-title"></span>
                        <span>{{ 'common.errors.non_cron_expression' | translate }}</span>
                    </mat-error>
                </mat-error>
            </mat-form-field>
        </div>

        <!--- dispatch method --->
        <div class="form-entry">
            <mat-form-field appearance="outline" floatLabel="always">
                <mat-label>{{ 'common.dispatch_method' | translate }}:</mat-label>
                <input
                    matInput
                    id="dispatchMethod"
                    #dispatchMethod="ngModel"
                    type="text"
                    name="dispatchMethod"
                    placeholder="{{ 'product.create.placeholder.dispatch_method' | translate }}"
                    maxlength="80"
                    [(ngModel)]="product.dispatchMethod"
                    data-automation="dispatchMethod"
                    data-url-persist
                    required
                />
                <mat-error *ngIf="dispatchMethod.invalid" class="alert alert-danger">
                    <mat-error *ngIf="dispatchMethod.errors.required">
                        <span class="input-title"></span>
                        <span>{{ 'common.validation.required' | translate }}</span>
                    </mat-error>
                </mat-error>
            </mat-form-field>
        </div>
        <!--- Dispatch System  --->

        <div class="form-entry">
            <mat-form-field appearance="outline" floatLabel="always">
                <mat-label>{{ 'common.dispatch_system' | translate }}</mat-label>
                <mat-select
                    id="dispatchSystem"
                    name="dispatchSystem"
                    #dipatchSystem="ngModel"
                    [(ngModel)]="product.dispatchConf.dispatchSystem"
                    data-automation="dispatchSystemSelector"
                    data-url-persist
                    appSelectValid
                    placeholder="{{ 'product.create.placeholder.dispatch_system' | translate }}"
                >
                    <mat-option
                        *ngFor="let dispatchSystem of dispatchSystems"
                        [value]="dispatchSystem.code"
                        id="{{ dispatchSystem.code }}"
                    >
                        {{ dispatchSystem.displayLabel | translate }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <!--- default dispatch duration --->
        <div class="form-entry">
            <mat-form-field appearance="outline" floatLabel="always">
                <mat-label>{{ 'common.default_dispatch_duration' | translate }}</mat-label>
                <input
                    matInput
                    id="defaultDispatchDuration"
                    type="number"
                    step="1"
                    #defaultDispatchDuration="ngModel"
                    name="defaultDispatchDuration"
                    placeholder="0"
                    maxlength="16"
                    [(ngModel)]="product.defaultDispatchDuration"
                    data-automation="defaultDispatchDuration"
                    data-url-persist
                    required
                />
                <mat-error *ngIf="defaultDispatchDuration.invalid" class="alert alert-danger">
                    <mat-error *ngIf="defaultDispatchDuration.errors.required">
                        <span class="input-title"></span>
                        <span class="input-entry">{{ 'common.validation.required' | translate }}</span>
                    </mat-error>
                </mat-error>
            </mat-form-field>
        </div>

        <ng-container
            ngModelGroup="minPerformanceErrorThreshold"
            #minPerformanceErrorThreshold="ngModelGroup"
            minMaxEqualValidator
            [minEqualControl]="performanceErrorThreshold"
            [maxEqualControl]="performanceWarningThreshold"
        >
            <!--- performance error threshold --->
            <div class="form-entry">
                <mat-form-field appearance="outline" floatLabel="always">
                    <mat-label>{{ 'common.performance_error_threshold' | translate }}</mat-label>
                    <input
                        matInput
                        id="performanceErrorThreshold"
                        type="number"
                        step="1"
                        #performanceErrorThreshold="ngModel"
                        name="performanceErrorThreshold"
                        placeholder="100"
                        maxlength="16"
                        [(ngModel)]="product.performanceErrorThreshold"
                        data-automation="performanceErrorThreshold"
                        data-url-persist
                        required
                    />
                    <mat-error *ngIf="performanceErrorThreshold.invalid" class="alert alert-danger">
                        <mat-error *ngIf="performanceErrorThreshold.errors.required">
                            <span class="input-title"></span>
                            <span class="input-entry">{{ 'common.validation.required' | translate }}</span>
                        </mat-error>
                    </mat-error>
                </mat-form-field>
            </div>

            <!--- performance warning threshold --->
            <div class="form-entry">
                <mat-form-field appearance="outline" floatLabel="always">
                    <mat-label>{{ 'common.performance_warning_threshold' | translate }}</mat-label>
                    <input
                        matInput
                        id="performanceWarningThreshold"
                        type="number"
                        step="1"
                        #performanceWarningThreshold="ngModel"
                        name="performanceWarningThreshold"
                        placeholder="115"
                        maxlength="16"
                        [(ngModel)]="product.performanceWarningThreshold"
                        data-automation="performanceWarningThreshold"
                        data-url-persist
                        required
                    />
                    <mat-error *ngIf="performanceWarningThreshold.invalid" class="alert alert-danger">
                        <mat-error *ngIf="performanceWarningThreshold.errors.required">
                            <span class="input-title"></span>
                            <span class="input-entry">{{ 'common.validation.required' | translate }}</span>
                        </mat-error>
                    </mat-error>
                </mat-form-field>
            </div>
            <mat-error class="group-error" *ngIf="minPerformanceErrorThreshold.invalid">
                <mat-error
                    *ngIf="minPerformanceErrorThreshold.errors && minPerformanceErrorThreshold.errors.minMaxEqualValid"
                >
                    <span class="input-title"></span>
                    <span class="input-entry" data-automation="validationMessage">{{
                        'common.errors.performance_error_threshold' | translate
                    }}</span>
                </mat-error>
            </mat-error>
        </ng-container>
        <!--- advance notice --->
        <div class="form-entry">
            <mat-form-field appearance="outline" floatLabel="always">
                <mat-label>{{ 'common.advance_notice' | translate }}</mat-label>
                <input
                    matInput
                    id="advanceNotice"
                    type="number"
                    step="1"
                    #advanceNotice="ngModel"
                    name="advanceNotice"
                    placeholder="0"
                    maxlength="16"
                    [(ngModel)]="product.advanceNotice"
                    data-automation="advanceNotice"
                    data-url-persist
                    required
                />
                <mat-error *ngIf="advanceNotice.invalid" class="alert alert-danger">
                    <mat-error *ngIf="advanceNotice.errors.required">
                        <span class="input-title"></span>
                        <span class="input-entry">{{ 'common.validation.required' | translate }}</span>
                    </mat-error>
                </mat-error>
            </mat-form-field>
        </div>

        <!--- bonus minutes --->
        <div class="form-entry">
            <mat-form-field appearance="outline" floatLabel="always">
                <mat-label>{{ 'common.bonus_minutes' | translate }}</mat-label>
                <input
                    matInput
                    id="bonusMinutes"
                    type="number"
                    step="1"
                    #bonusMinutes="ngModel"
                    name="bonusMinutes"
                    placeholder="0"
                    maxlength="16"
                    [(ngModel)]="product.bonusMinutes"
                    data-automation="bonusMinutes"
                    data-url-persist
                    required
                />
                <mat-error *ngIf="bonusMinutes.invalid" class="alert alert-danger">
                    <mat-error *ngIf="bonusMinutes.errors.required">
                        <span class="input-title"></span>
                        <span class="input-entry">{{ 'common.validation.required' | translate }}</span>
                    </mat-error>
                </mat-error>
            </mat-form-field>
        </div>

        <!--- Eb notification group sync --->
        <div class="form-entry">
            <mat-form-field appearance="outline" floatLabel="always">
                <mat-label>{{ 'common.eb_group_sync_schedule' | translate }}</mat-label>
                <mat-select
                    id="ebGroupSyncSchedule"
                    name="ebGroupSyncSchedule"
                    #ebGroupSyncSchedule="ngModel"
                    [(ngModel)]="product.ebGroupSyncSchedule"
                    data-automation="ebGroupSyncSelector"
                    data-url-persist
                    appSelectValid
                    placeholder="{{ 'product.create.placeholder.eb_group_sync_schedule' | translate }}"
                >
                    <mat-option [value]="'do_not_sync'" id="do_not_sync">
                        {{ 'common.eb_group_sync_schedule.not_sync' | translate }}
                    </mat-option>
                    <mat-option
                        *ngFor="let cronOption of cronOptions"
                        [value]="cronOption.value"
                        id="{{ cronOption.code }}"
                    >
                        {{ cronOption.displayLabel | translate }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <ng-container
            ngModelGroup="minMaxPerformanceTargets"
            #minMaxPerformanceTargets="ngModelGroup"
            minMaxValidator
            [minControl]="minPerformanceTarget"
            [maxControl]="maxPerformanceTarget"
        >
            <!--- min performance target --->
            <div class="form-entry">
                <mat-form-field appearance="outline" floatLabel="always">
                    <mat-label>{{ 'common.min_performance_target' | translate }}</mat-label>
                    <input
                        matInput
                        id="minPerformanceTarget"
                        type="number"
                        min="0"
                        step="1"
                        #minPerformanceTarget="ngModel"
                        name="minPerformanceTarget"
                        placeholder="100"
                        maxlength="16"
                        [(ngModel)]="product.performanceTargetMin"
                        data-automation="minPerformanceTarget"
                        data-url-persist
                        required
                    />
                    <mat-error *ngIf="minPerformanceTarget.invalid" class="alert alert-danger">
                        <mat-error *ngIf="minPerformanceTarget.errors.required">
                            <span class="input-title"></span>
                            <span class="input-entry">{{ 'common.validation.required' | translate }}</span>
                        </mat-error>
                    </mat-error>
                </mat-form-field>
            </div>

            <!--- max performance target --->
            <div class="form-entry">
                <mat-form-field appearance="outline" floatLabel="always">
                    <mat-label>{{ 'common.max_performance_target' | translate }}</mat-label>
                    <input
                        matInput
                        id="maxPerformanceTarget"
                        type="number"
                        min="0"
                        step="1"
                        #maxPerformanceTarget="ngModel"
                        name="maxPerformanceTarget"
                        placeholder="115"
                        maxlength="16"
                        [(ngModel)]="product.performanceTargetMax"
                        data-automation="maxPerformanceTarget"
                        data-url-persist
                        [required]="product.targetType === 'RANGE'"
                    />
                    <mat-error *ngIf="maxPerformanceTarget.invalid" class="alert alert-danger">
                        <mat-error *ngIf="maxPerformanceTarget.errors.required">
                            <span class="input-title"></span>
                            <span class="input-entry">{{ 'common.validation.required' | translate }}</span>
                        </mat-error>
                    </mat-error>
                </mat-form-field>
            </div>

            <mat-error class="group-error" *ngIf="minMaxPerformanceTargets.invalid">
                <mat-error *ngIf="minMaxPerformanceTargets.errors && minMaxPerformanceTargets.errors.minMaxValid">
                    <span class="input-title"></span>
                    <span class="input-entry" data-automation="validationMessage">{{
                        'common.errors.max_min_val' | translate
                    }}</span>
                </mat-error>
            </mat-error>
        </ng-container>

        <ng-container
            ngModelGroup="minMaxEventDuration"
            #minMaxEventDuration="ngModelGroup"
            minMaxValidator
            [minControl]="minEventDuration"
            [maxControl]="maxEventDuration"
        >
            <!--- min event duration --->
            <div class="form-entry">
                <mat-form-field appearance="outline" floatLabel="always">
                    <mat-label>{{ 'common.min_event_duration' | translate }}</mat-label>
                    <input
                        matInput
                        id="minEventDuration"
                        min="0"
                        type="number"
                        step="1"
                        #minEventDuration="ngModel"
                        name="minEventDuration"
                        placeholder="300000"
                        maxlength="16"
                        [(ngModel)]="product.minEventDuration"
                        data-automation="minEventDuration"
                        data-url-persist
                        required
                    />
                    <mat-error *ngIf="minEventDuration.invalid" class="alert alert-danger">
                        <mat-error *ngIf="minEventDuration.errors.required">
                            <span class="input-title"></span>
                            <span class="input-entry">{{ 'common.validation.required' | translate }}</span>
                        </mat-error>
                    </mat-error>
                </mat-form-field>
            </div>

            <!--- max event duration --->
            <div class="form-entry">
                <mat-form-field appearance="outline" floatLabel="always">
                    <mat-label>{{ 'common.max_event_duration' | translate }}</mat-label>
                    <input
                        matInput
                        id="maxEventDuration"
                        type="number"
                        min="0"
                        step="1"
                        #maxEventDuration="ngModel"
                        name="maxEventDuration"
                        placeholder="28800000"
                        maxlength="16"
                        [(ngModel)]="product.maxEventDuration"
                        data-automation="maxEventDuration"
                        data-url-persist
                        required
                    />
                    <mat-error *ngIf="maxEventDuration.invalid" class="alert alert-danger">
                        <mat-error *ngIf="maxEventDuration.errors.required">
                            <span class="input-title"></span>
                            <span class="input-entry">{{ 'common.validation.required' | translate }}</span>
                        </mat-error>
                    </mat-error>
                </mat-form-field>
            </div>

            <mat-error
                class="group-error"
                *ngIf="minMaxEventDuration.invalid && (minMaxEventDuration.dirty || minMaxEventDuration.touched)"
            >
                <mat-error *ngIf="minMaxEventDuration.errors && minMaxEventDuration.errors.minMaxValid">
                    <span class="input-title"></span>
                    <span class="input-entry" data-automation="validationMessage">{{
                        'common.errors.max_min_val' | translate
                    }}</span>
                </mat-error>
            </mat-error>
        </ng-container>

        <!--- min duration between events --->
        <div class="form-entry">
            <mat-form-field appearance="outline" floatLabel="always">
                <mat-label>{{ 'common.min_duration_between_events' | translate }}</mat-label>
                <input
                    matInput
                    id="minDurationBetweenEvents"
                    type="number"
                    min="0"
                    step="1"
                    #minDurationBetweenEvents="ngModel"
                    name="minDurationBetweenEvents"
                    placeholder="300000"
                    maxlength="16"
                    [(ngModel)]="product.minDurationBetweenEvents"
                    data-automation="minDurationBetweenEvents"
                    data-url-persist
                    required
                />
                <mat-error *ngIf="minDurationBetweenEvents.invalid" class="alert alert-danger">
                    <mat-error *ngIf="minDurationBetweenEvents.errors.required">
                        <span class="input-title"></span>
                        <span class="input-entry">{{ 'common.validation.required' | translate }}</span>
                    </mat-error>
                </mat-error>
            </mat-form-field>
        </div>

        <!--- max event hours per season --->
        <div class="form-entry">
            <mat-form-field appearance="outline" floatLabel="always">
                <mat-label>{{ 'common.max_event_hours_per_season' | translate }}</mat-label>
                <input
                    matInput
                    id="maxEventHoursPerSeason"
                    type="number"
                    min="0"
                    step="1"
                    #maxEventHoursPerSeason="ngModel"
                    name="maxEventHoursPerSeason"
                    placeholder="80"
                    maxlength="16"
                    [(ngModel)]="product.maxEventHoursPerSeason"
                    data-automation="maxEventHoursPerSeason"
                    data-url-persist
                    required
                />
                <mat-error *ngIf="maxEventHoursPerSeason.invalid" class="alert alert-danger">
                    <mat-error *ngIf="maxEventHoursPerSeason.errors.required">
                        <span class="input-title"></span>
                        <span class="input-entry">{{ 'common.validation.required' | translate }}</span>
                    </mat-error>
                </mat-error>
            </mat-form-field>
        </div>

        <!--- max events per season --->
        <div class="form-entry">
            <mat-form-field appearance="outline" floatLabel="always">
                <mat-label>{{ 'common.max_events_per_season' | translate }}</mat-label>
                <input
                    matInput
                    id="maxEventsPerSeason"
                    type="number"
                    min="0"
                    step="1"
                    #maxEventsPerSeason="ngModel"
                    name="maxEventsPerSeason"
                    placeholder="20"
                    maxlength="16"
                    [(ngModel)]="product.maxEventsPerSeason"
                    data-automation="maxEventsPerSeason"
                    data-url-persist
                    required
                />
                <mat-error *ngIf="maxEventsPerSeason.invalid" class="alert alert-danger">
                    <mat-error *ngIf="maxEventsPerSeason.errors.required">
                        <span class="input-title"></span>
                        <span class="input-entry">{{ 'common.validation.required' | translate }}</span>
                    </mat-error>
                </mat-error>
            </mat-form-field>
        </div>

        <!--- equipment interval frequency --->
        <div class="form-entry">
            <mat-form-field appearance="outline" floatLabel="always">
                <mat-label>{{ 'common.equipment_interval_frequency' | translate }}:</mat-label>
                <input
                    matInput
                    id="equipmentIntervalFrequency"
                    #equipmentIntervalFrequency="ngModel"
                    type="number"
                    step="1"
                    name="equipmentIntervalFrequency"
                    placeholder="0"
                    maxlength="16"
                    [(ngModel)]="product.equipmentIntervalFrequency"
                    data-automation="equipmentIntervalFrequency"
                    data-url-persist
                    required
                />
                <mat-error *ngIf="equipmentIntervalFrequency.invalid" class="alert alert-danger">
                    <mat-error *ngIf="equipmentIntervalFrequency.errors.required">
                        <span class="input-title"></span>
                        <span>{{ 'common.validation.required' | translate }}</span>
                    </mat-error>
                </mat-error>
            </mat-form-field>
        </div>

        <!--- weather window start offset --->
        <div class="form-entry">
            <mat-form-field appearance="outline" floatLabel="always">
                <mat-label>{{ 'common.weather_window_start_offset' | translate }}</mat-label>
                <input
                    matInput
                    id="weatherWindowStartOffset"
                    type="number"
                    step="1"
                    #weatherWindowStartOffset="ngModel"
                    name="weatherWindowStartOffset"
                    placeholder="0"
                    maxlength="16"
                    [(ngModel)]="product.weatherWindowStartOffset"
                    data-automation="weatherWindowStartOffset"
                    data-url-persist
                    required
                />
                <mat-error *ngIf="weatherWindowStartOffset.invalid" class="alert alert-danger">
                    <mat-error *ngIf="weatherWindowStartOffset.errors.required">
                        <span class="input-title"></span>
                        <span class="input-entry">{{ 'common.validation.required' | translate }}</span>
                    </mat-error>
                </mat-error>
            </mat-form-field>
        </div>

        <!--- weather window end offset --->
        <div class="form-entry">
            <mat-form-field appearance="outline" floatLabel="always">
                <mat-label>{{ 'common.weather_window_end_offset' | translate }}</mat-label>
                <input
                    matInput
                    id="weatherWindowEndOffset"
                    type="number"
                    step="1"
                    #weatherWindowEndOffset="ngModel"
                    name="weatherWindowEndOffset"
                    placeholder="0"
                    maxlength="16"
                    [(ngModel)]="product.weatherWindowEndOffset"
                    data-automation="weatherWindowEndOffset"
                    data-url-persist
                    required
                />
                <mat-error *ngIf="weatherWindowEndOffset.invalid" class="alert alert-danger">
                    <mat-error *ngIf="weatherWindowEndOffset.errors.required">
                        <span class="input-title"></span>
                        <span class="input-entry">{{ 'common.validation.required' | translate }}</span>
                    </mat-error>
                </mat-error>
            </mat-form-field>
        </div>

        <ng-container ngModelGroup="selectionBuffers" #selectionBuffers="ngModelGroup" selectionBufferValidator>
            <!--- min selection buffer --->
            <div class="form-entry">
                <mat-form-field appearance="outline" floatLabel="always">
                    <mat-label>{{ 'common.min_selection_buffer' | translate }}</mat-label>
                    <input
                        matInput
                        id="minSelectionBuffer"
                        type="number"
                        min="0"
                        step=".1"
                        #minSelectionBuffer="ngModel"
                        name="minSelectionBuffer"
                        placeholder="50.0"
                        maxlength="16"
                        [(ngModel)]="product.selectionBufferMin"
                        data-automation="minSelectionBuffer"
                        data-url-persist
                        required
                    />
                    <mat-error *ngIf="minSelectionBuffer.invalid" class="alert alert-danger">
                        <mat-error *ngIf="minSelectionBuffer.errors.required">
                            <span class="input-title"></span>
                            <span class="input-entry">{{ 'common.validation.required' | translate }}</span>
                        </mat-error>
                    </mat-error>
                </mat-form-field>
            </div>

            <!--- max selection buffer --->
            <div class="form-entry">
                <mat-form-field appearance="outline" floatLabel="always">
                    <mat-label>{{ 'common.max_selection_buffer' | translate }}</mat-label>
                    <input
                        matInput
                        id="maxSelectionBuffer"
                        type="number"
                        min="0"
                        step=".1"
                        #maxSelectionBuffer="ngModel"
                        name="maxSelectionBuffer"
                        placeholder="75.0"
                        maxlength="16"
                        [(ngModel)]="product.selectionBufferMax"
                        data-automation="maxSelectionBuffer"
                        data-url-persist
                        required
                    />
                    <mat-error *ngIf="maxSelectionBuffer.invalid" class="alert alert-danger">
                        <mat-error *ngIf="maxSelectionBuffer.errors.required">
                            <span class="input-title"></span>
                            <span class="input-entry">{{ 'common.validation.required' | translate }}</span>
                        </mat-error>
                    </mat-error>
                </mat-form-field>
            </div>

            <!--- selection upper target --->
            <div class="form-entry">
                <mat-form-field appearance="outline" floatLabel="always">
                    <mat-label>{{ 'common.selection_upper_target' | translate }}</mat-label>
                    <input
                        matInput
                        id="selectionUpperTarget"
                        type="number"
                        step=".1"
                        #selectionUpperTarget="ngModel"
                        name="selectionUpperTarget"
                        placeholder="150.0"
                        maxlength="16"
                        [(ngModel)]="product.selectionUpperTarget"
                        data-automation="selectionUpperTarget"
                        data-url-persist
                        required
                    />
                    <mat-error *ngIf="selectionUpperTarget.invalid" class="alert alert-danger">
                        <mat-error *ngIf="selectionUpperTarget.errors.required">
                            <span class="input-title"></span>
                            <span class="input-entry">{{ 'common.validation.required' | translate }}</span>
                        </mat-error>
                    </mat-error>
                </mat-form-field>
            </div>

            <mat-error class="group-error" *ngIf="selectionBuffers.invalid">
                <mat-error *ngIf="selectionBuffers.errors && selectionBuffers.errors.minMaxValid">
                    <span class="input-title"></span>
                    <span class="input-entry" data-automation="validationMessage"
                        >{{ 'common.errors.max_min_val' | translate }}
                        {{ 'common.errors.upper_target' | translate }}</span
                    >
                </mat-error>
            </mat-error>
        </ng-container>

        <div class="form-entry"></div>
        <div class="checkboxes-container">
            <div class="form-entry-column">
                <!--- baseline capacity--->
                <div class="checkbox-wrapper">
                    <mat-checkbox
                        id="baselineCapacity"
                        #baselineCapacity="ngModel"
                        class="input-entry"
                        name="baselineCapacity"
                        [(ngModel)]="product.baselineCapacity"
                        data-automation="baselineCapacity"
                        data-url-persist
                    >
                        {{ 'common.baseline_capacity' | translate }}
                    </mat-checkbox>
                </div>

                <!-- default nomination schedule --->
                <div class="checkbox-wrapper">
                    <mat-checkbox
                        id="defaultNominationSchedule"
                        #defaultNominationSchedule="ngModel"
                        class="input-entry"
                        name="defaultNominationSchedule"
                        [(ngModel)]="product.defaultNominationSchedule"
                        data-automation="defaultNominationSchedule"
                        data-url-persist
                    >
                        {{ 'common.default_nomination_schedule' | translate }}
                    </mat-checkbox>
                </div>

                <!-- include nomination in comms --->
                <div class="checkbox-wrapper">
                    <mat-checkbox
                        id="includeNominationInComms"
                        #includeNominationInComms="ngModel"
                        class="input-entry"
                        name="includeNominationInComms"
                        [(ngModel)]="product.includeNominationInComms"
                        data-automation="includeNominationInComms"
                        data-url-persist
                    >
                        {{ 'common.include_nomination_in_comms' | translate }}
                    </mat-checkbox>
                </div>
                <!-- scale capacity to obligation --->
                <div class="checkbox-wrapper">
                    <mat-checkbox
                        id="scaleCapacityToObligation"
                        #scaleCapacityToObligation="ngModel"
                        class="input-entry"
                        name="scaleCapacityToObligation"
                        [(ngModel)]="product.scaleCapacityToObligation"
                        data-automation="scaleCapacityToObligation"
                        data-url-persist
                    >
                        {{ 'common.scale_capacity_to_obligation' | translate }}
                    </mat-checkbox>
                </div>
                <!--- Ignore availability  for selection--->
                <div class="checkbox-wrapper">
                    <mat-checkbox
                        id="ignoreAvailabilityForSelection"
                        #baselineCapacity="ngModel"
                        class="input-entry"
                        name="ignoreAvailabilityForSelection"
                        [(ngModel)]="product.dispatchConf.ignoreAvailabilityForSelection"
                        data-automation="ignoreAvailabilityForSelection"
                        data-url-persist
                    >
                        {{ 'common.ignore_availability_for_selection' | translate }}
                    </mat-checkbox>
                </div>
            </div>

            <div class="form-entry-column">
                <!--- underfrequency product--->
                <div class="checkbox-wrapper">
                    <mat-checkbox
                        id="underfrequencyProduct"
                        #underfrequencyProduct="ngModel"
                        class="input-entry"
                        name="underfrequencyProduct"
                        [(ngModel)]="product.underfrequencyProduct"
                        data-automation="underfrequencyProduct"
                        data-url-persist
                    >
                        {{ 'common.underfrequency_product' | translate }}
                    </mat-checkbox>
                </div>

                <!-- collect notification time --->
                <div class="checkbox-wrapper">
                    <mat-checkbox
                        id="collectNotificationTime"
                        #collectNotificationTime="ngModel"
                        class="input-entry"
                        name="collectNotificationTime"
                        [(ngModel)]="product.collectNotificationTime"
                        data-automation="collectNotificationTime"
                        data-url-persist
                    >
                        {{ 'common.collect_notification_time' | translate }}
                    </mat-checkbox>
                </div>

                <!-- dispatch by portfolio cleared offers --->
                <div class="checkbox-wrapper">
                    <mat-checkbox
                        id="dispatchByPortfolioClearedOffers"
                        #dispatchByPortfolioClearedOffers="ngModel"
                        class="input-entry"
                        name="dispatchByPortfolioClearedOffers"
                        [(ngModel)]="product.dispatchByPortfolioClearedOffers"
                        data-automation="dispatchByPortfolioClearedOffers"
                        data-url-persist
                    >
                        {{ 'common.dispatch_by_portfolio_cleared_offers' | translate }}
                    </mat-checkbox>
                </div>

                <!-- implement registration limits --->
                <div class="checkbox-wrapper">
                    <mat-checkbox
                        id="implementRegistrationLimits"
                        #implementRegistrationLimits="ngModel"
                        class="input-entry"
                        name="implementRegistrationLimits"
                        [(ngModel)]="product.implementRegistrationLimits"
                        data-automation="implementRegistrationLimits"
                        data-url-persist
                    >
                        {{ 'common.implement_registration_limits' | translate }}
                    </mat-checkbox>
                </div>

                <!-- Allow flexible asset overlap --->
                <div class="checkbox-wrapper">
                    <mat-checkbox
                        id="allowFlexibleAssetOverlap"
                        #allowFlexibleAssetOverlap="ngModel"
                        class="input-entry"
                        name="allowFlexibleAssetOverlap"
                        [(ngModel)]="product.allowFlexibleAssetOverlap"
                        data-automation="allowFlexibleAssetOverlap"
                        data-url-persist
                    >
                        {{ 'common.allow_flexible_asset_overlap' | translate }}
                        <mat-icon
                            svgIcon="enelx:general-info"
                            data-automation="allowFlexibleAssetOverlapTooltip"
                            class="tooltip-icon"
                            [matTooltipPosition]="'below'"
                            matTooltip="{{ 'common.tooltip.allow_flexible_asset_overlap' | translate }}"
                        ></mat-icon>
                    </mat-checkbox>
                </div>
            </div>
        </div>

        <div class="spacer"></div>

        <!--- Optional Fields --->
        <mat-expansion-panel
            (opened)="panelOpenedState = true"
            (closed)="panelOpenedState = false"
            togglePosition="before"
            class="expansion-panel"
            *ngIf="isCreateMode || isEditMode"
            data-automation="optional_panel"
            id="optional_panel"
        >
            <mat-expansion-panel-header collapsedHeight="48px" expandedHeight="48px">
                <mat-panel-title class="panel-title">
                    <div data-automation="panelTitle">
                        {{ 'common.optional_fields' | translate }}
                    </div>
                </mat-panel-title>
            </mat-expansion-panel-header>

            <div class="panel-content-wrapper">
                <ng-container
                    ngModelGroup="minMaxNotificationDuration"
                    #minMaxNotificationDuration="ngModelGroup"
                    minMaxValidator
                    [minControl]="minNotificationDuration"
                    [maxControl]="maxNotificationDuration"
                >
                    <!--- min notification duration --->
                    <div class="form-entry">
                        <mat-form-field appearance="outline" floatLabel="always">
                            <mat-label>{{ 'common.min_notification_duration' | translate }}</mat-label>
                            <input
                                matInput
                                id="minNotificationDuration"
                                type="number"
                                min="0"
                                step="1"
                                #minNotificationDuration="ngModel"
                                name="minNotificationDuration"
                                placeholder="0"
                                maxlength="16"
                                [(ngModel)]="product.minimumNotificationDuration"
                                data-automation="minNotificationDuration"
                            />
                            <mat-error *ngIf="minNotificationDuration.invalid" class="alert alert-danger">
                                <mat-error *ngIf="minNotificationDuration.errors.required">
                                    <span class="input-title"></span>
                                    <span class="input-entry">{{ 'common.validation.required' | translate }}</span>
                                </mat-error>
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <!--- max notification duration --->
                    <div class="form-entry">
                        <mat-form-field appearance="outline" floatLabel="always">
                            <mat-label>{{ 'common.max_notification_duration' | translate }}</mat-label>
                            <input
                                matInput
                                id="maxNotificationDuration"
                                type="number"
                                min="0"
                                step="1"
                                #maxNotificationDuration="ngModel"
                                name="maxNotificationDuration"
                                placeholder="0"
                                maxlength="16"
                                [(ngModel)]="product.maximumNotificationDuration"
                                data-automation="maxNotificationDuration"
                            />
                            <mat-error
                                *ngIf="
                                    maxNotificationDuration.invalid &&
                                    (maxNotificationDuration.dirty || maxNotificationDuration.touched)
                                "
                                class="alert alert-danger"
                            >
                                <mat-error *ngIf="maxNotificationDuration.errors.required">
                                    <span class="input-title"></span>
                                    <span class="input-entry">{{ 'common.validation.required' | translate }}</span>
                                </mat-error>
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <mat-error class="group-error" *ngIf="minMaxNotificationDuration.invalid">
                        <mat-error
                            *ngIf="minMaxNotificationDuration.errors && minMaxNotificationDuration.errors.minMaxValid"
                        >
                            <span class="input-title"></span>
                            <span class="input-entry" data-automation="validationMessage">{{
                                'common.errors.max_min_val' | translate
                            }}</span>
                        </mat-error>
                    </mat-error>
                </ng-container>

                <ng-container
                    ngModelGroup="minMaxResponseDuration"
                    #minMaxResponseDuration="ngModelGroup"
                    minMaxValidator
                    [minControl]="minResponseDuration"
                    [maxControl]="maxResponseDuration"
                >
                    <!--- min response duration --->
                    <div class="form-entry">
                        <mat-form-field appearance="outline" floatLabel="always">
                            <mat-label>{{ 'common.min_response_duration' | translate }}</mat-label>
                            <input
                                matInput
                                id="minResponseDuration"
                                type="number"
                                min="0"
                                step="1"
                                #minResponseDuration="ngModel"
                                name="minResponseDuration"
                                placeholder="0"
                                maxlength="16"
                                [(ngModel)]="product.minimumResponseDuration"
                                data-automation="minResponseDuration"
                            />
                            <mat-error *ngIf="minResponseDuration.invalid" class="alert alert-danger">
                                <mat-error *ngIf="minResponseDuration.errors.required">
                                    <span class="input-title"></span>
                                    <span class="input-entry">{{ 'common.validation.required' | translate }}</span>
                                </mat-error>
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <!--- max response duration --->
                    <div class="form-entry">
                        <mat-form-field appearance="outline" floatLabel="always">
                            <mat-label>{{ 'common.max_response_duration' | translate }}</mat-label>
                            <input
                                matInput
                                id="maxResponseDuration"
                                type="number"
                                min="0"
                                step="1"
                                #maxResponseDuration="ngModel"
                                name="maxResponseDuration"
                                placeholder="0"
                                maxlength="16"
                                [(ngModel)]="product.maximumResponseDuration"
                                data-automation="maxResponseDuration"
                            />
                            <mat-error *ngIf="maxResponseDuration.invalid" class="alert alert-danger">
                                <mat-error *ngIf="maxResponseDuration.errors.required">
                                    <span class="input-title"></span>
                                    <span class="input-entry">{{ 'common.validation.required' | translate }}</span>
                                </mat-error>
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <mat-error class="group-error" *ngIf="minMaxResponseDuration.invalid">
                        <mat-error *ngIf="minMaxResponseDuration.errors && minMaxResponseDuration.errors.minMaxValid">
                            <span class="input-title"></span>
                            <span class="input-entry" data-automation="validationMessage">{{
                                'common.errors.max_min_val' | translate
                            }}</span>
                        </mat-error>
                    </mat-error>
                </ng-container>

                <!--- min recovery duration --->
                <div class="form-entry">
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>{{ 'common.min_recovery_duration' | translate }}</mat-label>
                        <input
                            matInput
                            id="minRecoveryDuration"
                            type="number"
                            min="0"
                            step="1"
                            #minRecoveryDuration="ngModel"
                            name="minRecoveryDuration"
                            placeholder="0"
                            maxlength="16"
                            [(ngModel)]="product.minimumRecoveryDuration"
                            data-automation="minRecoveryDuration"
                        />
                        <mat-error
                            *ngIf="
                                minRecoveryDuration.invalid &&
                                (minRecoveryDuration.dirty || minRecoveryDuration.touched)
                            "
                            class="alert alert-danger"
                        >
                            <mat-error *ngIf="minRecoveryDuration.errors.required">
                                <span class="input-title"></span>
                                <span class="input-entry">{{ 'common.validation.required' | translate }}</span>
                            </mat-error>
                        </mat-error>
                    </mat-form-field>
                </div>

                <!--- max consecutive durations --->
                <div class="form-entry">
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>{{ 'common.max_consecutive_durations' | translate }}</mat-label>
                        <input
                            matInput
                            id="maxConsecutiveDurations"
                            type="number"
                            min="0"
                            step="1"
                            #maxConsecutiveDurations="ngModel"
                            name="maxConsecutiveDurations"
                            placeholder="0"
                            maxlength="16"
                            [(ngModel)]="product.maximumConsecutiveDurations"
                            data-automation="maxConsecutiveDurations"
                        />
                        <mat-error
                            *ngIf="
                                maxConsecutiveDurations.invalid &&
                                (maxConsecutiveDurations.dirty || maxConsecutiveDurations.touched)
                            "
                            class="alert alert-danger"
                        >
                            <mat-error *ngIf="maxConsecutiveDurations.errors.required">
                                <span class="input-title"></span>
                                <span class="input-entry">{{ 'common.validation.required' | translate }}</span>
                            </mat-error>
                        </mat-error>
                    </mat-form-field>
                </div>

                <!--- max invocations per duration --->
                <div class="form-entry">
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>{{ 'common.max_invocations_per_duration' | translate }}</mat-label>
                        <input
                            matInput
                            id="maxInvocationsPerDuration"
                            min="0"
                            type="number"
                            step="1"
                            #maxInvocationsPerDuration="ngModel"
                            name="maxInvocationsPerDuration"
                            placeholder="0"
                            maxlength="16"
                            [(ngModel)]="product.maximumInvocationsPerDuration"
                            data-automation="maxInvocationsPerDuration"
                        />
                        <mat-error
                            *ngIf="
                                maxInvocationsPerDuration.invalid &&
                                (maxInvocationsPerDuration.dirty || maxInvocationsPerDuration.touched)
                            "
                            class="alert alert-danger"
                        >
                            <mat-error *ngIf="maxInvocationsPerDuration.errors.required">
                                <span class="input-title"></span>
                                <span class="input-entry">{{ 'common.validation.required' | translate }}</span>
                            </mat-error>
                        </mat-error>
                    </mat-form-field>
                </div>

                <!--- post bonus minutes --->
                <div class="form-entry">
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>{{ 'common.dispatch_conf.post_bonus_minutes' | translate }}</mat-label>
                        <input
                            matInput
                            id="postBonusMinutes"
                            type="number"
                            step="1"
                            #postBonusMinutes="ngModel"
                            name="postBonusMinutes"
                            placeholder="0"
                            maxlength="16"
                            [min]="0"
                            [(ngModel)]="product.dispatchConf.postBonusMinutes"
                            data-automation="postBonusMinutes"
                            data-url-persist
                        />
                    </mat-form-field>
                </div>

                <!--- Management Email --->
                <div class="form-entry">
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>{{ 'common.dispatch_conf.management_email' | translate }}</mat-label>
                        <input
                            matInput
                            id="managementEmail"
                            type="email"
                            #managementEmail="ngModel"
                            name="managementEmail"
                            placeholder="{{ 'product.create.placeholder.dispatch_conf.management_email' | translate }}"
                            maxlength="80"
                            email
                            pattern="^([A-Za-z0-9_\-\.]+)@[A-Za-z0-9-]+(\.[A-Za-z0-9-]+)*(\.[A-Za-z]{2,3})$"
                            [(ngModel)]="product.dispatchConf.managementEmail"
                            data-automation="managementEmail"
                            data-url-persist
                        />
                        <mat-error
                            *ngIf="managementEmail.invalid && (managementEmail.dirty || managementEmail.touched)"
                            class="alert alert-danger"
                        >
                            <mat-error *ngIf="managementEmail.errors.pattern">
                                <span class="input-title"></span>
                                <span class="input-entry">{{ 'common.errors.email' | translate }}</span>
                            </mat-error>
                        </mat-error>
                    </mat-form-field>
                </div>

                <!--- max events per day --->
                <div class="form-entry">
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>{{ 'common.dispatch_conf.max_events_per_day' | translate }}</mat-label>
                        <input
                            matInput
                            id="maxEventsPerDay"
                            type="number"
                            step="1"
                            #maxEventsPerDay="ngModel"
                            name="maxEventsPerDay"
                            placeholder="0"
                            maxlength="16"
                            [min]="0"
                            [(ngModel)]="product.dispatchConf.maxEventsPerDay"
                            data-automation="maxEventsPerDay"
                            data-url-persist
                        />
                    </mat-form-field>
                </div>

                <!--- max events per week --->
                <div class="form-entry">
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>{{ 'common.dispatch_conf.max_events_per_week' | translate }}</mat-label>
                        <input
                            matInput
                            id="maxEventsPerWeek"
                            type="number"
                            step="1"
                            #maxEventsPerWeek="ngModel"
                            name="maxEventsPerWeek"
                            placeholder="0"
                            maxlength="16"
                            [min]="0"
                            [(ngModel)]="product.dispatchConf.maxEventsPerWeek"
                            data-automation="maxEventsPerWeek"
                            data-url-persist
                        />
                    </mat-form-field>
                </div>

                <!--- max events per month --->
                <div class="form-entry">
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>{{ 'common.dispatch_conf.max_events_per_month' | translate }}</mat-label>
                        <input
                            matInput
                            id="maxEventsPerMonth"
                            type="number"
                            step="1"
                            #maxEventsPerMonth="ngModel"
                            name="maxEventsPerMonth"
                            placeholder="0"
                            maxlength="16"
                            [min]="0"
                            [(ngModel)]="product.dispatchConf.maxEventsPerMonth"
                            data-automation="maxEventsPerMonth"
                            data-url-persist
                        />
                    </mat-form-field>
                </div>

                <!-- Ramp Period -->
                <div class="form-entry">
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>{{ 'common.dispatch_conf.ramping_period' | translate }}</mat-label>
                        <input
                            matInput
                            id="rampingPeriod"
                            type="number"
                            step="1"
                            #maxEventsPerMonth="ngModel"
                            name="rampingPeriod"
                            placeholder="0"
                            maxlength="16"
                            [min]="0"
                            [(ngModel)]="product.dispatchConf.rampingPeriod"
                            data-automation="rampingPeriod"
                            data-url-persist
                        />
                    </mat-form-field>
                </div>

                <!-- Post Ramping Period -->
                <div class="form-entry">
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>{{ 'common.dispatch_conf.post_ramping_period' | translate }}</mat-label>
                        <input
                            matInput
                            id="postRampingPeriod"
                            type="number"
                            step="1"
                            #maxEventsPerMonth="ngModel"
                            name="postRampingPeriod"
                            placeholder="1200000"
                            maxlength="16"
                            [min]="0"
                            [(ngModel)]="product.dispatchConf.postRampingPeriod"
                            data-automation="postRampingPeriod"
                            data-url-persist
                        />
                    </mat-form-field>
                </div>

                <!-- Use Portfolio Availability --->
                <div class="form-entry">
                    <div class="checkbox-wrapper">
                        <mat-checkbox
                            id="usePortfolioAvailability"
                            #usePortfolioAvailability="ngModel"
                            class="input-entry"
                            [ngClass]="{
                                failure:
                                    usePortfolioAvailability.invalid &&
                                    (usePortfolioAvailability.dirty || usePortfolioAvailability.touched)
                            }"
                            name="usePortfolioAvailability"
                            [(ngModel)]="product.marketConfigurations.usePortfolioAvailability"
                            data-automation="usePortfolioAvailability"
                            data-url-persist
                        >
                            {{ 'common.use_portfolio_availability' | translate }}
                        </mat-checkbox>
                    </div>
                </div>

                <!--- Dispatch Instructions --->
                <div class="form-entry-full-width pad-2-b">
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>{{ 'common.dispatch_conf.dispatch_instructions' | translate }}:</mat-label>
                        <textarea
                            matInput
                            id="dispatchInstructions"
                            #dispatchInstructions="ngModel"
                            type="text"
                            rows="10"
                            name="dispatchInstructions"
                            placeholder="{{
                                'product.create.placeholder.dispatch_conf.dispatch_instructions' | translate
                            }}"
                            maxlength="1000"
                            [(ngModel)]="product.dispatchConf.dispatchInstructions"
                            data-automation="dispatchInstructions"
                            data-url-persist
                        ></textarea>
                        <mat-hint
                            >{{ 1000 - dispatchInstructions.value?.length }}
                            {{ 'common.characters_remaining' | translate }}</mat-hint
                        >
                    </mat-form-field>
                </div>

                <!-- include bonus minutes --->
                <div class="form-entry-column">
                    <div class="checkbox-wrapper">
                        <mat-checkbox
                            id="includeBonusMinutes"
                            #includeBonusMinutes="ngModel"
                            class="input-entry"
                            name="includeBonusMinutes"
                            [(ngModel)]="product.dispatchConf.includeBonusMinutes"
                            data-automation="includeBonusMinutes"
                            data-url-persist
                        >
                            {{ 'common.dispatch_conf.include_bonus_minutes' | translate }}
                        </mat-checkbox>
                    </div>
                </div>

                <!-- site list event notifications --->
                <div class="checkbox-wrapper">
                    <mat-checkbox
                        id="siteListEventNotifications"
                        #siteListEventNotifications="ngModel"
                        class="input-entry"
                        name="siteListEventNotifications"
                        [(ngModel)]="product.dispatchConf.siteListEventNotifications"
                        data-automation="siteListEventNotifications"
                        data-url-persist
                    >
                        {{ 'common.site_list_event_notifications' | translate }}
                    </mat-checkbox>
                </div>
                <!-- site list non event notifications --->
                <div class="checkbox-wrapper">
                    <mat-checkbox
                        id="siteListNonEventNotifications"
                        #siteListNonEventNotifications="ngModel"
                        class="input-entry"
                        name="siteListNonEventNotifications"
                        [(ngModel)]="product.dispatchConf.siteListNonEventNotifications"
                        data-automation="siteListNonEventNotifications"
                        data-url-persist
                    >
                        {{ 'common.site_list_non_event_notifications' | translate }}
                    </mat-checkbox>
                </div>
            </div>
        </mat-expansion-panel>

        <!--- schedule --->
        <mat-expansion-panel
            (opened)="panelOpenedState = true"
            (closed)="panelOpenedState = false"
            togglePosition="before"
            class="expansion-panel"
            *ngIf="isCreateMode || isEditMode"
            id="schedule_panel"
            name="schedulePanel"
            data-automation="schedulePanel"
        >
            <mat-expansion-panel-header collapsedHeight="48px" expandedHeight="48px">
                <mat-panel-title class="panel-title">
                    <div data-automation="panelTitle" data-automation="schedule_panel">
                        {{ 'common.schedule_title' | translate }}
                    </div>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="panel-content-wrapper">
                <!--- offer frequency --->
                <div class="form-entry">
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>{{ 'common.schedule.offer_frequency' | translate }}</mat-label>
                        <input
                            matInput
                            id="offerFrequency"
                            type="number"
                            step="1"
                            #offerFrequency="ngModel"
                            name="offerFrequency"
                            placeholder="0"
                            maxlength="16"
                            [(ngModel)]="product.schedule.offerFrequency"
                            data-automation="offerFrequency"
                            data-url-persist
                            required
                        />
                        <mat-error
                            *ngIf="offerFrequency.invalid && (offerFrequency.dirty || offerFrequency.touched)"
                            class="alert alert-danger"
                        >
                            <mat-error *ngIf="offerFrequency.errors.required">
                                <span class="input-title"></span>
                                <span class="input-entry">{{ 'common.validation.required' | translate }}</span>
                            </mat-error>
                        </mat-error>
                    </mat-form-field>
                </div>

                <!--- offer frequency unit--->
                <div class="form-entry">
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>{{ 'common.schedule.offer_frequency_unit' | translate }}</mat-label>
                        <mat-select
                            id="offerFrequencyUnit"
                            name="offerFrequencyUnit"
                            #offerFrequencyUnit="ngModel"
                            [(ngModel)]="product.schedule.offerFrequencyUnit"
                            (selectionChange)="updateGroupUnit($event)"
                            data-automation="offerFrequencyUnitSelector"
                            data-url-persist
                            required
                            placeholder="{{ 'product.create.placeholder.frequency_unit' | translate }}"
                        >
                            <mat-option>{{ 'product.create.placeholder.frequency_unit' | translate }}</mat-option>
                            <mat-option
                                *ngFor="let offerFrequencyUnit of offerFrequencyUnits"
                                [value]="offerFrequencyUnit.code"
                                id="offerFrequencyUnit_{{ offerFrequencyUnit.code }}"
                            >
                                {{ offerFrequencyUnit.displayLabel }}
                            </mat-option>
                        </mat-select>
                        <mat-error
                            *ngIf="
                                offerFrequencyUnit.invalid && (offerFrequencyUnit.dirty || offerFrequencyUnit.touched)
                            "
                            class="alert alert-danger"
                        >
                            <mat-error *ngIf="offerFrequencyUnit.errors.required">
                                {{ 'common.validation.required' | translate }}
                            </mat-error>
                        </mat-error>
                    </mat-form-field>
                </div>

                <ng-container
                    ngModelGroup="scheduleStartEndDates"
                    #scheduleStartEndDates="ngModelGroup"
                    startEndDateValidator
                    [startControl]="scheduleStartDttm"
                    [endControl]="scheduleEndDttm"
                >
                    <!--- start date --->
                    <div class="form-entry">
                        <mat-form-field appearance="outline" floatLabel="always">
                            <mat-label>{{ 'common.schedule.start_date' | translate }}</mat-label>
                            <input
                                matInput
                                [matDatepicker]="scheduleStartDate"
                                #scheduleStartDttm="ngModel"
                                [(ngModel)]="product.schedule.startDttm"
                                required
                                name="scheduleStartDttm"
                                id="scheduleStartDttm"
                                placeholder="{{ 'product.create.placeholder.select_date' | translate }}"
                                data-automation="scheduleStartDttm"
                                data-url-persist
                            />
                            <mat-datepicker-toggle matSuffix [for]="scheduleStartDate">
                                <mat-icon
                                    matDatepickerToggleIcon
                                    svgIcon="enelx:calendar"
                                    class="mat-form-icon"
                                    data-automation="scheduleStartDttm_icon"
                                >
                                </mat-icon>
                            </mat-datepicker-toggle>
                            <mat-datepicker #scheduleStartDate startView="month"></mat-datepicker>
                            <mat-error *ngIf="scheduleStartDttm.invalid" class="alert alert-danger">
                                <mat-error *ngIf="scheduleStartDttm.errors.required">
                                    {{ 'common.validation.required' | translate }}
                                </mat-error>
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <!--- end date --->
                    <div class="form-entry">
                        <mat-form-field appearance="outline" floatLabel="always">
                            <mat-label>{{ 'common.schedule.end_date' | translate }}</mat-label>
                            <input
                                matInput
                                [matDatepicker]="scheduleEndDate"
                                #scheduleEndDttm="ngModel"
                                [(ngModel)]="product.schedule.endDttm"
                                required
                                name="scheduleEndDttm"
                                id="scheduleEndDttm"
                                placeholder="{{ 'product.create.placeholder.select_date' | translate }}"
                                data-automation="scheduleEndDttm"
                                data-url-persist
                            />
                            <mat-datepicker-toggle matSuffix [for]="scheduleEndDate">
                                <mat-icon
                                    matDatepickerToggleIcon
                                    svgIcon="enelx:calendar"
                                    class="mat-form-icon"
                                    data-automation="scheduleEndDttm_icon"
                                >
                                </mat-icon>
                            </mat-datepicker-toggle>
                            <mat-datepicker #scheduleEndDate startView="month"></mat-datepicker>
                            <mat-error *ngIf="scheduleEndDttm.invalid" class="alert alert-danger">
                                <mat-error *ngIf="scheduleEndDttm.errors.required">
                                    {{ 'common.validation.required' | translate }}
                                </mat-error>
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <mat-error class="group-error" *ngIf="scheduleStartEndDates.invalid">
                        <mat-error
                            *ngIf="scheduleStartEndDates.errors && scheduleStartEndDates.errors.startEndDateValid"
                        >
                            <span class="input-title"></span>
                            <span class="input-entry" data-automation="validationMessage">{{
                                'common.errors.start_end_date' | translate
                            }}</span>
                        </mat-error>
                    </mat-error>
                </ng-container>

                <!--- offer group unit--->
                <div class="form-entry">
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>{{ 'common.schedule.offer_group_unit' | translate }}</mat-label>
                        <mat-select
                            id="offerGroupUnit"
                            name="offerGroupUnit"
                            #offerGroupUnit="ngModel"
                            [(ngModel)]="product.schedule.offerGroupUnit"
                            [disabled]="product.schedule.offerFrequencyUnit === ''"
                            data-automation="offerGroupUnitSelector"
                            data-url-persist
                            required
                            placeholder="{{ 'product.create.placeholder.frequency_unit' | translate }}"
                        >
                            <mat-option>{{ 'common.schedule.offer_group_unit' | translate }}</mat-option>
                            <mat-option
                                *ngFor="let offerFrequencyUnit of offerGroupFrequencyUnits"
                                [value]="offerFrequencyUnit.code"
                                id="offerGroupUnit_{{ offerFrequencyUnit.code }}"
                            >
                                {{ offerFrequencyUnit.displayLabel }}
                            </mat-option>
                        </mat-select>
                        <mat-error
                            *ngIf="offerGroupUnit.invalid && (offerGroupUnit.dirty || offerGroupUnit.touched)"
                            class="alert alert-danger"
                        >
                            <mat-error *ngIf="offerGroupUnit.errors.required">
                                {{ 'common.validation.required' | translate }}
                            </mat-error>
                        </mat-error>
                    </mat-form-field>
                </div>

                <!--- rule --->
                <div class="form-entry">
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>{{ 'common.schedule.rule' | translate }}:</mat-label>
                        <input
                            matInput
                            id="scheduleRule"
                            #scheduleRule="ngModel"
                            type="text"
                            name="scheduleRule"
                            placeholder="{{ 'product.create.placeholder.cron_expression' | translate }}"
                            maxlength="80"
                            [(ngModel)]="product.schedule.rule"
                            data-automation="scheduleRule"
                            data-url-persist
                            required
                            cronValidator
                        />
                        <mat-error
                            *ngIf="scheduleRule.invalid && (scheduleRule.dirty || scheduleRule.touched)"
                            class="alert alert-danger"
                        >
                            <mat-error *ngIf="scheduleRule.errors.required">
                                <span class="input-title"></span>
                                <span>{{ 'common.validation.required' | translate }}</span>
                            </mat-error>
                            <mat-error *ngIf="scheduleRule.errors.cronValid">
                                <span class="input-title"></span>
                                <span>{{ 'common.errors.non_cron_expression' | translate }}</span>
                            </mat-error>
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
        </mat-expansion-panel>

        <!--- Gate Rules --->
        <mat-expansion-panel
            (opened)="panelOpenedState = true"
            (closed)="panelOpenedState = false"
            togglePosition="before"
            class="expansion-panel"
            *ngIf="isCreateMode || isEditMode"
            id="gate_panel"
            data-automation="gateRulesPanel"
        >
            <mat-expansion-panel-header collapsedHeight="48px" expandedHeight="48px">
                <mat-panel-title class="panel-title">
                    <div data-automation="panelTitle">
                        {{ 'common.gate_rules_title' | translate }}
                    </div>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="panel-content-wrapper">
                <!--- gate closure description --->
                <div class="form-entry">
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>{{ 'common.gate_rules.gate_closure_description' | translate }}:</mat-label>
                        <input
                            matInput
                            id="gateClosureDescription"
                            #gateClosureDescription="ngModel"
                            type="text"
                            name="gateClosureDescription"
                            placeholder="{{ 'product.create.placeholder.gate_rules.gate_description' | translate }}"
                            maxlength="1000"
                            [(ngModel)]="product.gateRules.gateClosureDescription"
                            data-automation="gateClosureDescription"
                            data-url-persist
                            required
                        />
                        <mat-error *ngIf="gateClosureDescription.invalid" class="alert alert-danger">
                            <mat-error *ngIf="gateClosureDescription.errors.required">
                                <span>{{ 'common.validation.required' | translate }}</span>
                            </mat-error>
                        </mat-error>
                    </mat-form-field>
                </div>

                <!--- gate closure tolerance --->
                <div class="form-entry">
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>{{ 'common.gate_rules.gate_closure_tolerance' | translate }}</mat-label>
                        <input
                            matInput
                            id="gateClosureTolerance"
                            type="number"
                            step="1"
                            #gateClosureTolerance="ngModel"
                            name="gateClosureTolerance"
                            placeholder="0"
                            maxlength="16"
                            [(ngModel)]="product.gateRules.gateClosureTolerance"
                            data-automation="gateClosureTolerance"
                            data-url-persist
                            required
                        />
                        <mat-error *ngIf="gateClosureTolerance.invalid" class="alert alert-danger">
                            <mat-error *ngIf="gateClosureTolerance.errors.required">
                                <span class="input-title"></span>
                                <span class="input-entry">{{ 'common.validation.required' | translate }}</span>
                            </mat-error>
                        </mat-error>
                    </mat-form-field>
                </div>

                <!--- gate closes every --->
                <div class="form-entry">
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>{{ 'common.gate_rules.gate_closes_every' | translate }}:</mat-label>
                        <input
                            matInput
                            id="gateClosesEvery"
                            #gateClosesEvery="ngModel"
                            type="text"
                            name="gateClosesEvery"
                            placeholder="{{ 'product.create.placeholder.cron_expression' | translate }}"
                            maxlength="80"
                            [(ngModel)]="product.gateRules.gateClosesEvery"
                            data-automation="gateClosesEvery"
                            cronValidator
                        />
                        <mat-error
                            *ngIf="gateClosesEvery.invalid && (gateClosesEvery.dirty || gateClosesEvery.touched)"
                            class="alert alert-danger"
                        >
                            <mat-error *ngIf="gateClosesEvery.errors.required">
                                <span class="input-title"></span>
                                <span>{{ 'common.validation.required' | translate }}</span>
                            </mat-error>
                            <mat-error *ngIf="gateClosesEvery.errors.cronValid">
                                <span class="input-title"></span>
                                <span>{{ 'common.errors.non_cron_expression' | translate }}</span>
                            </mat-error>
                        </mat-error>
                    </mat-form-field>
                </div>

                <!--- gate closes on  --->
                <div class="form-entry">
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>{{ 'common.gate_rules.gate_closes_on' | translate }}</mat-label>
                        <input
                            matInput
                            [matDatepicker]="gateClosesOn"
                            [(ngModel)]="product.gateRules.gateClosesOn"
                            name="gateClosesOn"
                            id="gateClosesOn"
                            placeholder="{{ 'product.create.placeholder.select_date' | translate }}"
                            data-automation="gateClosesOn"
                        />
                        <mat-datepicker-toggle matSuffix [for]="gateClosesOn">
                            <mat-icon
                                matDatepickerToggleIcon
                                svgIcon="enelx:calendar"
                                class="mat-form-icon"
                                data-automation="gateClosesOn_icon"
                            >
                            </mat-icon>
                        </mat-datepicker-toggle>
                        <mat-datepicker #gateClosesOn startView="month"></mat-datepicker>
                    </mat-form-field>
                </div>

                <!--- gate closes to specific  --->
                <div class="form-entry">
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>{{ 'common.gate_rules.gate_closes_to_specific' | translate }}</mat-label>
                        <input
                            matInput
                            [matDatepicker]="gateClosesToSpecific"
                            [(ngModel)]="product.gateRules.gateClosesToSpecific"
                            name="gateClosesToSpecific"
                            id="gateClosesToSpecific"
                            placeholder="{{ 'product.create.placeholder.select_date' | translate }}"
                            data-automation="gateClosesToSpecific"
                        />
                        <mat-datepicker-toggle matSuffix [for]="gateClosesToSpecific">
                            <mat-icon
                                matDatepickerToggleIcon
                                svgIcon="enelx:calendar"
                                class="mat-form-icon"
                                data-automation="gateClosesToSpecific_icon"
                            >
                            </mat-icon>
                        </mat-datepicker-toggle>
                        <mat-datepicker #gateClosesToSpecific startView="month"></mat-datepicker>
                    </mat-form-field>
                </div>

                <!--- gate closes from specific  --->
                <div class="form-entry">
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>{{ 'common.gate_rules.gate_closes_from_specific' | translate }}</mat-label>
                        <input
                            matInput
                            [matDatepicker]="gateClosesFromSpecific"
                            [(ngModel)]="product.gateRules.gateClosesFromSpecific"
                            name="gateClosesFromSpecific"
                            id="gateClosesFromSpecific"
                            placeholder="{{ 'product.create.placeholder.select_date' | translate }}"
                            data-automation="gateClosesFromSpecific"
                        />
                        <mat-datepicker-toggle matSuffix [for]="gateClosesFromSpecific">
                            <mat-icon
                                matDatepickerToggleIcon
                                svgIcon="enelx:calendar"
                                class="mat-form-icon"
                                data-automation="gateClosesFromSpecific_icon"
                            >
                            </mat-icon>
                        </mat-datepicker-toggle>
                        <mat-datepicker #gateClosesFromSpecific startView="month"></mat-datepicker>
                    </mat-form-field>
                </div>

                <!--- gate closes to relative --->
                <div class="form-entry">
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>{{ 'common.gate_rules.gate_closes_to_relative' | translate }}</mat-label>
                        <input
                            matInput
                            id="gateClosesToRelative"
                            type="number"
                            step="1"
                            #gateClosesToRelative="ngModel"
                            name="gateClosesToRelative"
                            placeholder="0"
                            maxlength="16"
                            [(ngModel)]="product.gateRules.gateClosesToRelative"
                            data-automation="gateClosesToRelative"
                        />
                        <mat-error
                            *ngIf="
                                gateClosesToRelative.invalid &&
                                (gateClosesToRelative.dirty || gateClosesToRelative.touched)
                            "
                            class="alert alert-danger"
                        >
                            <mat-error *ngIf="gateClosesToRelative.errors.required">
                                <span class="input-title"></span>
                                <span class="input-entry">{{ 'common.validation.required' | translate }}</span>
                            </mat-error>
                        </mat-error>
                    </mat-form-field>
                </div>

                <!--- gate closes from relative --->
                <div class="form-entry">
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>{{ 'common.gate_rules.gate_closes_from_relative' | translate }}</mat-label>
                        <input
                            matInput
                            id="gateClosesFromRelative"
                            type="number"
                            step="1"
                            #gateClosesFromRelative="ngModel"
                            name="gateClosesFromRelative"
                            placeholder="0"
                            maxlength="16"
                            [(ngModel)]="product.gateRules.gateClosesFromRelative"
                            data-automation="gateClosesFromRelative"
                        />
                        <mat-error
                            *ngIf="
                                gateClosesFromRelative.invalid &&
                                (gateClosesFromRelative.dirty || gateClosesFromRelative.touched)
                            "
                            class="alert alert-danger"
                        >
                            <mat-error *ngIf="gateClosesFromRelative.errors.required">
                                <span class="input-title"></span>
                                <span class="input-entry">{{ 'common.validation.required' | translate }}</span>
                            </mat-error>
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="form-entry-column">
                    <!-- allow offer increase --->
                    <div class="checkbox-wrapper">
                        <mat-checkbox
                            id="allowOfferIncrease"
                            #allowOfferIncrease="ngModel"
                            class="input-entry"
                            name="allowOfferIncrease"
                            [(ngModel)]="product.gateRules.postGateEdits.allowOfferIncrease"
                            data-automation="allowOfferIncrease"
                            data-url-persist
                        >
                            {{ 'common.gate_rules.allow_offer_increase' | translate }}
                        </mat-checkbox>
                    </div>

                    <!-- allow offer decrease --->
                    <div class="checkbox-wrapper">
                        <mat-checkbox
                            id="allowOfferDecrease"
                            #allowOfferDecrease="ngModel"
                            class="input-entry"
                            name="allowOfferDecrease"
                            [(ngModel)]="product.gateRules.postGateEdits.allowOfferDecrease"
                            data-automation="allowOfferDecrease"
                            data-url-persist
                        >
                            {{ 'common.gate_rules.allow_offer_decrease' | translate }}
                        </mat-checkbox>
                    </div>

                    <!-- allow increase after decrease --->
                    <div class="checkbox-wrapper">
                        <mat-checkbox
                            id="allowIncreaseAfterDecrease"
                            #allowIncreaseAfterDecrease="ngModel"
                            class="input-entry"
                            name="allowIncreaseAfterDecrease"
                            [(ngModel)]="product.gateRules.postGateEdits.allowIncreaseAfterDecrease"
                            data-automation="allowIncreaseAfterDecrease"
                            data-url-persist
                        >
                            {{ 'common.gate_rules.allow_increase_after_decrease' | translate }}
                        </mat-checkbox>
                    </div>
                    <!-- allow offer edit --->
                    <div class="checkbox-wrapper">
                        <mat-checkbox
                            id="allowOfferEdit"
                            #allowOfferEdit="ngModel"
                            class="input-entry"
                            name="allowOfferEdit"
                            [(ngModel)]="product.gateRules.postGateEdits.allowOfferEdit"
                            data-automation="allowOfferEdit"
                            data-url-persist
                        >
                            {{ 'common.gate_rules.allow_offer_edit' | translate }}
                        </mat-checkbox>
                    </div>
                </div>

                <div class="form-entry-column">
                    <!--- provide change reason --->
                    <!--                    <div class="form-entry">-->
                    <!--                        <div class="checkbox-wrapper">-->
                    <!--                            <mat-checkbox-->
                    <!--                                id="provideChangeReason"-->
                    <!--                                #provideChangeReason="ngModel"-->
                    <!--                                class="input-entry"-->
                    <!--                                name="provideChangeReason"-->
                    <!--                                [(ngModel)]="product.gateRules.postGateEdits.provideChangeReason"-->
                    <!--                                data-automation="provideChangeReason"-->
                    <!--                                data-url-persist-->
                    <!--                            >-->
                    <!--                                {{ 'common.gate_rules.provide_change_reason' | translate }}-->
                    <!--                            </mat-checkbox>-->
                    <!--                        </div>-->
                    <!--                    </div>-->

                    <!--- allow post gate closure edits--->
                    <div class="checkbox-wrapper">
                        <mat-checkbox
                            id="allowPostGateClosureEdits"
                            #allowPostGateClosureEdits="ngModel"
                            class="input-entry"
                            name="allowPostGateClosureEdits"
                            [(ngModel)]="product.gateRules.postGateEdits.allowPostGateClosureEdits"
                            data-automation="allowPostGateClosureEdits"
                            data-url-persist
                        >
                            {{ 'common.gate_rules.allow_post_gate_closure_edits' | translate }}
                        </mat-checkbox>
                    </div>

                    <!-- allow current interval edits --->
                    <div class="checkbox-wrapper">
                        <mat-checkbox
                            id="allowCurrentIntervalEdit"
                            #allowCurrentIntervalEdit="ngModel"
                            class="input-entry"
                            name="allowCurrentIntervalEdit"
                            [(ngModel)]="product.gateRules.postGateEdits.allowCurrentIntervalEdit"
                            data-automation="allowCurrentIntervalEdit"
                            data-url-persist
                        >
                            {{ 'common.gate_rules.allow_current_interval_edit' | translate }}
                        </mat-checkbox>
                    </div>
                    <!-- full day offer value --->
                    <div class="checkbox-wrapper">
                        <mat-checkbox
                            id="fullDayOfferValue"
                            #fullDayOfferValue="ngModel"
                            class="input-entry"
                            name="fullDayOfferValue"
                            [(ngModel)]="product.gateRules.fullDayOfferValue"
                            data-automation="fullDayOfferValue"
                            data-url-persist
                        >
                            {{ 'common.gate_rules.full_day_offer_value' | translate }}
                        </mat-checkbox>
                    </div>
                </div>
            </div>
        </mat-expansion-panel>

        <!--- prez config --->
        <mat-expansion-panel
            (opened)="panelOpenedState = true"
            (closed)="panelOpenedState = false"
            togglePosition="before"
            class="expansion-panel"
            *ngIf="isCreateMode || isEditMode"
            id="prez_panel"
            data-automation="prezConfigPanel"
        >
            <mat-expansion-panel-header collapsedHeight="48px" expandedHeight="48px">
                <mat-panel-title class="panel-title">
                    <div data-automation="panelTitle">
                        {{ 'common.prez_config_title' | translate }}
                    </div>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="panel-content-wrapper">
                <!--- preferred prez demand uom--->
                <div class="form-entry">
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>{{ 'common.prez_config.prefered_prez_demand_uom' | translate }}</mat-label>
                        <mat-select
                            id="preferedPrezDemandUom"
                            name="preferedPrezDemandUom"
                            #preferedPrezDemandUom="ngModel"
                            [(ngModel)]="product.prezConf.preferedPrezDemandUom"
                            data-automation="preferedPrezDemandUomSelector"
                            data-url-persist
                            required
                            placeholder="{{ 'product.create.placeholder.prefered_prez_demand_uom' | translate }}"
                        >
                            <mat-option
                                *ngFor="let preferedPrezDemandUom of preferedPrezDemandUoms"
                                [value]="preferedPrezDemandUom.code"
                                id="{{ preferedPrezDemandUom.code }}"
                            >
                                {{ preferedPrezDemandUom.displayLabel }}
                            </mat-option>
                        </mat-select>
                        <mat-error
                            *ngIf="
                                preferedPrezDemandUom.invalid &&
                                (preferedPrezDemandUom.dirty || preferedPrezDemandUom.touched)
                            "
                            class="alert alert-danger"
                        >
                            <mat-error *ngIf="preferedPrezDemandUom.errors.required">
                                {{ 'common.validation.required' | translate }}
                            </mat-error>
                        </mat-error>
                    </mat-form-field>
                </div>

                <!-- event management hex code --->
                <div class="form-entry">
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>{{ 'common.prez_config.event_management_hex_code' | translate }}</mat-label>
                        <input
                            matInput
                            [ngxMatColorPicker]="picker"
                            #eventManagementHexCode="ngModel"
                            id="eventManagementHexCode"
                            name="eventManagementHexCode"
                            [(ngModel)]="product.prezConf.eventManagementHexCode"
                        />
                        <ngx-mat-color-toggle matSuffix [for]="picker"></ngx-mat-color-toggle>
                        <ngx-mat-color-picker #picker [touchUi]="false"> </ngx-mat-color-picker>
                    </mat-form-field>
                </div>

                <ng-container
                    ngModelGroup="rampUpDownGroup"
                    #rampUpDownGroup="ngModelGroup"
                    minMaxValidator
                    [minControl]="rampDown"
                    [maxControl]="rampUp"
                >
                    <!-- ramp up --->
                    <div class="form-entry">
                        <mat-form-field appearance="outline" floatLabel="always">
                            <mat-label>{{ 'common.prez_config.ramp_up' | translate }}</mat-label>
                            <input
                                matInput
                                type="number"
                                min="0"
                                oninput="this.value ? this.value = Math.abs(this.value) : this.value"
                                step=".1"
                                placeholder="0"
                                #rampUp="ngModel"
                                id="rampUp"
                                name="rampUp"
                                [(ngModel)]="product.prezConf.rampUp"
                            />
                        </mat-form-field>
                    </div>
                    <!-- ramp down --->
                    <div class="form-entry">
                        <mat-form-field appearance="outline" floatLabel="always">
                            <mat-label>{{ 'common.prez_config.ramp_down' | translate }}</mat-label>
                            <input
                                matInput
                                type="number"
                                min="0"
                                oninput="this.value ? this.value = Math.abs(this.value) : this.value"
                                step=".1"
                                placeholder="0"
                                #rampDown="ngModel"
                                id="rampDown"
                                name="rampDown"
                                [(ngModel)]="product.prezConf.rampDown"
                            />
                        </mat-form-field>
                    </div>
                    <mat-error class="group-error" *ngIf="rampUpDownGroup.invalid">
                        <mat-error *ngIf="rampUpDownGroup.errors && rampUpDownGroup.errors.minMaxValid">
                            <span class="input-title"></span>
                            <span class="input-entry" data-automation="validationMessage">{{
                                'common.errors.max_min_val' | translate
                            }}</span>
                        </mat-error>
                    </mat-error>

                    <div class="form-entry-column">
                        <!--Cleared Tab-->
                        <div class="checkbox-wrapper">
                            <mat-checkbox
                                id="hideClearedTab"
                                #clearedTab="ngModel"
                                class="input-entry"
                                name="hideClearedTab"
                                [(ngModel)]="product.prezConf.hideClearedTab"
                                data-automation="hideClearedTab"
                                data-url-persist
                            >
                                {{ 'common.prez_config.clearedTab' | translate }}
                            </mat-checkbox>
                        </div>
                    </div>
                    <div class="form-entry-column">
                        <!--Hide Baseline Tab-->
                        <div class="checkbox-wrapper">
                            <mat-checkbox
                                id="hideBaselinesTab"
                                #hideBaselinesTab="ngModel"
                                class="input-entry"
                                name="hideBaselinesTab"
                                [(ngModel)]="product.prezConf.hideBaselinesTab"
                                data-automation="hideBaselinesTab"
                                data-url-persist
                            >
                                {{ 'common.prez_config.hide_baselines_tab' | translate }}
                            </mat-checkbox>
                        </div>
                    </div>
                </ng-container>
            </div>
        </mat-expansion-panel>

        <!--- Baseline Configuration --->
        <mat-expansion-panel
            (opened)="panelOpenedState = true"
            (closed)="panelOpenedState = false"
            togglePosition="before"
            class="expansion-panel"
            *ngIf="isCreateMode || isEditMode"
            id="baseline_config_panel"
            data-automation="baselineConfigurationPanel"
        >
            <mat-expansion-panel-header collapsedHeight="48px" expandedHeight="48px">
                <mat-panel-title class="panel-title">
                    <div data-automation="panelTitle">
                        {{ 'common.baseline_config_title' | translate }}
                    </div>
                </mat-panel-title>
            </mat-expansion-panel-header>

            <div class="panel-content-wrapper">
                <!--- adjustment duration --->
                <div class="form-entry">
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label
                            >{{ 'common.baseline_configuration.baseline_adjustment_duration' | translate }}
                        </mat-label>
                        <input
                            matInput
                            id="adjustmentDuration"
                            type="number"
                            min="0"
                            step="1"
                            #adjustmentDuration="ngModel"
                            name="adjustmentDuration"
                            placeholder="0"
                            maxlength="16"
                            [(ngModel)]="product.baselineConfiguration.baselineAdjustmentDuration"
                            data-automation="adjustmentDuration"
                        />
                    </mat-form-field>
                </div>

                <!--- adjustment offset --->
                <div class="form-entry">
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label
                            >{{ 'common.baseline_configuration.baseline_adjustment_offset' | translate }}
                        </mat-label>
                        <input
                            matInput
                            id="adjustmentOffset"
                            type="number"
                            min="0"
                            step="1"
                            #adjustmentOffset="ngModel"
                            name="adjustmentOffset"
                            placeholder="0"
                            maxlength="16"
                            [(ngModel)]="product.baselineConfiguration.baselineAdjustmentOffset"
                            data-automation="adjustmentOffset"
                        />
                    </mat-form-field>
                </div>

                <!--- adjustment offset time --->
                <div class="form-entry">
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label
                            >{{ 'common.baseline_configuration.baseline_adjustment_offset_time' | translate }}
                        </mat-label>
                        <mat-select
                            id="adjustmentOffsetTime"
                            name="adjustmentOffsetTime"
                            #adjustmentOffsetTime="ngModel"
                            [(ngModel)]="product.baselineConfiguration.baselineAdjustmentOffsetTime"
                            data-automation="adjustmentOffsetTime"
                            data-url-persist
                            placeholder="{{ 'product.create.placeholder.adjustment_offset_time' | translate }}"
                        >
                            <mat-option>{{
                                'product.create.placeholder.adjustment_offset_time' | translate
                            }}</mat-option>
                            <mat-option
                                *ngFor="let adjustmentOffsetTime of adjustmentOffsetTimes"
                                [value]="adjustmentOffsetTime.code"
                                id="adjustmentOffsetTime_{{ adjustmentOffsetTime.code }}"
                            >
                                {{ adjustmentOffsetTime.displayLabel }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <!--- lookback window size --->
                <div class="form-entry">
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label
                            >{{ 'common.baseline_configuration.baseline_lookback_window_size' | translate }}
                        </mat-label>
                        <input
                            matInput
                            id="lookbackWindowSize"
                            type="number"
                            min="0"
                            step="1"
                            #lookbackWindowSize="ngModel"
                            name="lookbackWindowSize"
                            placeholder="0"
                            maxlength="16"
                            [(ngModel)]="product.baselineConfiguration.baselineLookbackWindowSize"
                            data-automation="lookbackWindowSize"
                        />
                    </mat-form-field>
                </div>

                <!--- lookback type --->
                <div class="form-entry">
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>{{ 'common.baseline_configuration.baseline_lookback_type' | translate }} </mat-label>
                        <mat-select
                            id="lookbackType"
                            name="lookbackType"
                            #lookbackType="ngModel"
                            [(ngModel)]="product.baselineConfiguration.baselineLookbackType"
                            data-automation="lookbackType"
                            data-url-persist
                            placeholder="{{ 'product.create.placeholder.lookback_type' | translate }}"
                        >
                            <mat-option>{{ 'product.create.placeholder.lookback_type' | translate }}</mat-option>
                            <mat-option
                                *ngFor="let lookbackType of lookbackTypes"
                                [value]="lookbackType.code"
                                id="lookbackType_{{ lookbackType.code }}"
                            >
                                {{ lookbackType.displayLabel }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div class="form-entry-column">
                    <!--- adjustment allow override --->
                    <div class="checkbox-wrapper">
                        <mat-checkbox
                            id="adjustmentAllowOverride"
                            #adjustmentAllowOverride="ngModel"
                            class="input-entry"
                            name="adjustmentAllowOverride"
                            [(ngModel)]="product.baselineConfiguration.baselineAdjustmentAllowOverride"
                            data-automation="adjustmentAllowOverride"
                            data-url-persist
                        >
                            {{ 'common.baseline_configuration.baseline_adjustment_allow_override' | translate }}
                        </mat-checkbox>
                    </div>

                    <!--- enable event triggered baseline --->
                    <div class="checkbox-wrapper">
                        <mat-checkbox
                            id="enableEventTriggeredBaseline"
                            #enableEventTriggeredBaseline="ngModel"
                            class="input-entry"
                            name="enableEventTriggeredBaseline"
                            [(ngModel)]="product.baselineConfiguration.enableEventTriggeredBaseline"
                            data-automation="enableEventTriggeredBaseline"
                            data-url-persist
                        >
                            {{ 'common.baseline_configuration.enable_event_triggered_baseline' | translate }}
                            <mat-icon
                                svgIcon="enelx:general-info"
                                data-automation="enableEventTriggeredBaselineTooltip"
                                class="tooltip-icon"
                                [matTooltipPosition]="'below'"
                                matTooltip="{{
                                    'common.tooltip.baseline_configuration.enable_event_triggered_baseline' | translate
                                }}"
                            ></mat-icon>
                        </mat-checkbox>
                    </div>
                </div>
            </div>
        </mat-expansion-panel>

        <!--- Notification Configuration --->
        <mat-expansion-panel
            (opened)="panelOpenedState = true"
            (closed)="panelOpenedState = false"
            togglePosition="before"
            class="expansion-panel"
            *ngIf="isCreateMode || isEditMode"
            id="notification_config_panel"
            name="notificationConfigurationPanel"
            data-automation="notificationConfigurationPanel"
        >
            <mat-expansion-panel-header collapsedHeight="48px" expandedHeight="48px">
                <mat-panel-title class="panel-title">
                    <div data-automation="panelTitle">
                        {{ 'common.notification_config_title' | translate }}
                    </div>
                </mat-panel-title>
            </mat-expansion-panel-header>

            <div class="panel-content-wrapper">
                <!--- template --->
                <div class="form-entry template-top-space">
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>{{ 'common.notification_conf.template' | translate }} </mat-label>
                        <input
                            matInput
                            id="templateNotify"
                            type="text"
                            step="1"
                            #templateNotify="ngModel"
                            [required]="
                                product.notificationConf.notifyBeforeGate || product.notificationConf.notifyInGate
                            "
                            name="templateNotify"
                            placeholder="Enter a template"
                            [(ngModel)]="product.notificationConf.template"
                            data-automation="templateNotify"
                        />
                        <mat-error
                            *ngIf="templateNotify.invalid && (templateNotify.dirty || templateNotify.touched)"
                            class="alert alert-danger"
                        >
                            <mat-error *ngIf="templateNotify.errors.required">
                                {{ 'common.validation.required' | translate }}
                            </mat-error>
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="form-entry template-top-space"></div>
                <!--- notify before gate --->
                <div class="form-entry">
                    <div class="checkbox-wrapper">
                        <mat-checkbox
                            id="notifyBeforeGate"
                            #notifyBeforeGate="ngModel"
                            class="input-entry"
                            name="notifyBeforeGate"
                            [(ngModel)]="product.notificationConf.notifyBeforeGate"
                            data-automation="notifyBeforeGate"
                            data-url-persist
                        >
                            {{ 'common.notification_conf.notify_before_gate' | translate }}
                        </mat-checkbox>
                    </div>
                </div>

                <!--- notify in gate --->
                <div class="form-entry">
                    <div class="checkbox-wrapper">
                        <mat-checkbox
                            id="notifyInGate"
                            #notifyInGate="ngModel"
                            class="input-entry"
                            name="notifyInGate"
                            [(ngModel)]="product.notificationConf.notifyInGate"
                            data-automation="notifyInGate"
                            data-url-persist
                        >
                            {{ 'common.notification_conf.notify_in_gate' | translate }}
                        </mat-checkbox>
                    </div>
                </div>
            </div>
        </mat-expansion-panel>
    </ng-container>
</div>

<!--- VIEW --->
<ng-container *ngIf="isViewMode && product">
    <h4 class="form-header">{{ 'product.product_dispatch_fields' | translate }}</h4>
    <!--- selection type --->
    <div class="form-entry">
        <mat-label class="info-title">{{ 'common.selection_type' | translate }}:</mat-label>
        <mat-label class="input-entry" data-automation="selectionType">
            {{ getSelectionTypeForProduct() }}
        </mat-label>
    </div>

    <!--- performance aggregate type --->
    <div class="form-entry">
        <mat-label class="info-title">{{ 'common.performance_aggregate_type' | translate }}:</mat-label>
        <mat-label class="input-entry" data-automation="performanceAggregateType">
            {{ getPerformanceAggregateTypeForProduct() }}
        </mat-label>
    </div>

    <!--- target type --->
    <div class="form-entry">
        <mat-label class="info-title">{{ 'common.target_type' | translate }}:</mat-label>
        <mat-label class="input-entry" data-automation="targetType">
            {{ getTargetTypeForProduct() }}
        </mat-label>
    </div>

    <!--- key performance metric --->
    <div class="form-entry">
        <mat-label class="info-title">{{ 'common.key_performance_metric' | translate }}:</mat-label>
        <mat-label class="input-entry" data-automation="keyPerformanceMetric">
            {{ getKeyPerformanceMetricForProduct() }}
        </mat-label>
    </div>

    <!--- selection offer time --->
    <div class="form-entry">
        <mat-label class="info-title">{{ 'common.selection_offer_time' | translate }}:</mat-label>
        <mat-label class="input-entry" data-automation="selectionOfferTime">
            {{ getSelectionOfferTimeForProduct() }}
        </mat-label>
    </div>

    <!--- expected capacity source --->
    <div class="form-entry">
        <mat-label class="info-title">{{ 'common.expected_capacity_source' | translate }}:</mat-label>
        <mat-label class="input-entry" data-automation="expectedCapacitySource">
            {{ getExpectedCapacitySourceForProduct() }}
        </mat-label>
    </div>

    <!--- reporting interval --->
    <div class="form-entry">
        <mat-label class="info-title">{{ 'common.reporting_interval' | translate }}:</mat-label>
        <mat-label class="input-entry" data-automation="reportingIntervalMs">
            {{ getReportingIntervalForProduct() }}
        </mat-label>
    </div>

    <!--- portfolio obligation source --->
    <div class="form-entry">
        <mat-label class="info-title">{{ 'common.portfolio_obligation_source' | translate }}:</mat-label>
        <mat-label class="input-entry" data-automation="portfolioObligationSource">
            {{ getPortfolioObligationSourceForProduct() }}
        </mat-label>
    </div>

    <!--- start date --->
    <div class="form-entry">
        <mat-label class="info-title">{{ 'common.start_date' | translate }}:</mat-label>
        <mat-label class="input-entry" data-automation="startDttm">
            {{ product.startDttm | date : 'shortDate' : '' : locale }}
        </mat-label>
    </div>

    <!--- end date --->
    <div class="form-entry">
        <mat-label class="info-title">{{ 'common.end_date' | translate }}:</mat-label>
        <mat-label class="input-entry" data-automation="endDttm">
            {{ product.endDttm | date : 'shortDate' : '' : locale }}
        </mat-label>
    </div>

    <!--- control timeout --->
    <div class="form-entry">
        <mat-label class="info-title">{{ 'common.control_timeout' | translate }}:</mat-label>
        <mat-label class="input-entry" data-automation="controlTimeout">
            {{ product.controlTimeout }}
        </mat-label>
    </div>

    <!--- season reset date --->
    <div class="form-entry">
        <mat-label class="info-title">{{ 'common.season_reset_date' | translate }}:</mat-label>
        <mat-label class="input-entry" data-automation="seasonResetDate">
            {{ product.seasonResetDate }}
        </mat-label>
    </div>

    <!--- performance error threshold --->
    <div class="form-entry">
        <mat-label class="info-title">{{ 'common.performance_error_threshold' | translate }}:</mat-label>
        <mat-label class="input-entry" data-automation="performanceErrorThreshold">
            {{ product.performanceErrorThreshold }}
        </mat-label>
    </div>

    <!--- performance warning threshold --->
    <div class="form-entry">
        <mat-label class="info-title">{{ 'common.performance_warning_threshold' | translate }}:</mat-label>
        <mat-label class="input-entry" data-automation="performanceWarningThreshold">
            {{ product.performanceWarningThreshold }}
        </mat-label>
    </div>

    <!--- dispatch method --->
    <div class="form-entry">
        <mat-label class="info-title">{{ 'common.dispatch_method' | translate }}:</mat-label>
        <mat-label class="input-entry" data-automation="dispatchMethod">
            {{ product.dispatchMethod }}
        </mat-label>
    </div>

    <!--- dispatch system --->
    <div class="form-entry">
        <mat-label class="info-title">{{ 'common.dispatch_system' | translate }}:</mat-label>
        <mat-label class="input-entry" data-automation="dispatchSystem">
            {{ product.dispatchConf.dispatchSystem }}
        </mat-label>
    </div>

    <!--- default dispatch duration --->
    <div class="form-entry">
        <mat-label class="info-title">{{ 'common.default_dispatch_duration' | translate }}:</mat-label>
        <mat-label class="input-entry" data-automation="defaultDispatchDuration">
            {{ product.defaultDispatchDuration }}
        </mat-label>
    </div>

    <!--- advance notice --->
    <div class="form-entry">
        <mat-label class="info-title">{{ 'common.advance_notice' | translate }}:</mat-label>
        <mat-label class="input-entry" data-automation="advanceNotice">
            {{ product.advanceNotice }}
        </mat-label>
    </div>

    <!--- bonus minutes --->
    <div class="form-entry">
        <mat-label class="info-title">{{ 'common.bonus_minutes' | translate }}:</mat-label>
        <mat-label class="input-entry" data-automation="bonusMinutes">
            {{ product.bonusMinutes }}
        </mat-label>
    </div>

    <!--- Eb Notification group schedule --->
    <div class="form-entry">
        <mat-label class="info-title">{{ 'common.eb_group_sync_schedule' | translate }}:</mat-label>
        <mat-label class="input-entry" data-automation="ebGroupSyncSchedule">
            {{
                (product.ebGroupSyncSchedule && product.ebGroupSyncSchedule !== null) ||
                product.ebGroupSyncSchedule === 'do_not_sync'
                    ? (getCronOption(product.ebGroupSyncSchedule) | translate)
                    : ('common.eb_group_sync_schedule.not_sync' | translate)
            }}
        </mat-label>
    </div>

    <!--- min performance target --->
    <div class="form-entry">
        <mat-label class="info-title">{{ 'common.min_performance_target' | translate }}:</mat-label>
        <mat-label class="input-entry" data-automation="minPerfTarget">
            {{ product.performanceTargetMin }}
        </mat-label>
    </div>

    <!--- max performance target --->
    <div class="form-entry">
        <mat-label class="info-title">{{ 'common.max_performance_target' | translate }}:</mat-label>
        <mat-label class="input-entry" data-automation="maxPerfTarget">
            {{ product.performanceTargetMax }}
        </mat-label>
    </div>

    <!--- min event duration --->
    <div class="form-entry">
        <mat-label class="info-title">{{ 'common.min_event_duration' | translate }}:</mat-label>
        <mat-label class="input-entry" data-automation="minEventDuration">
            {{ product.minEventDuration }}
        </mat-label>
    </div>

    <!--- max event duration --->
    <div class="form-entry">
        <mat-label class="info-title">{{ 'common.max_event_duration' | translate }}:</mat-label>
        <mat-label class="input-entry" data-automation="maxEventDuration">
            {{ product.maxEventDuration }}
        </mat-label>
    </div>

    <!--- Min duration between events --->
    <div class="form-entry">
        <mat-label class="info-title">{{ 'common.min_duration_between_events' | translate }}:</mat-label>
        <mat-label class="input-entry" data-automation="minDurationBetweenEvents">
            {{ product.minDurationBetweenEvents }}
        </mat-label>
    </div>
    <!--- max events per season --->
    <div class="form-entry">
        <mat-label class="info-title">{{ 'common.max_events_per_season' | translate }}:</mat-label>
        <mat-label class="input-entry" data-automation="maxEventsPerSeason">
            {{ product.maxEventsPerSeason }}
        </mat-label>
    </div>

    <!--- max Event Hours Per Season --->
    <div class="form-entry">
        <mat-label class="info-title">{{ 'common.max_event_hours_per_season' | translate }}:</mat-label>
        <mat-label class="input-entry" data-automation="maxEventHoursPerSeason">
            {{ product.maxEventHoursPerSeason }}
        </mat-label>
    </div>

    <!--- equipment interval frequency --->
    <div class="form-entry">
        <mat-label class="info-title">{{ 'common.equipment_interval_frequency' | translate }}:</mat-label>
        <mat-label class="input-entry" data-automation="equipmentIntervalFrequency">
            {{ product.equipmentIntervalFrequency }}
        </mat-label>
    </div>

    <!--- weather window start offset --->
    <div class="form-entry">
        <mat-label class="info-title">{{ 'common.weather_window_start_offset' | translate }}:</mat-label>
        <mat-label class="input-entry" data-automation="weatherWindowStartOffset">
            {{ product.weatherWindowStartOffset }}
        </mat-label>
    </div>

    <!--- weather window end offset --->
    <div class="form-entry">
        <mat-label class="info-title">{{ 'common.weather_window_end_offset' | translate }}:</mat-label>
        <mat-label class="input-entry" data-automation="weatherWindowEndOffset">
            {{ product.weatherWindowEndOffset }}
        </mat-label>
    </div>

    <!--- min selection buffer --->
    <div class="form-entry">
        <mat-label class="info-title">{{ 'common.min_selection_buffer' | translate }}:</mat-label>
        <mat-label class="input-entry" data-automation="minSelBuffer">
            {{ product.selectionBufferMin }}
        </mat-label>
    </div>

    <!--- max selection buffer --->
    <div class="form-entry">
        <mat-label class="info-title">{{ 'common.max_selection_buffer' | translate }}:</mat-label>
        <mat-label class="input-entry" data-automation="maxSelBuffer">
            {{ product.selectionBufferMax }}
        </mat-label>
    </div>

    <!--- selection upper target --->
    <div class="form-entry">
        <mat-label class="info-title">{{ 'common.selection_upper_target' | translate }}:</mat-label>
        <mat-label class="input-entry" data-automation="selUpperTarget">
            {{ product.selectionUpperTarget }}
        </mat-label>
    </div>

    <!--- underfrequency product --->
    <div class="form-entry">
        <mat-label class="info-title">{{ 'common.underfrequency_product' | translate }}:</mat-label>
        <mat-label class="input-entry" data-automation="underfrequencyProduct">
            {{ !!product.underfrequencyProduct }}
        </mat-label>
    </div>

    <!--- collect notification time --->
    <div class="form-entry">
        <mat-label class="info-title">{{ 'common.collect_notification_time' | translate }}:</mat-label>
        <mat-label class="input-entry" data-automation="collectNotificationTime">
            {{ !!product.collectNotificationTime }}
        </mat-label>
    </div>

    <!--- dispatch by portfolio cleared offers --->
    <div class="form-entry">
        <mat-label class="info-title">{{ 'common.dispatch_by_portfolio_cleared_offers' | translate }}:</mat-label>
        <mat-label class="input-entry" data-automation="dispatchByPortfolioClearedOffers">
            {{ !!product.dispatchByPortfolioClearedOffers }}
        </mat-label>
    </div>

    <!--- implement registration limits --->
    <div class="form-entry">
        <mat-label class="info-title">{{ 'common.implement_registration_limits' | translate }}:</mat-label>
        <mat-label class="input-entry" data-automation="implementRegistrationLimits">
            {{ !!product.implementRegistrationLimits }}
        </mat-label>
    </div>

    <!--- allow flexible asset overlap --->
    <div class="form-entry">
        <mat-label class="info-title"
            >{{ 'common.allow_flexible_asset_overlap' | translate }}:
            <mat-icon
                svgIcon="enelx:general-info"
                data-automation="allowFlexibleAssetOverlapTooltip"
                class="tooltip-icon"
                [matTooltipPosition]="'below'"
                matTooltip="{{ 'common.tooltip.allow_flexible_asset_overlap' | translate }}"
            ></mat-icon>
        </mat-label>
        <mat-label class="input-entry" data-automation="allowFlexibleAssetOverlap">
            {{ !!product.allowFlexibleAssetOverlap }}
        </mat-label>
    </div>

    <!--- baseline capacity --->
    <div class="form-entry">
        <mat-label class="info-title">{{ 'common.baseline_capacity' | translate }}:</mat-label>
        <mat-label class="input-entry" data-automation="baselineCapacity">
            {{ !!product.baselineCapacity }}
        </mat-label>
    </div>

    <!--- default nomination schedule --->
    <div class="form-entry">
        <mat-label class="info-title">{{ 'common.default_nomination_schedule' | translate }}:</mat-label>
        <mat-label class="input-entry" data-automation="defaultNominationSchedule">
            {{ !!product.defaultNominationSchedule }}
        </mat-label>
    </div>

    <!--- include nominations in comms --->
    <div class="form-entry">
        <mat-label class="info-title">{{ 'common.include_nomination_in_comms' | translate }}:</mat-label>
        <mat-label class="input-entry" data-automation="includeNominationInComms">
            {{ !!product.includeNominationInComms }}
        </mat-label>
    </div>

    <!--- scale capacity to obligation --->
    <div class="form-entry">
        <mat-label class="info-title">{{ 'common.scale_capacity_to_obligation' | translate }}:</mat-label>
        <mat-label class="input-entry" data-automation="scaleCapacityToObligation">
            {{ !!product.scaleCapacityToObligation }}
        </mat-label>
    </div>

    <!--- ignore avalability for selection --->
    <div class="form-entry">
        <mat-label class="info-title">{{ 'common.ignore_availability_for_selection' | translate }}:</mat-label>
        <mat-label class="input-entry" data-automation="ignoreAvalabilityForSelection">
            {{ !!product.dispatchConf.ignoreAvailabilityForSelection }}
        </mat-label>
    </div>

    <!--- site list event notifications --->
    <div class="form-entry">
        <mat-label class="info-title">{{ 'common.site_list_event_notifications' | translate }}:</mat-label>
        <mat-label class="input-entry" data-automation="siteListEventNotifications">
            {{ !!product.dispatchConf.siteListEventNotifications }}
        </mat-label>
    </div>

    <!---  site list non event notifications --->
    <div class="form-entry">
        <mat-label class="info-title">{{ 'common.site_list_non_event_notifications' | translate }}:</mat-label>
        <mat-label class="input-entry" data-automation="siteListNonEventNotifications">
            {{ !!product.dispatchConf.siteListNonEventNotifications }}
        </mat-label>
    </div>

    <h4 class="form-header">{{ 'common.optional_fields' | translate }}</h4>

    <!--- min notification duration --->
    <div class="form-entry">
        <mat-label class="info-title">{{ 'common.min_notification_duration' | translate }}:</mat-label>
        <mat-label class="input-entry" data-automation="minNotificationDuration">
            {{ product.minimumNotificationDuration }}
        </mat-label>
    </div>

    <!--- max notification duration --->
    <div class="form-entry">
        <mat-label class="info-title">{{ 'common.max_notification_duration' | translate }}:</mat-label>
        <mat-label class="input-entry" data-automation="maxNotificationDuration">
            {{ product.maximumNotificationDuration }}
        </mat-label>
    </div>

    <!--- min response duration --->
    <div class="form-entry">
        <mat-label class="info-title">{{ 'common.min_response_duration' | translate }}:</mat-label>
        <mat-label class="input-entry" data-automation="minimumResponseDuration">
            {{ product.minimumResponseDuration }}
        </mat-label>
    </div>

    <!--- max response duration --->
    <div class="form-entry">
        <mat-label class="info-title">{{ 'common.max_response_duration' | translate }}:</mat-label>
        <mat-label class="input-entry" data-automation="maximumResponseDuration">
            {{ product.maximumResponseDuration }}
        </mat-label>
    </div>

    <!--- min recovery duration --->
    <div class="form-entry">
        <mat-label class="info-title">{{ 'common.min_recovery_duration' | translate }}:</mat-label>
        <mat-label class="input-entry" data-automation="minimumRecoveryDuration">
            {{ product.minimumRecoveryDuration }}
        </mat-label>
    </div>

    <!--- max consecutive durations --->
    <div class="form-entry">
        <mat-label class="info-title">{{ 'common.max_consecutive_durations' | translate }}:</mat-label>
        <mat-label class="input-entry" data-automation="maximumConsecutiveDurations">
            {{ product.maximumConsecutiveDurations }}
        </mat-label>
    </div>

    <!--- max invocations per duration --->
    <div class="form-entry">
        <mat-label class="info-title">{{ 'common.max_invocations_per_duration' | translate }}:</mat-label>
        <mat-label class="input-entry" data-automation="maximumInvocationsPerDuration">
            {{ product.maximumInvocationsPerDuration }}
        </mat-label>
    </div>

    <!--- post bonus minutes --->
    <div class="form-entry">
        <mat-label class="info-title">{{ 'common.dispatch_conf.post_bonus_minutes' | translate }}:</mat-label>
        <mat-label class="input-entry" data-automation="postBonusMinutes">
            {{ product?.dispatchConf?.postBonusMinutes }}
        </mat-label>
    </div>

    <!--- management email --->
    <div class="form-entry">
        <mat-label class="info-title">{{ 'common.dispatch_conf.management_email' | translate }}:</mat-label>
        <mat-label class="input-entry" data-automation="managementEmail">
            {{ product.dispatchConf?.managementEmail }}
        </mat-label>
    </div>

    <!--- max events per day --->
    <div class="form-entry">
        <mat-label class="info-title">{{ 'common.dispatch_conf.max_events_per_day' | translate }}:</mat-label>
        <mat-label class="input-entry" data-automation="maxEventsPerDay">
            {{ product.dispatchConf?.maxEventsPerDay }}
        </mat-label>
    </div>

    <!--- max events per week --->
    <div class="form-entry">
        <mat-label class="info-title">{{ 'common.dispatch_conf.max_events_per_week' | translate }}:</mat-label>
        <mat-label class="input-entry" data-automation="maxEventsPerWeek">
            {{ product.dispatchConf?.maxEventsPerWeek }}
        </mat-label>
    </div>

    <!--- max events per month --->
    <div class="form-entry">
        <mat-label class="info-title">{{ 'common.dispatch_conf.max_events_per_month' | translate }}:</mat-label>
        <mat-label class="input-entry" data-automation="maxEventsPerMonth">
            {{ product.dispatchConf?.maxEventsPerMonth }}
        </mat-label>
    </div>

    <!--- ramping period --->
    <div class="form-entry">
        <mat-label class="info-title">{{ 'common.dispatch_conf.ramping_period' | translate }}:</mat-label>
        <mat-label class="input-entry" data-automation="rampingPeriod">
            {{ product.dispatchConf?.rampingPeriod }}
        </mat-label>
    </div>

    <!--- post ramping period --->
    <div class="form-entry">
        <mat-label class="info-title">{{ 'common.dispatch_conf.post_ramping_period' | translate }}:</mat-label>
        <mat-label class="input-entry" data-automation="postRampingPeriod">
            {{ product.dispatchConf?.postRampingPeriod }}
        </mat-label>
    </div>

    <!--- dispatch instructions --->
    <div class="form-entry-full-width">
        <mat-label class="info-title">{{ 'common.dispatch_conf.dispatch_instructions' | translate }}:</mat-label>
        <mat-label class="input-entry" data-automation="dispatchInstructions">
            {{ !!product.dispatchConf?.includeBonusMinutes }}
        </mat-label>
    </div>

    <!--- include bonus minutes --->
    <div class="form-entry">
        <mat-label class="info-title">{{ 'common.dispatch_conf.include_bonus_minutes' | translate }}:</mat-label>
        <mat-label class="input-entry" data-automation="includeBonusMinutes">
            {{ !!product.dispatchConf?.includeBonusMinutes }}
        </mat-label>
    </div>

    <!--- use protfolio availability --->
    <div class="form-entry">
        <mat-label class="info-title">{{ 'common.use_portfolio_availability' | translate }}:</mat-label>
        <mat-label class="input-entry" data-automation="viewUsePortfolioAvailability">
            {{ !!product.marketConfigurations.usePortfolioAvailability }}
        </mat-label>
    </div>

    <h4 class="form-header">{{ 'common.gate_rules_title' | translate }}</h4>

    <!--- gate closure description --->
    <div class="form-entry">
        <mat-label class="info-title">{{ 'common.gate_rules.gate_closure_description' | translate }}:</mat-label>
        <mat-label class="input-entry" data-automation="gateClosureDescription">
            {{ product.gateRules.gateClosureDescription }}
        </mat-label>
    </div>

    <!--- gate closure tolerance --->
    <div class="form-entry">
        <mat-label class="info-title">{{ 'common.gate_rules.gate_closure_tolerance' | translate }}:</mat-label>
        <mat-label class="input-entry" data-automation="gateClosureTolerance">
            {{ product.gateRules.gateClosureTolerance }}
        </mat-label>
    </div>

    <!--- gate closes every --->
    <div class="form-entry">
        <mat-label class="info-title">{{ 'common.gate_rules.gate_closes_every' | translate }}:</mat-label>
        <mat-label class="input-entry" data-automation="gateClosesEvery">
            {{ product.gateRules.gateClosesEvery }}
        </mat-label>
    </div>

    <!--- gate closes on --->
    <div class="form-entry">
        <mat-label class="info-title">{{ 'common.gate_rules.gate_closes_on' | translate }}:</mat-label>
        <mat-label class="input-entry" data-automation="gateClosesOn">
            {{ product.gateRules.gateClosesOn | date : 'shortDate' }}
        </mat-label>
    </div>

    <!--- gate closes to specific --->
    <div class="form-entry">
        <mat-label class="info-title">{{ 'common.gate_rules.gate_closes_to_specific' | translate }}:</mat-label>
        <mat-label class="input-entry" data-automation="gateClosesToSpecific">
            {{ product.gateRules.gateClosesToSpecific | date : 'shortDate' }}
        </mat-label>
    </div>

    <!--- gate closes from specific --->
    <div class="form-entry">
        <mat-label class="info-title">{{ 'common.gate_rules.gate_closes_from_specific' | translate }}:</mat-label>
        <mat-label class="input-entry" data-automation="gateClosesFromSpecific">
            {{ product.gateRules.gateClosesFromSpecific | date : 'shortDate' }}
        </mat-label>
    </div>

    <!--- gate closes to relative --->
    <div class="form-entry">
        <mat-label class="info-title">{{ 'common.gate_rules.gate_closes_to_relative' | translate }}:</mat-label>
        <mat-label class="input-entry" data-automation="gateClosesToRelative">
            {{ product.gateRules.gateClosesToRelative }}
        </mat-label>
    </div>

    <!--- gate closes from relative --->
    <div class="form-entry">
        <mat-label class="info-title">{{ 'common.gate_rules.gate_closes_from_relative' | translate }}:</mat-label>
        <mat-label class="input-entry" data-automation="gateClosesFromRelative">
            {{ product.gateRules.gateClosesFromRelative }}
        </mat-label>
    </div>

    <!--- provide change reason --->
    <div class="form-entry">
        <mat-label class="info-title">{{ 'common.gate_rules.provide_change_reason' | translate }}:</mat-label>
        <mat-label class="input-entry" data-automation="provideChangeReason">
            {{ product.gateRules.postGateEdits.provideChangeReason }}
        </mat-label>
    </div>

    <!--- allow post gate closure edits --->
    <ng-container *ngIf="!!product.gateRules.postGateEdits">
        <div class="form-entry">
            <mat-label class="info-title"
                >{{ 'common.gate_rules.allow_post_gate_closure_edits' | translate }}:
            </mat-label>
            <mat-label class="input-entry" data-automation="allowPostGateClosureEdits">
                {{ !!product.gateRules.postGateEdits.allowPostGateClosureEdits }}
            </mat-label>
        </div>

        <!--- allow current interval edit --->
        <div class="form-entry">
            <mat-label class="info-title">{{ 'common.gate_rules.allow_current_interval_edit' | translate }}:</mat-label>
            <mat-label class="input-entry" data-automation="allowCurrentIntervalEdit">
                {{ !!product.gateRules.postGateEdits.allowCurrentIntervalEdit }}
            </mat-label>
        </div>

        <!--- allow offer increase --->
        <div class="form-entry">
            <mat-label class="info-title">{{ 'common.gate_rules.allow_offer_increase' | translate }}:</mat-label>
            <mat-label class="input-entry" data-automation="allowOfferIncrease">
                {{ !!product.gateRules.postGateEdits.allowOfferIncrease }}
            </mat-label>
        </div>

        <!--- allow offer decrease --->
        <div class="form-entry">
            <mat-label class="info-title">{{ 'common.gate_rules.allow_offer_decrease' | translate }}:</mat-label>
            <mat-label class="input-entry" data-automation="allowOfferDecrease">
                {{ !!product.gateRules.postGateEdits.allowOfferDecrease }}
            </mat-label>
        </div>

        <!--- allow increase after decrease --->
        <div class="form-entry">
            <mat-label class="info-title"
                >{{ 'common.gate_rules.allow_increase_after_decrease' | translate }}:
            </mat-label>
            <mat-label class="input-entry" data-automation="allowIncreaseAfterDecrease">
                {{ !!product.gateRules.postGateEdits.allowIncreaseAfterDecrease }}
            </mat-label>
        </div>

        <!--- full day offer value --->
        <div class="form-entry">
            <mat-label class="info-title">{{ 'common.gate_rules.full_day_offer_value' | translate }}: </mat-label>
            <mat-label class="input-entry" data-automation="fullDayOfferValue">
                {{ !!product.gateRules.fullDayOfferValue }}
            </mat-label>
        </div>

        <!--- allow offer edit --->
        <div class="form-entry">
            <mat-label class="info-title">{{ 'common.gate_rules.allow_offer_edit' | translate }}: </mat-label>
            <mat-label class="input-entry" data-automation="allowOfferEdit">
                {{ !!product.gateRules.postGateEdits.allowOfferEdit }}
            </mat-label>
        </div>
    </ng-container>

    <h4 class="form-header">{{ 'common.schedule_title' | translate }}</h4>

    <!--- offer frequency --->
    <div class="form-entry">
        <mat-label class="info-title">{{ 'common.schedule.offer_frequency' | translate }}:</mat-label>
        <mat-label class="input-entry" data-automation="offerFrequency">
            {{ product.schedule.offerFrequency }}
        </mat-label>
    </div>

    <!--- offer frequency unit --->
    <div class="form-entry">
        <mat-label class="info-title">{{ 'common.schedule.offer_frequency_unit' | translate }}:</mat-label>
        <mat-label class="input-entry" data-automation="offerFrequencyUnit">
            {{ getOfferFrequencyUnitForProduct() }}
        </mat-label>
    </div>

    <!--- schedule start date --->
    <div class="form-entry">
        <mat-label class="info-title">{{ 'common.schedule.start_date' | translate }}:</mat-label>
        <mat-label class="input-entry" data-automation="scheduleStartDate">
            {{ product.schedule.startDttm | date : 'shortDate' : '' : locale }}
        </mat-label>
    </div>

    <!--- schedule end date --->
    <div class="form-entry">
        <mat-label class="info-title">{{ 'common.schedule.end_date' | translate }}:</mat-label>
        <mat-label class="input-entry" data-automation="scheduleEndDate">
            {{ product.schedule.endDttm | date : 'shortDate' : '' : locale }}
        </mat-label>
    </div>

    <!--- offer group frequency unit --->
    <div class="form-entry">
        <mat-label class="info-title">{{ 'common.schedule.offer_group_unit' | translate }}:</mat-label>
        <mat-label class="input-entry" data-automation="offerGroupUnit">
            {{ getOfferGroupUnitForProduct() }}
        </mat-label>
    </div>

    <!--- schedule rule --->
    <div class="form-entry">
        <mat-label class="info-title">{{ 'common.schedule.rule' | translate }}:</mat-label>
        <mat-label class="input-entry" data-automation="scheduleRule">
            {{ product.schedule.rule }}
        </mat-label>
    </div>

    <h4 class="form-header">{{ 'common.prez_config_title' | translate }}</h4>

    <!--- preferred prez demand uom --->
    <div class="form-entry">
        <mat-label class="info-title">{{ 'common.prez_config.prefered_prez_demand_uom' | translate }}:</mat-label>
        <mat-label class="input-entry" data-automation="preferredPrezDemandUOM">
            {{ getPreferredPrezDemandUomForProduct() }}
        </mat-label>
    </div>

    <!--- event management hex code --->
    <div class="form-entry">
        <mat-label class="info-title">{{ 'common.prez_config.event_management_hex_code' | translate }}:</mat-label>
        <mat-label class="input-entry" data-automation="eventManagementHexCode">
            {{ product.prezConf.eventManagementHexCode }}
            <span class="hex-box" [style.background-color]="product.prezConf.eventManagementHexCode"></span>
        </mat-label>
    </div>

    <!--- ramp up --->
    <div class="form-entry">
        <mat-label class="info-title">{{ 'common.prez_config.ramp_up' | translate }}:</mat-label>
        <mat-label class="input-entry" data-automation="rampUp">
            {{ product.prezConf.rampUp }}
        </mat-label>
    </div>

    <!--- ramp down --->
    <div class="form-entry">
        <mat-label class="info-title">{{ 'common.prez_config.ramp_down' | translate }}:</mat-label>
        <mat-label class="input-entry" data-automation="rampDown">
            {{ product.prezConf.rampDown }}
        </mat-label>
    </div>
    <!--- cleared tab --->
    <div class="form-entry">
        <mat-label class="info-title">{{ 'common.prez_config.clearedTab' | translate }}:</mat-label>
        <mat-label class="input-entry" data-automation="hideClearedTab">
            {{ product.prezConf.hideClearedTab }}
        </mat-label>
    </div>
    <!--- hide baselines tab --->
    <div class="form-entry">
        <mat-label class="info-title">{{ 'common.prez_config.hide_baselines_tab' | translate }}:</mat-label>
        <mat-label class="input-entry" data-automation="hideBaselinesTab">
            {{ product.prezConf.hideBaselinesTab }}
        </mat-label>
    </div>

    <h4 class="form-header">{{ 'common.baseline_config_title' | translate }}</h4>
    <!--- baseline adjustment allow override --->
    <div class="form-entry">
        <mat-label class="info-title">
            {{ 'common.baseline_configuration.baseline_adjustment_allow_override' | translate }}:</mat-label
        >
        <mat-label class="input-entry" data-automation="baselineAdjustmentAllowOverride">
            {{ product.baselineConfiguration.baselineAdjustmentAllowOverride }}
        </mat-label>
    </div>

    <!--- enable event triggered baseline --->
    <div class="form-entry">
        <mat-label class="info-title">
            {{ 'common.baseline_configuration.enable_event_triggered_baseline' | translate }}:
            <mat-icon
                svgIcon="enelx:general-info"
                data-automation="enableEventTriggeredBaselineTooltip"
                class="tooltip-icon"
                [matTooltipPosition]="'below'"
                matTooltip="{{ 'common.tooltip.baseline_configuration.enable_event_triggered_baseline' | translate }}"
            ></mat-icon>
        </mat-label>
        <mat-label class="input-entry" data-automation="enableEventTriggeredBaseline">
            {{ !!product.baselineConfiguration.enableEventTriggeredBaseline }}
        </mat-label>
    </div>

    <!--- baseline adjustment duration --->
    <div class="form-entry">
        <mat-label class="info-title"
            >{{ 'common.baseline_configuration.baseline_adjustment_duration' | translate }}:
        </mat-label>
        <mat-label class="input-entry" data-automation="baselineAdjustmentDuration">
            {{ product.baselineConfiguration.baselineAdjustmentDuration }}
        </mat-label>
    </div>

    <!--- baseline adjustment offset --->
    <div class="form-entry">
        <mat-label class="info-title"
            >{{ 'common.baseline_configuration.baseline_adjustment_offset' | translate }}:
        </mat-label>
        <mat-label class="input-entry" data-automation="baselineAdjustmentOffset">
            {{ product.baselineConfiguration.baselineAdjustmentOffset }}
        </mat-label>
    </div>

    <!--- baseline adjustment offset time --->
    <div class="form-entry">
        <mat-label class="info-title"
            >{{ 'common.baseline_configuration.baseline_adjustment_offset_time' | translate }}:
        </mat-label>
        <mat-label class="input-entry" data-automation="baselineAdjustmentOffsetTime">
            {{ setLabelForAdjustmentOffsetTimes() }}
        </mat-label>
    </div>

    <!--- baseline lookback window size --->
    <div class="form-entry">
        <mat-label class="info-title"
            >{{ 'common.baseline_configuration.baseline_lookback_window_size' | translate }}:
        </mat-label>
        <mat-label class="input-entry" data-automation="baselineLookbackWindowSize">
            {{ product.baselineConfiguration.baselineLookbackWindowSize }}
        </mat-label>
    </div>

    <!--- baseline lookback type --->
    <div class="form-entry">
        <mat-label class="info-title"
            >{{ 'common.baseline_configuration.baseline_lookback_type' | translate }}:
        </mat-label>
        <mat-label class="input-entry" data-automation="baselineLookbackType">
            {{ setLabelForLookbackTypes() }}
        </mat-label>
    </div>

    <h4 class="form-header">{{ 'common.notification_config_title' | translate }}</h4>

    <!--- notify before gate --->
    <div class="form-entry">
        <mat-label class="info-title">{{ 'common.notification_conf.notify_before_gate' | translate }}:</mat-label>
        <mat-label class="input-entry" data-automation="notifyBeforeGate">
            {{ product.notificationConf.notifyBeforeGate }}
        </mat-label>
    </div>

    <!--- notify in gate --->
    <div class="form-entry">
        <mat-label class="info-title">{{ 'common.notification_conf.notify_in_gate' | translate }}:</mat-label>
        <mat-label class="input-entry" data-automation="notifyInGate">
            {{ product.notificationConf.notifyInGate }}
        </mat-label>
    </div>

    <!--- template --->
    <div class="form-entry">
        <mat-label class="info-title">{{ 'common.notification_conf.template' | translate }}:</mat-label>
        <mat-label class="input-entry" data-automation="template">
            {{ product.notificationConf.template }}
        </mat-label>
    </div>
</ng-container>
