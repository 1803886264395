<app-global-alert></app-global-alert>
<enelx-navbar product-name="Flex" url="{{ navUrl }}" position="right">
  <div class="main-container">
    <mat-sidenav-container class="x-sidebar">
      <mat-sidenav
        #sidenav
        fixedInViewport="true"
        fixedTopGap="60"
        [mode]="isLargeScreen() ? 'side' : 'over'"
        [opened]="sidenavOpen"
        (openedChange)='triggerResize()'
        class="sidenav-container"
      >
        <header class="sidenav-header">
          <div class="left-column">
            <h1
              class="page-title x-ellipsis"
              data-automation="listTitle"
              title="{{ 'list.tabs.configured_markets' | translate }}"
            >
              {{ 'list.tabs.configured_markets' | translate }}
            </h1>
          </div>

          <div class="right-column">
            <button
              mat-icon-button
              color="accent"
              aria-label="Menu"
              class="toggle-sidenav"
              data-automation="toggle-sidenav"
              (click)="sidenav.toggle()"
            >
              <mat-icon>double_arrow</mat-icon>
            </button>
          </div>
        </header>
        <div class="sidenav-component-container" data-automation="operator_panel">
          <app-list></app-list>
        </div>
      </mat-sidenav>

      <mat-sidenav-content>
        <div class="content-section">
          <router-outlet></router-outlet>
          <ngx-global-contact-footer></ngx-global-contact-footer>
        </div>
      </mat-sidenav-content>
    </mat-sidenav-container>
  </div>
</enelx-navbar>
