import { Component, OnInit, Input, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { Locale } from '../../../shared/model/locale.model';
import { Product, RefData } from '../../../shared/model/product.model';
import { FormGroup } from '@angular/forms';
import { RefService } from '../../../shared/services/ref.service';
import { ControlContainer, NgForm } from '@angular/forms';

@Component({
  selector: 'app-product-dispatch-information',
  templateUrl: './product-dispatch-information.component.html',
  styleUrls: ['./product-dispatch-information.component.scss', '../../../shared/shared.styles.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class ProductDispatchInformationComponent implements OnInit {
  @Input() mode: string;
  @Input() locale: Locale;
  @Input() product: Product;
  @Input() frm;
  @Output('toggleDispatchableProduct') toggleDispatchableProduct: EventEmitter<any> = new EventEmitter();
  @Output('clearDispatch') clearDispatch: EventEmitter<any> = new EventEmitter();
  panelOpenState = false;
  selectionTypes: RefData[] = [];
  targetTypes: RefData[] = [];
  expectedCapacitySources: RefData[] = [];
  portfolioObligationSources: RefData[] = [];
  selectionOfferTimes: RefData[] = [];
  cronOptions: RefData[] = [];

  constructor(private refService: RefService) {}

  ngOnInit(): void {
    this.refService.selectionTypes$.subscribe((selectionTypes) => (this.selectionTypes = selectionTypes));
    this.refService.targetTypes$.subscribe((targetTypes) => (this.targetTypes = targetTypes));
    this.refService.expectedCapacitySources$.subscribe(
      (expectedCapacitySources) => (this.expectedCapacitySources = expectedCapacitySources),
    );
    this.refService.portfolioObligationSources$.subscribe(
      (portfolioObligationSources) => (this.portfolioObligationSources = portfolioObligationSources),
    );
    this.refService.selectionOfferTimes$.subscribe(
      (selectionOfferTimes) => (this.selectionOfferTimes = selectionOfferTimes),
    );
    this.refService.cronOptions$.subscribe((cronOptions) => (this.cronOptions = cronOptions));
    this.refService.getCronOptions();
  }

  get isEditMode() {
    return this.mode === 'edit';
  }

  get isCreateMode() {
    return this.mode === 'create';
  }

  get isViewMode() {
    return this.mode === 'view';
  }

  get selectionTypeForProduct() {
    if (this.product.selectionType && this.selectionTypes.length > 0) {
      const selectionType = this.selectionTypes.find((type) => type.code === this.product.selectionType);
      if (selectionType) {
        return selectionType.displayLabel;
      }
    }

    return '';
  }

  get targetTypeForProduct() {
    if (this.product.targetType && this.targetTypes.length > 0) {
      const targetType = this.targetTypes.find((type) => type.code === this.product.targetType);
      if (targetType) {
        return targetType.displayLabel;
      }
    }

    return '';
  }

  get dispatchableForm() {
    return this.frm.form.get('dispatchableFields');
  }

  get expectedCapacitySourceForProduct() {
    if (this.product.expectedCapacitySource && this.expectedCapacitySources.length > 0) {
      const capacitySource = this.expectedCapacitySources.find(
        (source) => source.code === this.product.expectedCapacitySource,
      );
      if (capacitySource) {
        return capacitySource.displayLabel;
      }
    }

    return '';
  }

  get portfolioObligationSourceForProduct() {
    if (this.product.portfolioObligationSource && this.portfolioObligationSources.length > 0) {
      const portfolioObligationSource = this.portfolioObligationSources.find(
        (obligationSource) => obligationSource.code === this.product.portfolioObligationSource,
      );
      if (portfolioObligationSource) {
        return portfolioObligationSource.displayLabel;
      }
    }

    return '';
  }

  get selectionOfferTimeForProduct() {
    if (this.product.selectionOfferTime && this.selectionOfferTimes.length > 0) {
      const selectionOfferTime = this.selectionOfferTimes.find(
        (offerTime) => offerTime.code === this.product.selectionOfferTime,
      );
      if (selectionOfferTime) {
        return selectionOfferTime.displayLabel;
      }
    }

    return '';
  }

  getCronOption(ebGroupValue) {
    const option = this.cronOptions.find((option: any) => {
      if (option.value == ebGroupValue) {
        return option;
      }
    });
    if (option) {
      return option.displayLabel;
    } else {
      return null;
    }
  }

  handleClearClick($event) {
    this.clearDispatch.emit($event);
  }

  onPanelExpand() {
    this.toggleDispatchableProduct.emit();
  }
}
