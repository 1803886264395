import { Injectable } from '@angular/core';

@Injectable()
export class MarketsService {
  marketTypes: any[];

  constructor() {
    this.marketTypes = [
      {
        displayLabel: 'operator.operator',
        name: 'Operator',
      },
      {
        displayLabel: 'program.program',
        name: 'Program',
      },
      {
        displayLabel: 'product.product',
        name: 'Product',
      },
    ];
  }

  getMarketTypes(): any[] {
    return this.marketTypes;
  }
}
