<ng-container ngModelGroup="dispatchableFields">
    <mat-expansion-panel (opened)="panelOpenState = true"
                         (closed)="panelOpenState = false"
                         (afterExpand)="onPanelExpand()"
                         (afterCollapse)="onPanelExpand()"
                         togglePosition="before"
                         class="expansion-panel"
                         id="product-customer-offer-management-panel"
                         data-automation="productCustomerOfferManagementPanel">

      <mat-expansion-panel-header collapsedHeight="48px" expandedHeight="48px">
        <mat-panel-title class="panel-title">
          <div data-automation="productCustomerOfferManagementPanelTitle">
            {{ 'common.product.customer_offer_management' | translate }}
          </div>
        </mat-panel-title>
      </mat-expansion-panel-header>

      <!--- CREATE/EDIT --->
      <div *ngIf="(isCreateMode || isEditMode) && product" class="panel-content">
        <div class="form-group">

          <!--- offer frequency --->
          <div class="form-entry">
            <mat-form-field appearance="outline" floatLabel="always">
              <mat-label>{{ 'common.schedule.offer_frequency' | translate }}</mat-label>
              <input
                matInput
                id="offerFrequency"
                type="number"
                step="1"
                #offerFrequency="ngModel"
                name="offerFrequency"
                placeholder="0"
                maxlength="16"
                [(ngModel)]="product.schedule.offerFrequency"
                data-automation="offerFrequency"
                data-url-persist
                required
              />
              <mat-icon
                matSuffix
                svgIcon="enelx:general-info"
                data-automation="offerFrequencyUnitTooltip"
                class="tooltip-icon"
                [matTooltipPosition]="'below'"
                matTooltip="{{ 'common.schedule.offer_frequency.tooltip' | translate }}"
              ></mat-icon>
              <mat-error
                *ngIf="offerFrequency.invalid && (offerFrequency.dirty || offerFrequency.touched)"
                class="alert alert-danger"
              >
                <mat-error *ngIf="offerFrequency.errors.required">
                  <span class="input-title"></span>
                  <span class="input-entry">{{ 'common.validation.required' | translate }}</span>
                </mat-error>
              </mat-error>
            </mat-form-field>
          </div>

          <!--- offer frequency unit --->
          <div class="form-entry">
            <mat-form-field appearance="outline" floatLabel="always">

              <mat-label>{{ 'common.schedule.offer_frequency_unit' | translate }}</mat-label>

              <mat-select
                id="offerFrequencyUnit"
                name="offerFrequencyUnit"
                #offerFrequencyUnit="ngModel"
                [(ngModel)]="product.schedule.offerFrequencyUnit"
                (selectionChange)="updateGroupUnit($event)"
                data-automation="offerFrequencyUnitSelector"
                data-url-persist
                required
                placeholder="{{ 'product.create.placeholder.frequency_unit' | translate }}"
              >
                <mat-option>{{ 'product.create.placeholder.frequency_unit' | translate }}</mat-option>
                <mat-option
                  *ngFor="let offerFrequencyUnit of offerFrequencyUnits"
                  [value]="offerFrequencyUnit.code"
                  id="offerFrequencyUnit_{{ offerFrequencyUnit.code }}"
                >
                  {{ offerFrequencyUnit.displayLabel }}
                </mat-option>
              </mat-select>
              <mat-error
                *ngIf="
                                offerFrequencyUnit.invalid && (offerFrequencyUnit.dirty || offerFrequencyUnit.touched)
                            "
                class="alert alert-danger"
              >
                <mat-error *ngIf="offerFrequencyUnit.errors.required">
                  {{ 'common.validation.required' | translate }}
                </mat-error>
              </mat-error>
            </mat-form-field>
          </div>

          <!--- offer group units --->
          <div class="form-entry">
            <mat-form-field appearance="outline" floatLabel="always">
              <mat-label>{{ 'common.schedule.offer_group_unit' | translate }}</mat-label>
              <mat-select
                id="offerGroupUnit"
                name="offerGroupUnit"
                #offerGroupUnit="ngModel"
                [(ngModel)]="product.schedule.offerGroupUnit"
                [disabled]="product.schedule.offerFrequencyUnit === ''"
                data-automation="offerGroupUnitSelector"
                data-url-persist
                required
                placeholder="{{ 'product.create.placeholder.frequency_unit' | translate }}"
              >
                <mat-option>{{ 'common.schedule.offer_group_unit' | translate }}</mat-option>
                <mat-option
                  *ngFor="let offerFrequencyUnit of offerGroupFrequencyUnits"
                  [value]="offerFrequencyUnit.code"
                  id="offerGroupUnit_{{ offerFrequencyUnit.code }}"
                >
                  {{ offerFrequencyUnit.displayLabel }}
                </mat-option>
              </mat-select>

              <mat-icon
                matSuffix
                svgIcon="enelx:general-info"
                data-automation="offerGroupUnitTooltip"
                class="tooltip-icon"
                [matTooltipPosition]="'below'"
                matTooltip="{{ 'common.schedule.offer_group_unit.tooltip' | translate }}"
              ></mat-icon>

              <mat-error
                *ngIf="offerGroupUnit.invalid && (offerGroupUnit.dirty || offerGroupUnit.touched)"
                class="alert alert-danger"
              >
                <mat-error *ngIf="offerGroupUnit.errors.required">
                  {{ 'common.validation.required' | translate }}
                </mat-error>
              </mat-error>
            </mat-form-field>
          </div>

          <!--- rule --->
          <div class="form-entry">
            <mat-form-field appearance="outline" floatLabel="always">
              <mat-label>{{ 'common.schedule.rule' | translate }}:</mat-label>
              <input
                matInput
                id="scheduleRule"
                #scheduleRule="ngModel"
                type="text"
                name="scheduleRule"
                placeholder="{{ 'product.create.placeholder.cron_expression' | translate }}"
                maxlength="80"
                [(ngModel)]="product.schedule.rule"
                data-automation="scheduleRule"
                data-url-persist
                required
                cronValidator
              />

              <mat-icon
                matSuffix
                svgIcon="enelx:general-info"
                data-automation="scheduleRuleTooltip"
                class="tooltip-icon"
                [matTooltipPosition]="'below'"
                matTooltip="{{ 'common.schedule.rule.tooltip' | translate }}"
              ></mat-icon>

              <mat-error
                *ngIf="scheduleRule.invalid && (scheduleRule.dirty || scheduleRule.touched)"
                class="alert alert-danger"
              >
                <mat-error *ngIf="scheduleRule.errors.required">
                  <span class="input-title"></span>
                  <span>{{ 'common.validation.required' | translate }}</span>
                </mat-error>
                <mat-error *ngIf="scheduleRule.errors.cronValid">
                  <span class="input-title"></span>
                  <span>{{ 'common.errors.non_cron_expression' | translate }}</span>
                </mat-error>
              </mat-error>
            </mat-form-field>
          </div>

          <!--- gate closure tolerance --->
          <div class="form-entry">
            <mat-form-field appearance="outline" floatLabel="always">
              <mat-label>{{ 'common.gate_rules.gate_closure_tolerance' | translate }}</mat-label>
              <input
                matInput
                id="gateClosureTolerance"
                type="number"
                step="1"
                #gateClosureTolerance="ngModel"
                name="gateClosureTolerance"
                placeholder="0"
                maxlength="16"
                [(ngModel)]="product.gateRules.gateClosureTolerance"
                data-automation="gateClosureTolerance"
                data-url-persist
                required
              />

              <mat-icon matSuffix
                        svgIcon="enelx:general-info"
                        data-automation="gateClosureToleranceTooltip"
                        class="tooltip-icon"
                        [matTooltipPosition]="'below'"
                        matTooltip="{{ 'common.gate_rules.gate_closure_tolerance.tooltip' | translate }}"
              ></mat-icon>

              <mat-error *ngIf="gateClosureTolerance.invalid" class="alert alert-danger">
                <mat-error *ngIf="gateClosureTolerance.errors.required">
                  <span class="input-title"></span>
                  <span class="input-entry">{{ 'common.validation.required' | translate }}</span>
                </mat-error>
              </mat-error>
            </mat-form-field>
          </div>

          <!--- gate closes every --->
          <div class="form-entry">
            <mat-form-field appearance="outline" floatLabel="always">
              <mat-label>{{ 'common.gate_rules.gate_closes_every' | translate }}:</mat-label>
              <input
                matInput
                id="gateClosesEvery"
                #gateClosesEvery="ngModel"
                type="text"
                name="gateClosesEvery"
                placeholder="{{ 'product.create.placeholder.cron_expression' | translate }}"
                maxlength="80"
                [(ngModel)]="product.gateRules.gateClosesEvery"
                data-automation="gateClosesEvery"
                cronValidator
              />

              <mat-icon matSuffix
                        svgIcon="enelx:general-info"
                        data-automation="gateClosesEveryTooltip"
                        class="tooltip-icon"
                        [matTooltipPosition]="'below'"
                        matTooltip="{{ 'common.gate_rules.gate_closes_every.tooltip' | translate }}"
              ></mat-icon>

              <mat-error
                *ngIf="gateClosesEvery.invalid && (gateClosesEvery.dirty || gateClosesEvery.touched)"
                class="alert alert-danger"
              >
                <mat-error *ngIf="gateClosesEvery.errors.required">
                  <span class="input-title"></span>
                  <span>{{ 'common.validation.required' | translate }}</span>
                </mat-error>
                <mat-error *ngIf="gateClosesEvery.errors.cronValid">
                  <span class="input-title"></span>
                  <span>{{ 'common.errors.non_cron_expression' | translate }}</span>
                </mat-error>
              </mat-error>
            </mat-form-field>
          </div>

          <!--- gate closure description --->
          <div class="form-entry-full-width pad-2-b">
            <mat-form-field appearance="outline" floatLabel="always">
              <mat-label>{{ 'common.gate_rules.gate_closure_description' | translate }}:</mat-label>
              <input
                matInput
                id="gateClosureDescription"
                #gateClosureDescription="ngModel"
                type="text"
                name="gateClosureDescription"
                placeholder="{{ 'product.create.placeholder.gate_rules.gate_description' | translate }}"
                maxlength="1000"
                [(ngModel)]="product.gateRules.gateClosureDescription"
                data-automation="gateClosureDescription"
                data-url-persist
                required
              />

              <mat-icon matSuffix
                        svgIcon="enelx:general-info"
                        data-automation="gateClosureDescriptionTooltip"
                        class="tooltip-icon"
                        [matTooltipPosition]="'below'"
                        matTooltip="{{ 'common.gate_rules.gate_closure_description.tooltip' | translate }}"
              ></mat-icon>
              <mat-error *ngIf="gateClosureDescription.invalid" class="alert alert-danger">
                <mat-error *ngIf="gateClosureDescription.errors.required">
                  <span>{{ 'common.validation.required' | translate }}</span>
                </mat-error>
              </mat-error>
            </mat-form-field>
          </div>

          <!--- gate closes from relative --->
          <div class="form-entry">
            <mat-form-field appearance="outline" floatLabel="always">
              <mat-label>{{ 'common.gate_rules.gate_closes_from_relative' | translate }}</mat-label>
              <input
                matInput
                id="gateClosesFromRelative"
                type="number"
                step="1"
                #gateClosesFromRelative="ngModel"
                name="gateClosesFromRelative"
                placeholder="0"
                maxlength="16"
                [(ngModel)]="product.gateRules.gateClosesFromRelative"
                data-automation="gateClosesFromRelative"
              />

              <mat-icon matSuffix
                        svgIcon="enelx:general-info"
                        data-automation="gateClosesFromRelativeTooltip"
                        class="tooltip-icon"
                        [matTooltipPosition]="'below'"
                        matTooltip="{{ 'common.gate_rules.gate_closes_from_relative.tooltip' | translate }}"
              ></mat-icon>

              <mat-error
                *ngIf="
                                gateClosesFromRelative.invalid &&
                                (gateClosesFromRelative.dirty || gateClosesFromRelative.touched)
                            "
                class="alert alert-danger"
              >
                <mat-error *ngIf="gateClosesFromRelative.errors.required">
                  <span class="input-title"></span>
                  <span class="input-entry">{{ 'common.validation.required' | translate }}</span>
                </mat-error>
              </mat-error>
            </mat-form-field>
          </div>

          <!--- gate closes to relative --->
          <div class="form-entry">
            <mat-form-field appearance="outline" floatLabel="always">
              <mat-label>{{ 'common.gate_rules.gate_closes_to_relative' | translate }}</mat-label>
              <input
                matInput
                id="gateClosesToRelative"
                type="number"
                step="1"
                #gateClosesToRelative="ngModel"
                name="gateClosesToRelative"
                placeholder="0"
                maxlength="16"
                [(ngModel)]="product.gateRules.gateClosesToRelative"
                data-automation="gateClosesToRelative"
              />

              <mat-icon matSuffix
                        svgIcon="enelx:general-info"
                        data-automation="gateClosesToRelativeTooltip"
                        class="tooltip-icon"
                        [matTooltipPosition]="'below'"
                        matTooltip="{{ 'common.gate_rules.gate_closes_to_relative.tooltip' | translate }}"
              ></mat-icon>

              <mat-error
                *ngIf="
                                gateClosesToRelative.invalid &&
                                (gateClosesToRelative.dirty || gateClosesToRelative.touched)
                            "
                class="alert alert-danger"
              >
                <mat-error *ngIf="gateClosesToRelative.errors.required">
                  <span class="input-title"></span>
                  <span class="input-entry">{{ 'common.validation.required' | translate }}</span>
                </mat-error>
              </mat-error>
            </mat-form-field>
          </div>

          <mat-grid-list cols="2" rowHeight="30px">
            <!--- allow offer increase --->
            <mat-grid-tile>
              <div class="checkbox-wrapper">
                <mat-checkbox
                  id="allowOfferIncrease"
                  #allowOfferIncrease="ngModel"
                  class="input-entry"
                  name="allowOfferIncrease"
                  [(ngModel)]="product.gateRules.postGateEdits.allowOfferIncrease"
                  data-automation="allowOfferIncrease"
                  data-url-persist
                >
                  {{ 'common.gate_rules.allow_offer_increase' | translate }}
                </mat-checkbox>
              </div>
            </mat-grid-tile>
            <mat-grid-tile>
              <!--- allow offer decrease --->
              <div class="checkbox-wrapper">
                <mat-checkbox
                  id="allowOfferDecrease"
                  #allowOfferDecrease="ngModel"
                  class="input-entry"
                  name="allowOfferDecrease"
                  [(ngModel)]="product.gateRules.postGateEdits.allowOfferDecrease"
                  data-automation="allowOfferDecrease"
                  data-url-persist
                >
                  {{ 'common.gate_rules.allow_offer_decrease' | translate }}
                </mat-checkbox>
              </div>
            </mat-grid-tile>
            <mat-grid-tile>
              <!--- allow increase after decrease --->
              <div class="checkbox-wrapper">
                <mat-checkbox
                  id="allowIncreaseAfterDecrease"
                  #allowIncreaseAfterDecrease="ngModel"
                  class="input-entry"
                  name="allowIncreaseAfterDecrease"
                  [(ngModel)]="product.gateRules.postGateEdits.allowIncreaseAfterDecrease"
                  data-automation="allowIncreaseAfterDecrease"
                  data-url-persist
                >
                  {{ 'common.gate_rules.allow_increase_after_decrease' | translate }}
                </mat-checkbox>
              </div>
            </mat-grid-tile>
            <mat-grid-tile>
              <!--- allow current interval edit --->
              <div class="checkbox-wrapper">
                <mat-checkbox
                  id="allowCurrentIntervalEdit"
                  #allowCurrentIntervalEdit="ngModel"
                  class="input-entry"
                  name="allowCurrentIntervalEdit"
                  [(ngModel)]="product.gateRules.postGateEdits.allowCurrentIntervalEdit"
                  data-automation="allowCurrentIntervalEdit"
                  data-url-persist
                >
                  {{ 'common.gate_rules.allow_current_interval_edit' | translate }}
                </mat-checkbox>
              </div>
            </mat-grid-tile>
          </mat-grid-list>

        </div>
      </div>

      <!--- VIEW --->
      <div *ngIf="isViewMode && product" class="panel-content">
        <div class="form-group">
          <!--- offer frequency --->
          <div class="form-entry">
            <mat-label class="info-title">{{ 'common.schedule.offer_frequency' | translate }}
              <mat-icon
                matSuffix
                svgIcon="enelx:general-info"
                data-automation="offerFrequencyUnitTooltip"
                class="tooltip-icon"
                [matTooltipPosition]="'below'"
                matTooltip="{{ 'common.schedule.offer_frequency.tooltip' | translate }}"
              ></mat-icon>
            </mat-label>
            <mat-label class="input-entry" data-automation="offerFrequency">
              {{ product.schedule.offerFrequency }}
            </mat-label>
          </div>

          <!--- offer frequency unit --->
          <div class="form-entry">
            <div class="label-container">
              <mat-label class="info-title">{{ 'common.schedule.offer_frequency_unit' | translate }}</mat-label>
            </div>
            <mat-label class="input-entry" data-automation="offerFrequencyUnit">
              {{ offerFrequencyUnitForProduct }}
            </mat-label>
          </div>

          <!--- offer group units --->
          <div class="form-entry">
            <mat-label class="info-title">{{ 'common.schedule.offer_group_unit' | translate }}

            <mat-icon
              svgIcon="enelx:general-info"
              data-automation="offerGroupUnitTooltip"
              class="tooltip-icon icon-container"
              [matTooltipPosition]="'below'"
              matTooltip="{{ 'common.schedule.offer_group_unit.tooltip' | translate }}"
            ></mat-icon>
            </mat-label>
            <mat-label class="input-entry" data-automation="offerGroupUnit">
              {{ offerGroupUnitForProduct }}
            </mat-label>
          </div>

          <!--- rule --->
          <div class="form-entry">
            <mat-label class="info-title">{{ 'common.schedule.rule' | translate }}

            <mat-icon
              svgIcon="enelx:general-info"
              data-automation="scheduleRuleTooltip"
              class="tooltip-icon icon-container"
              [matTooltipPosition]="'below'"
              matTooltip="{{ 'common.schedule.rule.tooltip' | translate }}"
            ></mat-icon>

            </mat-label>
            <mat-label class="input-entry" data-automation="rule">
              {{ product.schedule.rule }}
            </mat-label>
          </div>

          <!--- gate closure tolerance --->
          <div class="form-entry">
            <mat-label class="info-title">{{ 'common.gate_rules.gate_closure_tolerance' | translate }}

              <mat-icon
                svgIcon="enelx:general-info"
                data-automation="gateClosureToleranceTooltip"
                class="tooltip-icon icon-container"
                [matTooltipPosition]="'below'"
                matTooltip="{{ 'common.gate_rules.gate_closure_tolerance.tooltip' | translate }}"
              ></mat-icon>

            </mat-label>
            <mat-label class="input-entry" data-automation="gateClosureTolerance">
              {{ product.gateRules.gateClosureTolerance }}
            </mat-label>
          </div>

          <!--- gate closes every --->
          <div class="form-entry">
            <mat-label class="info-title">{{ 'common.gate_rules.gate_closes_every' | translate }}

            <mat-icon
              svgIcon="enelx:general-info"
              data-automation="gateClosesEveryTooltip"
              class="tooltip-icon icon-container"
              [matTooltipPosition]="'below'"
              matTooltip="{{ 'common.gate_rules.gate_closes_every.tooltip' | translate }}"
            ></mat-icon>

            </mat-label>
            <mat-label class="input-entry" data-automation="gateClosesEvery">
              {{ product.gateRules.gateClosesEvery }}
            </mat-label>
          </div>

          <!--- gate closure description --->
          <div class="form-entry">
            <mat-label class="info-title">{{ 'common.gate_rules.gate_closure_description' | translate }}

              <mat-icon
                svgIcon="enelx:general-info"
                data-automation="gateClosureDescriptionTooltip"
                class="tooltip-icon icon-container"
                [matTooltipPosition]="'below'"
                matTooltip="{{ 'common.gate_rules.gate_closure_description.tooltip' | translate }}"
              ></mat-icon>

            </mat-label>
            <mat-label class="input-entry" data-automation="gateClosureDescription">
              {{ product.gateRules.gateClosureDescription }}
            </mat-label>
          </div>

          <div class="form-entry"></div>

          <!--- gate closes from relative --->
          <div class="form-entry">
            <mat-label class="info-title">{{ 'common.gate_rules.gate_closes_from_relative' | translate }}

            <mat-icon
              svgIcon="enelx:general-info"
              data-automation="gateClosesFromRelativeTooltip"
              class="tooltip-icon icon-container"
              [matTooltipPosition]="'below'"
              matTooltip="{{ 'common.gate_rules.gate_closes_from_relative.tooltip' | translate }}"
            ></mat-icon>

            </mat-label>
            <mat-label class="input-entry" data-automation="gateClosesFromRelative">
              {{ product.gateRules.gateClosesFromRelative }}
            </mat-label>
          </div>

          <!--- gate closes to relative --->
          <div class="form-entry">
            <mat-label class="info-title">{{ 'common.gate_rules.gate_closes_to_relative' | translate }}

            <mat-icon
              svgIcon="enelx:general-info"
              data-automation="gateClosesToRelativeTooltip"
              class="tooltip-icon icon-container"
              [matTooltipPosition]="'below'"
              matTooltip="{{ 'common.gate_rules.gate_closes_to_relative.tooltip' | translate }}"
            ></mat-icon>

            </mat-label>
            <mat-label class="input-entry" data-automation="gateClosesToRelative">
              {{ product.gateRules.gateClosesToRelative }}
            </mat-label>
          </div>

        <div class="checkbox-container">
          <!--- allow offer increase --->
          <div class="form-entry">
            <mat-label class="info-title">{{ 'common.gate_rules.allow_offer_increase' | translate }}</mat-label>
            <mat-label class="input-entry" data-automation="allowOfferIncrease">
              {{ product.gateRules.postGateEdits.allowOfferIncrease }}
            </mat-label>
          </div>

          <!--- allow offer decrease --->
          <div class="form-entry">
            <mat-label class="info-title">{{ 'common.gate_rules.allow_offer_decrease' | translate }}</mat-label>
            <mat-label class="input-entry" data-automation="allowOfferDecrease">
              {{ product.gateRules.postGateEdits.allowOfferDecrease }}
            </mat-label>
          </div>

          <!--- allow increase after decrease --->
          <div class="form-entry">
            <mat-label class="info-title">{{ 'common.gate_rules.allow_increase_after_decrease' | translate }}</mat-label>
            <mat-label class="input-entry" data-automation="allowIncreaseAfterDecrease">
              {{ product.gateRules.postGateEdits.allowIncreaseAfterDecrease }}
            </mat-label>
          </div>

<!--          &lt;!&ndash;- allow post gate closure edits -&ndash;&gt;
          <div class="form-entry">
            <mat-label class="info-title">{{ 'common.gate_rules.allow_post_gate_closure_edits' | translate }}</mat-label>
            <mat-label class="input-entry" data-automation="allowPostGateClosureEdits">
              {{ product.gateRules.postGateEdits.allowPostGateClosureEdits }}
            </mat-label>
          </div>-->

          <!--- allow current interval edit --->
          <div class="form-entry">
            <mat-label class="info-title">{{ 'common.gate_rules.allow_current_interval_edit' | translate }}</mat-label>
            <mat-label class="input-entry" data-automation="allowCurrentIntervalEdit">
              {{ product.gateRules.postGateEdits.allowCurrentIntervalEdit }}
            </mat-label>
          </div>

<!--          &lt;!&ndash;- provide change reason -&ndash;&gt;
          <div class="form-entry">
            <mat-label class="info-title">{{ 'common.gate_rules.provide_change_reason' | translate }}</mat-label>
            <mat-label class="input-entry" data-automation="provideChangeReason">
              {{ product.gateRules.postGateEdits.provideChangeReason }}
            </mat-label>
          </div>-->

        </div>

        </div>
      </div>

      <app-product-com-ui
        [mode]="mode"
        [locale]="locale"
        [product]="product"
        [frm]="frm"
      ></app-product-com-ui>

    </mat-expansion-panel>
</ng-container>
