<ng-container ngModelGroup="dispatchableFields">
    <mat-expansion-panel (opened)="panelOpenState = true"
                         (closed)="panelOpenState = false"
                         (afterExpand)="onPanelExpand()"
                         (afterCollapse)="onPanelExpand()"
                         togglePosition="before"
                         class="expansion-panel"
                         id="product-baseline-adjustment-panel"
                         data-automation="productBaselineAdjustmentPanel">

      <mat-expansion-panel-header collapsedHeight="48px" expandedHeight="48px">
        <mat-panel-title class="panel-title">
          <div data-automation="productBaselineAdjustmentPanelTitle">
            {{ 'common.product.baseline_adjustment' | translate }}
          </div>
        </mat-panel-title>
      </mat-expansion-panel-header>

      <!--- CREATE/EDIT --->
      <div *ngIf="(isCreateMode || isEditMode) && product" class="panel-content">
        <div class="form-group">

          <!--- baseline adjustment duration --->
          <div class="form-entry">
            <mat-form-field appearance="outline" floatLabel="always">
              <mat-label
              >{{ 'common.baseline_configuration.baseline_adjustment_duration' | translate }}
              </mat-label>
              <input
                matInput
                id="adjustmentDuration"
                type="number"
                min="0"
                step="1"
                #adjustmentDuration="ngModel"
                name="adjustmentDuration"
                placeholder="0"
                maxlength="16"
                [(ngModel)]="product.baselineConfiguration.baselineAdjustmentDuration"
                data-automation="adjustmentDuration"
              />
            </mat-form-field>
          </div>

          <!--- baseline adjustment offset --->
          <div class="form-entry">
            <mat-form-field appearance="outline" floatLabel="always">
              <mat-label
              >{{ 'common.baseline_configuration.baseline_adjustment_offset' | translate }}
              </mat-label>
              <input
                matInput
                id="adjustmentOffset"
                type="number"
                min="0"
                step="1"
                #adjustmentOffset="ngModel"
                name="adjustmentOffset"
                placeholder="0"
                maxlength="16"
                [(ngModel)]="product.baselineConfiguration.baselineAdjustmentOffset"
                data-automation="adjustmentOffset"
              />

              <mat-icon
                matSuffix
                svgIcon="enelx:general-info"
                data-automation="baselineAdjustmentOffsetTooltip"
                class="tooltip-icon"
                [matTooltipPosition]="'below'"
                matTooltip="{{'common.baseline_configuration.baseline_adjustment_duration.tooltip' | translate}}"
              ></mat-icon>

            </mat-form-field>
          </div>

          <!--- baseline adjustment offset time --->
          <div class="form-entry">
            <mat-form-field appearance="outline" floatLabel="always">
              <mat-label
              >{{ 'common.baseline_configuration.baseline_adjustment_offset_time' | translate }}
              </mat-label>
              <mat-select
                id="adjustmentOffsetTime"
                name="adjustmentOffsetTime"
                #adjustmentOffsetTime="ngModel"
                [(ngModel)]="product.baselineConfiguration.baselineAdjustmentOffsetTime"
                data-automation="adjustmentOffsetTime"
                data-url-persist
                placeholder="{{ 'product.create.placeholder.adjustment_offset_time' | translate }}"
              >
                <mat-option>{{
                    'product.create.placeholder.adjustment_offset_time' | translate
                  }}</mat-option>
                <mat-option
                  *ngFor="let adjustmentOffsetTime of adjustmentOffsetTimes"
                  [value]="adjustmentOffsetTime.code"
                  id="adjustmentOffsetTime_{{ adjustmentOffsetTime.code }}"
                >
                  {{ adjustmentOffsetTime.displayLabel }}
                </mat-option>
              </mat-select>

              <mat-icon
                matSuffix
                svgIcon="enelx:general-info"
                data-automation="baselineAdjustmentOffsetTimeTooltip"
                class="tooltip-icon"
                [matTooltipPosition]="'below'"
                matTooltip="{{'common.baseline_configuration.baseline_adjustment_duration.tooltip' | translate}}"
              ></mat-icon>

            </mat-form-field>
          </div>

          <!--- baseline lookback type --->
          <div class="form-entry">
            <mat-form-field appearance="outline" floatLabel="always">
              <mat-label>{{ 'common.baseline_configuration.baseline_lookback_type' | translate }} </mat-label>
              <mat-select
                id="lookbackType"
                name="lookbackType"
                #lookbackType="ngModel"
                [(ngModel)]="product.baselineConfiguration.baselineLookbackType"
                data-automation="lookbackType"
                data-url-persist
                placeholder="{{ 'product.create.placeholder.lookback_type' | translate }}"
              >
                <mat-option>{{ 'product.create.placeholder.lookback_type' | translate }}</mat-option>
                <mat-option
                  *ngFor="let lookbackType of lookbackTypes"
                  [value]="lookbackType.code"
                  id="lookbackType_{{ lookbackType.code }}"
                >
                  {{ lookbackType.displayLabel }}
                </mat-option>
              </mat-select>

              <mat-icon
                matSuffix
                svgIcon="enelx:general-info"
                data-automation="baselineLookbackTypeTooltip"
                class="tooltip-icon"
                [matTooltipPosition]="'below'"
                matTooltip="{{'common.baseline_configuration.baseline_lookback_type.tooltip' | translate}}"
              ></mat-icon>

            </mat-form-field>
          </div>

          <!--- baseline lookback window size --->
          <div class="form-entry">
            <mat-form-field appearance="outline" floatLabel="always">
              <mat-label
              >{{ 'common.baseline_configuration.baseline_lookback_window_size' | translate }}
              </mat-label>
              <input
                matInput
                id="lookbackWindowSize"
                type="number"
                min="0"
                step="1"
                #lookbackWindowSize="ngModel"
                name="lookbackWindowSize"
                placeholder="0"
                maxlength="16"
                [(ngModel)]="product.baselineConfiguration.baselineLookbackWindowSize"
                data-automation="lookbackWindowSize"
              />

              <mat-icon
                matSuffix
                svgIcon="enelx:general-info"
                data-automation="baselineLookbackWindowSizeTooltip"
                class="tooltip-icon"
                [matTooltipPosition]="'below'"
                matTooltip="{{'common.baseline_configuration.baseline_lookback_window_size.tooltip' | translate}}"
              ></mat-icon>

            </mat-form-field>
          </div>

          <mat-grid-list cols="2" rowHeight="30px">
            <mat-grid-tile>
              <!--- use event time window for calculations --->
              <div class="checkbox-wrapper">
                <mat-checkbox
                  id="enableEventTriggeredBaseline"
                  #enableEventTriggeredBaseline="ngModel"
                  class="input-entry"
                  name="enableEventTriggeredBaseline"
                  [(ngModel)]="product.baselineConfiguration.enableEventTriggeredBaseline"
                  data-automation="enableEventTriggeredBaseline"
                  data-url-persist
                >
                  {{ 'common.baseline_configuration.enable_event_triggered_baseline' | translate }}
                  <mat-icon
                    svgIcon="enelx:general-info"
                    data-automation="enableEventTriggeredBaselineTooltip"
                    class="tooltip-icon"
                    [matTooltipPosition]="'below'"
                    matTooltip="{{'common.baseline_configuration.enable_event_triggered_baseline.tooltip' | translate}}"
                  ></mat-icon>
                </mat-checkbox>
              </div>
            </mat-grid-tile>
            <mat-grid-tile>
              <!--- baseline adjustment allow override --->
              <div class="checkbox-wrapper">
                <mat-checkbox
                  id="adjustmentAllowOverride"
                  #adjustmentAllowOverride="ngModel"
                  class="input-entry"
                  name="adjustmentAllowOverride"
                  [(ngModel)]="product.baselineConfiguration.baselineAdjustmentAllowOverride"
                  data-automation="adjustmentAllowOverride"
                  data-url-persist
                >
                  {{ 'common.baseline_configuration.baseline_adjustment_allow_override' | translate }}
                </mat-checkbox>

                <mat-icon svgIcon="enelx:general-info"
                          data-automation="baselineAdjustmentAllowOverrideTooltip"
                          class="tooltip-icon"
                          [matTooltipPosition]="'below'"
                          matTooltip="{{ 'common.baseline_configuration.baseline_adjustment_allow_override.tooltip' | translate }}"
                ></mat-icon>

              </div>
            </mat-grid-tile>
          </mat-grid-list>

        </div>
      </div>

      <!--- VIEW --->
      <div *ngIf="isViewMode && product" class="panel-content">
        <div class="form-group">
          <!--- baseline adjustment duration --->
          <div class="form-entry">
            <mat-label class="info-title">{{ 'common.baseline_configuration.baseline_adjustment_duration' | translate }}</mat-label>
            <mat-label class="input-entry" data-automation="baselineAdjustmentDuration">
              {{ product.baselineConfiguration.baselineAdjustmentDuration }}
            </mat-label>
          </div>

          <!--- baseline adjustment offset --->
          <div class="form-entry">
            <mat-label class="info-title">{{ 'common.baseline_configuration.baseline_adjustment_offset' | translate }}

              <mat-icon
                svgIcon="enelx:general-info"
                data-automation="baselineAdjustmentOffsetTooltip"
                class="tooltip-icon"
                [matTooltipPosition]="'below'"
                matTooltip="{{ 'common.baseline_configuration.baseline_adjustment_offset.tooltip' | translate }}"
              ></mat-icon>

            </mat-label>
            <mat-label class="input-entry" data-automation="baselineAdjustmentOffset">
              {{ product.baselineConfiguration.baselineAdjustmentOffset }}
            </mat-label>
          </div>

          <!--- baseline adjustment offset time --->
          <div class="form-entry">
            <mat-label class="info-title">{{ 'common.baseline_configuration.baseline_adjustment_offset_time' | translate }}

              <mat-icon
                svgIcon="enelx:general-info"
                data-automation="baselineAdjustmentOffsetTimeTooltip"
                class="tooltip-icon"
                [matTooltipPosition]="'below'"
                matTooltip="{{ 'common.baseline_configuration.baseline_adjustment_duration.tooltip' | translate }}"
              ></mat-icon>

            </mat-label>
            <mat-label class="input-entry" data-automation="baselineAdjustmentOffsetTime">
              {{ setLabelForAdjustmentOffsetTimes() }}
            </mat-label>
          </div>

          <!--- baseline lookback type --->
          <div class="form-entry">
            <mat-label class="info-title">{{ 'common.baseline_configuration.baseline_lookback_type' | translate }}

              <mat-icon
                svgIcon="enelx:general-info"
                data-automation="baselineLookbackTypeTooltip"
                class="tooltip-icon"
                [matTooltipPosition]="'below'"
                matTooltip="{{ 'common.baseline_configuration.baseline_lookback_type.tooltip' | translate }}"
              ></mat-icon>

            </mat-label>
            <mat-label class="input-entry" data-automation="baselineLookbackType">
              {{ setLabelForLookbackTypes() }}
            </mat-label>
          </div>

          <!--- baseline lookback window size --->
          <div class="form-entry">
            <mat-label class="info-title">{{ 'common.baseline_configuration.baseline_lookback_window_size' | translate }}

              <mat-icon
                svgIcon="enelx:general-info"
                data-automation="baselineLookbackWindowSizeTooltip"
                class="tooltip-icon"
                [matTooltipPosition]="'below'"
                matTooltip="{{ 'common.baseline_configuration.baseline_lookback_window_size.tooltip' | translate }}"
              ></mat-icon>

            </mat-label>
            <mat-label class="input-entry" data-automation="baselineLookbackWindowSize">
              {{ product.baselineConfiguration.baselineLookbackWindowSize }}
            </mat-label>
          </div>

          <div class="checkbox-container">
          <!--- enable event triggered baseline --->
          <div class="form-entry">
            <mat-label class="info-title">
              {{ 'common.baseline_configuration.enable_event_triggered_baseline' | translate }}:
              <mat-icon
                svgIcon="enelx:general-info"
                data-automation="enableEventTriggeredBaselineTooltip"
                class="tooltip-icon"
                [matTooltipPosition]="'below'"
                matTooltip="{{ 'common.baseline_configuration.enable_event_triggered_baseline.tooltip' | translate }}"
              ></mat-icon>
            </mat-label>
            <mat-label class="input-entry" data-automation="enableEventTriggeredBaseline">
              {{ !!product.baselineConfiguration.enableEventTriggeredBaseline }}
            </mat-label>
          </div>


          <!--- baseline adjustment allow override --->
          <div class="form-entry">
            <mat-label class="info-title">{{ 'common.baseline_configuration.baseline_adjustment_allow_override' | translate }}

              <mat-icon
                svgIcon="enelx:general-info"
                data-automation="baselineAdjustmentAllowOverrideTooltip"
                class="tooltip-icon"
                [matTooltipPosition]="'below'"
                matTooltip="{{ 'common.baseline_configuration.baseline_adjustment_allow_override.tooltip' | translate }}"
              ></mat-icon>

            </mat-label>
            <mat-label class="input-entry" data-automation="baselineAdjustmentAllowOverride">
              {{ product.baselineConfiguration.baselineAdjustmentAllowOverride }}
            </mat-label>
          </div>

          </div>

        </div>
      </div>

    </mat-expansion-panel>
</ng-container>
