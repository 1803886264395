<div class="app-equipment-edit content">
    <header class="sticky-header">
        <div class="left-column">
            <div class="icon-header">
                <div class="icon-container">
                    <mat-icon *ngIf="marketEntity">emoji_objects</mat-icon>
                    <mat-spinner *ngIf="!marketEntity" matSuffix diameter="30" color="warn"></mat-spinner>
                </div>
                <h1 id="edit-title" data-automation="editTitle">
                    <ng-container *ngIf="marketEntity">{{ this.getTitle() | translate }}</ng-container>
                    <ng-container *ngIf="!marketEntity">{{ 'common.loading' | translate }}</ng-container>
                </h1>
                <p class="subtitle" data-automation="editSubtitle">
                    {{ this.getSubtitle() | translate }}
                </p>
            </div>
        </div>
        <div class="right-column" data-automation="right_buttons">
            <button
                class="icon-btn x-btn-secondary"
                color="gray"
                aria-label="cancel"
                (click)="handleCancel()"
                data-automation="editCancel"
            >
                <mat-icon>clear</mat-icon>
                <span class="btn-text"> {{ 'common.button.cancel' | translate }}</span>
            </button>

            <div class="tooltip-btn-wrapper" matTooltip="{{ !canDelete() ? CANNOT_DELETE : null }}">
                <button
                    class="icon-btn x-btn-primary"
                    color="accent"
                    aria-label="delete"
                    (click)="openDeleteDialog()"
                    [disabled]="!marketEntity || !canDelete()"
                    data-automation="productDelete"
                >
                    <mat-icon>delete</mat-icon>
                    <span class="btn-text"> {{ 'common.button.delete' | translate }}</span>
                </button>
            </div>

            <button
                class="icon-btn x-btn-primary"
                color="accent"
                aria-label="Edit"
                (click)="handleSubmit()"
                [disabled]="edit.invalid || !edit.dirty"
                data-automation="editSubmit"
            >
                <mat-icon>done</mat-icon>
                <div *ngIf="saving" class="btn-spinner">
                    <mat-spinner diameter="15"></mat-spinner>
                </div>
                <span class="btn-text"> {{ 'common.button.submit' | translate }}</span>
            </button>
        </div>
    </header>
    <div class="page-content">
        <form #edit="ngForm" class="market-entity-form">
            <div class="form-wrapper">
                <div class="form-entry market-entity-type">
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>{{ 'common.market_entity_type' | translate }}</mat-label>
                        <mat-select
                            id="drType"
                            name="drType"
                            #drType="ngModel"
                            [ngClass]="{
                                failure: drType.invalid && (drType.dirty || drType.touched),
                                'create-select': true
                            }"
                            [(ngModel)]="marketEntityType"
                            [disabled]="mode === 'edit'"
                            data-automation="marketEntityTypeSelector"
                            data-url-persist
                        >
                            <mat-option
                                *ngFor="let typeOption of marketTypes"
                                [value]="typeOption.name"
                                id="equipType_{{ typeOption.name }}"
                                >{{ typeOption.displayLabel | translate }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <ng-container *ngIf="marketEntity">
                <div [ngSwitch]="marketEntityType">
                    <div *ngSwitchCase="'Product'">
                        <app-product [mode]="mode" [frm]="editForm"></app-product>
                    </div>
                    <div *ngSwitchCase="'Program'">
                        <app-program [mode]="mode" [frm]="editForm"></app-program>
                    </div>
                    <div *ngSwitchCase="'Operator'">
                        <app-operator [mode]="mode" [frm]="editForm"></app-operator>
                    </div>
                </div>
            </ng-container>
        </form>
    </div>
</div>
