<div id="productForm" [ngClass]="isEditMode ? 'editing' : isCreateMode ? 'creating' : 'viewing'">
    <div class="form-wrapper">
        <app-product-general-information
            [mode]="mode"
            [locale]="locale"
            [product]="product"
            [frm]="frm"
        ></app-product-general-information>
        <app-product-dispatch-information
            (toggleDispatchableProduct)="toggleDispatchableProduct('dispatch')"
            (clearDispatch)="clearDispatch($event)"
            [mode]="mode"
            [locale]="locale"
            [product]="product"
            [frm]="frm"
        ></app-product-dispatch-information>
        <app-product-performance
            (toggleDispatchableProduct)="toggleDispatchableProduct('performance')"
            [mode]="mode"
            [locale]="locale"
            [product]="product"
            [frm]="frm"
        ></app-product-performance>
        <app-product-baseline-adjustment
            (toggleDispatchableProduct)="toggleDispatchableProduct('baseline')"
            [mode]="mode"
            [locale]="locale"
            [product]="product"
            [frm]="frm"
        ></app-product-baseline-adjustment>
        <app-product-customer-offer-management
            (toggleDispatchableProduct)="toggleDispatchableProduct('customer')"
            [mode]="mode"
            [locale]="locale"
            [product]="product"
            [frm]="frm"
        ></app-product-customer-offer-management>
        <app-product-utility-portal
            (toggleDispatchableProduct)="toggleDispatchableProduct('utility')"
            [mode]="mode"
            [locale]="locale"
            [product]="product"
            [frm]="frm"
        ></app-product-utility-portal>
    </div>
</div>
