import { Program, RefData } from 'src/app/shared/model/program.model';
import { Component, OnInit, Input } from '@angular/core';
import { Currency } from '../../../shared/model/currency.model';
import { ControlContainer, UntypedFormGroup, NgForm } from '@angular/forms';
import { RefService } from '../../../shared/services/ref.service';
import { FormValidatorService } from '../../../shared/services/form-validator.service';

@Component({
  selector: 'app-program-dispatchable-fields',
  templateUrl: './program-dispatchable-fields.component.html',
  styleUrls: ['./program-dispatchable-fields.component.css', '../../../shared/shared.styles.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class ProgramDispatchableFieldsComponent implements OnInit {
  currencies: Currency[];
  isInit = true;
  panelOpenedState = false;
  scheduleSection = false;
  gateRulesSection = false;
  showGateRulesError = false;
  notificationConfigSection = false;

  offerFrequencyUnits: RefData[] = [];
  offerGroupFrequencyUnits: RefData[];

  @Input() mode;
  @Input() program;
  @Input() frm;

  constructor(
    private refService: RefService,
    private formValidatorService: FormValidatorService
  ) {}

  get theForm() {
    return this.frm.form as UntypedFormGroup;
  }

  get dispatchableForm() {
    return this.frm.form.get('dispatchableFields') as UntypedFormGroup;
  }

  get gateRulesForm() {
    return this.dispatchableForm.get('gateRules') as UntypedFormGroup;
  }

  get scheduleForm() {
    return this.dispatchableForm.get('schedulePanel') as UntypedFormGroup;
  }
  
  get isEditMode() {
    return this.mode === 'edit';
  }
  get isCreateMode() {
    return this.mode === 'create';
  }
  get isViewMode() {
    return this.mode === 'view';
  }

  ngOnInit(): void {
    this.refService.currencies$.subscribe(currencies => {
      if (currencies.length > 0) {
        this.currencies = currencies;
      }
    });

    setTimeout(() => {
      if (
        this.frm &&
        this.dispatchableForm &&
        !this.dispatchableForm.disabled &&
        !this.program?.isDispatchableProgram &&
        this.isInit
      ) {
        this.dispatchableForm.disable();
        this.isInit = false;
      }
    }, 0);

    this.refService.offerFrequencyUnits$.subscribe(offerFrequencyUnits => {
      this.offerFrequencyUnits = offerFrequencyUnits;
      this.updateGroupUnit({ value: this.program.schedule.offerFrequencyUnit });
    });
  }

  getCurrencyCodeDisplayLabel() {
    if (this.program && this.program.currencyCode && this.currencies) {
      const currency = this.currencies.find(currency => this.program.currencyCode === currency.code);
      if (currency) {
        return currency.displayLabel;
      }
    }

    return '';
  }

  getOfferFrequencyUnitForProgram() {
    if (this.program.schedule && this.program.schedule.offerFrequencyUnit && this.offerFrequencyUnits.length > 0) {
      const offerFrequencyUnit = this.offerFrequencyUnits.find(
        frequencyUnit => frequencyUnit.code === this.program.schedule.offerFrequencyUnit,
      );
      if (offerFrequencyUnit) {
        return offerFrequencyUnit.displayLabel;
      }
    }

    return '';
  }

  getOfferGroupUnitForProgram() {
    if (this.program.schedule && this.program.schedule.offerGroupUnit && this.offerFrequencyUnits.length > 0) {
      const offerGroupUnit = this.offerFrequencyUnits.find(
        frequencyUnit => frequencyUnit.code === this.program.schedule.offerGroupUnit,
      );
      if (offerGroupUnit) {
        return offerGroupUnit.displayLabel;
      }
    }

    return '';
  }

  updateGroupUnit($event) {
    const index = this.offerFrequencyUnits.findIndex(unit => unit.code === $event.value);
    this.offerGroupFrequencyUnits = this.offerFrequencyUnits.slice(index + 1);
  }

  toggleSchedule() {
    this.scheduleSection = !this.scheduleSection;
  }

  toggleGateRules() {
    // Open gate rules flag on html
    this.gateRulesSection = !this.gateRulesSection;

    if (this.gateRulesForm?.valid) {
      return;
    }

    // On open
    if (this.gateRulesSection) {
      // Revert disabled so user can use form
      if (this.gateRulesForm?.disabled) {
        this.gateRulesForm.enable();
      }
      // If there's already an error, retrigger the fields
      if (this.showGateRulesError) {
        this.formValidatorService.triggerValidation(this.gateRulesForm);
      }
      this.showGateRulesError = false;
    } else {
      // On close
      // If both required fields are empty, revert behavior
      if (!this.gateRulesForm.controls['gateClosureDescription'].value &&
      !this.gateRulesForm.controls['gateClosureTolerance'].value) {
        this.showGateRulesError = false;
        return;
      }
      // If fields are touched and required, show error border
      if (this.formValidatorService.hasRequiredTouchedFields(this.gateRulesForm)) {
        this.showGateRulesError = true;
      }
      
    }
  }
}
