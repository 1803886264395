import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ProductDetailsComponent } from '../details/product/product-details.component';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalAlertService } from '../shared/services/global-alert.service';
import { ProductsService } from '../shared/services/products.service';
import { TranslateService } from '@ngx-translate/core';
import { ProgramsService } from '../shared/services/programs.service';
import { OperatorsService } from '../shared/services/operators.service';
import { MarketsService } from '../shared/services/markets.service';
import { MarketEntity } from '../shared/model/market-entity.model';
import { ProgramDetailsComponent } from '../details/program/program-details.component';
import { OperatorDetailsComponent } from '../details/operator/operator-details.component';
import { DeleteDialogComponent } from '../dialogs/delete/delete-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { SidenavService } from '../shared/services/sidenav.service';
import { ErrorHandlingService } from '../shared/services/error-handling.service';
import { TreeService } from "../shared/services/tree.service";
@Component({
  selector: 'app-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.scss', '../shared/shared.styles.scss'],
})
export class ViewComponent implements OnInit {
  form: UntypedFormGroup;
  marketTypes: any[];
  marketId = '';
  marketEntityType = 'Product';
  marketEntity: MarketEntity;
  appData: any = {};
  subscriptions = [];
  disableActions = false;
  incomplete = false;
  appPrefix = 'mrk';
  SUCCESS = 'Deleted Successfully';
  BAD_REQUEST = 'Oops, There was a problem with your request';
  NOT_DELETED = 'Oops, There was a problem deleting your product';
  CONFLICT = 'There is a data conflict with this product';
  PARTIAL_SUCCESS = 'Not all data may have returned successfully';
  REQUIRED = '';
  CANNOT_DELETE = '';
  readonly mode = 'view';
  private productComponentView: ProductDetailsComponent;
  private programComponentView: ProgramDetailsComponent;
  private operatorComponentView: OperatorDetailsComponent;
  flagLoadViewEnded = false;

  @ViewChild(ProductDetailsComponent)
  set productComponent(productDetailsComponent: ProductDetailsComponent) {
    this.productComponentView = productDetailsComponent;
  }

  @ViewChild(ProgramDetailsComponent)
  set programComponent(programDetailsComponent: ProgramDetailsComponent) {
    this.programComponentView = programDetailsComponent;
  }

  @ViewChild(OperatorDetailsComponent)
  set operatorComponent(operatorDetailsComponent: OperatorDetailsComponent) {
    this.operatorComponentView = operatorDetailsComponent;
  }

  constructor(
    private router: Router,
    private productsService: ProductsService,
    private programsService: ProgramsService,
    private operatorsService: OperatorsService,
    private messageService: GlobalAlertService,
    private marketsService: MarketsService,
    private route: ActivatedRoute,
    private translateService: TranslateService,
    private productService: ProductsService,
    private messagingService: GlobalAlertService,
    public dialog: MatDialog,
    private sidenavService: SidenavService,
    private errorHandlingService: ErrorHandlingService,
    private treeService: TreeService,
  ) {
    this.marketTypes = marketsService.getMarketTypes();

    this.translateService.get('product.notification.deleted_successfully').subscribe((result: string) => {
      this.SUCCESS = result;
      switch (this.marketEntityType) {
        case 'Operator':
          this.SUCCESS = this.translateService.instant('operator.notification.deleted_successfully');
          this.BAD_REQUEST = this.translateService.instant('operator.notification.bad_request');
          this.NOT_DELETED = this.translateService.instant('operator.notification.product.not_deleted');
          this.REQUIRED = this.translateService.instant('common.validation.required');
          this.CANNOT_DELETE = this.translateService.instant('operator.tooltip.must_delete_children');
          break;
        case 'Program':
          this.SUCCESS = this.translateService.instant('program.notification.deleted_successfully');
          this.BAD_REQUEST = this.translateService.instant('program.notification.bad_request');
          this.NOT_DELETED = this.translateService.instant('program.notification.product.not_deleted');
          this.REQUIRED = this.translateService.instant('common.validation.required');
          this.CANNOT_DELETE = this.translateService.instant('program.tooltip.must_delete_children');
          break;
        default:
          this.SUCCESS = this.translateService.instant('product.notification.deleted_successfully');
          this.BAD_REQUEST = this.translateService.instant('product.notification.bad_request');
          this.NOT_DELETED = this.translateService.instant('product.notification.product.not_deleted');
          this.REQUIRED = this.translateService.instant('common.validation.required');
          this.CONFLICT = this.translateService.instant('common.errors.conflict_error');
          this.PARTIAL_SUCCESS = this.translateService.instant('common.errors.partial_success');
      }
    });

    this.productService.conflictError$.subscribe((conflictError) => {
      this.errorHandlingService.setErrorMessage(conflictError);
    });

    this.productService.partialSuccessWarning$.subscribe(() => {
      this.messagingService.setError(this.PARTIAL_SUCCESS, null, 7000);
    });

    this.programsService.conflictError$.subscribe((conflictError) => {
      this.errorHandlingService.setErrorMessage(conflictError);
    });

    this.programsService.partialSuccessWarning$.subscribe(() => {
      this.messagingService.setError(this.PARTIAL_SUCCESS, null, 7000);
    });
  }
  sendMessage(flagLoadViewEnded) {
    this.sidenavService.sendMessage(flagLoadViewEnded);
  }

  clearMessages() {
    // clear messages
    this.sidenavService.clearMessages();
  }
  ngOnInit() {
    this.form = new UntypedFormGroup({});
    this.operatorsService.operator$.subscribe((operator) => {
      if (operator) {
        this.marketEntity = operator;
        this.appData.marketEntity = operator;
        this.marketEntityType = this.marketEntity.drType;
      }
    });

    this.programsService.program$.subscribe((program) => {
      if (program && program.incomplete) {
        this.incomplete = true;
        this.disableActions = true;
      } else {
        this.incomplete = false;
        this.disableActions = false;
      }

      if (program) {
        this.marketEntity = program;
        this.appData.marketEntity = program;
        this.marketEntityType = this.marketEntity.drType;
      }
    });

    this.productsService.product$.subscribe((product) => {
      if (product && product.incomplete) {
        this.incomplete = true;
        this.disableActions = true;
      } else {
        this.incomplete = false;
        this.disableActions = false;
      }

      if (product) {
        this.marketEntity = product;
        this.appData.marketEntity = product;
        this.marketEntityType = this.marketEntity.drType || 'Product';
        this.appData.product = product;
      }
    });
    /* tslint:disable:no-string-literal */
    this.route.params.subscribe(this.getNext());
  }

  private getNext() {
    this.incomplete = false;
    this.disableActions = false;
    return async () => {
      if (
        this.route.params['value']['id'] &&
        this.route.queryParams['value'] &&
        this.route.queryParams['value']['drType']
      ) {
        this.marketEntityType = this.route.queryParams['value']['drType'];
        this.marketId = this.route.params['value']['id'];
        this.marketEntity = null;
      }

      if (!this.marketId || !this.marketEntityType) {
        return;
      }
      this.treeService.setSelectedTreeNode(this.marketId);
      switch (this.marketEntityType) {
        case 'Operator':
          await this.operatorsService.fetchOperator(this.marketId);
          this.flagLoadViewEnded = false;
          this.sendMessage(this.flagLoadViewEnded);
          break;
        case 'Program':
          await this.programsService.fetchProgram(this.marketId);
          this.flagLoadViewEnded = false;
          this.sendMessage(this.flagLoadViewEnded);
          break;
        default:
          await this.productsService.fetchProduct(this.marketId);
          this.flagLoadViewEnded = false;
          this.sendMessage(this.flagLoadViewEnded);
      }
    };
  }

  getTitle() {
    return this.marketEntity.displayLabel;
  }

  handleCancel() {
    this.router.navigate(['list'], {});
  }

  handleEdit() {
    this.router.navigate([`details/${this.marketId}/edit`], { queryParams: { drType: this.marketEntityType } });
  }

  openDeleteDialog() {
    this.dialog.open(DeleteDialogComponent, {
      width: '400px',
      data: {
        marketEntity: this.marketEntity,
        marketId: this.marketId,
        marketEntityType: this.marketEntityType.toLowerCase(),
      },
    });
  }

  canDelete() {
    return !(this.marketEntity && this.marketEntity.children && this.marketEntity.children.length > 0);
  }
}
