<div data-automation="dispatchInformationFieldsPanel" ngModelGroup="dispatchableFields">
    <ng-container>
        <mat-expansion-panel
            (opened)="panelOpenState = true"
            (closed)="panelOpenState = false"
            (afterExpand)="onPanelExpand()"
            (afterCollapse)="onPanelExpand()"
            togglePosition="before"
            class="expansion-panel"
            id="product-dispatch-information-panel"
            data-automation="dispatchInformationPanel"
        >
            <mat-expansion-panel-header collapsedHeight="48px" expandedHeight="48px">
                <mat-panel-title class="panel-title">
                    <div data-automation="dispatchInformationPanelTitle">
                        {{ 'common.product.dispatch_information' | translate }}
                    </div>
                </mat-panel-title>
                <mat-panel-description *ngIf="(isCreateMode || isEditMode) && product">
                    <button
                        *ngIf="panelOpenState && dispatchableForm?.dirty"
                        class="icon-btn x-btn-secondary panel-btn"
                        (click)="handleClearClick($event)"
                        matTooltip="{{ 'common.button.clear.tooltip' | translate }}"
                    >
                        {{ 'common.button.clear' | translate }}
                    </button>
                </mat-panel-description>
            </mat-expansion-panel-header>

            <!--- CREATE/EDIT --->
            <div *ngIf="(isCreateMode || isEditMode) && product" class="panel-content">
                <div class="form-group">
                    <!--- selection type --->
                    <div class="form-entry">
                        <mat-form-field appearance="outline" floatLabel="always">
                            <mat-label>{{ 'common.selection_type' | translate }}</mat-label>

                            <mat-select
                                id="selectionType"
                                name="selectionType"
                                #selectionType="ngModel"
                                [(ngModel)]="product.selectionType"
                                data-automation="selectionTypeSelector"
                                data-url-persist
                                required
                                placeholder="{{ 'product.create.placeholder.selection_type' | translate }}"
                            >
                                <mat-option>{{ 'product.create.placeholder.selection_type' | translate }}</mat-option>
                                <mat-option
                                    *ngFor="let selectionType of selectionTypes"
                                    [value]="selectionType.code"
                                    id="{{ selectionType.code }}"
                                >
                                    {{ selectionType.displayLabel }}
                                </mat-option>
                            </mat-select>
                            <mat-icon
                                matSuffix
                                svgIcon="enelx:general-info"
                                data-automation="selectionTypeTooltip"
                                class="tooltip-icon"
                                [matTooltipPosition]="'below'"
                                matTooltip="{{ 'common.selection_type.tooltip' | translate }}"
                            ></mat-icon>
                            <mat-error *ngIf="selectionType.invalid" class="alert alert-danger">
                                <mat-error *ngIf="selectionType.errors.required">
                                    {{ 'common.validation.required' | translate }}
                                </mat-error>
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <!--- target type --->
                    <div class="form-entry">
                        <mat-form-field appearance="outline" floatLabel="always">
                            <mat-label>{{ 'common.target_type' | translate }}</mat-label>

                            <mat-select
                                id="targetType"
                                name="targetType"
                                #targetType="ngModel"
                                [(ngModel)]="product.targetType"
                                data-automation="targetTypeSelector"
                                data-url-persist
                                required
                                placeholder="{{ 'product.create.placeholder.target_type' | translate }}"
                            >
                                <mat-option>{{ 'product.create.placeholder.target_type' | translate }}</mat-option>
                                <mat-option
                                    *ngFor="let targetType of targetTypes"
                                    [value]="targetType.code"
                                    id="{{ targetType.code }}"
                                >
                                    {{ targetType.displayLabel }}
                                </mat-option>
                            </mat-select>
                            <mat-icon
                                matSuffix
                                svgIcon="enelx:general-info"
                                data-automation="targetTypeTooltip"
                                class="tooltip-icon"
                                [matTooltipPosition]="'below'"
                                matTooltip="{{ 'common.target_type.tooltip' | translate }}"
                            ></mat-icon>
                            <mat-error *ngIf="targetType.invalid" class="alert alert-danger">
                                <mat-error *ngIf="targetType.errors.required">
                                    {{ 'common.validation.required' | translate }}
                                </mat-error>
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <!--- expected capacity source --->
                    <div class="form-entry">
                        <mat-form-field appearance="outline" floatLabel="always">
                            <mat-label>{{ 'common.expected_capacity_source' | translate }}</mat-label>

                            <mat-select
                                id="expectedCapacitySource"
                                name="expectedCapacitySource"
                                #expectedCapacitySource="ngModel"
                                [(ngModel)]="product.expectedCapacitySource"
                                data-automation="expectedCapacitySourceSelector"
                                data-url-persist
                                required
                                placeholder="{{ 'product.create.placeholder.expected_capacity_source' | translate }}"
                            >
                                <mat-option>{{
                                    'product.create.placeholder.expected_capacity_source' | translate
                                }}</mat-option>
                                <mat-option
                                    *ngFor="let expectedCapacitySource of expectedCapacitySources"
                                    [value]="expectedCapacitySource.code"
                                    id="{{ expectedCapacitySource.code }}"
                                >
                                    {{ expectedCapacitySource.displayLabel }}
                                </mat-option>
                            </mat-select>
                            <mat-icon
                                matSuffix
                                svgIcon="enelx:general-info"
                                data-automation="expectedCapacitySourceTooltip"
                                class="tooltip-icon"
                                [matTooltipPosition]="'below'"
                                matTooltip="{{ 'common.expected_capacity_source.tooltip' | translate }}"
                            ></mat-icon>
                            <mat-error *ngIf="expectedCapacitySource.invalid" class="alert alert-danger">
                                <mat-error *ngIf="expectedCapacitySource.errors.required">
                                    {{ 'common.validation.required' | translate }}
                                </mat-error>
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <!--- portfolio obligation source --->
                    <div class="form-entry">
                        <mat-form-field appearance="outline" floatLabel="always">
                            <mat-label>{{ 'common.portfolio_obligation_source' | translate }}</mat-label>

                            <mat-select
                                id="portfolioObligationSource"
                                name="portfolioObligationSource"
                                #portfolioObligationSource="ngModel"
                                [(ngModel)]="product.portfolioObligationSource"
                                data-automation="portfolioObligationSourceSelector"
                                data-url-persist
                                required
                                placeholder="{{ 'product.create.placeholder.portfolio_obligation_source' | translate }}"
                            >
                                <mat-option>{{
                                    'product.create.placeholder.portfolio_obligation_source' | translate
                                }}</mat-option>
                                <mat-option
                                    *ngFor="let portfolioObligationSource of portfolioObligationSources"
                                    [value]="portfolioObligationSource.code"
                                    id="{{ portfolioObligationSource.code }}"
                                >
                                    {{ portfolioObligationSource.displayLabel }}
                                </mat-option>
                            </mat-select>
                            <mat-icon
                                matSuffix
                                svgIcon="enelx:general-info"
                                data-automation="portfolioObligationSourceTooltip"
                                class="tooltip-icon"
                                [matTooltipPosition]="'below'"
                                matTooltip="{{ 'common.portfolio_obligation_source.tooltip' | translate }}"
                            ></mat-icon>
                            <mat-error *ngIf="portfolioObligationSource.invalid" class="alert alert-danger">
                                <mat-error *ngIf="portfolioObligationSource.errors.required">
                                    {{ 'common.validation.required' | translate }}
                                </mat-error>
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <ng-container
                        ngModelGroup="selectionBuffers"
                        #selectionBuffers="ngModelGroup"
                        selectionBufferValidator
                    >
                        <!--- min selection buffer --->
                        <div class="form-entry">
                            <mat-form-field appearance="outline" floatLabel="always">
                                <mat-label>{{ 'common.min_selection_buffer' | translate }}</mat-label>

                                <input
                                    matInput
                                    id="minSelectionBuffer"
                                    type="number"
                                    min="0"
                                    step=".1"
                                    #minSelectionBuffer="ngModel"
                                    name="minSelectionBuffer"
                                    placeholder="50.0"
                                    maxlength="16"
                                    [(ngModel)]="product.selectionBufferMin"
                                    data-automation="minSelectionBuffer"
                                    data-url-persist
                                    required
                                />
                                <mat-icon
                                    matSuffix
                                    svgIcon="enelx:general-info"
                                    data-automation="minSelectionBufferTooltip"
                                    class="tooltip-icon"
                                    [matTooltipPosition]="'below'"
                                    matTooltip="{{ 'common.min_selection_buffer.tooltip' | translate }}"
                                ></mat-icon>
                                <mat-error *ngIf="minSelectionBuffer.invalid" class="alert alert-danger">
                                    <mat-error *ngIf="minSelectionBuffer.errors.required">
                                        <span class="input-title"></span>
                                        <span class="input-entry">{{ 'common.validation.required' | translate }}</span>
                                    </mat-error>
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <!--- max selection buffer --->
                        <div class="form-entry">
                            <mat-form-field appearance="outline" floatLabel="always">
                                <mat-label>{{ 'common.max_selection_buffer' | translate }}</mat-label>

                                <input
                                    matInput
                                    id="maxSelectionBuffer"
                                    type="number"
                                    min="0"
                                    step=".1"
                                    #maxSelectionBuffer="ngModel"
                                    name="maxSelectionBuffer"
                                    placeholder="75.0"
                                    maxlength="16"
                                    [(ngModel)]="product.selectionBufferMax"
                                    data-automation="maxSelectionBuffer"
                                    data-url-persist
                                    required
                                />

                                <mat-icon
                                    matSuffix
                                    svgIcon="enelx:general-info"
                                    data-automation="maxSelectionBufferTooltip"
                                    class="tooltip-icon"
                                    [matTooltipPosition]="'below'"
                                    matTooltip="{{ 'common.max_selection_buffer.tooltip' | translate }}"
                                ></mat-icon>

                                <mat-error *ngIf="maxSelectionBuffer.invalid" class="alert alert-danger">
                                    <mat-error *ngIf="maxSelectionBuffer.errors.required">
                                        <span class="input-title"></span>
                                        <span class="input-entry">{{ 'common.validation.required' | translate }}</span>
                                    </mat-error>
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <!--- selection upper target --->
                        <div class="form-entry">
                            <mat-form-field appearance="outline" floatLabel="always">
                                <mat-label>{{ 'common.selection_upper_target' | translate }}</mat-label>

                                <input
                                    matInput
                                    id="selectionUpperTarget"
                                    type="number"
                                    step=".1"
                                    #selectionUpperTarget="ngModel"
                                    name="selectionUpperTarget"
                                    placeholder="150.0"
                                    maxlength="16"
                                    [(ngModel)]="product.selectionUpperTarget"
                                    data-automation="selectionUpperTarget"
                                    data-url-persist
                                    required
                                />

                                <mat-icon
                                    matSuffix
                                    svgIcon="enelx:general-info"
                                    data-automation="selectionUpperTargetTooltip"
                                    class="tooltip-icon"
                                    [matTooltipPosition]="'below'"
                                    matTooltip="{{ 'common.selection_upper_target.tooltip' | translate }}"
                                ></mat-icon>

                                <mat-error *ngIf="selectionUpperTarget.invalid" class="alert alert-danger">
                                    <mat-error *ngIf="selectionUpperTarget.errors.required">
                                        <span class="input-title"></span>
                                        <span class="input-entry">{{ 'common.validation.required' | translate }}</span>
                                    </mat-error>
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <mat-error class="group-error" *ngIf="selectionBuffers.invalid">
                            <mat-error *ngIf="selectionBuffers.errors && selectionBuffers.errors.minMaxValid">
                                <span class="input-title"></span>
                                <span class="input-entry" data-automation="validationMessage"
                                    >{{ 'common.errors.max_min_val' | translate }}
                                    {{ 'common.errors.upper_target' | translate }}</span
                                >
                            </mat-error>
                        </mat-error>
                    </ng-container>

                    <!--- scale capacity to obligation --->
                    <div class="form-entry">
                        <div class="checkbox-wrapper">
                            <mat-checkbox
                                id="scaleCapacityToObligation"
                                #scaleCapacityToObligation="ngModel"
                                class="input-entry checkbox-container"
                                name="scaleCapacityToObligation"
                                [(ngModel)]="product.scaleCapacityToObligation"
                                data-automation="scaleCapacityToObligation"
                                data-url-persist
                            >
                                {{ 'common.scale_capacity_to_obligation' | translate }}
                            </mat-checkbox>

                            <mat-icon
                                matSuffix
                                svgIcon="enelx:general-info"
                                data-automation="scaleCapacityToObligationTooltip"
                                class="tooltip-icon"
                                [matTooltipPosition]="'below'"
                                matTooltip="{{ 'common.scale_capacity_to_obligation.tooltip' | translate }}"
                            ></mat-icon>
                        </div>
                    </div>

                    <!--- selection offer time --->
                    <div class="form-entry">
                        <mat-form-field appearance="outline" floatLabel="always">
                            <mat-label>{{ 'common.selection_offer_time' | translate }}</mat-label>

                            <mat-select
                                id="selectionOfferTime"
                                name="selectionOfferTime"
                                #selectionOfferTime="ngModel"
                                [(ngModel)]="product.selectionOfferTime"
                                data-automation="selectionOfferTimeSelector"
                                data-url-persist
                                required
                                placeholder="{{ 'product.create.placeholder.selection_offer_time' | translate }}"
                            >
                                <mat-option>{{
                                    'product.create.placeholder.selection_offer_time' | translate
                                }}</mat-option>
                                <mat-option
                                    *ngFor="let selectionOfferTime of selectionOfferTimes"
                                    [value]="selectionOfferTime.code"
                                    id="{{ selectionOfferTime.code }}"
                                >
                                    {{ selectionOfferTime.displayLabel }}
                                </mat-option>
                            </mat-select>

                            <mat-icon
                                matSuffix
                                svgIcon="enelx:general-info"
                                data-automation="selectionOfferTimeTooltip"
                                class="tooltip-icon"
                                [matTooltipPosition]="'below'"
                                matTooltip="{{ 'common.selection_offer_time.tooltip' | translate }}"
                            ></mat-icon>

                            <mat-error *ngIf="selectionOfferTime.invalid" class="alert alert-danger">
                                <mat-error *ngIf="selectionOfferTime.errors.required">
                                    {{ 'common.validation.required' | translate }}
                                </mat-error>
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <!--- collect notification time --->
                    <div class="form-entry">
                        <div class="checkbox-wrapper">
                            <mat-checkbox
                                id="collectNotificationTime"
                                #collectNotificationTime="ngModel"
                                class="input-entry checkbox-container"
                                name="collectNotificationTime"
                                [(ngModel)]="product.collectNotificationTime"
                                data-automation="collectNotificationTime"
                                data-url-persist
                            >
                                {{ 'common.collect_notification_time' | translate }}
                            </mat-checkbox>
                            <mat-icon
                                matSuffix
                                svgIcon="enelx:general-info"
                                data-automation="collectNotificationTimeTooltip"
                                class="tooltip-icon"
                                [matTooltipPosition]="'below'"
                                matTooltip="{{ 'common.collect_notification_time.tooltip' | translate }}"
                            ></mat-icon>
                        </div>
                    </div>

                    <!--- default dispatch duration --->
                    <div class="form-entry">
                        <mat-form-field appearance="outline" floatLabel="always">
                            <mat-label>{{ 'common.default_dispatch_duration' | translate }}</mat-label>

                            <input
                                matInput
                                id="defaultDispatchDuration"
                                type="number"
                                step="1"
                                #defaultDispatchDuration="ngModel"
                                name="defaultDispatchDuration"
                                placeholder="0"
                                maxlength="16"
                                [(ngModel)]="product.defaultDispatchDuration"
                                data-automation="defaultDispatchDuration"
                                data-url-persist
                                required
                            />

                            <mat-icon
                                matSuffix
                                svgIcon="enelx:general-info"
                                data-automation="defaultDispatchDurationTooltip"
                                class="tooltip-icon"
                                [matTooltipPosition]="'below'"
                                matTooltip="{{ 'common.default_dispatch_duration.tooltip' | translate }}"
                            ></mat-icon>

                            <mat-error *ngIf="defaultDispatchDuration.invalid" class="alert alert-danger">
                                <mat-error *ngIf="defaultDispatchDuration.errors.required">
                                    <span class="input-title"></span>
                                    <span class="input-entry">{{ 'common.validation.required' | translate }}</span>
                                </mat-error>
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <!--- control timeout --->
                    <div class="form-entry">
                        <mat-form-field appearance="outline" floatLabel="always">
                            <mat-label>{{ 'common.control_timeout' | translate }}</mat-label>

                            <input
                                matInput
                                id="controlTimeout"
                                #controlTimeout="ngModel"
                                type="number"
                                step="1"
                                name="controlTimeout"
                                placeholder="0"
                                maxlength="16"
                                [(ngModel)]="product.controlTimeout"
                                data-automation="controlTimeout"
                                data-url-persist
                                required
                            />

                            <mat-icon
                                matSuffix
                                svgIcon="enelx:general-info"
                                data-automation="controlTimeoutTooltip"
                                class="tooltip-icon"
                                [matTooltipPosition]="'below'"
                                matTooltip="{{ 'common.control_timeout.tooltip' | translate }}"
                            ></mat-icon>

                            <mat-error *ngIf="controlTimeout.invalid" class="alert alert-danger">
                                <mat-error *ngIf="controlTimeout.errors.required">
                                    <span class="input-title"></span>
                                    <span>{{ 'common.validation.required' | translate }}</span>
                                </mat-error>
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <ng-container
                        ngModelGroup="minMaxPerformanceTargets"
                        #minMaxPerformanceTargets="ngModelGroup"
                        minMaxValidator
                        [minControl]="minPerformanceTarget"
                        [maxControl]="maxPerformanceTarget"
                    >
                        <!--- min performance target --->
                        <div class="form-entry">
                            <mat-form-field appearance="outline" floatLabel="always">
                                <mat-label>{{ 'common.min_performance_target' | translate }}</mat-label>

                                <input
                                    matInput
                                    id="minPerformanceTarget"
                                    type="number"
                                    min="0"
                                    step="1"
                                    #minPerformanceTarget="ngModel"
                                    name="minPerformanceTarget"
                                    placeholder="100"
                                    maxlength="16"
                                    [(ngModel)]="product.performanceTargetMin"
                                    data-automation="minPerformanceTarget"
                                    data-url-persist
                                    required
                                />

                                <mat-icon
                                    matSuffix
                                    svgIcon="enelx:general-info"
                                    data-automation="minPerformanceTargetTooltip"
                                    class="tooltip-icon"
                                    [matTooltipPosition]="'below'"
                                    matTooltip="{{ 'common.performance_target.tooltip' | translate }}"
                                ></mat-icon>

                                <mat-error *ngIf="minPerformanceTarget.invalid" class="alert alert-danger">
                                    <mat-error *ngIf="minPerformanceTarget.errors.required">
                                        <span class="input-title"></span>
                                        <span class="input-entry">{{ 'common.validation.required' | translate }}</span>
                                    </mat-error>
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <!--- max performance target --->
                        <div class="form-entry">
                            <mat-form-field appearance="outline" floatLabel="always">
                                <mat-label>{{ 'common.max_performance_target' | translate }}</mat-label>

                                <input
                                    matInput
                                    id="maxPerformanceTarget"
                                    type="number"
                                    min="0"
                                    step="1"
                                    #maxPerformanceTarget="ngModel"
                                    name="maxPerformanceTarget"
                                    placeholder="115"
                                    maxlength="16"
                                    [(ngModel)]="product.performanceTargetMax"
                                    data-automation="maxPerformanceTarget"
                                    data-url-persist
                                    [required]="product.targetType === 'RANGE'"
                                />

                                <mat-icon
                                    matSuffix
                                    svgIcon="enelx:general-info"
                                    data-automation="maxPerformanceTargetTooltip"
                                    class="tooltip-icon"
                                    [matTooltipPosition]="'below'"
                                    matTooltip="{{ 'common.performance_target.tooltip' | translate }}"
                                ></mat-icon>

                                <mat-error *ngIf="maxPerformanceTarget.invalid" class="alert alert-danger">
                                    <mat-error *ngIf="maxPerformanceTarget.errors.required">
                                        <span class="input-title"></span>
                                        <span class="input-entry">{{ 'common.validation.required' | translate }}</span>
                                    </mat-error>
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <mat-error class="group-error" *ngIf="minMaxPerformanceTargets.invalid">
                            <mat-error
                                *ngIf="minMaxPerformanceTargets.errors && minMaxPerformanceTargets.errors.minMaxValid"
                            >
                                <span class="input-title"></span>
                                <span class="input-entry" data-automation="validationMessage">{{
                                    'common.errors.max_min_val' | translate
                                }}</span>
                            </mat-error>
                        </mat-error>
                    </ng-container>

                    <!--- Eb notification group sync --->
                    <div class="form-entry">
                        <mat-form-field appearance="outline" floatLabel="always">
                            <mat-label>{{ 'common.eb_group_sync_schedule' | translate }}</mat-label>

                            <mat-select
                                id="ebGroupSyncSchedule"
                                name="ebGroupSyncSchedule"
                                #ebGroupSyncSchedule="ngModel"
                                [(ngModel)]="product.ebGroupSyncSchedule"
                                data-automation="ebGroupSyncSelector"
                                data-url-persist
                                appSelectValid
                                placeholder="{{ 'product.create.placeholder.eb_group_sync_schedule' | translate }}"
                            >
                                <mat-option [value]="'do_not_sync'" id="do_not_sync">
                                    {{ 'common.eb_group_sync_schedule.not_sync' | translate }}
                                </mat-option>
                                <mat-option
                                    *ngFor="let cronOption of cronOptions"
                                    [value]="cronOption.value"
                                    id="{{ cronOption.code }}"
                                >
                                    {{ cronOption.displayLabel | translate }}
                                </mat-option>
                            </mat-select>

                            <mat-icon
                                matSuffix
                                svgIcon="enelx:general-info"
                                data-automation="notificationGroupSyncScheduleTooltip"
                                class="tooltip-icon"
                                [matTooltipPosition]="'below'"
                                matTooltip="{{ 'common.eb_group_sync_schedule.tooltip' | translate }}"
                            ></mat-icon>
                        </mat-form-field>
                    </div>

                    <!--- dispatch method --->
                    <div class="form-entry">
                        <mat-form-field appearance="outline" floatLabel="always">
                            <mat-label>{{ 'common.dispatch_method' | translate }}</mat-label>

                            <input
                                matInput
                                id="dispatchMethod"
                                #dispatchMethod="ngModel"
                                type="text"
                                name="dispatchMethod"
                                placeholder="{{ 'product.create.placeholder.dispatch_method' | translate }}"
                                maxlength="80"
                                [(ngModel)]="product.dispatchMethod"
                                data-automation="dispatchMethod"
                                data-url-persist
                                required
                            />

                            <mat-icon
                                matSuffix
                                svgIcon="enelx:general-info"
                                data-automation="dispatchMethodTooltip"
                                class="tooltip-icon"
                                [matTooltipPosition]="'below'"
                                matTooltip="{{ 'common.dispatch_method.tooltip' | translate }}"
                            ></mat-icon>

                            <mat-error *ngIf="dispatchMethod.invalid" class="alert alert-danger">
                                <mat-error *ngIf="dispatchMethod.errors.required">
                                    <span class="input-title"></span>
                                    <span>{{ 'common.validation.required' | translate }}</span>
                                </mat-error>
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <!-- Start / End Wrapper --->
                    <ng-container
                        ngModelGroup="StartEndDates"
                        #StartEndDates="ngModelGroup"
                        startEndDateValidator
                        [startControl]="StartDttm"
                        [endControl]="EndDttm"
                    >
                        <!--- start date --->
                        <div class="form-entry">
                            <mat-form-field appearance="outline" floatLabel="always">
                                <mat-label>{{ 'common.start_date' | translate }}</mat-label>

                                <mat-icon
                                    matPrefix
                                    svgIcon="enelx:general-info"
                                    data-automation="startDateTooltip"
                                    class="tooltip-icon"
                                    [matTooltipPosition]="'below'"
                                    matTooltip="{{ 'common.schedule.start_date.tooltip' | translate }}"
                                ></mat-icon>

                                <input
                                    matInput
                                    [matDatepicker]="StartDate"
                                    #StartDttm="ngModel"
                                    [(ngModel)]="product.startDttm"
                                    required
                                    name="StartDttm"
                                    id="StartDttm"
                                    placeholder="{{ 'product.create.placeholder.select_date' | translate }}"
                                    data-automation="StartDttm"
                                    data-url-persist
                                />
                                <mat-datepicker-toggle matSuffix [for]="StartDate">
                                    <mat-icon
                                        matDatepickerToggleIcon
                                        svgIcon="enelx:calendar"
                                        class="mat-form-icon"
                                        data-automation="StartDttm_icon"
                                    >
                                    </mat-icon>
                                </mat-datepicker-toggle>
                                <mat-datepicker #StartDate startView="month"></mat-datepicker>

                                <mat-error *ngIf="StartDttm.invalid" class="alert alert-danger">
                                    <mat-error *ngIf="StartDttm.errors.required">
                                        {{ 'common.validation.required' | translate }}
                                    </mat-error>
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <!--- end date --->
                        <div class="form-entry">
                            <mat-form-field appearance="outline" floatLabel="always">
                                <mat-label>{{ 'common.end_date' | translate }}</mat-label>
                                <mat-icon
                                    matPrefix
                                    svgIcon="enelx:general-info"
                                    data-automation="endDateTooltip"
                                    class="tooltip-icon"
                                    [matTooltipPosition]="'below'"
                                    matTooltip="{{ 'common.schedule.end_date.tooltip' | translate }}"
                                ></mat-icon>
                                <input
                                    matInput
                                    [matDatepicker]="EndDate"
                                    #EndDttm="ngModel"
                                    [(ngModel)]="product.endDttm"
                                    required
                                    name="EndDttm"
                                    id="EndDttm"
                                    placeholder="{{ 'product.create.placeholder.select_date' | translate }}"
                                    data-automation="EndDttm"
                                    data-url-persist
                                />
                                <mat-datepicker-toggle matSuffix [for]="EndDate">
                                    <mat-icon
                                        matDatepickerToggleIcon
                                        svgIcon="enelx:calendar"
                                        class="mat-form-icon"
                                        data-automation="EndDttm_icon"
                                    >
                                    </mat-icon>
                                </mat-datepicker-toggle>
                                <mat-datepicker #EndDate startView="month"></mat-datepicker>

                                <mat-error *ngIf="EndDttm.invalid" class="alert alert-danger">
                                    <mat-error *ngIf="EndDttm.errors.required">
                                        {{ 'common.validation.required' | translate }}
                                    </mat-error>
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <mat-error class="group-error" *ngIf="StartEndDates.invalid">
                            <mat-error
                                *ngIf="StartEndDates.errors && StartEndDates.errors.startEndDateValid"
                            >
                                <span class="input-title"></span>
                                <span class="input-entry" data-automation="validationMessage">{{
                                    'common.errors.start_end_date' | translate
                                }}</span>
                            </mat-error>
                        </mat-error>
                    </ng-container>

                    <mat-grid-list cols="2" rowHeight="30px">
                        <!--- allow flexible asset overlap --->
                        <mat-grid-tile>
                            <div class="checkbox-wrapper">
                                <mat-checkbox
                                    id="allowFlexibleAssetOverlap"
                                    #allowFlexibleAssetOverlap="ngModel"
                                    class="input-entry"
                                    name="allowFlexibleAssetOverlap"
                                    [(ngModel)]="product.allowFlexibleAssetOverlap"
                                    data-automation="allowFlexibleAssetOverlap"
                                    data-url-persist
                                >
                                    {{ 'common.allow_flexible_asset_overlap' | translate }}
                                    <mat-icon
                                        matSuffix
                                        svgIcon="enelx:general-info"
                                        data-automation="allowFlexibleAssetOverlapTooltip"
                                        class="tooltip-icon"
                                        [matTooltipPosition]="'below'"
                                        matTooltip="{{ 'common.allow_flexible_asset_overlap.tooltip' | translate }}"
                                    ></mat-icon>
                                </mat-checkbox>
                            </div>
                        </mat-grid-tile>
                        <mat-grid-tile>
                            <!--- implement registration limits --->
                            <div class="checkbox-wrapper">
                                <mat-checkbox
                                    id="implementRegistrationLimits"
                                    #implementRegistrationLimits="ngModel"
                                    class="input-entry"
                                    name="implementRegistrationLimits"
                                    [(ngModel)]="product.implementRegistrationLimits"
                                    data-automation="implementRegistrationLimits"
                                    data-url-persist
                                >
                                    {{ 'common.implement_registration_limits' | translate }}
                                </mat-checkbox>
                                <mat-icon
                                    matSuffix
                                    svgIcon="enelx:general-info"
                                    data-automation="implementRegistrationLimitsTooltip"
                                    class="tooltip-icon"
                                    [matTooltipPosition]="'below'"
                                    matTooltip="{{ 'common.implement_registration_limits.tooltip' | translate }}"
                                ></mat-icon>
                            </div>
                        </mat-grid-tile>
                        <mat-grid-tile>
                            <!--- site list event notifications --->
                            <div class="checkbox-wrapper">
                                <mat-checkbox
                                    id="siteListEventNotifications"
                                    #siteListEventNotifications="ngModel"
                                    class="input-entry"
                                    name="siteListEventNotifications"
                                    [(ngModel)]="product.dispatchConf.siteListEventNotifications"
                                    data-automation="siteListEventNotifications"
                                    data-url-persist
                                >
                                    {{ 'common.site_list_event_notifications' | translate }}
                                </mat-checkbox>
                                <mat-icon
                                    matSuffix
                                    svgIcon="enelx:general-info"
                                    data-automation="siteListEventNotificationsTooltip"
                                    class="tooltip-icon"
                                    [matTooltipPosition]="'below'"
                                    matTooltip="{{ 'common.site_list_event_notifications.tooltip' | translate }}"
                                ></mat-icon>
                            </div>
                        </mat-grid-tile>
                        <mat-grid-tile>
                            <!--- site list non-event notifications --->
                            <div class="checkbox-wrapper">
                                <mat-checkbox
                                    id="siteListNonEventNotifications"
                                    #siteListNonEventNotifications="ngModel"
                                    class="input-entry"
                                    name="siteListNonEventNotifications"
                                    [(ngModel)]="product.dispatchConf.siteListNonEventNotifications"
                                    data-automation="siteListNonEventNotifications"
                                    data-url-persist
                                >
                                    {{ 'common.site_list_non_event_notifications' | translate }}
                                </mat-checkbox>
                                <mat-icon
                                    matSuffix
                                    svgIcon="enelx:general-info"
                                    data-automation="siteListNonEventNotificationsTooltip"
                                    class="tooltip-icon"
                                    [matTooltipPosition]="'below'"
                                    matTooltip="{{ 'common.site_list_non_event_notifications.tooltip' | translate }}"
                                ></mat-icon>
                            </div>
                        </mat-grid-tile>
                        <mat-grid-tile>
                            <!--- underfrequency product --->
                            <div class="checkbox-wrapper">
                                <mat-checkbox
                                    id="underfrequencyProduct"
                                    #underfrequencyProduct="ngModel"
                                    class="input-entry"
                                    name="underfrequencyProduct"
                                    [(ngModel)]="product.underfrequencyProduct"
                                    data-automation="underfrequencyProduct"
                                    data-url-persist
                                >
                                    {{ 'common.underfrequency_product' | translate }}
                                </mat-checkbox>
                                <mat-icon
                                    matSuffix
                                    svgIcon="enelx:general-info"
                                    data-automation="underfrequencyProductTooltip"
                                    class="tooltip-icon"
                                    [matTooltipPosition]="'below'"
                                    matTooltip="{{ 'common.underfrequency_product.tooltip' | translate }}"
                                ></mat-icon>
                            </div>
                        </mat-grid-tile>
                    </mat-grid-list>
                </div>
            </div>

            <!--- VIEW --->
            <div *ngIf="isViewMode && product" class="panel-content">
                <div class="form-group form-container">
                    <!--- selection type --->
                    <div class="form-entry">
                        <div class="label-container">
                            <mat-label class="info-title"
                                >{{ 'common.selection_type' | translate }}
                                <mat-icon
                                    svgIcon="enelx:general-info"
                                    data-automation="selectionTypeTooltip"
                                    class="tooltip-icon icon-container"
                                    [matTooltipPosition]="'below'"
                                    matTooltip="{{ 'common.selection_type.tooltip' | translate }}"
                                ></mat-icon>
                            </mat-label>
                        </div>
                        <mat-label class="input-entry" data-automation="selectionType">
                            {{ selectionTypeForProduct }}
                        </mat-label>
                    </div>

                    <!--- target type --->
                    <div class="form-entry">
                        <div class="label-container">
                            <mat-label class="info-title"
                                >{{ 'common.target_type' | translate }}
                                <mat-icon
                                    svgIcon="enelx:general-info"
                                    data-automation="targetTypeTooltip"
                                    class="tooltip-icon icon-container"
                                    [matTooltipPosition]="'below'"
                                    matTooltip="{{ 'common.target_type.tooltip' | translate }}"
                                ></mat-icon>
                            </mat-label>
                        </div>
                        <mat-label class="input-entry" data-automation="targetType">
                            {{ targetTypeForProduct }}
                        </mat-label>
                    </div>

                    <!--- expected capacity source --->
                    <div class="form-entry">
                        <div class="label-container">
                            <mat-label class="info-title"
                                >{{ 'common.expected_capacity_source' | translate }}
                                <mat-icon
                                    svgIcon="enelx:general-info"
                                    data-automation="localeTooltip"
                                    class="tooltip-icon icon-container"
                                    [matTooltipPosition]="'below'"
                                    matTooltip="{{ 'common.expected_capacity_source.tooltip' | translate }}"
                                ></mat-icon>
                            </mat-label>
                        </div>
                        <mat-label class="input-entry" data-automation="expectedCapacitySource">
                            {{ expectedCapacitySourceForProduct }}
                        </mat-label>
                    </div>

                    <!--- portfolio obligation source --->
                    <div class="form-entry">
                        <div class="label-container">
                            <mat-label class="info-title"
                                >{{ 'common.portfolio_obligation_source' | translate }}
                                <mat-icon
                                    svgIcon="enelx:general-info"
                                    data-automation="localeTooltip"
                                    class="tooltip-icon icon-container"
                                    [matTooltipPosition]="'below'"
                                    matTooltip="{{ 'common.portfolio_obligation_source.tooltip' | translate }}"
                                ></mat-icon>
                            </mat-label>
                        </div>
                        <mat-label class="input-entry" data-automation="portfolioObligationSource">
                            {{ portfolioObligationSourceForProduct }}
                        </mat-label>
                    </div>

                    <!--- min selection buffer --->
                    <div class="form-entry">
                        <div class="label-container">
                            <mat-label class="info-title"
                                >{{ 'common.min_selection_buffer' | translate }}
                                <mat-icon
                                    svgIcon="enelx:general-info"
                                    data-automation="minSelectionBufferTooltip"
                                    class="tooltip-icon icon-container"
                                    [matTooltipPosition]="'below'"
                                    matTooltip="{{ 'common.min_selection_buffer.tooltip' | translate }}"
                                ></mat-icon>
                            </mat-label>
                        </div>
                        <mat-label class="input-entry" data-automation="minSelectionBuffer">
                            {{ product.selectionBufferMin }}
                        </mat-label>
                    </div>

                    <!--- max selection buffer --->
                    <div class="form-entry">
                        <div class="label-container">
                            <mat-label class="info-title"
                                >{{ 'common.max_selection_buffer' | translate }}
                                <mat-icon
                                    svgIcon="enelx:general-info"
                                    data-automation="maxSelectionBufferTooltip"
                                    class="tooltip-icon icon-container"
                                    [matTooltipPosition]="'below'"
                                    matTooltip="{{ 'common.max_selection_buffer.tooltip' | translate }}"
                                ></mat-icon>
                            </mat-label>
                        </div>
                        <mat-label class="input-entry" data-automation="maxSelectionBuffer">
                            {{ product.selectionBufferMax }}
                        </mat-label>
                    </div>

                    <!--- selection upper target --->
                    <div class="form-entry">
                        <div class="label-container">
                            <mat-label class="info-title"
                                >{{ 'common.selection_upper_target' | translate }}
                                <mat-icon
                                    svgIcon="enelx:general-info"
                                    data-automation="selectionUpperTargetTooltip"
                                    class="tooltip-icon icon-container"
                                    [matTooltipPosition]="'below'"
                                    matTooltip="{{ 'common.selection_upper_target.tooltip' | translate }}"
                                ></mat-icon>
                            </mat-label>
                        </div>
                        <mat-label class="input-entry" data-automation="selectionUpperTarget">
                            {{ product.selectionUpperTarget }}
                        </mat-label>
                    </div>

                    <!--- scale capacity to obligation --->
                    <div class="form-entry">
                        <div class="label-container">
                            <mat-label class="info-title"
                                >{{ 'common.scale_capacity_to_obligation' | translate }}
                                <mat-icon
                                    svgIcon="enelx:general-info"
                                    data-automation="scaleCapacityToObligationTooltip"
                                    class="tooltip-icon icon-container"
                                    [matTooltipPosition]="'below'"
                                    matTooltip="{{ 'common.scale_capacity_to_obligation.tooltip' | translate }}"
                                ></mat-icon>
                            </mat-label>
                        </div>
                        <mat-label class="input-entry" data-automation="scaleCapacityToObligation">
                            {{ product.scaleCapacityToObligation }}
                        </mat-label>
                    </div>

                    <!--- selection offer time --->
                    <div class="form-entry">
                        <div class="label-container">
                            <mat-label class="info-title"
                                >{{ 'common.selection_offer_time' | translate }}
                                <mat-icon
                                    svgIcon="enelx:general-info"
                                    data-automation="selectionOfferTimeTooltip"
                                    class="tooltip-icon icon-container"
                                    [matTooltipPosition]="'below'"
                                    matTooltip="{{ 'common.selection_offer_time.tooltip' | translate }}"
                                ></mat-icon>
                            </mat-label>
                        </div>
                        <mat-label class="input-entry" data-automation="selectionOfferTime">
                            {{ selectionOfferTimeForProduct }}
                        </mat-label>
                    </div>

                    <!--- collect notification time --->
                    <div class="form-entry">
                        <mat-label class="info-title"
                            >{{ 'common.collect_notification_time' | translate }}
                            <mat-icon
                                svgIcon="enelx:general-info"
                                data-automation="collectNotificationTimeTooltip"
                                class="tooltip-icon icon-container"
                                [matTooltipPosition]="'below'"
                                matTooltip="{{ 'common.collect_notification_time.tooltip' | translate }}"
                            ></mat-icon>
                        </mat-label>
                        <mat-label class="input-entry" data-automation="collectNotificationTime">
                            {{ !!product.collectNotificationTime }}
                        </mat-label>
                    </div>

                    <!--- default dispatch duration --->
                    <div class="form-entry">
                        <div class="label-container">
                            <mat-label class="info-title"
                                >{{ 'common.default_dispatch_duration' | translate }}
                                <mat-icon
                                    svgIcon="enelx:general-info"
                                    data-automation="defaultDispatchDurationTooltip"
                                    class="tooltip-icon icon-container"
                                    [matTooltipPosition]="'below'"
                                    matTooltip="{{ 'common.default_dispatch_duration.tooltip' | translate }}"
                                ></mat-icon>
                            </mat-label>
                        </div>
                        <mat-label class="input-entry" data-automation="defaultDispatchDuration">
                            {{ product.defaultDispatchDuration }}
                        </mat-label>
                    </div>

                    <!--- control timeout --->
                    <div class="form-entry">
                        <div class="label-container">
                            <mat-label class="info-title"
                                >{{ 'common.control_timeout' | translate }}
                                <mat-icon
                                    svgIcon="enelx:general-info"
                                    data-automation="controlTimeoutTooltip"
                                    class="tooltip-icon icon-container"
                                    [matTooltipPosition]="'below'"
                                    matTooltip="{{ 'common.control_timeout.tooltip' | translate }}"
                                ></mat-icon>
                            </mat-label>
                        </div>
                        <mat-label class="input-entry" data-automation="controlTimeout">
                            {{ product.controlTimeout }}
                        </mat-label>
                    </div>

                    <!--- portfolio performanc target --->
                    <div class="form-entry">
                        <div class="label-container">
                            <mat-label class="info-title"
                                >{{ 'common.min_performance_target' | translate }}
                                <mat-icon
                                    svgIcon="enelx:general-info"
                                    data-automation="portfolioMinPerformanceTargetTooltip"
                                    class="tooltip-icon icon-container"
                                    [matTooltipPosition]="'below'"
                                    matTooltip="{{ 'common.performance_target.tooltip' | translate }}"
                                ></mat-icon>
                            </mat-label>
                        </div>
                        <mat-label class="input-entry" data-automation="portfolioMinPerformanceTarget">
                            {{ product.performanceTargetMin }}
                        </mat-label>
                    </div>

                    <!--- portfolio max performance target --->
                    <div class="form-entry">
                        <div class="label-container">
                            <mat-label class="info-title"
                                >{{ 'common.max_performance_target' | translate }}
                                <mat-icon
                                    svgIcon="enelx:general-info"
                                    data-automation="portfolioMaxPerformanceTargetTooltip"
                                    class="tooltip-icon icon-container"
                                    [matTooltipPosition]="'below'"
                                    matTooltip="{{ 'common.performance_target.tooltip' | translate }}"
                                ></mat-icon>
                            </mat-label>
                        </div>
                        <mat-label class="input-entry" data-automation="portfolioMaxPerformanceTarget">
                            {{ product.performanceTargetMax }}
                        </mat-label>
                    </div>

                    <!--- notification group sync schedule --->
                    <div class="form-entry">
                        <div class="label-container">
                            <mat-label class="info-title"
                                >{{ 'common.eb_group_sync_schedule' | translate }}
                                <mat-icon
                                    svgIcon="enelx:general-info"
                                    data-automation="notificationGroupSyncScheduleTooltip"
                                    class="tooltip-icon icon-container"
                                    [matTooltipPosition]="'below'"
                                    matTooltip="{{ 'common.eb_group_sync_schedule.tooltip' | translate }}"
                                ></mat-icon>
                            </mat-label>
                        </div>
                        <mat-label class="input-entry" data-automation="notificationGroupSyncSchedule">
                            {{
                                (product.ebGroupSyncSchedule && product.ebGroupSyncSchedule !== null) ||
                                product.ebGroupSyncSchedule === 'do_not_sync'
                                    ? (getCronOption(product.ebGroupSyncSchedule) | translate)
                                    : ('common.eb_group_sync_schedule.not_sync' | translate)
                            }}
                        </mat-label>
                    </div>

                    <!--- dispatch method --->
                    <div class="form-entry">
                        <div class="label-container">
                            <mat-label class="info-title"
                                >{{ 'common.dispatch_method' | translate }}
                                <mat-icon
                                    svgIcon="enelx:general-info"
                                    data-automation="dispatchMethodTooltip"
                                    class="tooltip-icon icon-container"
                                    [matTooltipPosition]="'below'"
                                    matTooltip="{{ 'common.dispatch_method.tooltip' | translate }}"
                                ></mat-icon>
                            </mat-label>
                        </div>
                        <mat-label class="input-entry" data-automation="dispatchMethod">
                            {{ product.dispatchMethod }}
                        </mat-label>
                    </div>

                    <!--- start date --->
                    <div class="form-entry">
                        <div class="label-container">
                            <mat-label class="info-title"
                                >{{ 'common.start_date' | translate }}
                                <mat-icon
                                    svgIcon="enelx:general-info"
                                    data-automation="startDateTooltip"
                                    class="tooltip-icon icon-container"
                                    [matTooltipPosition]="'below'"
                                    matTooltip="{{ 'common.schedule.start_date.tooltip' | translate }}"
                                ></mat-icon>
                            </mat-label>
                        </div>
                        <mat-label class="input-entry" data-automation="startDate">
                            {{ product.startDttm | date : 'shortDate' : '' : locale }}
                        </mat-label>
                    </div>

                    <!--- end date --->
                    <div class="form-entry">
                        <div class="label-container">
                            <mat-label class="info-title"
                                >{{ 'common.end_date' | translate }}
                                <mat-icon
                                    svgIcon="enelx:general-info"
                                    data-automation="endDateTooltip"
                                    class="tooltip-icon icon-container"
                                    [matTooltipPosition]="'below'"
                                    matTooltip="{{ 'common.schedule.end_date.tooltip' | translate }}"
                                ></mat-icon>
                            </mat-label>
                        </div>
                        <mat-label class="input-entry" data-automation="endDate">
                            {{ product.endDttm | date : 'shortDate' : '' : locale }}
                        </mat-label>
                    </div>

                    <div class="checkbox-container">
                        <!--- allow flexible asset overlap --->
                        <div class="checkbox-group">
                            <div class="checkbox-wrapper">
                                <div class="form-entry">
                                    <div class="label-container">
                                        <mat-label class="info-title"
                                            >{{ 'common.allow_flexible_asset_overlap' | translate }}
                                            <mat-icon
                                                svgIcon="enelx:general-info"
                                                data-automation="allowFlexibleAssetOverlapTooltip"
                                                class="tooltip-icon icon-container"
                                                [matTooltipPosition]="'below'"
                                                matTooltip="{{
                                                    'common.allow_flexible_asset_overlap.tooltip' | translate
                                                }}"
                                            ></mat-icon>
                                        </mat-label>
                                    </div>
                                    <mat-label class="input-entry" data-automation="allowFlexibleAssetOverlap">
                                        {{ !!product.allowFlexibleAssetOverlap }}
                                    </mat-label>
                                </div>
                            </div>

                            <!--- site list event notifications --->
                            <div class="checkbox-wrapper">
                                <div class="form-entry">
                                    <div class="label-container">
                                        <mat-label class="info-title"
                                            >{{ 'common.site_list_event_notifications' | translate }}
                                            <mat-icon
                                                svgIcon="enelx:general-info"
                                                data-automation="siteListEventNotificationsTooltip"
                                                class="tooltip-icon icon-container"
                                                [matTooltipPosition]="'below'"
                                                matTooltip="{{
                                                    'common.site_list_event_notifications.tooltip' | translate
                                                }}"
                                            ></mat-icon>
                                        </mat-label>
                                    </div>
                                    <mat-label class="input-entry" data-automation="siteListEventNotifications">
                                        {{ !!product.dispatchConf.siteListEventNotifications }}
                                    </mat-label>
                                </div>
                            </div>

                            <!--- underfrequency product --->
                            <div class="checkbox-wrapper">
                                <div class="form-entry">
                                    <div class="label-container">
                                        <mat-label class="info-title"
                                            >{{ 'common.underfrequency_product' | translate }}
                                            <mat-icon
                                                svgIcon="enelx:general-info"
                                                data-automation="underfrequencyProductTooltip"
                                                class="tooltip-icon icon-container"
                                                [matTooltipPosition]="'below'"
                                                matTooltip="{{ 'common.underfrequency_product.tooltip' | translate }}"
                                            ></mat-icon>
                                        </mat-label>
                                    </div>
                                    <mat-label class="input-entry" data-automation="underfrequencyProduct">
                                        {{ !!product.underfrequencyProduct }}
                                    </mat-label>
                                </div>
                            </div>
                        </div>

                        <div class="checkbox-group">
                            <!--- implement registration limits --->
                            <div class="checkbox-wrapper">
                                <div class="form-entry">
                                    <div class="label-container">
                                        <mat-label class="info-title"
                                            >{{ 'common.implement_registration_limits' | translate }}
                                            <mat-icon
                                                svgIcon="enelx:general-info"
                                                data-automation="implementRegistrationLimitsTooltip"
                                                class="tooltip-icon icon-container"
                                                [matTooltipPosition]="'below'"
                                                matTooltip="{{
                                                    'common.implement_registration_limits.tooltip' | translate
                                                }}"
                                            ></mat-icon>
                                        </mat-label>
                                    </div>
                                    <mat-label class="input-entry" data-automation="implementRegistrationLimits">
                                        {{ !!product.implementRegistrationLimits }}
                                    </mat-label>
                                </div>
                            </div>

                            <!--- site list non-event notifications --->
                            <div class="checkbox-wrapper">
                                <div class="form-entry">
                                    <div class="label-container">
                                        <mat-label class="info-title"
                                            >{{ 'common.site_list_non_event_notifications' | translate }}
                                            <mat-icon
                                                svgIcon="enelx:general-info"
                                                data-automation="siteListNonEventNotificationsTooltip"
                                                class="tooltip-icon icon-container"
                                                [matTooltipPosition]="'below'"
                                                matTooltip="{{
                                                    'common.site_list_non_event_notifications.tooltip' | translate
                                                }}"
                                            ></mat-icon>
                                        </mat-label>
                                    </div>
                                    <mat-label class="input-entry" data-automation="siteListNonEventNotifications">
                                        {{ !!product.dispatchConf.siteListNonEventNotifications }}
                                    </mat-label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </mat-expansion-panel>
    </ng-container>
</div>
