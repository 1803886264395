import { Directive, Input } from '@angular/core';
import { UntypedFormGroup, NG_VALIDATORS, Validator } from '@angular/forms';


@Directive({
    selector: '[minMaxEqualValidator]',
    providers: [{ provide: NG_VALIDATORS, useExisting: MinMaxEqualValidator, multi: true }],
  })
  export class MinMaxEqualValidator implements Validator {
    @Input('minEqualControl') minEqualControl: any;
    @Input('maxEqualControl') maxEqualControl: any;
    validate(theForm: UntypedFormGroup) {
      let isValid = true;
  
      if (
        Object.keys(theForm.controls).length > 0 &&
        this.minEqualControl.control.value !== null &&
        this.maxEqualControl.control.value !== null &&
        this.minEqualControl.control.value >= 0 &&
        this.maxEqualControl.control.value >= 0
      ) {
        isValid = this.minEqualControl.control.value <= this.maxEqualControl.control.value;
      }
  
      if (isValid) {
        return null;
      } else {
        return {
          minMaxEqualValid: {
            valid: false,
          },
        };
      }
    }
  }
  
  