import { Environment } from './environment.interface';
export { Environment } from './environment.interface';

export const environment: Environment = {
  production: false,
  brands: {
    enelx: {
      baseUrl: 'https://api.nominations.apps.enelx.com/v1',
      globalNavUrl: 'https://nav.payments.eis.enelx.com/v1/menu',
    },
  },
  defaults: {
    brand: 'enelx',
    locale: 'en-us',
  },
};
