import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';

// Translate
import { TranslateModule } from '@ngx-translate/core';
import { ApiService } from './services/api.service';
import { CookieService } from './services/cookie.service';
import { GlobalAlertService } from './services/global-alert.service';
import { ProductsService } from './services/products.service';
import { UserService } from './services/user.service';

@NgModule({
  declarations: [],
  exports: [BrowserModule, TranslateModule, FormsModule],
  providers: [ApiService, UserService, CookieService, ProductsService, GlobalAlertService],
})
export class SharedModule {}
