import { Component, OnInit, Input } from '@angular/core';
import { Locale } from '../../../../shared/model/locale.model';
import { Product } from '../../../../shared/model/product.model';
import { FormGroup } from '@angular/forms';
import { ControlContainer, NgForm } from '@angular/forms';

@Component({
  selector: 'app-product-dispatch-limits',
  templateUrl: './product-dispatch-limits.component.html',
  styleUrls: ['./product-dispatch-limits.component.scss', '../../../../shared/shared.styles.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class ProductDispatchLimitsComponent implements OnInit {
  @Input() mode: string;
  @Input() locale: Locale;
  @Input() product: Product;
  @Input() frm: FormGroup;

  constructor() {
    console.log('ProductDispatchLimitsComponent.constructor()');
  }

  ngOnInit(): void {
    console.log('ProductDispatchLimitsComponent.ngOnInit()');
  }

  get isEditMode() {
    return this.mode === 'edit';
  }

  get isCreateMode() {
    return this.mode === 'create';
  }

  get isViewMode() {
    return this.mode === 'view';
  }
}
