import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { Operator } from '../../shared/model/operator.model';
import { Locale, LocaleBaseConfig } from '../../shared/model/locale.model';
import { Timezone } from '../../shared/model/timezone.model';
import { CookieService as NgxCookieService } from 'ngx-shared-services';
import { ActivatedRoute, Router } from '@angular/router';
import { OperatorsService } from '../../shared/services/operators.service';
import { LocalesService } from '../../shared/services/locales.service';
import { TimezonesService } from '../../shared/services/timezones.service';
import { ContextSelectorService } from 'ngx-global-nav';
import { DeeplinksService } from '../../shared/services/deeplinks.service';
import { TranslateService } from '@ngx-translate/core';
import { Product } from '../../shared/model/product.model';
import { Program } from '../../shared/model/program.model';
import { NgxDeeplinkerService } from 'ngx-deeplinker';
import { SidenavService } from '../../shared/services/sidenav.service';
import { RefService } from '../../shared/services/ref.service';
import { Status } from '../../shared/model/status.model';
import { FormValidatorService } from '../../shared/services/form-validator.service';

@Component({
  selector: 'app-operator',
  templateUrl: './operator-details.component.html',
  styleUrls: ['../../shared/shared.styles.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class OperatorDetailsComponent implements OnInit {
  readonly EDIT = 'edit';
  readonly VIEW = 'view';
  readonly CREATE = 'create';

  CREATE_NEW_PRODUCT = 'Create New Operator';
  REQUIRED = 'required';
  clone = false;
  org = '';
  locales: Locale[];
  statuses: Status[];
  timezones: Timezone[];
  appPrefix = 'mkt';
  deeplinkUrl: string;
  deeplinkerData: any;
  DRGroups: any[];
  _operator: Operator;
  _programs: Program[];
  _products: Product[];
  cleanCloneLabelOnChild: boolean;
  private _mode: string;
  cloneLabel = ' Clone';
  @Input() frm;
  userLocale = 'en_US';
  shortDisplayLabel: '';
  shortDisplayLabelPlaceholder: '';

  constructor(
    private ngxCookieService: NgxCookieService,
    private ngxDeeplinkerService: NgxDeeplinkerService,
    private route: ActivatedRoute,
    private operatorsService: OperatorsService,
    private localesService: LocalesService,
    private timezonesService: TimezonesService,
    private orgSelectorService: ContextSelectorService,
    private deeplinksInternalService: DeeplinksService,
    private translateService: TranslateService,
    private router: Router,
    private sidenavService: SidenavService,
    private refService: RefService,
    private formValidatorService: FormValidatorService,
  ) {
    this.userLocale = this.ngxCookieService.getCookie('locale') || 'en_US';
    this.route.queryParams.subscribe(async (queryParams) => {
      if (queryParams.clone) {
        this.clone = true;
      }
    });
    this._operator = Operator.create({});

    this.timezones = timezonesService.timezones;

    this.deeplinksInternalService.deeplink$.subscribe(async (deeplinkUrl) => {
      this.deeplinkUrl = deeplinkUrl;
    });

    this.translateService.get('operator.create.button.create_new_operator').subscribe((result: string) => {
      this.CREATE_NEW_PRODUCT = result;
      this.REQUIRED = this.translateService.instant('common.validation.required');
    });

    this.shortDisplayLabel = this.translateService.instant('operator.create.short_display_label');
    this.shortDisplayLabelPlaceholder = this.translateService.instant(
      'operator.create.placeholder.short_display_label',
    );

    this.operatorsService.DRGroups$.subscribe((DRGroups) => {
      this.DRGroups = DRGroups;
    });

    this.refService.getStatuses();
    this.localesService.fetchLocales();
    this.timezonesService.fetchTimezones();
    this.deeplinksInternalService.setMarketAdminUrl();
    this.operatorsService.fetchDrGroups();
  }

  @Input() mode: string;

  multiLocaleConfig: LocaleBaseConfig = {
    supportedLocales: [new Locale()],
    locales: [new Locale()],
    defaultLocale: new Locale(),
    id: '',
    displayLabelKey: 'displayLabel',
    localeKey: 'localeName',
  };

  operatorLabel: '';
  operatorPlaceholder: '';
  descriptionLabel: '';
  descriptionPlaceholder: '';

  @Input('cleanCloneLabel')
  set cleanCloneLabel(cleanCloneLabel: any) {
    this.cleanCloneLabelOnChild = cleanCloneLabel;
  }
  @Input()
  get operator() {
    return this._operator;
  }

  set operator(operator: Operator) {
    this._operator = operator;
  }

  get programs() {
    return this._programs;
  }

  get products() {
    return this._products;
  }

  get isEditMode() {
    return this.mode === this.EDIT;
  }

  get isCreateMode() {
    return this.mode === this.CREATE;
  }

  get isViewMode() {
    return this.mode === this.VIEW;
  }
  ngOnChanges(changes: SimpleChanges) {
    const mainDataChange = changes.cleanCloneLabel?.currentValue;
    if (mainDataChange) {
      this.cleanCloneLabelOnChild = mainDataChange;
      if (this.cleanCloneLabelOnChild) {
        this.cloneLabel = '';
      }
    }
  }

  ngOnInit() {
    if (this.isCreateMode) {
      const deeplinkerData = this.ngxDeeplinkerService.getReturnData(this.appPrefix);
      if (deeplinkerData) {
        if (Object.keys(deeplinkerData).length) {
          const { displayLabel } = deeplinkerData;
          this._operator.displayLabels[this._operator.locale] = displayLabel || '';
        }
      }
    }

    this.operatorsService.operator$.subscribe(this.getNext());

    this.localesService.locales$.subscribe((locales) => {
      if (locales?.length) {
        this.locales = locales;
        const locale = this.localesService.checkForLocale(this.userLocale, locales) || locales[0];
        this.multiLocaleConfig.defaultLocale = locale;

        if (this.isCreateMode) {
          this.multiLocaleConfig.supportedLocales = [locale];
          this.operator.locale = this.userLocale;
        } else if (this.isEditMode) {
          this.buildSupportedLocales();
        }
      }

      this.refService.statuses$.subscribe((statuses) => {
        if (statuses && statuses.length > 0) {
          this.statuses = statuses;
        }
      });
    });

    this.operatorLabel = this.translateService.instant('operator.create.operator_display_label');
    this.operatorPlaceholder = this.translateService.instant('operator.create.placeholder.operator_name');

    this.descriptionLabel = this.translateService.instant('common.description');
    this.descriptionPlaceholder = this.translateService.instant('operator.create.placeholder.operator_description');
  }

  private getNext() {
    return (operator: Operator) => this.populateOperator(operator);
  }

  populateOperator(operatorData) {
    if (operatorData && operatorData.id) {
      this._operator = { ...operatorData };
      if (this.clone) {
        this._operator.operatorExternalReferenceId = '';
      }
      this._programs = this._operator.children || [];

      if (this._operator.children && this._operator.children.length > 0) {
        this._products = this._programs.reduce((products, child: Program) => {
          if (child.children && child.children.length > 0) {
            products.push(...child.children);
          }
          return products;
        }, Array<Product>());
      } else {
        this._products = [];
      }
      this.buildSupportedLocales();
      if (this.isEditMode) {
        setTimeout(() => {
          this.formValidatorService.triggerFormValidation(this.frm.form);
        }, 1000);
      }
    }
  }

  getTimezoneForOperator() {
    if (this.timezones.length > 0) {
      const timezone = this.timezones.find((timezone) => this._operator.timezone === timezone.timezoneName);
      if (timezone) {
        return timezone.displayLabel;
      }
    }
    return '-';
  }

  getLocaleForOperator() {
    if (this.locales?.length > 0) {
      const locale = this.locales.find((locale) => this._operator.locale === locale.localeName);
      if (locale) {
        return locale.displayLabel;
      }
    }
    return '-';
  }

  getStatusForOperator() {
    if (this.statuses && this.statuses.length > 0) {
      return (
        this.statuses.find((status) => status.code === this.operator.status)?.displayLabel || new Status().displayLabel
      );
    }

    return '-';
  }

  getDRGroupForOperator() {
    if (this.DRGroups && this.DRGroups.length > 0) {
      const DRGroup = this.DRGroups.find((DRGroup) => this._operator.drGroupId === DRGroup.id);
      if (DRGroup) {
        return DRGroup.displayLabel;
      }
    }
    return '-';
  }

  hasPrograms() {
    return this._programs && this._programs.length > 0;
  }

  hasProducts() {
    return this._products && this._products.length > 0;
  }

  routeToCreatePage(drType: string) {
    this.sidenavService.closeIfMobile();
    this.router.navigate([`create`], { queryParams: { drType } });
  }

  async handleSelectionChange() {
    if (this.multiLocaleConfig.supportedLocales.length === 0) {
      this.multiLocaleConfig.supportedLocales = [
        ...this.multiLocaleConfig.supportedLocales,
        this.multiLocaleConfig.defaultLocale,
      ];
    }
    await this.localesService.getLocaleSelectorChanged();
    if (this.isEditMode) {
      this.frm.form.controls['localeInputsForm_operator_display_labels']?.markAsDirty();
      this.frm.form.controls['localeInputsForm_description_display_labels']?.markAsDirty();
      this.frm.form.controls['localeInputsForm_short_display_labels']?.markAsDirty();
    }
  }

  limitLocaleSelectList(num: number, locale: Locale) {
    return (
      this.multiLocaleConfig.supportedLocales.length > num - 1 &&
      !this.multiLocaleConfig.supportedLocales.includes(locale)
    );
  }

  buildSupportedLocales() {
    if (this.multiLocaleConfig.supportedLocales && this.operator && this.locales) {
      this.multiLocaleConfig.supportedLocales = [];
      const keys = Object.keys(this.operator.displayLabels);
      for (const key of keys) {
        const localeFound = this.locales.find((locale) => locale.localeName === key);
        if (localeFound) {
          this.multiLocaleConfig.supportedLocales.push(localeFound);
        }
      }
    }
  }

  ngOnDestroy(): void {
    this.cloneLabel = '';
  }

  getShortDisplayLabel(): string {
    return this.localesService.getValueFromMultilocaleObject(
      this.operator.shortDisplayLabels,
      this.userLocale,
      this.operator.locale,
    );
  }

  getDescription(): string {
    return this.localesService.getValueFromMultilocaleObject(
      this.operator.descriptions,
      this.userLocale,
      this.operator.locale,
    );
  }

  getDisplayLabel(): string {
    return this.localesService.getValueFromMultilocaleObject(
      this.operator.displayLabels,
      this.userLocale,
      this.operator.locale,
    );
  }
}
