import { Injectable } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';

@Injectable()
export class SidenavService {
  private subject = new Subject<any>();

  private sidenav: MatSidenav;

  public setSidenav(sidenav: MatSidenav) {
    this.sidenav = sidenav;
  }

  public open() {
    return this.sidenav.open();
  }

  public close() {
    return this.sidenav.close();
  }

  public closeIfMobile() {
    if (this.sidenav.mode === 'over') {
      return this.sidenav.close();
    }
  }

  public toggle(): void {
    this.sidenav.toggle();
  }

  sendMessage(message: boolean) {
    this.subject.next({ flag: message });
  }

  clearMessages() {
    this.subject.next(null);
  }

  getMessage(): Observable<any> {
    return this.subject.asObservable();
  }
}
