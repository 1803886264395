import { Component, Input, OnInit } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { Program } from '../../shared/model/program.model';
import { ProgramsService } from '../../shared/services/programs.service';
import { LocalesService } from '../../shared/services/locales.service';
import { TimezonesService } from '../../shared/services/timezones.service';
import { Timezone } from '../../shared/model/timezone.model';
import { Locale } from '../../shared/model/locale.model';
import { CookieService as NgxCookieService } from 'ngx-shared-services';

@Component({
  selector: 'app-nested-program',
  templateUrl: './nested-program-details.component.html',
  styleUrls: ['./nested-program-details.component.scss', '../../shared/shared.styles.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class NestedProgramDetailsComponent {
  readonly EDIT = 'edit';
  readonly VIEW = 'view';
  readonly CREATE = 'create';
  panelOpenedState: boolean;
  locales: Locale[] = [];
  timezones: Timezone[] = [];
  userLocale = 'en_US';

  private _program: Program;
  private _index: number;
  private _data: Record<string, never>;
  private _mode: string;

  constructor(
    private programsService: ProgramsService,
    private router: Router,
    private localesService: LocalesService,
    private timezonesService: TimezonesService,
    private ngxCookieService: NgxCookieService
  ) {
    this.localesService.locales$.subscribe(locales => this.locales = locales);
    this.userLocale = this.ngxCookieService.getCookie('locale') || 'en_US';
    this.timezones = timezonesService.timezones;
  }

  @Input()
  set program(program: Program) {
    this._program = program;
  }

  get program() {
    return this._program;
  }

  @Input()
  set index(index: number) {
    this._index = index;
  }

  get index() {
    return this._index;
  }

  @Input()
  set data(data: Record<string, never>) {
    this._data = data;
  }

  get data() {
    return this._data;
  }

  @Input()
  set mode(mode: string) {
    this._mode = mode;
  }

  get mode() {
    return this._mode;
  }

  getDisplayLabels(): string {
    return this.localesService.getValueFromMultilocaleObject(this._program.displayLabels, this.userLocale, this._program.locale);
  }

  getDescriptions(): string {
    return this.localesService.getValueFromMultilocaleObject(this._program.descriptions, this.userLocale, this._program.locale);
  }

  get status() {
    if (this._program) {
      return this._program.status;
    }
  }

  get timezone() {
    if (this._program && this.timezones.length > 0) {
      const timezone = this.timezones.find((timezone) => this._program.timezone === timezone.timezoneName);
      if (timezone) {
        return timezone.displayLabel;
      }
    }
  }

  get locale() {
    if (this._program && this.locales.length > 0) {
      const locale = this.locales.find((locale) => this._program.locale === locale.localeName);
      if (locale) {
        return locale.displayLabel;
      }
    }
  }

  get isEditMode() {
    return this.mode === this.EDIT;
  }

  get isCreateMode() {
    return this.mode === this.CREATE;
  }

  get isViewMode() {
    return this.mode === this.VIEW;
  }

  handleViewDetails($e) {
    $e.stopPropagation();
    if (this._program) {
      this.router.navigate([`details/${this._program.id}/view`], { queryParams: { drType: this._program.drType } });
    }
  }

  addProgram() {
    if (this._program) {
      this.router.navigate([`details/${this._program.id}/create`], { queryParams: { drType: this._program.drType } });
    }
  }

  handleEditDetails() {
    if (this._program) {
      this.router.navigate([`details/${this._program.id}/edit`], { queryParams: { drType: this._program.drType } });
    }
  }

  deleteProgram() {
    if (this._program) {
      const { isDispatchableProgram } = this._program;
      this.programsService.deleteProgram(this._program.id, isDispatchableProgram);
    }
  }
}
