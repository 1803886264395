import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class DeeplinksService {
  deeplink$: BehaviorSubject<string>;

  constructor(private apiService: ApiService) {
    this.deeplink$ = new BehaviorSubject<string>('');
  }

  async setMarketAdminUrl() {
    try {
      const url = await this.apiService.get('deeplinks/markets/');
      this.deeplink$.next(url + '/create');
    } catch (err) {
      console.log('Could not load market admin url.', err);
    }
  }
}
