<div
    data-automation="generalInformationFieldsPanel"
    class="general-information-fields-panel"
    ngModelGroup="blueButtonFields"
>
    <ng-container>
        <!--- CREATE/EDIT --->
        <div *ngIf="(isCreateMode || isEditMode) && product" class="panel-content">
            <div class="form-group">
                <!--- program name --->
                <div class="form-entry">
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>{{ 'common.program' | translate }}</mat-label>
                        <mat-select
                            id="programId"
                            name="programId"
                            #programId="ngModel"
                            [ngClass]="{
                                failure: programId.invalid && (programId.dirty || programId.touched),
                                'create-select': true
                            }"
                            [(ngModel)]="product.programId"
                            [disabled]="isEditMode"
                            data-automation="programId"
                            data-url-persist
                            required
                            appSelectValid
                            placeholder="{{ 'product.create.placeholder.program' | translate }}"
                        >
                            <mat-optgroup *ngFor="let operator of operators" [label]="operator.displayLabel">
                                <mat-option
                                    *ngFor="let program of operator.children"
                                    [value]="program.id"
                                    id="program_{{ program.id }}"
                                >
                                    {{ program.displayLabel }}
                                </mat-option>
                            </mat-optgroup>
                        </mat-select>
                        <mat-error
                            *ngIf="programId.invalid && (programId.dirty || programId.touched)"
                            class="alert alert-danger"
                        >
                            <mat-error *ngIf="programId.errors.selectValid">
                                {{ 'common.validation.required' | translate }}
                            </mat-error>
                        </mat-error>
                    </mat-form-field>
                </div>

                <!--    Supported Locales  -->
                <div class="form-entry">
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>{{ 'common.supported_locales' | translate }}</mat-label>
                        <mat-select
                            id="supportedLocale"
                            name="supportedLocale"
                            #supportedLocale="ngModel"
                            [ngClass]="{
                                failure: supportedLocale.invalid && (supportedLocale.dirty || supportedLocale.touched),
                                'create-select': true,
                                'input-entry': true
                            }"
                            [(ngModel)]="multiLocaleConfig.supportedLocales"
                            (selectionChange)="handleSelectionChange()"
                            data-automation="supportedLocale"
                            required
                            placeholder="{{ 'common.supported_locales.placeholder' | translate }}"
                            multiple
                        >
                            <mat-option
                                [disabled]="limitLocaleSelectList(5, locale)"
                                *ngFor="let locale of locales"
                                [value]="locale"
                                >{{ locale.displayLabel }}</mat-option
                            >
                        </mat-select>
                        <mat-icon
                            matSuffix
                            svgIcon="enelx:general-info"
                            data-automation="supportedLocaleTooltip"
                            class="tooltip-icon"
                            [matTooltipPosition]="'below'"
                            matTooltip="{{ 'common.supported_locales.tooltip' | translate }}"
                        ></mat-icon>
                        <mat-error *ngIf="supportedLocale.invalid">
                            <mat-error *ngIf="supportedLocale.errors.pattern">
                                {{ 'common.validation.required' | translate }}
                            </mat-error>
                        </mat-error>
                    </mat-form-field>
                </div>

                <!--- locale --->
                <div class="form-entry">
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>{{ 'common.locale' | translate }}</mat-label>

                        <mat-select
                            id="locale"
                            name="defaultLocale"
                            #localeId="ngModel"
                            [ngClass]="{
                                failure: localeId.invalid && (localeId.dirty || localeId.touched),
                                'create-select': true
                            }"
                            [(ngModel)]="product.defaultLocale"
                            data-automation="localeSelector"
                            data-url-persist
                            required
                            appSelectValid
                            placeholder="{{ 'common.locale_selector' | translate }}"
                        >
                            <mat-option
                                *ngFor="let locale of multiLocaleConfig.supportedLocales"
                                [value]="locale.localeName"
                            >
                                {{ locale.displayLabel }}
                            </mat-option>
                        </mat-select>
                        <mat-icon
                            matSuffix
                            svgIcon="enelx:general-info"
                            data-automation="localeTooltip"
                            class="tooltip-icon"
                            [matTooltipPosition]="'below'"
                            matTooltip="{{ 'common.locale.tooltip' | translate }}"
                        ></mat-icon>
                        <mat-error
                            *ngIf="localeId.invalid && (localeId.dirty || localeId.touched)"
                            class="alert alert-danger"
                        >
                            <mat-error *ngIf="localeId.errors.selectValid">
                                {{ 'common.validation.required' | translate }}
                            </mat-error>
                        </mat-error>
                    </mat-form-field>
                </div>

                <!--- product name --->

                <div class="form-entry">
                    <multi-locale-input
                        data-automation="productName"
                        name="displayLabels"
                        type="text"
                        [inputLabel]="productLabel"
                        [locales]="multiLocaleConfig.supportedLocales"
                        [(ngModel)]="product.displayLabels"
                        [displayLabelKey]="multiLocaleConfig.displayLabelKey"
                        [localeKey]="multiLocaleConfig.localeKey"
                        [defaultLocale]="multiLocaleConfig.defaultLocale"
                        [placeholderText]="productPlaceholder"
                        [required]="true"
                        [id]="'product_display_labels'"
                    >
                    </multi-locale-input>
                </div>

                <!--- short display label --->

                <div class="form-entry">
                    <multi-locale-input
                        name="shortDisplayLabels"
                        [inputLabel]="shortDisplayLabel"
                        [locales]="multiLocaleConfig.supportedLocales"
                        [(ngModel)]="product.shortDisplayLabels"
                        [displayLabelKey]="multiLocaleConfig.displayLabelKey"
                        [localeKey]="multiLocaleConfig.localeKey"
                        [defaultLocale]="multiLocaleConfig.defaultLocale"
                        [placeholderText]="shortDisplayLabelPlaceholder"
                        [required]="false"
                        [id]="'short_display_labels'"
                    >
                    </multi-locale-input>
                </div>

                <!--- description --->
                <div class="form-entry-full-width pad-2-b">
                    <multi-locale-input
                        name="desscriptionLabels"
                        data-automation="description"
                        [inputLabel]="descriptionLabel"
                        [locales]="multiLocaleConfig.supportedLocales"
                        [(ngModel)]="product.descriptions"
                        [displayLabelKey]="multiLocaleConfig.displayLabelKey"
                        [localeKey]="multiLocaleConfig.localeKey"
                        [defaultLocale]="multiLocaleConfig.defaultLocale"
                        [placeholderText]="descriptionPlaceholder"
                        [required]="false"
                        [id]="'product_descriptions'"
                    >
                    </multi-locale-input>
                </div>

                <!--- timezone --->
                <div class="form-entry">
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>{{ 'common.timezone' | translate }}</mat-label>
                        <mat-select
                            id="timezone"
                            name="timezoneId"
                            #timezoneId="ngModel"
                            [ngClass]="{
                                failure: timezoneId.invalid && (timezoneId.dirty || timezoneId.touched),
                                'create-select': true
                            }"
                            [(ngModel)]="product.timezone"
                            data-automation="timezoneSelector"
                            data-url-persist
                            required
                            appSelectValid
                            placeholder="{{ 'common.timezone_selector' | translate }}"
                        >
                            <mat-option *ngFor="let timezone of timezones" [value]="timezone.timezoneName">
                                {{ timezone.displayLabel }}
                            </mat-option>
                        </mat-select>
                        <mat-icon
                            matSuffix
                            svgIcon="enelx:general-info"
                            data-automation="timezoneTooltip"
                            class="tooltip-icon"
                            [matTooltipPosition]="'below'"
                            matTooltip="{{ 'common.timezone.tooltip' | translate }}"
                        ></mat-icon>
                        <mat-error
                            *ngIf="timezoneId.invalid && (timezoneId.dirty || timezoneId.touched)"
                            class="alert alert-danger"
                        >
                            <mat-error *ngIf="timezoneId.errors.selectValid">
                                {{ 'common.validation.required' | translate }}
                            </mat-error>
                        </mat-error>
                    </mat-form-field>
                </div>

                <!--- status --->
                <div class="form-entry">
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>{{ 'common.status' | translate }}</mat-label>

                        <mat-select
                            id="status"
                            name="statusId"
                            #statusId="ngModel"
                            [ngClass]="{
                                failure: statusId.invalid && (statusId.dirty || statusId.touched),
                                'create-select': true
                            }"
                            [(ngModel)]="product.status"
                            data-automation="statusSelector"
                            data-url-persist
                            required
                            appSelectValid
                            placeholder="{{ 'common.status_selector' | translate }}"
                        >
                            <mat-option *ngFor="let status of statuses" [value]="status.code">
                                {{ status.displayLabel | translate }}
                            </mat-option>
                        </mat-select>

                        <mat-icon
                            matSuffix
                            svgIcon="enelx:general-info"
                            data-automation="statusTooltip"
                            class="tooltip-icon"
                            [matTooltipPosition]="'below'"
                            matTooltip="{{ 'common.status.tooltip' | translate }}"
                        ></mat-icon>

                        <mat-error
                            *ngIf="statusId.invalid && (statusId.dirty || statusId.touched)"
                            class="alert alert-danger"
                        >
                            <mat-error *ngIf="statusId.errors.selectValid">
                                {{ 'common.validation.required' | translate }}
                            </mat-error>
                        </mat-error>
                    </mat-form-field>
                </div>

                <!--- ecrm id --->
                <div class="form-entry">
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>{{ 'common.product_ecrm_id' | translate }}</mat-label>

                        <input
                            matInput
                            id="ecrmId"
                            #ecrmId="ngModel"
                            type="text"
                            [ngClass]="{
                                failure: ecrmId.invalid && (ecrmId.dirty || ecrmId.touched)
                            }"
                            name="ecrmId"
                            placeholder="{{ 'product.create.placeholder.ecrm_id' | translate }}"
                            maxlength="80"
                            [(ngModel)]="product.ecrmId"
                            data-automation="ecrmId"
                            [pattern]="ecrmPattern"
                            data-url-persist
                        />
                        <mat-icon
                            matSuffix
                            svgIcon="enelx:general-info"
                            data-automation="ecrmIdTooltip"
                            class="tooltip-icon"
                            [matTooltipPosition]="'below'"
                            matTooltip="{{ 'common.product_ecrm_id.tooltip' | translate }}"
                        ></mat-icon>
                        <mat-error
                            *ngIf="ecrmId.invalid && (ecrmId.dirty || ecrmId.touched)"
                            class="alert alert-danger"
                        >
                            <mat-error *ngIf="ecrmId.errors.pattern">
                                <span class="input-entry">{{ 'common.errors.ecrm_id' | translate }}</span>
                            </mat-error>
                        </mat-error>
                    </mat-form-field>
                </div>

                <!--- external reference id --->
                <div class="form-entry">
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>{{ 'common.product_external_reference_id' | translate }}</mat-label>

                        <input
                            matInput
                            id="productExternalReferenceId"
                            #productExternalReferenceId="ngModel"
                            type="text"
                            [ngClass]="{ failure: productExternalReferenceId.invalid }"
                            name="productExternalReferenceId"
                            placeholder="{{ 'product.create.placeholder.product_id' | translate }}"
                            maxlength="80"
                            [(ngModel)]="product.productExternalReferenceId"
                            data-automation="productExternalReferenceId"
                            data-url-persist
                            required
                        />
                        <mat-icon
                            matSuffix
                            svgIcon="enelx:general-info"
                            data-automation="externalReferenceIdTooltip"
                            class="tooltip-icon"
                            [matTooltipPosition]="'below'"
                            matTooltip="{{ 'common.product_external_reference_id.tooltip' | translate }}"
                        ></mat-icon>
                        <mat-error *ngIf="productExternalReferenceId.invalid" class="alert alert-danger">
                            <mat-error *ngIf="productExternalReferenceId.errors.required">
                                <span class="input-title"></span>
                                <span>{{ 'common.validation.required' | translate }}</span>
                            </mat-error>
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
        </div>

        <!--- VIEW --->
        <div *ngIf="isViewMode && product" class="panel-content">
            <div class="form-group">
                <!--- type --->
                <div class="form-entry">
                    <mat-label class="info-title">{{ 'common.market_entity_type' | translate }}</mat-label>
                    <mat-label class="input-entry" data-automation="selectionType">
                        {{ product.drType }}
                    </mat-label>
                </div>

                <!--- program --->
                <div class="form-entry">
                    <mat-label class="info-title">{{ 'common.program' | translate }}</mat-label>
                    <mat-label class="input-entry" data-automation="program">
                        {{ programDisplayLabel }}
                    </mat-label>
                </div>

                <!--- locale --->
                <div class="form-entry">
                    <div class="label-container">
                        <mat-label class="info-title"
                            >{{ 'common.locale' | translate }}
                            <mat-icon
                                svgIcon="enelx:general-info"
                                data-automation="localeTooltip"
                                class="tooltip-icon icon-container"
                                [matTooltipPosition]="'below'"
                                matTooltip="{{ 'common.locale.tooltip' | translate }}"
                            ></mat-icon>
                        </mat-label>
                    </div>
                    <mat-label class="input-entry" data-automation="locale">
                        {{ localeForProduct }}
                    </mat-label>
                </div>

                <!--- product name --->
                <div class="form-entry">
                    <div class="label-container">
                        <mat-label class="info-title"
                            >{{ 'common.product_display_label' | translate }}
                            <mat-icon
                                svgIcon="enelx:general-info"
                                data-automation="nameTooltip"
                                class="tooltip-icon icon-container"
                                [matTooltipPosition]="'below'"
                                matTooltip="{{ 'common.product_display_label' | translate }}"
                            ></mat-icon>
                        </mat-label>
                    </div>
                    <mat-label class="input-entry" data-automation="productName">
                        {{ displayLabel }}
                    </mat-label>
                </div>

                <!--- short display label --->
                <div class="form-entry">
                    <div class="label-container">
                        <mat-label class="info-title"
                            >{{ 'product.create.short_display_label' | translate }}
                            <mat-icon
                                svgIcon="enelx:general-info"
                                data-automation="shortDisplayLabelTooltip"
                                class="tooltip-icon icon-container"
                                [matTooltipPosition]="'below'"
                                matTooltip="{{ 'product.short_display_label.tooltip' | translate }}"
                            ></mat-icon>
                        </mat-label>
                    </div>
                    <mat-label class="input-entry" data-automation="shortDisplayLabel">
                        {{ getShortDisplayLabel() }}
                    </mat-label>
                </div>

                <!--- description --->
                <div class="form-entry">
                    <mat-label class="info-title">{{ 'common.description' | translate }}:</mat-label>
                    <mat-label class="input-entry" data-automation="description">
                        {{ descriptions }}
                    </mat-label>
                </div>

                <!--- timezone --->
                <div class="form-entry">
                    <div class="label-container">
                        <mat-label class="info-title"
                            >{{ 'common.timezone' | translate }}
                            <mat-icon
                                svgIcon="enelx:general-info"
                                data-automation="timezoneTooltip"
                                class="tooltip-icon icon-container"
                                [matTooltipPosition]="'below'"
                                matTooltip="{{ 'common.timezone.tooltip' | translate }}"
                            ></mat-icon>
                        </mat-label>
                    </div>
                    <mat-label class="input-entry" data-automation="timezone">
                        {{ product.timezone }}
                    </mat-label>
                </div>

                <!--- status --->
                <div class="form-entry">
                    <div class="label-container">
                        <mat-label class="info-title"
                            >{{ 'common.status' | translate }}
                            <mat-icon
                                svgIcon="enelx:general-info"
                                data-automation="statusTooltip"
                                class="tooltip-icon icon-container"
                                [matTooltipPosition]="'below'"
                                matTooltip="{{ 'common.status.tooltip' | translate }}"
                            ></mat-icon>
                        </mat-label>
                    </div>
                    <mat-label class="input-entry" data-automation="status">
                        {{ product.status }}
                    </mat-label>
                </div>

                <!--- ecrm id --->
                <div class="form-entry">
                    <div class="label-container">
                        <mat-label class="info-title"
                            >{{ 'common.product_ecrm_id' | translate }}
                            <mat-icon
                                svgIcon="enelx:general-info"
                                data-automation="ecrmIdTooltip"
                                class="tooltip-icon icon-container"
                                [matTooltipPosition]="'below'"
                                matTooltip="{{ 'common.product_ecrm_id.tooltip' | translate }}"
                            ></mat-icon>
                        </mat-label>
                    </div>
                    <mat-label class="input-entry" data-automation="ecrmId">
                        {{ product.ecrmId }}
                    </mat-label>
                </div>

                <!--- external reference id --->
                <div class="form-entry">
                    <div class="label-container">
                        <mat-label class="info-title"
                            >{{ 'common.product_external_reference_id' | translate }}
                            <mat-icon
                                svgIcon="enelx:general-info"
                                data-automation="externalReferenceIdTooltip"
                                class="tooltip-icon icon-container"
                                [matTooltipPosition]="'below'"
                                matTooltip="{{ 'common.product_external_reference_id.tooltip' | translate }}"
                            ></mat-icon>
                        </mat-label>
                    </div>
                    <mat-label class="input-entry" data-automation="externalReferenceId">
                        {{ product.productExternalReferenceId }}
                    </mat-label>
                </div>
            </div>
        </div>
    </ng-container>
</div>
