import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Product } from '../../../shared/model/product.model';
import { RefService } from '../../../shared/services/ref.service';
import { Timezone } from '../../../shared/model/timezone.model';
import { ControlContainer, UntypedFormGroup, NgForm } from '@angular/forms';
import { OperatorsService } from '../../../shared/services/operators.service';
import { ActivatedRoute } from '@angular/router';
import { Status } from '../../../shared/model/status.model';
import { Locale, LocaleBaseConfig } from '../../../shared/model/locale.model';
import { TranslateService } from '@ngx-translate/core';
import { LocalesService } from '../../../shared/services/locales.service';
import { CookieService as NgxCookieService } from 'ngx-shared-services';

@Component({
  selector: 'app-product-blue-button-fields',
  templateUrl: './product-blue-button-fields.component.html',
  styleUrls: ['./product-blue-button-fields.component.scss', '../../../shared/shared.styles.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class ProductBlueButtonFieldsComponent implements OnInit {
  @ViewChild(`displayLabel`, { read: ElementRef }) displayLabel: ElementRef;
  locales: Locale[];
  statuses: Status[];
  timezones: Timezone[];
  operators = [];
  isInit = true;
  clone = false;
  ecrmPattern = /^[A-Za-z0-9]{18}$/;
  @Input() product: Product;
  @Input() mode: string;
  @Input() locale: string;
  @Input() frm;

  multiLocaleConfig: LocaleBaseConfig = {
    supportedLocales: [new Locale()],
    locales: [new Locale()],
    defaultLocale: new Locale(),
    id: '',
    displayLabelKey: 'displayLabel',
    localeKey: 'localeName',
  };
  productLabel: '';
  productPlaceholder: '';

  descriptionLabel: '';
  descriptionPlaceholder: '';
  shortDisplayLabel: '';
  shortDisplayLabelPlaceholder: '';

  userLocale = 'en_US';

  @Input()
  set LocaleConfig(localeConfig: any) {
    this.multiLocaleConfig = localeConfig;
    this.buildSupportedLocales();
  }

  constructor(
    private ngxCookieService: NgxCookieService,
    private refService: RefService,
    private route: ActivatedRoute,
    private operatorsService: OperatorsService,
    private localeService: LocalesService,
    private translateService: TranslateService,
  ) {
    this.userLocale = this.ngxCookieService.getCookie('locale') || 'en_US';
    this.route.queryParams.subscribe(async (queryParams) => {
      if (queryParams.clone) {
        this.clone = true;
      }
    });
    this.refService.timezones$.subscribe((timezones) => (this.timezones = timezones));
    this.localeService.fetchLocales();

    this.localeService.locales$.subscribe((locales) => {
      if (locales?.length) {
        this.locales = locales;
        const locale = this.localeService.checkForLocale(this.userLocale, locales) || locales[0];
        this.multiLocaleConfig.defaultLocale = locale;

        if (this.isCreateMode) {
          this.multiLocaleConfig.supportedLocales = [locale];
          this.product.defaultLocale = this.userLocale;
        } else if (this.isEditMode) {
          this.buildSupportedLocales();
        }
      }
    });

    this.operatorsService.operators$.subscribe((operators) => {
      if (operators.length > 0 && this.operators.length !== operators.length) {
        this.operators = operators;
      }
    });

    this.productLabel = this.translateService.instant('common.product_display_label');
    this.productPlaceholder = this.translateService.instant('product.create.placeholder.product_name');

    this.descriptionLabel = this.translateService.instant('common.description');
    this.descriptionPlaceholder = this.translateService.instant('product.create.placeholder.product_description');

    this.shortDisplayLabel = this.translateService.instant('product.create.short_display_label');
    this.shortDisplayLabelPlaceholder = this.translateService.instant('product.create.placeholder.short_display_label');

  }

  ngOnInit(): void {
    this.refService.statuses$.subscribe((statuses) => {
      if (statuses && statuses.length > 0) {
        this.statuses = statuses;
      }
    });
    this.refService.getStatuses();
  }
  ngAfterViewInit() {
    if (this.clone) {
      this.displayLabel.nativeElement.focus();
    }
  }
  get theForm() {
    if (this.frm.form) {
      return this.frm.form as UntypedFormGroup;
    }
  }

  get isEditMode() {
    return this.mode === 'edit';
  }

  get isCreateMode() {
    return this.mode === 'create';
  }

  get isViewMode() {
    return this.mode === 'view';
  }

  getTimezoneForProduct() {
    if (this.timezones.length > 0) {
      return (
        this.timezones.find((timezone) => this.product.timezone.indexOf(timezone.timezoneName) !== -1) || new Timezone()
      );
    }

    return new Timezone();
  }

  getLocaleForProduct() {
    if (this.locales?.length > 0) {
      return (
        this.locales.find((locale) => this.product.defaultLocale.indexOf(locale.localeName) !== -1) || new Locale()
      );
    }

    return new Locale();
  }

  getStatusForProgram() {
    if (this.statuses && this.statuses.length > 0) {
      return (
        this.statuses.find((status) => status.code === this.product.status)?.displayLabel || new Status().displayLabel
      );
    }

    return '-';
  }

  get programDisplayLabel() {
    let foundProgram;
    if (this.product.programId && this.product.programId !== '-1' && this.operators.length > 0) {
      this.operators.find((operator) => {
        if (operator.children && operator.children.length > 0) {
          return operator.children.find((program) => {
            if (program.id === this.product.programId) {
              foundProgram = program;
              return true;
            }
          });
        }
      });

      if (foundProgram) {
        return foundProgram.displayLabel;
      }
    }
    return '';
  }

  limitLocaleSelectList(num: number, locale: Locale) {
    return (
      this.multiLocaleConfig.supportedLocales.length > num - 1 &&
      !this.multiLocaleConfig.supportedLocales.includes(locale)
    );
  }

  async handleSelectionChange() {
    if (this.multiLocaleConfig.supportedLocales.length === 0) {
      this.multiLocaleConfig.supportedLocales = [
        ...this.multiLocaleConfig.supportedLocales,
        this.multiLocaleConfig.defaultLocale,
      ];
    }
    await this.localeService.getLocaleSelectorChanged();
    if (this.isEditMode) {
      this.frm.form.controls['localeInputsForm_product_display_labels']?.markAsDirty();
      this.frm.form.controls['localeInputsForm_product_descriptions']?.markAsDirty();
      this.frm.form.controls['localeInputsForm_short_display_labels']?.markAsDirty();
    }
  }

  buildSupportedLocales() {
    if (this.multiLocaleConfig.supportedLocales && this.product && this.locales) {
      this.multiLocaleConfig.supportedLocales = [];
      const keys = Object.keys(this.product.displayLabels);
      for (const key of keys) {
        const localeFound = this.locales.find((locale) => locale.localeName === key);
        if (localeFound) {
          this.multiLocaleConfig.supportedLocales.push(localeFound);
        }
      }
    }
  }

  getShortDisplayLabel(): string {
    return this.localeService.getValueFromMultilocaleObject(this.product.shortDisplayLabels, this.userLocale, this.product.defaultLocale);
  }

  getDisplayLabel(): string {
    return this.localeService.getValueFromMultilocaleObject(this.product.displayLabels, this.userLocale, this.product.defaultLocale);
  }

  getDescriptions(): string {
    return this.localeService.getValueFromMultilocaleObject(this.product.descriptions, this.userLocale, this.product.defaultLocale);
  }
}
