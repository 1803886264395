<div class="app-product-create content">
    <header class="sticky-header">
        <div class="left-column">
            <div class="icon-header">
                <div class="icon-container">
                    <mat-icon *ngIf="!loadingClone">library_add</mat-icon>
                    <mat-spinner *ngIf="loadingClone" matSuffix diameter="30" color="warn"></mat-spinner>
                </div>
                <h1 id="create-title" data-automation="createTitle">{{ getTitle() | translate }}</h1>
                <p class="subtitle" data-automation="createSubtitle">{{ getSubtitle() | translate }}</p>
            </div>
        </div>

        <div class="right-column" data-automation="right_buttons">
            <button
                class="icon-btn x-btn-secondary"
                aria-label="Cancel"
                (click)="handleCancel()"
                data-automation="createCancel"
            >
                <mat-icon>clear</mat-icon>
                <span class="btn-text"> {{ 'common.button.cancel' | translate }}</span>
            </button>

            <button
                class="icon-btn x-btn-primary"
                aria-label="Submit"
                (click)="handleSubmit()"
                [disabled]="create.invalid"
                type="submit"
                data-automation="createSubmit"
            >
                <mat-icon>done</mat-icon>
                <div *ngIf="saving" class="btn-spinner">
                    <mat-spinner diameter="15"></mat-spinner>
                </div>
                <span class="btn-text" data-automation="createButtonLabel">
                    {{ 'common.button.submit' | translate }}</span
                >
            </button>
        </div>
    </header>
    <div class="page-content">
        <form #create="ngForm" class="product-create-form">
            <div class="form-wrapper" *ngIf="!loading">
                <div class="form-entry market-entity-type">
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>{{ 'common.market_entity_type' | translate }}</mat-label>
                        <mat-select
                            id="drType"
                            name="drType"
                            #drType="ngModel"
                            [ngClass]="{
                                failure: drType.invalid && (drType.dirty || drType.touched),
                                'create-select': true
                            }"
                            [(ngModel)]="marketEntityType"
                            data-automation="marketEntityTypeSelector"
                            data-url-persist
                        >
                            <mat-option
                                *ngFor="let typeOption of marketTypes"
                                [value]="typeOption.name"
                                id="equipType_{{ typeOption.name }}"
                                >{{ typeOption.displayLabel | translate }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>

            <ng-container [ngSwitch]="marketEntityType" *ngIf="!loading">
                <ng-container *ngSwitchCase="'Product'">
                    <app-product [mode]="mode" [frm]="createForm"></app-product>
                </ng-container>
                <ng-container *ngSwitchCase="'Program'">
                    <app-program [mode]="mode" [frm]="createForm"></app-program>
                </ng-container>
                <ng-container *ngSwitchCase="'Operator'">
                    <app-operator [mode]="mode" [frm]="createForm"></app-operator>
                </ng-container>
            </ng-container>
            <div *ngIf="loading" class="spinner-container">
                <img src="../../assets/images/spinner.gif" alt="loading" />
            </div>
        </form>
    </div>
</div>
