import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Locale } from '../../../shared/model/locale.model';
import { Product, RefData } from '../../../shared/model/product.model';
import { FormGroup } from '@angular/forms';
import { RefService } from '../../../shared/services/ref.service';
import { ControlContainer, NgForm } from '@angular/forms';

@Component({
  selector: 'app-product-baseline-adjustment',
  templateUrl: './product-baseline-adjustment.component.html',
  styleUrls: ['./product-baseline-adjustment.component.scss', '../../../shared/shared.styles.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class ProductBaselineAdjustmentComponent implements OnInit {
  @Input() mode: string;
  @Input() locale: Locale;
  @Input() product: Product;
  @Input() frm: FormGroup;
  @Output("toggleDispatchableProduct") toggleDispatchableProduct: EventEmitter<any> = new EventEmitter();
  panelOpenState = false;
  adjustmentOffsetTimes: RefData[] = [];
  lookbackTypes: RefData[] = [];

  constructor(private refService: RefService) { }

  ngOnInit(): void {
    this.refService.baselineOffsetTimes$.subscribe(
      (baselineOffsetTimes) => (this.adjustmentOffsetTimes = baselineOffsetTimes),
    );
    this.refService.baselineLookbackTypes$.subscribe((types) => (this.lookbackTypes = types));
  }

  get isEditMode() {
    return this.mode === 'edit';
  }

  get isCreateMode() {
    return this.mode === 'create';
  }

  get isViewMode() {
    return this.mode === 'view';
  }

  setLabelForAdjustmentOffsetTimes() {
    const displayLabel = '';

    if (this.product.baselineConfiguration && this.product.baselineConfiguration.baselineAdjustmentOffsetTime) {
      const foundOffsetTime = this.adjustmentOffsetTimes.find(
        (offsetTime) => offsetTime.code === this.product.baselineConfiguration.baselineAdjustmentOffsetTime,
      );
      if (foundOffsetTime) {
        return foundOffsetTime.displayLabel;
      }
    }

    return displayLabel;
  }

  setLabelForLookbackTypes() {
    const displayLabel = '';

    if (this.product.baselineConfiguration && this.product.baselineConfiguration.baselineLookbackType) {
      const foundLookbackType = this.lookbackTypes.find(
        (lookbackType) => lookbackType.code === this.product.baselineConfiguration.baselineLookbackType,
      );
      if (foundLookbackType) {
        return foundLookbackType.displayLabel;
      }
    }

    return displayLabel;
  }

  onPanelExpand() {
    this.toggleDispatchableProduct.emit();
  }
}
