import { Component, OnDestroy, OnInit } from '@angular/core';
import { OperatorsService } from '../shared/services/operators.service';
import { Router } from '@angular/router';
import { TreeService } from '../shared/services/tree.service';

@Component({
  selector: 'app-default',
  templateUrl: './default.component.html',
  styleUrls: ['./default.component.scss', '../shared/shared.styles.scss'],
})
export class DefaultComponent implements OnInit, OnDestroy {
  loading = true;
  noOperators = false;
  operators;
  subs = [];

  constructor(private router: Router, private operatorsService: OperatorsService, private treeService: TreeService) {}

  navigateToFirstOperator(operators) {
    if (operators?.length > 0) {
      const operatorId = operators[0].id;
      this.loading = true;
      this.noOperators = false;
      this.router.navigate([`details/${operatorId}/view`], { queryParams: { drType: 'Operator' } });
    } else {
      this.loading = false;
      this.noOperators = true;
    }
  }

  async ngOnInit() {
    const operatorSub = this.operatorsService.operators$.subscribe((operators) => {
      if (this.router.isActive('/', true)) {
        this.operators = operators;
        this.navigateToFirstOperator(operators);
      }
    });

    await this.operatorsService.fetchOperators();

    this.subs.push(operatorSub);
  }

  async ngOnDestroy() {
    for (const sub of this.subs) {
      sub.unsubscribe();
    }
  }
}
