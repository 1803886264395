import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { auditTime, fromEvent, map, distinctUntilChanged } from 'rxjs';
import { CookieService as NgxCookieService } from 'ngx-shared-services';
import { environment } from 'src/environments/environment.prod';
import { MatSidenav } from '@angular/material/sidenav';
import { NavService } from './shared/services/global-nav.service';
import { RefService } from './shared/services/ref.service';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import 'enel-navbar/dist';
import { SidenavService } from './shared/services/sidenav.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit, AfterViewInit {
  @ViewChild('sidenav') public sidenav: MatSidenav;
  navUrl = '';
  sidenavOpen = true;

  constructor(
    private translate: TranslateService,
    private cookieService: NgxCookieService,
    private navService: NavService,
    private refService: RefService,
    private sidenavService: SidenavService,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
  ) {
    iconRegistry.addSvgIconSetInNamespace(
      'enelx',
      sanitizer.bypassSecurityTrustResourceUrl(`../assets/icons/enelx-icons.svg`),
    );
    this.getNavUrl();
    addEventListener('openedChange', (e: CustomEvent) => {
      this.setDimensions();
    });
    fromEvent(window, 'resize')
      .pipe(
        auditTime(100),
        map(() => window.innerWidth),
        distinctUntilChanged(),
      )
      .subscribe(() => this.setDimensions());
  }

  ngOnInit(): void {
    const { defaults } = environment;
    const locale = this.cookieService.getI18NLocale() || defaults.locale;
    const brand = this.cookieService.getI18NBrand() || defaults.brand.toUpperCase();
    this.translate.setDefaultLang(`${defaults.locale}.${defaults.brand.toUpperCase()}`);
    this.translate.use(`${locale}.${brand}`).subscribe(() => {
      this.translate.get('APP.TITLE').subscribe((result) => {
        document.title = result;
      });
    });
    this.refService.setErrorMessages();
  }

  ngAfterViewInit(): void {
    this.sidenavService.setSidenav(this.sidenav);
    this.setDimensions();
    this.sidenav.openedChange.subscribe(() => (this.setDimensions()));
  }

  setDimensions(): void {
    const height = document.querySelector('body > app-root > enelx-navbar > enelx-navbar-header > dsy-header')?.shadowRoot?.querySelector('div')?.clientHeight;
    if (!height) {
      setTimeout(() => this.setDimensions(), 50);
      return;
    }
    // @ts-ignore
    const navbar = document.querySelector('enelx-navbar').ngElementStrategy.componentRef.instance;
    const navbarWidth = navbar.navbarOpen ? navbar.sidebarSize : navbar.dockedSize;
    const sidenav = document.querySelector('mat-sidenav');
    (<HTMLElement>sidenav).style.top = `${height}px`;
    (<HTMLElement>document.querySelector('.content-section')).style.top = `${height}px`;
    const isSidenavOver = sidenav.className.includes('mat-drawer-over');
    const isSidenavOpened = sidenav.className.includes('mat-drawer-opened');
    const sidenavDock = 50;
    const sidenavSize = isSidenavOver ? sidenavDock : isSidenavOpened ? sidenav.clientWidth : sidenavDock;
    (<HTMLElement>document.querySelector('.content-section')).style.width = `${window.innerWidth - (sidenavSize+navbarWidth)}px`;
  }

  async getNavUrl() {
    this.navUrl = await this.navService.getNavUrl();
  }

  isLargeScreen() {
    const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    return width > 1280;
  }

  triggerResize(): void {
    window.dispatchEvent(new Event('resize'));
  }
}
