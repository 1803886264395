import { Injectable } from '@angular/core';
import { ErrorMessage } from '../model/error-message.model';
import { RefService } from './ref.service';
import { GlobalAlertService } from './global-alert.service';

@Injectable()
export class ErrorHandlingService {
  errorList: ErrorMessage[];

  constructor(private refService: RefService, private messageService: GlobalAlertService) {
    this.refService.errorMessages$.subscribe((errors) => {
        this.errorList = errors;
    });
  }

  setErrorMessage(e: any){
    let error;
    const fields = this.getMissingOrInvalidFields(e);
    if(e?.error?.code !== 400 && this.errorList.find(err => err.code === e.error.code.toString())){
      error =  this.errorList.find(err => err.code === e.error?.code.toString());
    } else {
      error =  this.errorList.find(err => err.code === "400");
    }
    this.messageService.setError(error.displayLabel, fields);
  }

  getErrorMessage(e){
    let error;
    if(e?.error?.code !== 400 && this.errorList.find(err => err.code === e.error.code.toString())){
      error =  this.errorList.find(err => err.code === e.error?.code.toString());
    } else {
      error =  this.errorList.find(err => err.code === "400");
    }
    return error.displayLabel;
  }

  getMissingOrInvalidFields(e){
    let fields;
    if(e?.error?.data && e.error.data.errors){
      fields = e.error.data.errors.map((error) => (error.param));
    }
    if(e?.data && e.data.conflicts){
      fields = e.data.conflicts.map((error) => (error.value));
    }
    return fields;
  }
}
