import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Locale } from '../../../shared/model/locale.model';
import { Product, RefData } from '../../../shared/model/product.model';
import { FormGroup } from '@angular/forms';
import { RefService } from '../../../shared/services/ref.service';
import { ControlContainer, NgForm } from '@angular/forms';

@Component({
  selector: 'app-product-performance',
  templateUrl: './product-performance.component.html',
  styleUrls: ['./product-performance.component.scss', '../../../shared/shared.styles.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class ProductPerformanceComponent implements OnInit {
  @Input() mode: string;
  @Input() locale: Locale;
  @Input() product: Product;
  @Input() frm: FormGroup;
  @Output("toggleDispatchableProduct") toggleDispatchableProduct: EventEmitter<any> = new EventEmitter();
  panelOpenState = false;
  reportingIntervals: RefData[] = [];
  performanceAggregateTypes: RefData[] = [];
  keyPerformanceMetrics: RefData[] = [];
  preferedPrezDemandUoms: RefData[] = [];

  constructor(private refService: RefService) {}

  ngOnInit(): void {
    this.refService.reportingIntervals$.subscribe(
      (reportingIntervals) => (this.reportingIntervals = reportingIntervals),
    );
    this.refService.performanceAggregateTypes$.subscribe(
      (performanceAggregateTypes) => (this.performanceAggregateTypes = performanceAggregateTypes),
    );
    this.refService.keyPerformanceMetrics$.subscribe(
      (keyPerformanceMetrics) => (this.keyPerformanceMetrics = keyPerformanceMetrics),
    );
    this.refService.preferedPrezDemandUoms$.subscribe((uoms) => (this.preferedPrezDemandUoms = uoms));
  }

  get isEditMode() {
    return this.mode === 'edit';
  }

  get isCreateMode() {
    return this.mode === 'create';
  }

  get isViewMode() {
    return this.mode === 'view';
  }

  get reportingIntervalForProduct() {
    if (this.product.reportingIntervalMs && this.reportingIntervals.length > 0) {
      const reportingInterval = this.reportingIntervals.find(
        (reportingInterval) =>
          parseInt(reportingInterval.durationInMilliseconds) === parseInt(this.product.reportingIntervalMs),
      );
      if (reportingInterval) {
        return reportingInterval.displayLabel;
      }
    }

    return '';
  }

  compareReportingInterval(item1, item2) {
    return parseInt(item1) === parseInt(item2);
  }

  get performanceAggregateTypeForProduct() {
    if (this.product.performanceAggregateType && this.performanceAggregateTypes.length > 0) {
      const aggregateType = this.performanceAggregateTypes.find(
        (performanceType) => performanceType.code === this.product.performanceAggregateType,
      );
      if (aggregateType) {
        return aggregateType.displayLabel;
      }
    }

    return '';
  }

  get keyPerformanceMetricForProduct() {
    if (this.product.keyPerformanceMetric && this.keyPerformanceMetrics.length > 0) {
      const metric = this.keyPerformanceMetrics.find(
        (keyMetric) => keyMetric.code === this.product.keyPerformanceMetric,
      );
      if (metric) {
        return metric.displayLabel;
      }
    }
    return '';
  }

  getPreferredPrezDemandUomForProduct() {
    if (
      this.product.prezConf &&
      this.product.prezConf.preferedPrezDemandUom &&
      this.preferedPrezDemandUoms.length > 0
    ) {
      const uom = this.preferedPrezDemandUoms.find(
        (preferredUom) => preferredUom.code === this.product.prezConf.preferedPrezDemandUom,
      );
      if (uom) {
        return uom.displayLabel;
      }
    }
    return '';
  }

  onPanelExpand() {
    this.toggleDispatchableProduct.emit();
  }
}
