import { MarketEntity } from './market-entity.model';
import { statuses } from './product.model';

export class ProductStub implements MarketEntity {
  static create(product: any) {
    return new ProductStub(
      product.defaultLocale,
      product.description,
      product.descriptions,
      product.displayLabel,
      product.displayLabels,
      product.shortDisplayLabels,
      product.drType,
      product.ecrmId,
      product.id,
      product.productExternalReferenceId,
      product.programId,
      product.status,
      product.timezone,
      product.incomplete,
      product.isDispatchableProduct,
    );
  }

  // tslint:disable-next-line:variable-name
  display_label: string;

  constructor(
    public defaultLocale = 'en_US',
    public description = '',
    public descriptions = {},
    public displayLabel = '',
    public displayLabels = {},
    public shortDisplayLabels = {},
    public drType = '',
    public ecrmId = '',
    public id = '',
    public productExternalReferenceId,
    public programId = '-1',
    public status = statuses[0].id,
    public timezone = 'America/New_York',
    public incomplete?,
    public isDispatchableProduct?,
  ) {
    this.display_label = this.displayLabel;
  }
}
