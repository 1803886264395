import { Injectable } from '@angular/core';
import * as convertKeys from 'convert-keys';
import { RefData } from '../model/product.model';
import { Timezone } from '../model/timezone.model';
import { Locale } from '../model/locale.model';
import { ApiService } from './api.service';
import { BehaviorSubject } from 'rxjs';
import { Currency } from '../model/currency.model';
import { Status } from '../model/status.model';
import { ErrorMessage } from '../model/error-message.model';

@Injectable()
export class RefService {
  expectedCapacitySources$: BehaviorSubject<RefData[]>;
  selectionTypes$: BehaviorSubject<RefData[]>;
  targetTypes$: BehaviorSubject<RefData[]>;
  keyPerformanceMetrics$: BehaviorSubject<RefData[]>;
  selectionOfferTimes$: BehaviorSubject<RefData[]>;
  performanceAggregateTypes$: BehaviorSubject<RefData[]>;
  offerFrequencyUnits$: BehaviorSubject<RefData[]>;
  portfolioObligationSources$: BehaviorSubject<RefData[]>;
  preferedPrezDemandUoms$: BehaviorSubject<RefData[]>;
  baselineLookbackTypes$: BehaviorSubject<RefData[]>;
  reportingIntervals$: BehaviorSubject<RefData[]>;
  dispatchSystems$: BehaviorSubject<RefData[]>;
  cronOptions$: BehaviorSubject<RefData[]>;
  baselineOffsetTimes$: BehaviorSubject<RefData[]>;
  currencies$: BehaviorSubject<Currency[]>;
  timezones$: BehaviorSubject<Timezone[]>;
  locales$: BehaviorSubject<Locale[]>;
  statuses$: BehaviorSubject<Status[]>;
  errorMessages$: BehaviorSubject<ErrorMessage[]>;

  constructor(private apiService: ApiService) {
    this.expectedCapacitySources$ = new BehaviorSubject<RefData[]>([]);
    this.selectionTypes$ = new BehaviorSubject<RefData[]>([]);
    this.targetTypes$ = new BehaviorSubject<RefData[]>([]);
    this.keyPerformanceMetrics$ = new BehaviorSubject<RefData[]>([]);
    this.selectionOfferTimes$ = new BehaviorSubject<RefData[]>([]);
    this.performanceAggregateTypes$ = new BehaviorSubject<RefData[]>([]);
    this.offerFrequencyUnits$ = new BehaviorSubject<RefData[]>([]);
    this.portfolioObligationSources$ = new BehaviorSubject<RefData[]>([]);
    this.preferedPrezDemandUoms$ = new BehaviorSubject<RefData[]>([]);
    this.baselineLookbackTypes$ = new BehaviorSubject<RefData[]>([]);
    this.reportingIntervals$ = new BehaviorSubject<RefData[]>([]);
    this.dispatchSystems$ = new BehaviorSubject<RefData[]>([]);
    this.cronOptions$ = new BehaviorSubject<RefData[]>([]);
    this.baselineOffsetTimes$ = new BehaviorSubject<RefData[]>([]);
    this.currencies$ = new BehaviorSubject<Currency[]>([]);
    this.timezones$ = new BehaviorSubject<Timezone[]>([]);
    this.locales$ = new BehaviorSubject<Locale[]>([]);
    this.statuses$ = new BehaviorSubject<Status[]>([]);
    this.errorMessages$ = new BehaviorSubject<ErrorMessage[]>([]);
  }

  async getRefMetadata() {
    const metadata: any = convertKeys.toCamel(await this.apiService.get(`ref-metadata`));
    const {
      selectionTypes,
      targetTypes,
      selectionOfferTimes,
      performanceAggregateTypes,
      keyPerformanceMetrics,
      offerFrequencyUnits,
      portfolioObligationSources,
      preferedPrezDemandUoms,
      baselineLookbackTypes,
      expectedCapacitySources,
      reportingIntervals,
      dispatchSystems,
      baselineOffsetTimes,
    } = metadata;

    this.expectedCapacitySources$.next(expectedCapacitySources.data);
    this.selectionTypes$.next(selectionTypes.data);
    this.targetTypes$.next(targetTypes.data);
    this.keyPerformanceMetrics$.next(keyPerformanceMetrics.data);
    this.selectionOfferTimes$.next(selectionOfferTimes.data);
    this.performanceAggregateTypes$.next(performanceAggregateTypes.data);
    this.offerFrequencyUnits$.next(offerFrequencyUnits.data);
    this.portfolioObligationSources$.next(portfolioObligationSources.data);
    this.preferedPrezDemandUoms$.next(preferedPrezDemandUoms.data);
    this.baselineLookbackTypes$.next(baselineLookbackTypes.data);
    this.reportingIntervals$.next(reportingIntervals.data);
    this.dispatchSystems$.next(dispatchSystems.data);
    this.baselineOffsetTimes$.next(baselineOffsetTimes.data);
  }

  async getTimezones() {
    const timezones = await this.apiService.get(`timezones`);
    this.timezones$.next(convertKeys.toCamel(timezones));
  }

  async getLocales() {
    const locales = await this.apiService.get(`locales`);
    this.locales$.next(convertKeys.toCamel(locales));
  }

  async getCurrencies() {
    const currencies = await this.apiService.get('currencies');
    this.currencies$.next(convertKeys.toCamel(currencies));
  }

  async getStatuses() {
    const statuses = await this.apiService.get('statuses');

    if (typeof statuses !== undefined && Array.isArray(statuses) && statuses.length > 0) {
      statuses.sort((a, b) => Number(a.display_seq) - Number(b.display_seq));
      this.statuses$.next(convertKeys.toCamel(statuses));
    }
  }

  async getCronOptions() {
    const cronOptions = await this.apiService.get(`cron-options`);
    this.cronOptions$.next(convertKeys.toCamel(cronOptions));
  }

  async setErrorMessages() {
    const messages = await this.apiService.get(`error-messages`);
    this.errorMessages$.next(convertKeys.toCamel(messages));
  }

  getErrorMessages() {
    return this.errorMessages$.asObservable();
  }
}
