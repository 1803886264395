import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Locale } from '../../../shared/model/locale.model';
import { Product } from '../../../shared/model/product.model';
import { FormGroup } from '@angular/forms';
import { ControlContainer, NgForm } from '@angular/forms';

@Component({
  selector: 'app-product-utility-portal',
  templateUrl: './product-utility-portal.component.html',
  styleUrls: ['./product-utility-portal.component.scss', '../../../shared/shared.styles.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class ProductUtilityPortalComponent implements OnInit {
  @Input() mode: string;
  @Input() locale: Locale;
  @Input() product: Product;
  @Input() frm: FormGroup;
  @Output('toggleDispatchableProduct') toggleDispatchableProduct: EventEmitter<any> = new EventEmitter();
  panelOpenState = false;
  constructor() {
    console.log('ProductUtilityPortalComponent.constructor()');
  }

  ngOnInit(): void {
    console.log('ProductUtilityPortalComponent.ngOnInit()');
  }

  get isEditMode() {
    return this.mode === 'edit';
  }

  get isCreateMode() {
    return this.mode === 'create';
  }

  get isViewMode() {
    return this.mode === 'view';
  }

  onPanelExpand() {
    this.toggleDispatchableProduct.emit();
  }
}
