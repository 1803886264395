import { Component, OnInit, Input } from '@angular/core';
import { Locale } from '../../../shared/model/locale.model';
import { Product, RefData } from '../../../shared/model/product.model';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-product-to-be-deleted',
  templateUrl: './product-to-be-deleted.component.html',
  styleUrls: ['./product-to-be-deleted.component.scss', '../../../shared/shared.styles.scss']
})
export class ProductToBeDeletedComponent implements OnInit {
  @Input() mode: string;
  @Input() locale: Locale;
  @Input() product: Product;
  @Input() frm: FormGroup;

  panelOpenState = false;

  constructor() {
    console.log('ProductToBeDeletedComponent.constructor()');
  }

  ngOnInit(): void {
    console.log('ProductToBeDeletedComponent.ngOnInit()');
  }

  get isEditMode() {
    return this.mode === 'edit';
  }

  get isCreateMode() {
    return this.mode === 'create';
  }

  get isViewMode() {
    return this.mode === 'view';
  }

}
