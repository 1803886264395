<div class="app-global-alert">
    <div *ngIf="isErrorAlertVisible" class="global-message-wrapper">
        <div [ngClass]="messageClassName">
            <span [ngClass]="iconClassName"></span>
            <div class="message-field-wrapper">
                <div class="message">
                    <span data-automation="globalMessage">{{ message.message }}</span>
                </div>
                <div class="field-list" *ngIf="message.fields">
                    <ul class="field-ul">
                        <li *ngFor="let field of message.fields">{{ 'common.' + field | translate }}</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
