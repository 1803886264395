/* * * ANGULAR * * */
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';

/* * * 3RD PARTY * * */
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

/* * * SHARED * * */
import { NgxGlobalContactFooterModule } from 'ngx-global-contact-footer';
import { GlobalNavModule } from 'ngx-global-nav';
import { TreeModule } from 'enel-tree/dist/enel-tree';

import { NgxDeeplinkerModule } from 'ngx-deeplinker';
import { AnalyticsService, CookieService as NgxCookieService, NOC_ANALYTICS } from 'ngx-shared-services';
import { analytics } from 'noc-analytics';
import { CookieService } from './shared/services/cookie.service';
import { GlobalAlertService } from './shared/services/global-alert.service';
import { GlobalAlertModule } from './global_alert/global-alert.module';
import { MarketsService } from './shared/services/markets.service';
import { ProductsService } from './shared/services/products.service';
import { ProgramsService } from './shared/services/programs.service';
import { OperatorsService } from './shared/services/operators.service';
import { ApiService } from './shared/services/api.service';
import { DeeplinksService } from './shared/services/deeplinks.service';
import { LocalesService } from './shared/services/locales.service';
import { TimezonesService } from './shared/services/timezones.service';
import { RefService } from './shared/services/ref.service';
import { SidenavService } from './shared/services/sidenav.service';
import { TreeService } from './shared/services/tree.service';
import { NavService } from './shared/services/global-nav.service';
import { ErrorHandlingService } from './shared/services/error-handling.service';
import { FormValidatorService } from './shared/services/form-validator.service';

import { AuthHttpInterceptor } from './services/auth.interceptor';
import { MockHttpInterceptor } from './services/mock.interceptor';
import { SelectValidator } from './shared/directives/select-valid.directive';
import { MinMaxValidator } from './shared/directives/max-greater-than-min.directive';
import { MinMaxEqualValidator } from './shared/directives/max-greater-equal-than-min.directive';
import { SelectionBufferValidator } from './shared/directives/selection-buffer.directive';
import { CronValidator } from './shared/directives/cron.directive';
import { StartEndDateValidator } from './shared/directives/start-end-date.directive';
import { MultiLocaleModule } from 'multi-locale-input';

/* * * COMPONENTS * * */
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CreateComponent } from './create/create.component';
import { ListComponent } from './list/list.component';
import { EditComponent } from './edit/edit.component';
import { ProductDetailsComponent } from './details/product/product-details.component';
import { ProgramDetailsComponent } from './details/program/program-details.component';
import { OperatorDetailsComponent } from './details/operator/operator-details.component';
import { NestedProgramDetailsComponent } from './details/nested-program-details.component.ts/nested-program-details.component';
import { NestedProductDetailsComponent } from './details/nested-product-details.component.ts/nested-product-details.component';
import { ViewComponent } from './view/view.component';
import { DeleteDialogComponent } from './dialogs/delete/delete-dialog.component';
import { DefaultComponent } from './default/default.component';
import { ProductDispatchableFieldsComponent } from './details/product/product-dispatchable-fields/product-dispatchable-fields.component';
import { ProductBlueButtonFieldsComponent } from './details/product/product-blue-button-fields/product-blue-button-fields.component';
import { ProgramBlueButtonFieldsComponent } from './details/program/program-blue-button-fields/program-blue-button-fields.component';
import { ProgramDispatchableFieldsComponent } from './details/program/program-dispatchable-fields/program-dispatchable-fields.component';

import { ProductGeneralInformationComponent } from './details/product/product-general-information/product-general-information.component';
import { ProductDispatchInformationComponent } from './details/product/product-dispatch-information/product-dispatch-information.component';
import { ProductPerformanceComponent } from './details/product/product-performance/product-performance.component';
import { ProductBaselineAdjustmentComponent } from './details/product/product-baseline-adjustment/product-baseline-adjustment.component';
import { ProductCustomerOfferManagementComponent } from './details/product/product-customer-offer-management/product-customer-offer-management.component';
import { ProductComUiComponent } from './details/product/product-customer-offer-management/product-com-ui/product-com-ui.component';
import { ProductUtilityPortalComponent } from './details/product/product-utility-portal/product-utility-portal.component';
import { ProductDispatchLimitsComponent } from './details/product/product-utility-portal/product-dispatch-limits/product-dispatch-limits.component';
import { ProductToBeDeletedComponent } from './details/product/product-to-be-deleted/product-to-be-deleted.component';

/* * * LOCALES * * */
import localeIt from '@angular/common/locales/it';
import localeJa from '@angular/common/locales/ja';
import localePl from '@angular/common/locales/pl';
import localeZh from '@angular/common/locales/zh';

import { registerLocaleData } from '@angular/common';

registerLocaleData(localeIt, 'it-IT');
registerLocaleData(localeJa, 'ja-JP');
registerLocaleData(localePl, 'pl-PL');
registerLocaleData(localeZh, 'zh-TW');

/* * * MATERIAL * * */

import { MatTabsModule } from '@angular/material/tabs';
import { MatTableModule } from '@angular/material/table';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatDatepickerModule } from '@angular/material/datepicker';
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MAT_MOMENT_DATE_FORMATS,
  MatMomentDateModule,
} from '@angular/material-moment-adapter';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import {
  MAT_COLOR_FORMATS,
  NgxMatColorPickerModule,
  NGX_MAT_COLOR_FORMATS,
} from '@angular-material-components/color-picker';
import { MatExpansionModule } from '@angular/material/expansion';
import { SharedModule } from './shared/shared.module';
import { MatButtonModule } from '@angular/material/button';
import { MatRadioModule } from '@angular/material/radio';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTreeModule } from '@angular/material/tree';
import { MatGridListModule } from '@angular/material/grid-list';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, '/assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    ListComponent,
    CreateComponent,
    EditComponent,
    ProductDetailsComponent,
    ProgramDetailsComponent,
    OperatorDetailsComponent,
    NestedProgramDetailsComponent,
    NestedProductDetailsComponent,
    ViewComponent,
    SelectValidator,
    MinMaxValidator,
    MinMaxEqualValidator,
    CronValidator,
    StartEndDateValidator,
    SelectionBufferValidator,
    DeleteDialogComponent,
    DefaultComponent,
    ProductDispatchableFieldsComponent,
    ProductBlueButtonFieldsComponent,
    ProgramBlueButtonFieldsComponent,
    ProgramDispatchableFieldsComponent,
    ProductGeneralInformationComponent,
    ProductDispatchInformationComponent,
    ProductPerformanceComponent,
    ProductBaselineAdjustmentComponent,
    ProductCustomerOfferManagementComponent,
    ProductComUiComponent,
    ProductUtilityPortalComponent,
    ProductDispatchLimitsComponent,
    ProductToBeDeletedComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    TreeModule,
    BrowserAnimationsModule,
    GlobalNavModule.forRoot({ namespace: 'ngx-global-nav.' }),
    NgxGlobalContactFooterModule.forRoot({ namespace: 'ngx-global-contact-footer.' }),
    GlobalAlertModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    RouterModule.forRoot([]),
    AppRoutingModule,
    MatFormFieldModule,
    MatSelectModule,
    MatTabsModule,
    MatTableModule,
    MatInputModule,
    NgxDeeplinkerModule,
    MatProgressSpinnerModule,
    MatExpansionModule,
    SharedModule,
    MatButtonModule,
    MatRadioModule,
    MatCheckboxModule,
    MatListModule,
    MatDatepickerModule,
    MatMomentDateModule,
    NgxMatColorPickerModule,
    MatTreeModule,
    MatGridListModule,
    MatIconModule,
    MatTooltipModule,
    MatDialogModule,
    MatSidenavModule,
    HttpClientModule,
    MultiLocaleModule,
  ],
  providers: [
    TranslateService,
    AnalyticsService,
    NgxCookieService,
    CookieService,
    { provide: HTTP_INTERCEPTORS, useClass: MockHttpInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true },
    HttpClient,
    ApiService,
    MarketsService,
    ProductsService,
    ProgramsService,
    OperatorsService,
    FormValidatorService,
    GlobalAlertService,
    TimezonesService,
    LocalesService,
    DeeplinksService,
    RefService,
    SidenavService,
    TreeService,
    NavService,
    ErrorHandlingService,
    MatDatepickerModule,
    {
      provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS,
      useValue: {
        useUtc: false,
        strict: true,
      },
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
    { provide: MAT_COLOR_FORMATS, useValue: NGX_MAT_COLOR_FORMATS },
    { provide: NOC_ANALYTICS, useValue: analytics },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
