<div data-automation="productToBeDeletedFieldsPanel">
  <ng-container>
    <mat-expansion-panel (opened)="panelOpenState = true"
                         (closed)="panelOpenState = false"
                         togglePosition="before"
                         class="expansion-panel"
                         id="product-to-be-deleted-fields-panel"
                         data-automation="productToBeDeletedFieldsPanel"
    >
      <mat-expansion-panel-header collapsedHeight="48px" expandedHeight="48px">
        <mat-panel-title class="panel-title">
          <div data-automation="productToBeDeletedFieldsPanelTitle">
            {{ 'common.product.to_be_deleted' | translate }}
          </div>
        </mat-panel-title>
      </mat-expansion-panel-header>

      <!--- CREATE/EDIT --->
      <div *ngIf="(isCreateMode || isEditMode) && product" class="panel-content">
        <div class="form-group">

        </div>
      </div>

      <!--- VIEW --->
      <div *ngIf="isViewMode && product" class="panel-content">
        <div class="form-group">

        </div>
      </div>

    </mat-expansion-panel>
  </ng-container>
</div>
