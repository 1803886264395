import { Injectable } from '@angular/core';
import { BehaviorSubject } from "rxjs";

@Injectable()
export class TreeService {

  selectedTreeNode$ = new BehaviorSubject<string>('');
  setSelectedTreeNode(id) {
    if(this.selectedTreeNode$.value != id) {
      this.selectedTreeNode$.next(id);
    }
  }

  hasCurrentNode(): boolean {
    return this.selectedTreeNode$.value != '';
  }

  getCurrentNode() {
    return this.selectedTreeNode$.value;
  }
}
