<ng-container ngModelGroup="dispatchableFields">
    <mat-expansion-panel (opened)="panelOpenState = true"
                         (closed)="panelOpenState = false"
                         (afterExpand)="onPanelExpand()"
                         (afterCollapse)="onPanelExpand()"
                         togglePosition="before"
                         class="expansion-panel"
                         id="product-utility-portal-panel"
                         data-automation="productUtilityPortalPanel">

      <mat-expansion-panel-header collapsedHeight="48px" expandedHeight="48px">
        <mat-panel-title class="panel-title">
          <div data-automation="productUtilityPortalPanelTitle">
            {{ 'common.product.utility_portal' | translate }}
          </div>
        </mat-panel-title>
      </mat-expansion-panel-header>

      <!--- CREATE/EDIT --->
      <div *ngIf="(isCreateMode || isEditMode) && product" class="panel-content">
        <div class="form-group">

          <!--- management email --->
          <div class="form-entry">
            <mat-form-field appearance="outline" floatLabel="always">
              <mat-label>{{ 'common.dispatch_conf.management_email' | translate }}</mat-label>
              <input
                matInput
                id="managementEmail"
                type="email"
                #managementEmail="ngModel"
                name="managementEmail"
                placeholder="{{ 'product.create.placeholder.dispatch_conf.management_email' | translate }}"
                maxlength="80"
                email
                pattern="^([A-Za-z0-9_\-\.]+)@[A-Za-z0-9-]+(\.[A-Za-z0-9-]+)*(\.[A-Za-z]{2,3})$"
                [(ngModel)]="product.dispatchConf.managementEmail"
                data-automation="managementEmail"
                data-url-persist
              />

              <mat-icon matSuffix svgIcon="enelx:general-info"
                        data-automation="productManagementEmailTooltip"
                        class="tooltip-icon"
                        [matTooltipPosition]="'below'"
                        matTooltip="{{ 'common.dispatch_conf.management_email.tooltip' | translate }}"
              ></mat-icon>

              <mat-error
                *ngIf="managementEmail.invalid && (managementEmail.dirty || managementEmail.touched)"
                class="alert alert-danger"
              >
                <mat-error *ngIf="managementEmail.errors.pattern">
                  <span class="input-title"></span>
                  <span class="input-entry">{{ 'common.errors.email' | translate }}</span>
                </mat-error>
              </mat-error>
            </mat-form-field>
          </div>

          <!--- dispatch instructions --->
          <div class="form-entry-full-width pad-2-b">
            <mat-form-field appearance="outline" floatLabel="always">
              <mat-label>{{ 'common.dispatch_conf.dispatch_instructions' | translate }}:</mat-label>
              <textarea
                matInput
                id="dispatchInstructions"
                #dispatchInstructions="ngModel"
                type="text"
                rows="10"
                name="dispatchInstructions"
                placeholder="{{
                                'product.create.placeholder.dispatch_conf.dispatch_instructions' | translate
                            }}"
                maxlength="1000"
                [(ngModel)]="product.dispatchConf.dispatchInstructions"
                data-automation="dispatchInstructions"
                data-url-persist
              ></textarea>

              <mat-icon matSuffix svgIcon="enelx:general-info"
                        data-automation="productDispatchInstructionsTooltip"
                        class="tooltip-icon"
                        [matTooltipPosition]="'below'"
                        matTooltip="{{ 'common.dispatch_conf.dispatch_instructions.tooltip' | translate }}"
              ></mat-icon>

              <mat-hint
              >{{ 1000 - dispatchInstructions.value?.length }}
                {{ 'common.characters_remaining' | translate }}</mat-hint
              >
            </mat-form-field>
          </div>

        </div>
      </div>

      <!--- VIEW --->
      <div *ngIf="isViewMode && product" class="panel-content">
        <div class="form-group">
          <!--- management email --->
          <div class="form-entry">
            <mat-label class="info-title">{{ 'common.dispatch_conf.management_email' | translate }}

              <mat-icon
                svgIcon="enelx:general-info"
                data-automation="productManagementEmailTooltip"
                class="tooltip-icon icon-container"
                [matTooltipPosition]="'below'"
                matTooltip="{{ 'common.dispatch_conf.management_email.tooltip' | translate }}"
              ></mat-icon>

            </mat-label>
            <mat-label class="input-entry" data-automation="productManagementEmail">
              {{ product.dispatchConf?.managementEmail }}
            </mat-label>
          </div>

          <!--- dispatch instructions --->
          <div class="form-entry">
            <mat-label class="info-title">{{ 'common.dispatch_instructions' | translate }}

              <mat-icon
                svgIcon="enelx:general-info"
                data-automation="productDispatchInstructionsTooltip"
                class="tooltip-icon icon-container"
                [matTooltipPosition]="'below'"
                matTooltip="{{ 'common.dispatch_conf.dispatch_instructions.tooltip' | translate }}"
              ></mat-icon>

            </mat-label>
            <mat-label class="input-entry" data-automation="productDispatchInstructions">
              {{ product.dispatchInstructions }}
            </mat-label>
          </div>
        </div>
      </div>

      <app-product-dispatch-limits
        [mode]="mode"
        [locale]="locale"
        [product]="product"
        [frm]="frm"
      ></app-product-dispatch-limits>

    </mat-expansion-panel>
</ng-container>
