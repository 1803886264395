import { Injectable } from '@angular/core';
import { User } from '../model/user.model';
import { ApiService } from './api.service';
import { Permission } from './auth-guard.service';

const appName = 'UTILITY_SERVICE_ACCOUNT_MANAGEMENT';

@Injectable()
export class UserService {
  redirectUrl: string;
  private user: User;
  private permissions: Permission[];

  constructor(private apiService: ApiService) {
    this.apiService = apiService;
  }

  /**
   * Get's a user from the backend and stores it in cache for reuse
   *
   * @returns {Promise<User>}
   */
  getUser(): Promise<User> {
    return new Promise((resolve, reject) => {
      if (this.user) {
        resolve(this.user);
      } else {
        this.apiService.get('user').then(
          (dataUser) => {
            this.user = new User();
            this.user.firstName = dataUser.first_name;
            this.user.lastSystemName = dataUser.last_system_name;
            this.user.userType = dataUser.user_type;
            this.user.userClass = dataUser.user_class;
            this.user.email = dataUser.email;
            this.user.phone = dataUser.phone;
            this.user.countryCode = dataUser.country;
            this.user.username = dataUser.username;
            this.user.userId = dataUser.user_id;
            this.user.brand = dataUser.brand;
            this.user.locale = dataUser.locale;
            this.user.primaryOrg = dataUser.primary_org;
            resolve(this.user);
          },
          (err) => {
            console.log('Error: UserService - could not fetch user');
            console.log(JSON.stringify(err));
            reject(err);
          },
        );
      }
    });
  }

  /**
   * Get the Permissions for the Markets app that the logged in User has.
   *
   * @returns {Promise<User>}
   */
  async getPermissions(): Promise<Permission[]> {
    if (this.permissions) {
      return this.permissions;
    } else {
      try {
        const perms = await this.apiService.get('user/permissions');
        const marketPerms = perms && perms[appName] ? perms[appName] : [];
        return marketPerms;
      } catch (err) {
        console.log('Error: UserService - could not fetch user permissions');
        console.log(JSON.stringify(err));
        throw err;
      }
    }
  }
}
