import { Injectable } from '@angular/core';
import * as convertKeys from 'convert-keys';
import { ApiService } from './api.service';
import { Program } from '../model/program.model';
import { BehaviorSubject, Subject } from 'rxjs';
import { ProgramStub } from '../model/program-stub.model';
import { ProductStub } from '../model/product-stub.model';

@Injectable()
export class ProgramsService {
  programs$: BehaviorSubject<Program[]>;
  program$: BehaviorSubject<Program>;
  partialSuccessWarning$ = new Subject<any>();
  conflictError$ = new Subject<any>();
  private programsArr;

  constructor(private apiService: ApiService) {
    this.programs$ = new BehaviorSubject<Program[]>([]);
    this.program$ = new BehaviorSubject<Program>(null);
    this.programsArr = [];
  }

  async fetchPrograms() {
    try {
      const programs = await this.apiService.get(`programs`);
      if (this.programsArr && Array.isArray(this.programsArr)) {
        while (this.programsArr.length > 0) {
          this.programsArr.pop();
        }
      }
      this.programsArr.push(...programs.map((program: any) => this.newProgram(program)));
      this.programs$.next(this.programsArr);
    } catch (err) {
      console.log('Could not load programs list.', err);
    }
  }

  async fetchProgram(id: string) {
    if (id === null || id === '-1') {
      return;
    }
    let programResp = await this.apiService.get(`programs/${id}`);

    if (programResp.isConflict || programResp.isPartialSuccess) {
      if (programResp.isConflict) {
        const { data } = programResp;
        programResp = data.program;
        this.conflictError$.next(programResp);
      } else if (programResp.isPartialSuccess) {
        programResp = programResp.program;
        programResp.incomplete = true;
        this.partialSuccessWarning$.next(null);
      }
    }

    const program = convertKeys.toCamel<any>(programResp);

    program.displayLabels = programResp.display_labels;
    program.descriptions = programResp.descriptions || {};
    program.shortDisplayLabels = programResp.short_display_labels || {};

    if (program.children) {
      for (let i = 0; i < program.children?.length; i ++) {
        program.children[i].displayLabels = programResp.children[i].display_labels;
        program.children[i].descriptions = programResp.children[i].descriptions || {};
      }
    }

    program.programExternalReferenceId = program.alternateIds ? program.alternateIds.programExternalReferenceId : '';
    program.classicProgramName = program.alternateIds ? program.alternateIds.classicProgramName : '';

    const { accountNumberDisplayLabel, currencyCode, holidayCalendarName } = program;
    program.isDispatchableProgram =
      accountNumberDisplayLabel !== undefined && currencyCode !== undefined && holidayCalendarName !== undefined;
    const newProgram = Program.create({ ...program });
    this.program$.next({ ...program, ...newProgram });
  }

  async createProgram(program: Program) {
    const dto = convertKeys.toSnake<any>(program);
    dto.default_locale = program.locale;
    dto.display_labels = program.displayLabels;
    dto.short_display_labels = program.shortDisplayLabels;
    dto.descriptions = program.descriptions;
    delete dto.alternate_ids;
    let createdProgram;

    if (program.isDispatchableProgram) {
      createdProgram = await this.apiService.post(`programs`, dto);
    } else {
      createdProgram = await this.apiService.post(`program-stubs`, dto);
    }
    return this.newProgram({ ...createdProgram, isDispatchableProgram: program.isDispatchableProgram });
  }

  async deleteProgram(programId: string, isDispatchableProgram) {
    if (isDispatchableProgram) {
      return await this.apiService.delete(`programs/${programId}`);
    } else {
      return await this.apiService.delete(`program-stubs/${programId}`);
    }
  }

  async updateProgram(program: Program) {
    const { id } = program;
    const dto = convertKeys.toSnake<any>(program);
    dto.default_locale = program.locale;
    dto.display_labels = program.displayLabels;
    dto.short_display_labels = program.shortDisplayLabels;
    dto.descriptions = program.descriptions;
    delete dto.children;
    delete dto.alternate_ids;

    let updatedProgram;

    if (program.isDispatchableProgram) {
      updatedProgram = await this.apiService.put(`programs/${id}`, dto);
    } else {
      updatedProgram = await this.apiService.put(`program-stubs/${id}`, dto);
    }

    return this.newProgram({ ...updatedProgram, isDispatchableProgram: program.isDispatchableProgram });
  }

  newProgram(rawProgram: any) {
    const program = convertKeys.toCamel<any>(rawProgram);

    program.displayLabels = rawProgram.display_labels;
    program.descriptions = rawProgram.descriptions || {};
    program.short_display_labels = rawProgram.short_display_labels || {};

    program.programExternalReferenceId = program.alternateIds ? program.alternateIds.programExternalReferenceId : '';
    program.classicProgramName = program.alternateIds ? program.alternateIds.classicProgramName : '';

    if (program.isDispatchableProgram) {
      return Program.create(program);
    } else {
      if (!program.children) {
        program.children = [];

        program.children = (program.children || []).map((product: any) => {
          return ProductStub.create(convertKeys.toCamel<any>(product));
        });
      }

      return ProgramStub.create(program);
    }
  }
}
