<div
    ngModelGroup="app-program"
    id="programForm"
    [ngClass]="isEditMode ? 'editing' : isCreateMode ? 'creating' : 'viewing'"
>
    <div class="form-wrapper">
        <app-program-blue-button-fields [mode]="mode" [frm]="frm" [program]="program"></app-program-blue-button-fields>
        <mat-expansion-panel
            (opened)="toggleDispatchableProgram()"
            (closed)="toggleDispatchableProgram()"
            [expanded]="program.isDispatchableProgram"
            togglePosition="before"
            class="expansion-panel nested-panel dispatchable-fields"
            data-automation="dispatchableFieldsPanel" id="dispatchableFieldsPanel" name="dispatchableFieldsPanel"
            [ngClass]="{ failure: showDispatchableFieldsError }"
            *ngIf="isCreateMode || isEditMode"
        >
            <mat-expansion-panel-header class="panel-override" collapsedHeight="48px" expandedHeight="72px">
                <mat-panel-title class="panel-title">
                    <div data-automation="dispatchableFieldsPanelTitle">
                        {{ 'program.program_dispatch_fields' | translate }}
                    </div>
                </mat-panel-title>
                <mat-panel-description>
                    <button
                        *ngIf="panelOpenedState && dispatchableForm?.dirty && !program?.isDispatchableProgram"
                        class="icon-btn x-btn-secondary panel-btn"
                        (click)="handleClearClick($event)"
                    >
                        {{ 'common.button.clear' | translate }}
                    </button>
                </mat-panel-description>
            </mat-expansion-panel-header>
            <div class="panel-content-wrapper">
                <app-program-dispatchable-fields
                    [mode]="mode"
                    [program]="program"
                    [frm]="frm"
                ></app-program-dispatchable-fields>
            </div>
        </mat-expansion-panel>

        <ng-container *ngIf="isViewMode && program?.isDispatchableProgram">
            <app-program-dispatchable-fields
                [mode]="mode"
                [program]="program"
                [frm]="frm"
            ></app-program-dispatchable-fields>
        </ng-container>
    </div>


    <ng-container *ngIf="isViewMode">
        <div class="form-header with-button">
            <h4 id="products-title" data-automation="productsListTitle">
                {{ 'common.products' | translate }}
            </h4>
            <button (click)="routeToCreatePage('Product')" class="icon-btn x-btn-primary">
                <mat-icon>add</mat-icon>
                <span class="btn-text">{{ 'list.button.create' | translate }}</span>
            </button>
        </div>

        <ng-container *ngIf="hasProducts()">
            <ng-container *ngFor="let product of products; let productIndex = index">
                <app-nested-product [product]="product" [index]="productIndex"> </app-nested-product>
            </ng-container>
        </ng-container>
        <ng-container *ngIf="!hasProducts()">
            <div>{{ 'program.no_products' | translate }}</div>
        </ng-container>
    </ng-container>
</div>
