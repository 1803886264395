<div data-automation="productDispatchLimitsPanel" ngModelGroup="dispatchableFields">
  <ng-container>
    <div class="form-header form-header-subsection">{{ 'common.product.dispatch_limits' | translate }}
      <div class="label-container">
        <mat-icon
          svgIcon="enelx:general-info"
          data-automation="productDispatchLimitsTooltip"
          class="tooltip-icon icon-container"
          [matTooltipPosition]="'below'"
          matTooltip="{{ 'common.product.dispatch_limits.tooltip' | translate }}"
        ></mat-icon>
      </div>
    </div>
    <!--- CREATE/EDIT --->
    <div *ngIf="(isCreateMode || isEditMode) && product" class="panel-content">
      <div class="form-group">

        <!--- Max Events Per Day --->
        <div class="form-entry">
          <mat-form-field appearance="outline" floatLabel="always">
            <mat-label>{{ 'common.dispatch_conf.max_events_per_day' | translate }}</mat-label>
            <input
              matInput
              id="maxEventsPerDay"
              type="number"
              step="1"
              #maxEventsPerDay="ngModel"
              name="maxEventsPerDay"
              placeholder="0"
              maxlength="16"
              [min]="0"
              [(ngModel)]="product.dispatchConf.maxEventsPerDay"
              data-automation="maxEventsPerDay"
              data-url-persist
            />
          </mat-form-field>
        </div>

        <!--- Max Events Per Week --->
        <div class="form-entry">
          <mat-form-field appearance="outline" floatLabel="always">
            <mat-label>{{ 'common.dispatch_conf.max_events_per_week' | translate }}</mat-label>
            <input
              matInput
              id="maxEventsPerWeek"
              type="number"
              step="1"
              #maxEventsPerWeek="ngModel"
              name="maxEventsPerWeek"
              placeholder="0"
              maxlength="16"
              [min]="0"
              [(ngModel)]="product.dispatchConf.maxEventsPerWeek"
              data-automation="maxEventsPerWeek"
              data-url-persist
            />
          </mat-form-field>
        </div>

        <!--- Max Events Per Month --->
        <div class="form-entry">
          <mat-form-field appearance="outline" floatLabel="always">
            <mat-label>{{ 'common.dispatch_conf.max_events_per_month' | translate }}</mat-label>
            <input
              matInput
              id="maxEventsPerMonth"
              type="number"
              step="1"
              #maxEventsPerMonth="ngModel"
              name="maxEventsPerMonth"
              placeholder="0"
              maxlength="16"
              [min]="0"
              [(ngModel)]="product.dispatchConf.maxEventsPerMonth"
              data-automation="maxEventsPerMonth"
              data-url-persist
            />
          </mat-form-field>
        </div>

        <!--- Season Reset Date --->
        <div class="form-entry">
          <mat-form-field appearance="outline" floatLabel="always">
            <mat-label>{{ 'common.season_reset_date' | translate }}:</mat-label>
            <input
              matInput
              id="seasonResetDate"
              #seasonResetDate="ngModel"
              type="text"
              name="seasonResetDate"
              placeholder="{{ 'product.create.placeholder.cron_expression' | translate }}"
              maxlength="80"
              [(ngModel)]="product.seasonResetDate"
              data-automation="seasonResetDate"
              data-url-persist
              required
              cronValidator
            />

            <mat-icon
              matSuffix
              svgIcon="enelx:general-info"
              data-automation="seasonResetDateTooltip"
              class="tooltip-icon icon-container"
              [matTooltipPosition]="'below'"
              matTooltip="{{ 'common.season_reset_date.tooltip' | translate }}"
            ></mat-icon>

            <mat-error *ngIf="seasonResetDate.invalid" class="alert alert-danger">
              <mat-error *ngIf="seasonResetDate.errors.required">
                <span class="input-title"></span>
                <span>{{ 'common.validation.required' | translate }}</span>
              </mat-error>
              <mat-error *ngIf="seasonResetDate.errors.cronValid">
                <span class="input-title"></span>
                <span>{{ 'common.errors.non_cron_expression' | translate }}</span>
              </mat-error>
            </mat-error>
          </mat-form-field>
        </div>

        <!--- Max Events per Season --->
        <div class="form-entry">
          <mat-form-field appearance="outline" floatLabel="always">
            <mat-label>{{ 'common.max_events_per_season' | translate }}</mat-label>
            <input
              matInput
              id="maxEventsPerSeason"
              type="number"
              min="0"
              step="1"
              #maxEventsPerSeason="ngModel"
              name="maxEventsPerSeason"
              placeholder="20"
              maxlength="16"
              [(ngModel)]="product.maxEventsPerSeason"
              data-automation="maxEventsPerSeason"
              data-url-persist
              required
            />
            <mat-error *ngIf="maxEventsPerSeason.invalid" class="alert alert-danger">
              <mat-error *ngIf="maxEventsPerSeason.errors.required">
                <span class="input-title"></span>
                <span class="input-entry">{{ 'common.validation.required' | translate }}</span>
              </mat-error>
            </mat-error>
          </mat-form-field>
        </div>

        <!--- Max Event Hours Per Season --->
        <div class="form-entry">
          <mat-form-field appearance="outline" floatLabel="always">
            <mat-label>{{ 'common.max_event_hours_per_season' | translate }}</mat-label>
            <input
              matInput
              id="maxEventHoursPerSeason"
              type="number"
              min="0"
              step="1"
              #maxEventHoursPerSeason="ngModel"
              name="maxEventHoursPerSeason"
              placeholder="80"
              maxlength="16"
              [(ngModel)]="product.maxEventHoursPerSeason"
              data-automation="maxEventHoursPerSeason"
              data-url-persist
              required
            />
            <mat-error *ngIf="maxEventHoursPerSeason.invalid" class="alert alert-danger">
              <mat-error *ngIf="maxEventHoursPerSeason.errors.required">
                <span class="input-title"></span>
                <span class="input-entry">{{ 'common.validation.required' | translate }}</span>
              </mat-error>
            </mat-error>
          </mat-form-field>
        </div>

        <ng-container
          ngModelGroup="minMaxEventDuration"
          #minMaxEventDuration="ngModelGroup"
          minMaxValidator
          [minControl]="minEventDuration"
          [maxControl]="maxEventDuration"
        >
          <!--- min event duration --->
          <div class="form-entry">
            <mat-form-field appearance="outline" floatLabel="always">
              <mat-label>{{ 'common.min_event_duration' | translate }}</mat-label>
              <input
                matInput
                id="minEventDuration"
                min="0"
                type="number"
                step="1"
                #minEventDuration="ngModel"
                name="minEventDuration"
                placeholder="300000"
                maxlength="16"
                [(ngModel)]="product.minEventDuration"
                data-automation="minEventDuration"
                data-url-persist
                required
              />
              <mat-error *ngIf="minEventDuration.invalid" class="alert alert-danger">
                <mat-error *ngIf="minEventDuration.errors.required">
                  <span class="input-title"></span>
                  <span class="input-entry">{{ 'common.validation.required' | translate }}</span>
                </mat-error>
              </mat-error>
            </mat-form-field>
          </div>

          <!--- max event duration --->
          <div class="form-entry">
            <mat-form-field appearance="outline" floatLabel="always">
              <mat-label>{{ 'common.max_event_duration' | translate }}</mat-label>
              <input
                matInput
                id="maxEventDuration"
                type="number"
                min="0"
                step="1"
                #maxEventDuration="ngModel"
                name="maxEventDuration"
                placeholder="28800000"
                maxlength="16"
                [(ngModel)]="product.maxEventDuration"
                data-automation="maxEventDuration"
                data-url-persist
                required
              />

              <mat-icon
                matSuffix
                svgIcon="enelx:general-info"
                data-automation="maxEventDurationTooltip"
                class="tooltip-icon icon-container"
                [matTooltipPosition]="'below'"
                matTooltip="{{ 'common.max_event_duration.tooltip' | translate }}"
              ></mat-icon>

              <mat-error *ngIf="maxEventDuration.invalid" class="alert alert-danger">
                <mat-error *ngIf="maxEventDuration.errors.required">
                  <span class="input-title"></span>
                  <span class="input-entry">{{ 'common.validation.required' | translate }}</span>
                </mat-error>
              </mat-error>
            </mat-form-field>
          </div>

          <mat-error
            class="group-error"
            *ngIf="minMaxEventDuration.invalid && (minMaxEventDuration.dirty || minMaxEventDuration.touched)"
          >
            <mat-error *ngIf="minMaxEventDuration.errors && minMaxEventDuration.errors.minMaxValid">
              <span class="input-title"></span>
              <span class="input-entry" data-automation="validationMessage">{{
                  'common.errors.max_min_val' | translate
                }}</span>
            </mat-error>
          </mat-error>
        </ng-container>

        <!--- Advance Notice --->
        <div class="form-entry">
          <mat-form-field appearance="outline" floatLabel="always">
            <mat-label>{{ 'common.advance_notice' | translate }}</mat-label>
            <input
              matInput
              id="advanceNotice"
              type="number"
              step="1"
              #advanceNotice="ngModel"
              name="advanceNotice"
              placeholder="0"
              maxlength="16"
              [(ngModel)]="product.advanceNotice"
              data-automation="advanceNotice"
              data-url-persist
              required
            />

            <mat-icon matSuffix svgIcon="enelx:general-info" data-automation="advanceNoticeTooltip" class="tooltip-icon icon-container" [matTooltipPosition]="'below'" matTooltip="{{ 'common.advance_notice.tooltip' | translate }}"></mat-icon>

            <mat-error *ngIf="advanceNotice.invalid" class="alert alert-danger">
              <mat-error *ngIf="advanceNotice.errors.required">
                <span class="input-title"></span>
                <span class="input-entry">{{ 'common.validation.required' | translate }}</span>
              </mat-error>
            </mat-error>
          </mat-form-field>
        </div>

        <!--- Min Duration Between Events --->
        <div class="form-entry">
          <mat-form-field appearance="outline" floatLabel="always">
            <mat-label>{{ 'common.min_duration_between_events' | translate }}</mat-label>
            <input
              matInput
              id="minDurationBetweenEvents"
              type="number"
              min="0"
              step="1"
              #minDurationBetweenEvents="ngModel"
              name="minDurationBetweenEvents"
              placeholder="300000"
              maxlength="16"
              [(ngModel)]="product.minDurationBetweenEvents"
              data-automation="minDurationBetweenEvents"
              data-url-persist
              required
            />

            <mat-icon
              matSuffix
              svgIcon="enelx:general-info"
              data-automation="minDurationBetweenEventsTooltip"
              class="tooltip-icon icon-container"
              [matTooltipPosition]="'below'"
              matTooltip="{{ 'common.min_duration_between_events.tooltip' | translate }}"
            ></mat-icon>

            <mat-error *ngIf="minDurationBetweenEvents.invalid" class="alert alert-danger">
              <mat-error *ngIf="minDurationBetweenEvents.errors.required">
                <span class="input-title"></span>
                <span class="input-entry">{{ 'common.validation.required' | translate }}</span>
              </mat-error>
            </mat-error>
          </mat-form-field>
        </div>

      </div>
    </div>

    <!--- VIEW --->
    <div *ngIf="isViewMode && product" class="panel-content">
      <div class="form-group">
        <!--- Max Events Per Day --->
        <div class="form-entry">
          <mat-label class="info-title">{{ 'common.dispatch_conf.max_events_per_day' | translate }}</mat-label>
          <mat-label class="input-entry" data-automation="productMaxEventsPerDay">
            {{ product.dispatchConf.maxEventsPerDay }}
          </mat-label>
        </div>

        <!--- Max Events Per Week --->
        <div class="form-entry">
          <mat-label class="info-title">{{ 'common.dispatch_conf.max_events_per_week' | translate }}</mat-label>
          <mat-label class="input-entry" data-automation="productMaxEventsPerWeek">
            {{ product.dispatchConf.maxEventsPerWeek }}
          </mat-label>
        </div>

        <!--- Max Events Per Month --->
        <div class="form-entry">
          <mat-label class="info-title">{{ 'common.dispatch_conf.max_events_per_month' | translate }}</mat-label>
          <mat-label class="input-entry" data-automation="productMaxEventsPerMonth">
            {{ product.dispatchConf.maxEventsPerMonth }}
          </mat-label>
        </div>

        <!--- Season Reset Date --->
        <div class="form-entry">
          <mat-label class="info-title">{{ 'common.season_reset_date' | translate }}

            <mat-icon
              matSuffix
              svgIcon="enelx:general-info"
              data-automation="seasonResetDateTooltip"
              class="tooltip-icon icon-container"
              [matTooltipPosition]="'below'"
              matTooltip="{{ 'common.season_reset_date.tooltip' | translate }}"
            ></mat-icon>

          </mat-label>
          <mat-label class="input-entry" data-automation="productSeasonResetDate">
            {{ product.seasonResetDate }}
          </mat-label>
        </div>

        <!--- Max Events per Season --->
        <div class="form-entry">
          <mat-label class="info-title">{{ 'common.max_events_per_season' | translate }}</mat-label>
          <mat-label class="input-entry" data-automation="productMaxEventsPerSeason">
            {{ product.maxEventsPerSeason }}
          </mat-label>
        </div>

        <!--- Max Event Hours Per Season --->
        <div class="form-entry">
          <mat-label class="info-title">{{ 'common.max_event_hours_per_season' | translate }}</mat-label>
          <mat-label class="input-entry" data-automation="productMaxEventHoursPerSeason">
            {{ product.maxEventHoursPerSeason }}
          </mat-label>
        </div>

        <!--- Min Event Duration --->
        <div class="form-entry">
          <mat-label class="info-title">{{ 'common.min_event_duration' | translate }}</mat-label>
          <mat-label class="input-entry" data-automation="productMinEventDuration">
            {{ product.minEventDuration }}
          </mat-label>
        </div>

        <!--- Max Event Duration --->
        <div class="form-entry">
          <mat-label class="info-title">{{ 'common.max_event_duration' | translate }}

            <mat-icon
              matSuffix
              svgIcon="enelx:general-info"
              data-automation="maxEventDurationTooltip"
              class="tooltip-icon icon-container"
              [matTooltipPosition]="'below'"
              matTooltip="{{ 'common.max_event_duration.tooltip' | translate }}"
            ></mat-icon>

          </mat-label>
          <mat-label class="input-entry" data-automation="productMaxEventDuration">
            {{ product.maxEventDuration }}
          </mat-label>
        </div>

        <!--- Advance Notice --->
        <div class="form-entry">
          <mat-label class="info-title">{{ 'common.advance_notice' | translate }}

            <mat-icon
              matSuffix
              svgIcon="enelx:general-info"
              data-automation="advanceNoticeTooltip"
              class="tooltip-icon icon-container"
              [matTooltipPosition]="'below'"
              matTooltip="{{ 'common.advance_notice.tooltip' | translate }}"
            ></mat-icon>

          </mat-label>
          <mat-label class="input-entry" data-automation="productAdvanceNotice">
            {{ product.advanceNotice }}
          </mat-label>
        </div>

        <!--- Min Duration Between Events --->
        <div class="form-entry">
          <mat-label class="info-title">{{ 'common.min_duration_between_events' | translate }}

            <mat-icon
              matSuffix
              svgIcon="enelx:general-info"
              data-automation="minDurationBetweenEventsTooltip"
              class="tooltip-icon icon-container"
              [matTooltipPosition]="'below'"
              matTooltip="{{ 'common.min_duration_between_events.tooltip' | translate }}"
            ></mat-icon>

          </mat-label>
          <mat-label class="input-entry" data-automation="productMinDurationBetweenEvents">
            {{ product.minDurationBetweenEvents }}
          </mat-label>
        </div>

      </div>
    </div>

  </ng-container>
</div>
