import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { ControlContainer, UntypedFormGroup, NgForm } from '@angular/forms';
import { Product } from '../../shared/model/product.model';
import { CookieService as NgxCookieService } from 'ngx-shared-services';
import { NgxDeeplinkerService } from 'ngx-deeplinker';
import { ActivatedRoute } from '@angular/router';
import { ProductsService } from '../../shared/services/products.service';
import { Context, ContextSelectorService } from 'ngx-global-nav';
import { DeeplinksService } from '../../shared/services/deeplinks.service';
import { TranslateService } from '@ngx-translate/core';
import { ProgramsService } from '../../shared/services/programs.service';
import { Program } from '../../shared/model/program.model';
import { DateAdapter } from '@angular/material/core';
import { RefService } from '../../shared/services/ref.service';
import { FormValidatorService } from '../../shared/services/form-validator.service';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-product',
  templateUrl: './product-details.component.html',
  styleUrls: ['../../shared/shared.styles.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class ProductDetailsComponent implements OnInit, OnChanges {
  CREATE_NEW_PRODUCT = 'Create New Product';
  REQUIRED = 'required';

  org = '';
  idToPopulate = 'Program';
  appPrefix = 'mkt';
  deeplinkUrl: string;
  deeplinkerData: any;
  locale: string;
  isDispatchableProduct = false;
  _showProgram = true;
  product: Product;
  _program: Program;
  panelOpenedState = false;
  showDispatchableFieldsError = false;
  isInit = true;
  clone = false;
  parent: any;
  cloneLabel = ' Clone';
  cleanCloneLabelOnChild: boolean;

  changes: any;

  constructor(
    private ngxCookieService: NgxCookieService,
    private ngxDeeplinkerService: NgxDeeplinkerService,
    private route: ActivatedRoute,
    private productsService: ProductsService,
    private programsService: ProgramsService,
    private orgSelectorService: ContextSelectorService,
    private deeplinksInternalService: DeeplinksService,
    private translateService: TranslateService,
    private refService: RefService,
    private formValidatorService: FormValidatorService,
    private _adapter: DateAdapter<any>,
    private ref: ChangeDetectorRef,
  ) {
    this.route.queryParams.subscribe(async (queryParams) => {
      if (queryParams.clone) {
        this.clone = true;
      }
    });
    this.product = Product.create({});
    this._program = Program.create({});

    this.locale = ngxCookieService.getCookie('locale') || 'en_US';
    this._adapter.setLocale(this.locale || 'en_US');

    this.deeplinksInternalService.deeplink$.subscribe(async (deeplinkUrl) => {
      this.deeplinkUrl = deeplinkUrl;
    });

    this.translateService.get('product.create.button.create_newproduct').subscribe((result: string) => {
      this.CREATE_NEW_PRODUCT = result;
      this.REQUIRED = this.translateService.instant('product.validation.required');
    });

    this.deeplinksInternalService.setMarketAdminUrl();
    this.refService.getLocales();
    this.refService.getTimezones();
    this.refService.getRefMetadata();
  }
  @Input() mode: string;
  @Input() frm;
  @Input()
  set showProgram(showProgram: any) {
    this._showProgram = showProgram;
  }

  get showProgram() {
    return this._showProgram;
  }

  @Input('cleanCloneLabel')
  set cleanCloneLabel(cleanCloneLabel: any) {
    this.cleanCloneLabelOnChild = cleanCloneLabel;
  }

  get theForm() {
    return this.frm.form as UntypedFormGroup;
  }

  get blueButtonForm() {
    if (this.frm) {
      return this.frm.form.get('blueButtonFields') as UntypedFormGroup;
    }
  }

  get dispatchableForm() {
    if (this.frm) {
      return this.frm.form.get('dispatchableFields') as UntypedFormGroup;
    }
  }

  get program() {
    return this._program;
  }

  get isEditMode() {
    return this.mode === 'edit';
  }

  get isCreateMode() {
    return this.mode === 'create';
  }

  get isViewMode() {
    return this.mode === 'view';
  }

  ngOnChanges(changes: SimpleChanges) {
    const mainDataChange = changes.cleanCloneLabel?.currentValue;
    if (mainDataChange) {
      this.cleanCloneLabelOnChild = mainDataChange;
      if (this.cleanCloneLabelOnChild) {
        this.cloneLabel = '';
      }
    }
    this.ref.detectChanges();

    // if the form is in create/edit mode, I'm listening for changes
    if (!this.isViewMode) {
      this.frm.valueChanges.pipe(debounceTime(1000), distinctUntilChanged()).subscribe((changes) => {
        this.changes = this.getDirtyValues(this.frm);
        // dispatch the change to check the type of form
        //this.listenToChanges();
      });
    }
  }
  ngOnInit() {
    if (this.isCreateMode) {
      const deeplinkerData = this.ngxDeeplinkerService.getReturnData(this.appPrefix);
      if (deeplinkerData) {
        if (Object.keys(deeplinkerData).length) {
          const { displayLabel, program } = deeplinkerData;
          this.product.displayLabels[this.product.defaultLocale] = displayLabel || '';
          this.product.programId = program || -1;
        }
      }
    }

    if (!this.isViewMode) {
      setTimeout(() => {
        if (
          this.frm &&
          this.dispatchableForm &&
          !this.dispatchableForm.disabled &&
          !this.product?.isDispatchableProduct &&
          this.isInit
        ) {
          this.dispatchableForm.disable();
          this.isInit = false;
        }
      }, 0);
    }

    this.productsService.product$.subscribe(this.getNext());
  }

  ngOnDestroy(): void {
    this.cloneLabel = '';
  }
  private getNext() {
    return (product: Product) => {
      if (product && product.id) {
        this.populateProduct(product);
      }
    };
  }

  populateProduct(productData) {
    if (productData) {
      this.product = productData;
      if (this.product.baselineConfiguration) {
        this.product.baselineConfiguration.enableEventTriggeredBaseline =
          this.product.baselineConfiguration.enableEventTriggeredBaseline || false;
      }
      if (this.clone) {
        this.product['displayLabel'] = this.product['displayLabel'] + this.cloneLabel;
      }
      if (this.isEditMode) {
        setTimeout(() => {
          this.formValidatorService.triggerFormValidation(this.frm.form);
        }, 1000);
      }
    }
  }

  resetPrograms() {
    this.product.programId = '-1';
  }

  resetDeeplinkerData() {
    this.deeplinkerData = {};
  }

  resetForm() {
    this.resetPrograms();
    this.resetDeeplinkerData();
  }

  clearDispatch($event) {
    $event.stopPropagation();
    const newProduct = Product.create({});
    const blueButtonFields = this.blueButtonForm.getRawValue();
    this.product = { ...newProduct, ...blueButtonFields };
    this.formValidatorService.clearFormControlErrors(this.dispatchableForm);
    this.dispatchableForm.markAsPristine({ onlySelf: false });
  }

  listenToChanges() {
    if (this.changes && this.changes.blueButtonFields) {
      // if we have changes in the form and we have only blue button fields
      this.dispatchableForm.disable();

      this.isDispatchableProduct = false;
      if (this.blueButtonForm.touched) {
        // if the blue button form is touched, we trigger the validation
        this.formValidatorService.triggerValidation(this.blueButtonForm);
      }
    }

    if (this.changes && this.changes.dispatchableFields) {
      // if we have changes in the form and we have the dispatchable fields also
      this.dispatchableForm.enable();

      this.isDispatchableProduct = true;
      if (this.dispatchableForm.touched) {
        // if the blue button form is touched, we trigger the validation
        this.formValidatorService.triggerValidation(this.dispatchableForm);
      }
    }
  }

  formStates = {
    dispatch: false,
    performance: false,
    baseline: false,
    customer: false,
    utility: false,
  };

  // this function is used to toggle the dispatchable product and is called from the sub component (child to parent)
  toggleDispatchableProduct(form: string) {
    if (!this.isViewMode) {
      this.formStates[form] = !this.formStates[form];
      // One panel was enabled
      if (this.formStates[form]) {
        this.dispatchableForm.enable();
      }
      // If the panel was closed
      else {
        // If every panel was closed
        if (!Object.values(this.formStates).some((formState) => formState == true)) {
          if (!this.dispatchableForm.dirty) {
            this.dispatchableForm.disable();
          }
        }
      }
    }
  }

  // this function is used to get only the dirty values from the form
  getDirtyValues(form: any) {
    const dirtyValues = {};

    Object.keys(form.controls).forEach((key) => {
      const currentControl = form.controls[key];

      if (currentControl.dirty) {
        if (currentControl.controls) dirtyValues[key] = this.getDirtyValues(currentControl);
        else dirtyValues[key] = currentControl.value;
      }
    });

    return dirtyValues;
  }
}
