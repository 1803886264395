import { Directive } from '@angular/core';
import { UntypedFormGroup, NG_VALIDATORS, Validator } from '@angular/forms';

@Directive({
  selector: '[selectionBufferValidator]',
  providers: [{ provide: NG_VALIDATORS, useExisting: SelectionBufferValidator, multi: true }],
})
export class SelectionBufferValidator implements Validator {
  validate(theForm: UntypedFormGroup) {
    let isValid = true;

    if (
      Object.keys(theForm.controls).length > 0 &&
      theForm.controls.minSelectionBuffer &&
      theForm.controls.maxSelectionBuffer &&
      theForm.controls.selectionUpperTarget
    ) {
      isValid =
        theForm.controls.minSelectionBuffer.value < theForm.controls.maxSelectionBuffer.value &&
        theForm.controls.selectionUpperTarget.value > theForm.controls.maxSelectionBuffer.value;
    }

    if (isValid) {
      return null;
    } else {
      return {
        minMaxValid: {
          valid: false,
        },
      };
    }
  }
}
