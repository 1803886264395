import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { OperatorsService } from '../../shared/services/operators.service';
import { ProductsService } from '../../shared/services/products.service';
import { ProgramsService } from '../../shared/services/programs.service';
import { ErrorHandlingService } from '../../shared/services/error-handling.service';

export interface MarketData {
  marketId: string;
  marketEntityType: string;
  marketEntity: any;
}

@Component({
  selector: 'app-delete-dialog',
  templateUrl: './delete-dialog.component.html',
  styleUrls: ['./delete-dialog.component.scss'],
})
export class DeleteDialogComponent {
  dialogState = 'DEFAULT';
  errorMessage: string;
  errorFields: any;

  constructor(
    private router: Router,
    private operatorsService: OperatorsService,
    private programsService: ProgramsService,
    private productsService: ProductsService,
    private errorHandlingService: ErrorHandlingService,
    public dialogRef: MatDialogRef<DeleteDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: MarketData,
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  async handleDelete() {
    console.log('DELETE', this.data);
    try {
      this.dialogState = 'DELETING';
      switch (this.data.marketEntityType) {
        case 'operator':
          await this.operatorsService.deleteOperator(this.data.marketId);
          break;
        case 'program':
          const { isDispatchableProgram } = this.data.marketEntity;
          await this.programsService.deleteProgram(this.data.marketId, isDispatchableProgram);
          break;
        default:
          const { isDispatchableProduct } = this.data.marketEntity;
          await this.productsService.deleteProduct(this.data.marketId, isDispatchableProduct);
      }
      this.dialogState = 'DELETE_SUCCESS';
      setTimeout(async () => {
        this.dialogRef.close();
        this.operatorsService.refetchOperators();
        await this.router.navigate([`list`]);
      }, 2000);
    } catch (e) {
      const errorMessage = e && e.error ? e.error.message : '';
      this.errorMessage = this.errorHandlingService.getErrorMessage(e);
      if (errorMessage === 'ERR_BAD_REQUEST') {
        // this.messageService.setError(this.BAD_REQUEST);
      } else {
        // this.messageService.setError(this.NOT_DELETED);
      }

      this.dialogState = 'DELETE_FAILED';
      console.log(e);
      setTimeout(() => {
        this.dialogRef.close();
      }, 2000);
    }
  }
}
