import { Component, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthService, GlobalNavService, Space, Context } from 'ngx-global-nav';
import { CookieService as NgxCookieService } from 'ngx-shared-services';
import { environment } from 'src/environments/environment.prod';
import { ApiEndpointsService } from './services/api-endpoints.service';
import { MatSidenav } from '@angular/material/sidenav';
import { SidenavService } from './shared/services/sidenav.service';
import { NavService } from './shared/services/global-nav.service';
import { RefService } from './shared/services/ref.service';
import {MatIconRegistry} from "@angular/material/icon";
import {DomSanitizer} from "@angular/platform-browser";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  @ViewChild('sidenav') public sidenav: MatSidenav;
  orgList: Context[] = [];
  navUrl = '';
  sidenavOpen = true;

  constructor(
    private translate: TranslateService,
    private apiEndpointsService: ApiEndpointsService,
    private globalNavService: GlobalNavService,
    private authService: AuthService,
    private cookieService: NgxCookieService,
    private sidenavService: SidenavService,
    private navService: NavService,
    private refService: RefService,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
  ) {
    iconRegistry.addSvgIconSetInNamespace(
      'enelx',
      sanitizer.bypassSecurityTrustResourceUrl(`../assets/icons/enelx-icons.svg`),
    );
    this.getNavUrl();
  }

  ngOnInit(): void {
    const { defaults } = environment;
    const locale = this.cookieService.getI18NLocale() || defaults.locale;
    const brand = this.cookieService.getI18NBrand() || defaults.brand.toUpperCase();
    this.translate.setDefaultLang(`${defaults.locale}.${defaults.brand.toUpperCase()}`);
    this.translate.use(`${locale}.${brand}`).subscribe(() => {
      this.translate.get('APP.TITLE').subscribe((result) => {
        document.title = result;
      });
    });
    this.refService.setErrorMessages();
  }

  ngAfterViewInit(): void {
    this.sidenavService.setSidenav(this.sidenav);
  }

  async getNavUrl() {
    this.navUrl = await this.navService.getNavUrl();
  }

  isLargeScreen() {
    const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    return width > 1280;
  }

  triggerResize(): void {
    window.dispatchEvent(new Event('resize'));
  }
}
