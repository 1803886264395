<div ngModelGroup="blueButtonFields">
    <ng-container *ngIf="isCreateMode || isEditMode">
        <!--- Operator --->
        <div class="form-entry">
            <mat-form-field appearance="outline" floatLabel="always">
                <mat-label>{{ 'program.create.operator' | translate }}</mat-label>
                <mat-select
                    id="operator"
                    name="operatorId"
                    #operatorId="ngModel"
                    [ngClass]="{
                        failure: operatorId.invalid && (operatorId.dirty || operatorId.touched),
                        'create-select': true
                    }"
                    [(ngModel)]="program.operatorId"
                    data-automation="operatorSelector"
                    data-url-persist
                    [disabled]="isEditMode"
                    required
                    appSelectValid
                    placeholder="{{ 'program.create.placeholder.operator' | translate }}"
                >
                    <mat-option *ngFor="let operator of operators" [value]="operator.id" id="operator_{{ operator.id }}"
                        >{{ operator.displayLabel }}
                    </mat-option>
                </mat-select>
                <mat-error
                    *ngIf="operatorId.invalid && (operatorId.dirty || operatorId.touched)"
                    class="alert alert-danger"
                >
                    <mat-error *ngIf="operatorId.errors.selectValid">
                        {{ 'common.validation.required' | translate }}
                    </mat-error>
                </mat-error>
            </mat-form-field>
        </div>

        <!--    Supported Locales   -->
        <div class="form-entry">
            <mat-form-field appearance="outline" floatLabel="always">
                <mat-label>{{ 'common.supported_locales' | translate }}</mat-label>
                <mat-select
                    id="supportedLocale"
                    name="supportedLocale"
                    #supportedLocale="ngModel"
                    [ngClass]="{
                        failure: supportedLocale.invalid && (supportedLocale.dirty || supportedLocale.touched),
                        'create-select': true,
                        'input-entry': true
                    }"
                    [(ngModel)]="multiLocaleConfig.supportedLocales"
                    (selectionChange)="handleSelectionChange()"
                    data-automation="supportedLocale"
                    required
                    placeholder="{{ 'common.supported_locales.placeholder' | translate }}"
                    multiple
                >
                    <mat-option
                        [disabled]="limitLocaleSelectList(5, locale)"
                        *ngFor="let locale of locales"
                        [value]="locale"
                        >{{ locale.displayLabel }}</mat-option
                    >
                </mat-select>
                <mat-error *ngIf="supportedLocale.invalid">
                    <mat-error *ngIf="supportedLocale.errors.pattern">
                        {{ 'common.validation.required' | translate }}
                    </mat-error>
                </mat-error>
            </mat-form-field>
        </div>

        <!--- Program Display Labels --->

        <div class="form-entry">
            <multi-locale-input
                name="displayLabels"
                [inputLabel]="programLabel"
                [locales]="multiLocaleConfig.supportedLocales"
                [(ngModel)]="program.displayLabels"
                [displayLabelKey]="multiLocaleConfig.displayLabelKey"
                [localeKey]="multiLocaleConfig.localeKey"
                [defaultLocale]="multiLocaleConfig.defaultLocale"
                [placeholderText]="programPlaceholder"
                [required]="true"
                [id]="'display_labels'"
            >
            </multi-locale-input>
        </div>

        <!--- Program Name --->

        <div class="form-entry">
            <mat-form-field appearance="outline" floatLabel="always">
                <mat-label for="programName">{{ 'program.create.program_program_name' | translate }}</mat-label>
                <input
                    matInput
                    id="programName"
                    #programName="ngModel"
                    type="text"
                    [ngClass]="{
                        failure: programName.invalid && (programName.dirty || programName.touched)
                    }"
                    name="programName"
                    placeholder="{{ 'program.create.placeholder.program_program_name' | translate }}"
                    maxlength="80"
                    [(ngModel)]="program.programName"
                    data-automation="programName"
                    data-url-persist
                    required
                />
                <mat-error
                    *ngIf="programName.invalid && (programName.dirty || programName.touched)"
                    class="alert alert-danger"
                >
                    <mat-error *ngIf="programName.errors.required">
                        {{ 'common.validation.required' | translate }}
                    </mat-error>
                </mat-error>
            </mat-form-field>
        </div>

        <!--- Short Labels --->

        <div class="form-entry">
            <multi-locale-input
                    name="shortDisplayLabels"
                    [inputLabel]="shortDisplayLabel"
                    [locales]="multiLocaleConfig.supportedLocales"
                    [(ngModel)]="program.shortDisplayLabels"
                    [displayLabelKey]="multiLocaleConfig.displayLabelKey"
                    [localeKey]="multiLocaleConfig.localeKey"
                    [defaultLocale]="multiLocaleConfig.defaultLocale"
                    [placeholderText]="shortDisplayLabelPlaceholder"
                    [required]="false"
                    [id]="'short_display_labels'"
            >
            </multi-locale-input>
        </div>

        <!--- Alternate IDs --->

        <!--- Program External Reference Id --->

        <div class="form-entry">
            <mat-form-field appearance="outline" floatLabel="always">
                <mat-label for="programExternalReferenceId">{{
                    'program.create.program_external_reference_id' | translate
                }}</mat-label>
                <input
                    matInput
                    id="programExternalReferenceId"
                    #programExternalReferenceId="ngModel"
                    type="text"
                    [ngClass]="{
                        failure:
                            programExternalReferenceId.invalid &&
                            (programExternalReferenceId.dirty || programExternalReferenceId.touched)
                    }"
                    name="programExternalReferenceId"
                    placeholder="{{ 'program.create.placeholder.program_external_reference_id' | translate }}"
                    maxlength="80"
                    [(ngModel)]="program.programExternalReferenceId"
                    data-automation="programExternalReferenceId"
                    data-url-persist
                />
                <mat-error
                    *ngIf="
                        programExternalReferenceId.invalid &&
                        (programExternalReferenceId.dirty || programExternalReferenceId.touched)
                    "
                    class="alert alert-danger"
                >
                </mat-error>
            </mat-form-field>
        </div>

        <!--- Classic Program Name --->

        <div class="form-entry">
            <mat-form-field appearance="outline" floatLabel="always">
                <mat-label for="classicProgramName">{{ 'program.create.classic_program_name' | translate }}</mat-label>
                <input
                    matInput
                    id="classicProgramName"
                    #classicProgramName="ngModel"
                    type="text"
                    [ngClass]="{
                        failure: classicProgramName.invalid && (classicProgramName.dirty || classicProgramName.touched)
                    }"
                    name="classicProgramName"
                    placeholder="{{ 'program.create.placeholder.classic_program_name' | translate }}"
                    maxlength="80"
                    [(ngModel)]="program.classicProgramName"
                    data-automation="classicProgramName"
                    data-url-persist
                />
                <mat-error
                    *ngIf="classicProgramName.invalid && (classicProgramName.dirty || classicProgramName.touched)"
                    class="alert alert-danger"
                >
                </mat-error>
            </mat-form-field>
        </div>

        <!--- Timezone --->

        <div class="form-entry">
            <mat-form-field appearance="outline" floatLabel="always">
                <mat-label>{{ 'common.timezone' | translate }}</mat-label>
                <mat-select
                    id="timezone"
                    name="timezone"
                    #timezone="ngModel"
                    [ngClass]="{
                        failure: timezone.invalid && (timezone.dirty || timezone.touched),
                        'create-select': true
                    }"
                    [(ngModel)]="program.timezone"
                    data-automation="timezone"
                    required
                >
                    <mat-option *ngFor="let timezone of timezones" [value]="timezone.timezoneName">
                        {{ timezone.displayLabel }}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="timezone.invalid && (timezone.dirty || timezone.touched)" class="alert alert-danger">
                    <mat-error *ngIf="timezone.errors.required">
                        {{ 'common.validation.required' | translate }}
                    </mat-error>
                </mat-error>
            </mat-form-field>
        </div>

        <!--- Locale --->
        <div class="form-entry">
            <mat-form-field appearance="outline" floatLabel="always">
                <mat-label>{{ 'common.locale' | translate }}</mat-label>
                <mat-select
                    id="locale"
                    name="locale"
                    #locale="ngModel"
                    [ngClass]="{
                        failure: locale.invalid && (locale.dirty || locale.touched),
                        'create-select': true
                    }"
                    [(ngModel)]="program.locale"
                    data-automation="locale"
                    required
                >
                    <mat-option *ngFor="let locale of multiLocaleConfig.supportedLocales" [value]="locale.localeName">{{
                        locale.displayLabel
                    }}</mat-option>
                </mat-select>
                <mat-error *ngIf="locale.invalid && (locale.dirty || locale.touched)" class="alert alert-danger">
                    <mat-error *ngIf="locale.errors.required">
                        {{ 'common.validation.required' | translate }}
                    </mat-error>
                </mat-error>
            </mat-form-field>
        </div>

        <!--- Status --->
        <div class="form-entry">
            <mat-form-field appearance="outline" floatLabel="always">
                <mat-label>{{ 'common.status' | translate }}</mat-label>
                <mat-select
                    id="status"
                    name="status"
                    #status="ngModel"
                    [ngClass]="{
                        failure: status.invalid && (status.dirty || status.touched),
                        'create-select': true
                    }"
                    [(ngModel)]="program.status"
                    data-automation="status"
                    required
                >
                    <mat-option *ngFor="let status of statuses" [value]="status.code" id="{{ status.code }}">
                        {{ status.displayLabel }}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="status.invalid && (status.dirty || status.touched)" class="alert alert-danger">
                    <mat-error *ngIf="status.errors.required">
                        {{ 'common.validation.required' | translate }}
                    </mat-error>
                </mat-error>
            </mat-form-field>
        </div>

        <!--- descriptions --->
        <div class="form-entry">
            <multi-locale-input
                name="descriptions"
                data-automation="description"
                [inputLabel]="descriptionLabel"
                [locales]="multiLocaleConfig.supportedLocales"
                [(ngModel)]="program.descriptions"
                [displayLabelKey]="multiLocaleConfig.displayLabelKey"
                [localeKey]="multiLocaleConfig.localeKey"
                [defaultLocale]="multiLocaleConfig.defaultLocale"
                [placeholderText]="descriptionPlaceholder"
                [required]="false"
                [id]="'program_descriptions'"
            >
            </multi-locale-input>
        </div>
    </ng-container>
</div>

<!--- View Mode --->
<ng-container *ngIf="isViewMode">
    <div class="form-entry">
        <mat-label class="info-title">{{ 'program.create.operator' | translate }}</mat-label>
        <mat-label class="input-entry" data-automation="operatorId">
            {{ operatorDisplayLabel }}
        </mat-label>
    </div>

    <div class="form-entry">
        <mat-label for="displayLabel" class="info-title"
            >{{ 'program.create.program_display_label' | translate }}:</mat-label
        >
        <mat-label class="input-entry" data-automation="displayLabel">
            {{ getProgramDisplayLabel() }}
        </mat-label>
    </div>

    <div class="form-entry">
        <mat-label for="programName" class="info-title"
            >{{ 'program.create.program_program_name' | translate }}:</mat-label
        >
        <mat-label class="input-entry" data-automation="programName">
            {{ program.programName }}
        </mat-label>
    </div>

        <div class="form-entry">
        <mat-label for="shortDisplayLabel" class="info-title"
            >{{ 'program.create.short_display_label' | translate }}:</mat-label
        >
        <mat-label class="input-entry" data-automation="shortDisplayLabel">
            {{ getShortDisplayLabel() }}
        </mat-label>
    </div>

    <div class="form-entry">
        <mat-label for="classicProgramName" class="info-title"
            >{{ 'program.create.classic_program_name' | translate }}:</mat-label
        >
        <mat-label class="input-entry" data-automation="classicProgramName">
            {{
                program.classicProgramName
                    ? program.classicProgramName
                    : ('program.view.no.classic_program_name' | translate)
            }}
        </mat-label>
    </div>

    <div class="form-entry">
        <mat-label for="programExternalReferenceId" class="info-title"
            >{{ 'program.create.program_external_reference_id' | translate }}:</mat-label
        >
        <mat-label class="input-entry" data-automation="externalReferenceId">
            {{
                program.programExternalReferenceId
                    ? program.programExternalReferenceId
                    : ('program.view.no.program_external_reference_id' | translate)
            }}
        </mat-label>
    </div>

    <div class="form-entry">
        <mat-label class="info-title">{{ 'common.timezone' | translate }}:</mat-label>
        <mat-label class="input-entry" data-automation="timezoneSelector">
            {{ getTimezoneForProgram().displayLabel }}
        </mat-label>
    </div>

    <div class="form-entry">
        <mat-label class="info-title">{{ 'common.locale' | translate }}:</mat-label>
        <mat-label class="input-entry" data-automation="localeSelector">
            {{ getLocaleForProgram().displayLabel }}
        </mat-label>
    </div>

    <div class="form-entry">
        <mat-label class="info-title">{{ 'common.status' | translate }}:</mat-label>
        <mat-label class="input-entry" data-automation="statusSelector">
            {{ program.status }}
        </mat-label>
    </div>

    <div class="form-entry">
        <mat-label for="description" class="info-title">{{ 'common.description' | translate }}:</mat-label>
        <mat-label class="input-entry" data-automation="description">
            {{ getDescriptions() }}
        </mat-label>
    </div>
</ng-container>
