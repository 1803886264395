import { Injectable } from '@angular/core';
import * as convertKeys from 'convert-keys';
import { Locale } from '../model/locale.model';
import { ApiService } from './api.service';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable()
export class LocalesService {
  locales$: Subject<Locale[]>;
  localeSelectorChanged$: BehaviorSubject<boolean>;

  constructor(private apiService: ApiService) {
    this.locales$ = new Subject<Locale[]>();
    this.localeSelectorChanged$ = new BehaviorSubject<any>(false);
  }

  async fetchLocales() {
    const locales = await this.apiService.get('locales');
    this.locales$.next(convertKeys.toCamel(locales));
  }

  async getLocaleSelectorChanged() {
    this.localeSelectorChanged$.next(true);
  }

  checkForLocale(locale_key: string, locales: Locale[]): Locale {
    return locales.find((locale) => locale.localeName === locale_key);
  }

  getValueFromMultilocaleObject(multilocaleObject: object, userLocale: string, fallbackLocale: string) : string {
    return (multilocaleObject[userLocale] ||
        multilocaleObject[fallbackLocale] ||
        multilocaleObject['en_US'] ||
        multilocaleObject[0] || '-' );
  }
}
