import { ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { TreeNode } from 'enel-tree';
import { Operator } from '../shared/model/operator.model';
import { OperatorsService } from '../shared/services/operators.service';
import { SidenavService } from '../shared/services/sidenav.service';
import { TreeService } from '../shared/services/tree.service';
import { Subscription } from 'rxjs';
import {MatIconRegistry} from "@angular/material/icon";
import {DomSanitizer} from "@angular/platform-browser";

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
})
export class ListComponent implements OnDestroy {
  operators: Operator[];
  selection: TreeNode<any>[];
  selected: TreeNode<any>;
  selectedTreeNodes = new Set<TreeNode<any>>();
  loading = true;
  iconIdentifier = 'drType';
  iconDefinitions = {
    Operator: 'ADMIN',
    Program: 'PATH',
    Product: 'PLANS'
  }

  showCloneButton = false;
  selectedRowType: any;
  messages: any[] = [];
  subscription: Subscription;
  operatorSubscription: Subscription;
  disableCloneButton: boolean;

  selectedNode = '';

  searchText = '';

  selectedState: string = '-1';

  constructor(
    private router: Router,
    private operatorsService: OperatorsService,
    private sidenavService: SidenavService,
    private treeService: TreeService,
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer,
    changeDetectorRef: ChangeDetectorRef
  ) {
    iconRegistry.addSvgIcon('ADMIN', sanitizer.bypassSecurityTrustResourceUrl('/assets/svgs/admin.svg'));
    iconRegistry.addSvgIcon('PATH', sanitizer.bypassSecurityTrustResourceUrl('/assets/svgs/path.svg'));
    iconRegistry.addSvgIcon('PLANS', sanitizer.bypassSecurityTrustResourceUrl('/assets/svgs/plans.svg'));
    iconRegistry.addSvgIcon('SEARCH', sanitizer.bypassSecurityTrustResourceUrl('/assets/svgs/search-icon.svg'));
    this.operatorSubscription = this.operatorsService.operators$.subscribe((operators) => {
      if (operators.length > 0) {
        //Set new tree nodes
        this.operators = operators
        if(treeService.hasCurrentNode() && treeService.getCurrentNode() != this.selectedState) {
          this.selectNode(treeService.getCurrentNode(), false);
        }
        if(treeService.hasCurrentNode() && treeService.getCurrentNode() == this.selectedState && Array.from(this.selectedTreeNodes).find(node => node.id != this.selectedState)) {
          this.selectNode(treeService.getCurrentNode(), false);
        }
        changeDetectorRef.detectChanges()
      }
    });
    this.operatorsService.operatorsLoading$.subscribe((value) => {
      this.loading = value;
    });
    this.operatorsService.fetchOperators();
    this.treeService.selectedTreeNode$.subscribe((id) => {
      // If tree is already loaded
      if(!this.loading && this.selectedState != id) {
        this.selectNode(id, true);
        changeDetectorRef.detectChanges()
      }
    });
    this.subscription = this.sidenavService.getMessage().subscribe((message) => {
      if (message) {
        this.disableCloneButton = message.flag;
      }
    });
  }

  handleSelection(nodes: TreeNode<any>[]) {
    this.showCloneButton = nodes.length > 0;
    this.selection = nodes;
    this.selected = this.selection[0];
    this.selectedRowType = this.selected.drType;
    if(nodes.length && nodes[0].id != this.selectedState) {
      this.selectedState = this.selected.id;
      if (this.showCloneButton) {
        this.onRowClicked(this.selected.id, this.selectedRowType);
      }
    }
  }

  handleDeselection(nodes: TreeNode<any>[]) {
    this.selection = nodes;
    this.showCloneButton = this.selection.length > 0;
  }

  selectNode(id: string, loaded: boolean) {
    if(loaded) {
      this.selectedNode = id;
    }
    else {
      this.selectedTreeNodes = new Set(this.findNodesOnTreeByIds([id], this.operators));
    }
    this.selectedState = id;
  }

  onRowClicked(id, drType) {
    this.treeService.setSelectedTreeNode(id);
    this.router.navigate([`details/${id}/view`], { queryParams: { drType } });
  }

  ngOnDestroy(): void {
    this.operatorSubscription.unsubscribe();
    this.subscription.unsubscribe();
  }

  routeToCreatePageClone() {
    const drType = this.selectedRowType;
    this.sidenavService.closeIfMobile();
    this.router.navigate([`create`], { queryParams: { drType, clone: this.selected.id } });
  }

  routeToCreatePage() {
    this.sidenavService.closeIfMobile();
    this.router.navigate([`create`], {});
  }
  findNodesOnTreeByIds(nodeIds: any[], tree: any[]) {
    const foundNodes = [];
    const mapIds = new Map<string, any>();
    nodeIds.forEach((id) => {
      mapIds.set(id, false);
    });
    this.searchTree(mapIds, tree, foundNodes);
    return foundNodes;
  }

  searchTree(mapIds, tree, foundNodes) {
    for (let node of tree) {
      let allFound = true;
      mapIds.forEach((value) => {
        allFound = value ? allFound : false;
      });
      if (allFound) {
        break;
      }
      if (mapIds.get(node.id) !== undefined) {
        foundNodes.push(node);
        mapIds.set(node.id, true);
      }
      if (node.children) {
        this.searchTree(mapIds, node.children, foundNodes);
      }
    }
  }
}
