<div data-automation="comUIPanel" ngModelGroup="dispatchableFields">
    <ng-container>
        <div class="form-header form-header-subsection">
            {{ 'common.product.customer_offer_management_ui' | translate }}
            <div class="label-container">
                <mat-icon
                    svgIcon="enelx:general-info"
                    data-automation="viewTitleTooltip"
                    class="tooltip-icon icon-container"
                    [matTooltipPosition]="'below'"
                    matTooltip="{{ 'common.tooltip.product.title' | translate }}"
                ></mat-icon>
            </div>
        </div>
        <!--- CREATE/EDIT --->
        <div *ngIf="(isCreateMode || isEditMode) && product" class="panel-content">
            <div class="form-group">
                <!--- confirmation message --->
                <div class="form-entry-full-width pad-2-b">
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>{{ 'common.confirmation_message' | translate }}</mat-label>
                        <input
                            matInput
                            id="confirmationMessage"
                            name="confirmationMessage"
                            [(ngModel)]="product.confirmationMessage"
                            data-automation="confirmationMessage"
                            data-url-persist
                            placeholder="{{ 'product.create.placeholder.confirmation_message' | translate }}"
                        />

                        <mat-icon
                            matSuffix
                            svgIcon="enelx:general-info"
                            class="tooltip-icon"
                            [matTooltipPosition]="'below'"
                            matTooltip="{{ 'common.confirmation_message.tooltip' | translate }}"
                        ></mat-icon>
                    </mat-form-field>
                </div>

                <mat-grid-list cols="2" rowHeight="30px">
                    <mat-grid-tile>
                        <!--- hide baselines tab --->
                        <div class="checkbox-wrapper">
                            <mat-checkbox
                                id="hideBaselinesTab"
                                #hideBaselinesTab="ngModel"
                                class="input-entry"
                                name="hideBaselinesTab"
                                [(ngModel)]="product.prezConf.hideBaselinesTab"
                                data-automation="hideBaselinesTab"
                                data-url-persist
                            >
                                {{ 'common.prez_config.hide_baselines_tab' | translate }}
                            </mat-checkbox>

                            <mat-icon
                                matSuffix
                                svgIcon="enelx:general-info"
                                class="tooltip-icon"
                                [matTooltipPosition]="'below'"
                                matTooltip="{{ 'common.prez_config.hide_baselines_tab.tooltip' | translate }}"
                            ></mat-icon>
                        </div>
                    </mat-grid-tile>
                    <mat-grid-tile>
                        <!--- show schedule offer tab --->
                        <div class="checkbox-wrapper">
                            <mat-checkbox
                                id="hideScheduleTab"
                                #hideScheduleTab="ngModel"
                                class="input-entry"
                                name="hideScheduleTab"
                                [(ngModel)]="product.defaultNominationSchedule"
                                data-automation="hideScheduleTab"
                                data-url-persist
                            >
                                {{ 'common.prez_config.hide_schedule_tab' | translate }}
                            </mat-checkbox>

                            <mat-icon
                                matSuffix
                                svgIcon="enelx:general-info"
                                class="tooltip-icon"
                                [matTooltipPosition]="'below'"
                                matTooltip="{{ 'common.prez_config.hide_schedule_tab.tooltip' | translate }}"
                            ></mat-icon>
                        </div>
                    </mat-grid-tile>
                    <mat-grid-tile>
                        <!--- hide cleared offers tab --->
                        <div class="checkbox-wrapper">
                            <mat-checkbox
                                id="hideClearedTab"
                                #clearedTab="ngModel"
                                class="input-entry"
                                name="hideClearedTab"
                                [(ngModel)]="product.prezConf.hideClearedTab"
                                data-automation="hideClearedTab"
                                data-url-persist
                            >
                                {{ 'common.prez_config.clearedTab' | translate }}
                            </mat-checkbox>

                            <mat-icon
                                matSuffix
                                svgIcon="enelx:general-info"
                                class="tooltip-icon"
                                [matTooltipPosition]="'below'"
                                matTooltip="{{ 'common.prez_config.clearedTab.tooltip' | translate }}"
                            ></mat-icon>
                        </div>
                    </mat-grid-tile>
                    <mat-grid-tile>
                        <!--- provide change reason --->
                        <div class="checkbox-wrapper">
                            <mat-checkbox
                                id="provideChangeReason"
                                #provideChangeReason="ngModel"
                                class="input-entry"
                                name="provideChangeReason"
                                [(ngModel)]="product.gateRules.postGateEdits.provideChangeReason"
                                data-automation="provideChangeReason"
                                data-url-persist
                            >
                                {{ 'common.prez_config.provide_change_reason' | translate }}
                            </mat-checkbox>

                            <mat-icon
                                matSuffix
                                svgIcon="enelx:general-info"
                                class="tooltip-icon"
                                [matTooltipPosition]="'below'"
                                matTooltip="{{ 'common.prez_config.provide_change_reason.tooltip' | translate }}"
                            ></mat-icon>
                        </div>
                    </mat-grid-tile>
                </mat-grid-list>
            </div>
        </div>

        <!--- VIEW --->
        <div *ngIf="isViewMode && product" class="panel-content">
            <div class="form-group">
                <!--- confirmation message --->
                <div class="form-entry">
                    <mat-label class="info-title"
                        >{{ 'common.confirmation_message' | translate }}

                        <mat-icon
                            svgIcon="enelx:general-info"
                            data-automation="viewTitleTooltip"
                            class="tooltip-icon icon-container"
                            [matTooltipPosition]="'below'"
                            matTooltip="{{ 'common.confirmation_message.tooltip' | translate }}"
                        ></mat-icon>
                    </mat-label>
                    <mat-label class="input-entry" data-automation="confirmationMessage">
                        {{ product.confirmationMessage }}
                    </mat-label>
                </div>

                <div class="checkbox-container">
                    <!--- hide baselines tab --->
                    <div class="form-entry">
                        <mat-label class="info-title"
                            >{{ 'common.prez_config.hide_baselines_tab' | translate }}

                            <mat-icon
                                svgIcon="enelx:general-info"
                                data-automation="viewTitleTooltip"
                                class="tooltip-icon icon-container"
                                [matTooltipPosition]="'below'"
                                matTooltip="{{ 'common.prez_config.hide_baselines_tab.tooltip' | translate }}"
                            ></mat-icon>
                        </mat-label>
                        <mat-label class="input-entry" data-automation="hideBaselinesTab">
                            {{ product.prezConf.hideBaselinesTab }}
                        </mat-label>
                    </div>

                    <!--- show schedule offer tab --->
                    <div class="form-entry">
                        <mat-label class="info-title"
                            >{{ 'common.prez_config.hide_schedule_tab' | translate }}

                            <mat-icon
                                svgIcon="enelx:general-info"
                                data-automation="viewTitleTooltip"
                                class="tooltip-icon icon-container"
                                [matTooltipPosition]="'below'"
                                matTooltip="{{ 'common.prez_config.hide_schedule_tab.tooltip' | translate }}"
                            ></mat-icon>
                        </mat-label>
                        <mat-label class="input-entry" data-automation="hideScheduleTab">
                            {{ product.defaultNominationSchedule }}
                        </mat-label>
                    </div>

                    <!--- hide cleared offers tab --->
                    <div class="form-entry">
                        <mat-label class="info-title"
                            >{{ 'common.prez_config.clearedTab' | translate }}

                            <mat-icon
                                svgIcon="enelx:general-info"
                                data-automation="viewTitleTooltip"
                                class="tooltip-icon icon-container"
                                [matTooltipPosition]="'below'"
                                matTooltip="{{ 'common.prez_config.clearedTab.tooltip' | translate }}"
                            ></mat-icon>
                        </mat-label>
                        <mat-label class="input-entry" data-automation="hideClearedOffersTab">
                            {{ product.prezConf.hideClearedTab }}
                        </mat-label>
                    </div>

                    <!--- provide change reason --->
                    <div class="form-entry">
                        <mat-label class="info-title"
                            >{{ 'common.prez_config.provide_change_reason' | translate }}

                            <mat-icon
                                svgIcon="enelx:general-info"
                                data-automation="viewTitleTooltip"
                                class="tooltip-icon icon-container"
                                [matTooltipPosition]="'below'"
                                matTooltip="{{ 'common.prez_config.provide_change_reason.tooltip' | translate }}"
                            ></mat-icon>
                        </mat-label>
                        <mat-label class="input-entry" data-automation="provideChangeReason">
                            {{ product.gateRules.postGateEdits.provideChangeReason }}
                        </mat-label>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
</div>
